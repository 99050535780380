import React, {useEffect, useState} from 'react'
import {documentFilterState, selectedDocumentTypeState} from '../../../../state/document-filters'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {DropdownData} from '../../../../types'
import {IdEnum} from '../../../../types/insurance-provider'
import {FilterEnum} from '../../../Member/Enums/FilterEnum'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {useDocumentApi} from '../../../../hooks/api/document'
import {useApiValue} from '../../../../hooks/api'

const TypeDropdown = () => {
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const {getDocumentTypes} = useDocumentApi()
	const {data} = useApiValue(documentFilterState, getDocumentTypes, [])

	useEffect(() => {
		if (data) {
			const next = [
				{value: IdEnum.DefaultId, label: FilterEnum.DefaultFilterAll},
				...data.map((x: DropdownData) => ({
					value: x.id,
					label: x.name
				}))
			]
			setMenuItems(next)
		}
	}, [data])

	return (
		<StatefulSelectFilterCard
			state={selectedDocumentTypeState}
			title="Type"
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {TypeDropdown}



