import {useRecoilValue} from 'recoil'
import {selectedProductTypeUpload} from '../../../state/upload-data'
import React, {useEffect, useState} from 'react'
import classNames from 'classnames'
import {Button} from 'reactstrap'
import {rpDynamicProduct, rpStandardProduct} from '../../../types/upload'
import {useDocumentApi} from '../../../hooks/api/document'
import {useApiValue} from '../../../hooks/api'
import {documentFilterState} from '../../../state/document-filters'

interface DownloadCsvTemplateProps {
	loadingState: boolean
}

const DownloadCsvTemplate = (props: DownloadCsvTemplateProps) => {

	const {getBlobInfo} = useDocumentApi()
	const {data: blobInfo} = useApiValue(documentFilterState, getBlobInfo, [])
	const [downloadUrl, setDownloadUrl] = useState<string>('')

	const productType = useRecoilValue(selectedProductTypeUpload)

	useEffect(() => {
		if (blobInfo) {

			const {sasToken, baseUrl} = blobInfo
			const type = productType === rpStandardProduct?.value
				? rpStandardProduct.label
				: rpDynamicProduct?.label

			const next = `${baseUrl}/dashboard-upload-csv-templates/${type} Upload Template.csv${sasToken}`
			setDownloadUrl(next)
		}
	}, [blobInfo, productType])

	const handleDownload = () => {
		if (downloadUrl) {
			window.location.href = downloadUrl
		}
	}

	return (
		<Button
			onClick={handleDownload}
			className="btn btn-md float-right"
			color="dark"
			disabled={props.loadingState}
		>
			Download Template
			<i className={classNames('tim-icons', 'icon-cloud-download-93')}/>
		</Button>
	)
}

export {DownloadCsvTemplate}