import React, {memo} from 'react'
import {MainChartComponent} from './MainChartComponent'
import useGetCurrencyChartOptions from '../../../hooks/useGetCurrencyChartOptions'
import {tooltipList} from '../../../../../utils/chart'
import {useMemberApi} from '../../../../../hooks/api/member'
import {useApiValue} from '../../../../../hooks/api'
import {memberFilterState} from '../../../../../state/memberFilterState'
import {defaultChartSummaryData} from '../../../../../types'

const TransactionValueChart = memo(() => {
	const {getTransactionValueChartData, getTransactionValueChartSummaryData} = useMemberApi()
	const {
		data: chartData,
		isLoading: chartLoading
	} = useApiValue(memberFilterState, getTransactionValueChartData, [])
	const {
		data: summaryData,
		isLoading: summaryLoading
	} = useApiValue(memberFilterState, getTransactionValueChartSummaryData, defaultChartSummaryData)
	const options = useGetCurrencyChartOptions()

	return (
		<MainChartComponent
			chartData={chartData}
			isCurrency={true}
			isLoading={chartLoading || summaryLoading}
			options={options}
			summaryData={summaryData}
			title={`transaction value`}
			tooltip={tooltipList.TransactionValue}
		/>
	)
})

export {TransactionValueChart}
