import React from 'react'
import {Col, Row} from 'reactstrap'
import {Filters} from './components/Filters'
import {DocumentTable} from './components/DocumentTable'

const DocumentView = () => {
	return (
		<div className="content">
			<Row className="pb-3">
				<Col>
					<Filters/>
				</Col>
			</Row>
			<Row className="pb-3">
				<Col>
					<DocumentTable/>
				</Col>
			</Row>
		</div>
	)
}

export default DocumentView
