import React from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import {toUsd} from '../../../utils/number'
import DataTable, {TableColumn} from 'react-data-table-component'
import {Button} from 'reactstrap'

const ExportButton = () => {
	return (
		<div className="d-flex flex-fill justify-content-end align-items-center">
			<Button
				size="sm"
			>
				<i className="tim-icons icon-upload pr-2"/>
				Export
			</Button>
		</div>
	)
}

const ContentPerformanceTable = () => {
	return (
		<TableCard title="Content Performance" headerContent={<ExportButton/>}>
			<div>
				<DataTable
					data={tableData}
					columns={columns}
					theme="dark"
				/>
			</div>
		</TableCard>
	)
}

export default ContentPerformanceTable

const columns: TableColumn<any> [] = [
	{
		name: 'Sales Presentation Version',
		selector: (row: any) => row.id,
		center: true,
		cell: (row: any) => {
			const {id} = row
			return (
				<div className="d-flex flex-row justify-content-between w-100">
					<div>
						<span>{id}</span>
					</div>
					<div>
						<a href={`#`}>View</a>
					</div>
				</div>
			)
		}
	},
	{
		name: 'Sales Volume',
		selector: (row: any) => row.salesVolume,
		center: true
	},
	{
		name: 'Sales Value',
		selector: (row: any) => row.salesValue,
		center: true,
		cell: (row: any) => toUsd(row.salesValue)
	},
	{
		name: 'Average Price',
		selector: (row: any) => row.averagePrice,
		center: true,
		cell: (row: any) => toUsd(row.averagePrice)
	},
	{
		name: 'Opportunity',
		selector: (row: any) => row.opportunity,
		center: true,
		cell: (row: any) => toUsd(row.opportunity)
	},
	{
		name: 'Conversion',
		selector: (row: any) => row.conversion,
		center: true,
		cell: (row: any) => `${row.conversion}%`
	},
	{
		name: 'Revenue',
		selector: (row: any) => row.revenue,
		center: true,
		cell: (row: any) => toUsd(row.revenue)
	},
	{
		name: 'Revenue per $',
		selector: (row: any) => row.revenueValue,
		center: true,
		cell: (row: any) => toUsd(row.revenueValue)
	},
	{
		name: 'No. of Active Tests',
		selector: (row: any) => row.activeTests,
		center: true
	},
	{
		name: 'Impact',
		selector: (row: any) => row.impact,
		center: true,
		cell: (row: any) => `${row.impact}%`
	}
]

const tableData = [
	{
		id: '1.1',
		salesVolume: 20,
		salesValue: 30,
		averagePrice: 2.5,
		opportunity: 7,
		conversion: 30,
		revenue: 500,
		revenueValue: 400,
		activeTests: 4,
		impact: 40
	},
	{
		id: '1.2',
		salesVolume: 20,
		salesValue: 30,
		averagePrice: 2.5,
		opportunity: 7,
		conversion: 30,
		revenue: 500,
		revenueValue: 400,
		activeTests: 4,
		impact: 40
	},
	{
		id: '1.3',
		salesVolume: 20,
		salesValue: 30,
		averagePrice: 2.5,
		opportunity: 7,
		conversion: 30,
		revenue: 500,
		revenueValue: 400,
		activeTests: 4,
		impact: 40
	},
	{
		id: '1.4',
		salesVolume: 20,
		salesValue: 30,
		averagePrice: 2.5,
		opportunity: 7,
		conversion: 30,
		revenue: 500,
		revenueValue: 400,
		activeTests: 4,
		impact: 40
	}
]
