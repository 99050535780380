import React from 'react'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {integrationMigrationState} from '../../../../state/integration'
import {IntegrationMigration} from '../../../../types/integration'

const IntegrationMigrationFilter = () => {

	return (
		<StatefulSelectFilterCard
			state={integrationMigrationState}
			title={'Integration'}
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {IntegrationMigrationFilter}

const menuItems = [
	{value: -1, label: 'All'},
	{value: IntegrationMigration.Migration, label: 'Migration'},
	{value: IntegrationMigration.NewIntegration, label: 'New Integration'},
]