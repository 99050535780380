import React from 'react'
import {Col, Row} from 'reactstrap'
import {DateFilter} from './DateFilter'
import {RegionFilter} from './RegionFilter'
import {TerritoryFilter} from './TerritoryFilter'
import {CurrencyFilter} from './CurrencyFilter'
import {ProductFilter} from './ProductFilter'
import {MemberFilter} from './MemberFilter'

export const Filters = () => {
	return (
		<Row>
			<Col className="pb-1">
				<DateFilter/>
			</Col>
			<Col className="pb-1">
				<TerritoryFilter/>
			</Col>
			<Col className="pb-1">
				<RegionFilter/>
			</Col>
			<Col className="pb-1">
				<CurrencyFilter/>
			</Col>
			<Col className="pb-1">
				<ProductFilter/>
			</Col>
			<Col className="pb-1">
				<MemberFilter/>
			</Col>
		</Row>
	)
}