import React, {useEffect, useState} from 'react'
import {selectedMemberUpload, selectedProductTypeUpload} from '../../../../state/upload-data'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {
	IdEnum,
	rpDynamicProduct,
	rpStandardProduct,
	uploadProductTypes
} from '../../../../types/upload'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {FilterCard} from '../../../../components/Card/FilterCard'
import {CardBody} from 'reactstrap'

const ProductTypeDropdown = () => {

	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])

	const selectedMember = useRecoilValue(selectedMemberUpload)
	const setValue = useSetRecoilState(selectedProductTypeUpload)

	useEffect(() => {

		if (!selectedMember) {
			setMenuItems(uploadProductTypes)
			return
		}

		let next = [] as SelectFilterItem[]

		if (selectedMember.isRpEnabled && rpStandardProduct) {
			next.push(rpStandardProduct)
		}

		if (selectedMember.isDpEnabled && rpDynamicProduct) {
			next.push(rpDynamicProduct)
		}

		if (next.length === 0 && rpStandardProduct) {
			next.push(rpStandardProduct)
		}

		if (next.length === 1) {
			setValue(next[0].value)
		}

		setMenuItems(next)
	}, [selectedMember])

	if (menuItems.length === 1) {
		return (
			<FilterCard
				title="Product Type"
			>
				<CardBody>
					{menuItems[0].label}
				</CardBody>
			</FilterCard>
		)
	}

	return (
		<StatefulSelectFilterCard
			state={selectedProductTypeUpload}
			title="Product Type"
			items={menuItems}
			defaultValue={IdEnum.DefaultId}
			showDefault={false}
		/>
	)
}

export {ProductTypeDropdown}
