import React, {useEffect, useState} from 'react'
import {FilterEnum, IdEnum} from "../../../../types/insurance-provider";
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {selectedMainProductIdStateInsurance} from "../../../../state/insurance-provider-filters";
import {MainProduct} from "../../../../types";
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const ProductDropdown = () => {
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const {getProducts} = useInsuranceApi();
	const {data} = useApiValue(insuranceProviderFilterState, getProducts, [])
	
	useEffect(() => {
		if(data){
			const next = [
				{value: IdEnum.DefaultId, label: FilterEnum.DefaultFilterAll},
				...data.map((x: MainProduct) => ({
					value: x.id,
					label: x.productName
				}))
			]
			setMenuItems(next)
		}
	}, [data])
	
	return (
		<StatefulSelectFilterCard
			state={selectedMainProductIdStateInsurance}
			title="Product"
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {ProductDropdown}