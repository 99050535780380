import React, {useEffect, useState} from 'react'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {selectedLocationStateInsurance} from "../../../../state/insurance-provider-filters"
import {FilterEnum} from "../../../../types/insurance-provider";
import {Location} from "../../../../types";
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const LocationDropdown = () => {
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const {getLocations} = useInsuranceApi();
	const {data, isLoading} = useApiValue(insuranceProviderFilterState, getLocations, [])
	
	useEffect(() => {
		if(data && !isLoading){
			const next = [
				{value: FilterEnum.DefaultFilterAll, label: FilterEnum.DefaultFilterAll},
				...data.map((x: Location) => ({
					value: x.id,
					label: x.name
				}))
			]
			setMenuItems(next)
		}
	}, [data,isLoading])
	
	return (
		<StatefulSelectFilterCard
			title="Location"
			items={menuItems}
			state={selectedLocationStateInsurance}
			showDefault={false}
		/>
	)
}

export {LocationDropdown}