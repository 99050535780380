import React from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import classNames from 'classnames'
import {toUsd} from '../../../utils/number'

const PriceChangeLogTable = () => {
	return (
		<TableCard
			title="Price Change Log"
		>
			<div>
				<DataTable
					data={tableData}
					columns={columns}
					theme="dark"
				/>
			</div>
		</TableCard>
	)
}

export default PriceChangeLogTable

interface ComparisonCellProps {
	current: number
	previous: number,
	format: (value: any) => string
}

const ComparisonCell = (props: ComparisonCellProps) => {
	const {current, previous, format} = props
	const hasIncreased = current > previous

	const indicator = () => {
		if (current === previous) {
			return null
		}
		if (hasIncreased) {
			return '+'
		}
		return '-'
	}

	return (
		<div className="d-flex align-items-center justify-content-center w-100">
			<i className={classNames('tim-icons', 'pr-2', {
				'icon-minimal-up': hasIncreased,
				'icon-minimal-down': !hasIncreased,
				'up': hasIncreased,
				'down': !hasIncreased,
			})}/>
			<span className={classNames({
				'text-success': hasIncreased,
				'text-error': !hasIncreased
			})}>{indicator()} {format(current)}</span>
		</div>
	)
}

const columns: TableColumn<any> [] = [
	{
		name: 'Date of Change',
		selector: (row: any) => row.date,
		center: true
	},
	{
		name: 'Status',
		selector: (row: any) => row.member,
		center: true
	},
	{
		name: 'Currency',
		selector: (row: any) => row.currency,
		center: true
	},
	{
		name: 'Risk Type',
		selector: (row: any) => row.riskType,
		center: true
	},
	{
		name: 'Country',
		selector: (row: any) => row.country,
		center: true
	},
	{
		name: 'Region',
		selector: (row: any) => row.region,
		center: true
	},
	{
		name: 'Band No.',
		selector: (row: any) => row.bandId,
		center: true
	},
	{
		name: 'Band',
		selector: (row: any) => row.band,
		center: true
	},
	{
		name: 'Time Period',
		selector: (row: any) => row.timePeriod,
		center: true
	},
	{
		name: 'Value',
		selector: (row: any) => row.value,
		center: true
	},
	{
		name: 'Previous Rate',
		selector: (row: any) => row.previousRate,
		center: true
	},
	{
		name: 'New Rate',
		selector: (row: any) => row.newRate,
		center: true
	},
	{
		name: 'CVR Difference',
		center: true,
		cell: (row: any) => {
			const {previousRate, newRate} = row
			return (
				<ComparisonCell
					current={newRate}
					previous={previousRate}
					format={(value) => `${value}%`}
				/>
			)
		}
	},
	{
		name: 'Revenue Difference',
		center: true,
		cell: (row: any) => {
			const {previousRate, newRate} = row
			return (
				<ComparisonCell
					current={newRate}
					previous={previousRate}
					format={(value) => toUsd(value)}
				/>
			)
		}
	}
]

const tableData = [
	{
		date: '01/01/2022',
		member: 'Member A',
		currency: 'USD',
		riskType: 'Flight',
		country: 'USA',
		region: 'Texas',
		bandId: 1,
		band: 'AA',
		timePeriod: '11-20',
		value: 8,
		previousRate: 10,
		newRate: 12
	},
	{
		date: '01/01/2022',
		member: 'Member A',
		currency: 'USD',
		riskType: 'Flight',
		country: 'USA',
		region: 'Texas',
		bandId: 1,
		band: 'AA',
		timePeriod: '11-20',
		value: 8,
		previousRate: 10,
		newRate: 9
	},
	{
		date: '01/01/2022',
		member: 'Member A',
		currency: 'USD',
		riskType: 'Flight',
		country: 'USA',
		region: 'Texas',
		bandId: 1,
		band: 'AA',
		timePeriod: '11-20',
		value: 8,
		previousRate: 10,
		newRate: 12
	}
]
