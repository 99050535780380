import React, {memo} from 'react'
import {useRecoilValue} from 'recoil'
import {Bar} from 'react-chartjs-2'
import {selectedCoreCurrencyStateInsurance} from '../../../../state/insurance-provider-filters'
import {tooltipList} from '../../../../utils/chart'
import {ChartCard} from '../../../../components/Card/ChartCard'
import {useGetBarChartData} from '../../hooks/useGetBarChartData'
import {useInsuranceApi} from '../../../../hooks/api/insurance'

const RollingRiskChart = memo(() => {
	const {getRollingRiskChartData} = useInsuranceApi()
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateInsurance)
	const {getChartData, chartOptions} = useGetBarChartData(getRollingRiskChartData, true)

	return (
		<ChartCard
			title={`Rolling Risk (${coreCurrencyState})`}
			titleClass="card-title"
			tooltip={tooltipList.RiskByRegion}
		>
			<Bar
				data={getChartData()}
				options={chartOptions}
			/>
		</ChartCard>
	)
})

export {RollingRiskChart}
