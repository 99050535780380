import React from 'react'
import {RegionEU} from './RegionEU';
import {RegionAS} from './RegionAS';
import {RegionNA} from './RegionNA';
import {RegionOC} from './RegionOC';
import {RegionAF} from './RegionAF';
import {RegionSA} from './RegionSA';
import {Col, Row} from 'reactstrap';
import {TableCard} from "../../../../components/Card/TableCard";

const RegionVectorMaps = () => {
    return (
        <TableCard
            title="Regions"
            titleClass="card-title"
            showToggle={true}
        >
            <>
                <Row className="pb-4">
                    <Col className="pb-4" sm={12} md={6} lg={4} w={100}>
                        <RegionAS/>
                    </Col>
                    <Col className="pb-4" sm={12} md={6} lg={4} w={100}>
                        <RegionAF/>
                    </Col>
                    <Col className="pb-4" sm={12} md={6} lg={4} w={100}>
                        <RegionEU/>
                    </Col>
                    <Col className="pb-4" sm={12} md={6} lg={4} w={100}>
                        <RegionNA/>
                    </Col>
                    <Col className="pb-4" sm={12} md={6} lg={4} w={100}>
                        <RegionOC/>
                    </Col>
                    <Col className="pb-4" sm={12} md={6} lg={4} w={100}>
                        <RegionSA/>
                    </Col>
                </Row>
            </>
        </TableCard>
    )
}

export {RegionVectorMaps}
