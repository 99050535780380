import React from 'react'
import Tooltip from '../Tooltip/Tooltip'
import {v4 as uuidv4} from 'uuid'
import LoadingSpinner from '../Spinner/LoadingSpinner'

interface ListValueCardProps {
	header: string,
	tooltipText: string,
	value: string[],
	isLoading: boolean
}

const ListValueCard = (props: ListValueCardProps) => {
	const id = uuidv4()

	return (
		<div className="
			flex flex-col h-full min-h-32 px-5 py-3 relative
			col-span-1
			bg-white
			border border-gray-200 rounded-lg
			md:col-span-2"
		>
			<LoadingSpinner
				isLoading={props.isLoading}
				className="h-full w-full"
			>
				<>
					<div className="
						mb-1
						text-pulse-label-gray text-xs"
					>
						{props.header}
					</div>
					<div className="flex flex-col flex-1">
						{props.value.map((value, index) => (
							<div key={index}>
								<span className="
									align-middle mr-1
									text-base text-pulse-icon-gray
									material-symbols-outlined"
								>
									assignment
								</span>
								<span className="
									align-middle
									text-base text-black"
								>
									{value}
								</span>
							</div>
						))}

					</div>
					{props.tooltipText && (
						<Tooltip
							id={`${id}-popup`}
							tooltipTitle={props.header}
							tooltipText={props.tooltipText}
						/>
					)}
				</>
			</LoadingSpinner>
		</div>
	)
}

export default ListValueCard
