import React from "react";
import {AutoRefresh} from "../../components/AutoRefresh";
import {Col, Row} from "reactstrap";
import {Filters} from "./components/Filters/Filters";
import {Summary} from "./components/Summary/Summary";
import {RevenueChart} from "./components/RevenueChart";
import {ConversionChart} from "./components/ConversionChart";
import {RegionalLeaderboard} from "./components/RegionalLeaderboard";
import {MemberTable} from "./components/MemberTable";
import {RevenuePerDollarChart} from "./components/RevenuePerDollarChart";
import {VerticalLeaderboard} from "./components/VerticalLeaderboard";

const SalesView = () => {
	return (
		<div className="content">
			<AutoRefresh/>
			<Row className={'pb-2'}>
				<Col>
					<Filters/>
				</Col>
			</Row>
			<Row className={'pb-2'}>
				<Col>
					<Summary/>
				</Col>
			</Row>
			<Row className="pb-4">
				<Col>
					<MemberTable/>
				</Col>
			</Row>
			<Row className="pb-4">
				<Col sm={12} md={12}>
					<RevenueChart/>
				</Col>
			</Row>
			<Row className="pb-4">
				<Col sm={12} md={6}>
					<ConversionChart/>
				</Col>
				<Col sm={12} md={6}>
					<RevenuePerDollarChart/>
				</Col>
			</Row>
			<Row className="pb-2">
				<Col sm={12} md={6}>
					<RegionalLeaderboard/>
				</Col>
				<Col sm={12} md={6}>
					<VerticalLeaderboard/>
				</Col>
			</Row>
		</div>
	)
}

export default SalesView