import {usePulseApi} from '../../../hooks/api/pulse'
import {filterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {ExportData} from '../../../types/pulse-data'
import {useExportData} from './useExportData'
import {selectedCoreCurrencyState, selectedGraphFiltersState} from '../../../state/pulse-filters'
import {graphFilterType} from '../../../types/pulse'
import {formatCurrency} from '../../../utils/currency'
import useGetGraphFilterLabel from './useGetGraphFilterLabel'
import {formatNumberMember} from '../../../utils/number'

export default function useExportRevenueTrendsData(): ExportData {
	const {
		getRevenueTrendsChartData
	} = usePulseApi()
	const filter = useRecoilValue(filterState)
	const graphFilters = useRecoilValue(selectedGraphFiltersState)
	const coreCurrencyCode = useRecoilValue(selectedCoreCurrencyState)
	const getGraphFilterLabel = useGetGraphFilterLabel()
	const getData = async (): Promise<any> => {
		const result = await getRevenueTrendsChartData(filter)
		const data = []

		for (let i = 0; i < result.labels.length; i++) {
			const record: any = {
				Date: result.labels[i]
			}

			for (const graphFilter in graphFilters) {
				const filterType = graphFilters[graphFilter]
				const label = getGraphFilterLabel(filterType)

				switch (filterType) {
					case graphFilterType.RefundProtectValue:
					case graphFilterType.GrossBookingValue:
					case graphFilterType.YourRevenue:
						record[label] = (result.chartData && formatCurrency(coreCurrencyCode, result.chartData[filterType][i], 0, 0)) || null
						break
					case graphFilterType.RevenuePerDollar:
						record[label] = (result.chartData && formatCurrency(coreCurrencyCode, result.chartData[filterType][i], 0, 4)) || null
						break
					case graphFilterType.GrossBookingVolume:
					case graphFilterType.RefundProtectVolume:
						record[label] = (result.chartData && formatNumberMember(result.chartData[filterType][i])) || null
						break
					case graphFilterType.AverageConversionRate:
						record[label] = result.chartData && result.chartData[filterType][i] + '%'
						break
					default:
						record[label] = result.chartData && result.chartData[filterType][i]
				}
			}

			data.push(record)
		}

		return data
	}
	const getFileName = () => `RevenueTrends-${moment().toISOString()}`
	const getHeaders = () => {
		const headers = ['Date']

		for (const filter in graphFilters) {
			headers.push(getGraphFilterLabel(graphFilters[filter]))
		}

		return headers
	}

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
