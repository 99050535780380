import {useEffect, FC} from 'react';

const MSClarityScript: FC = () => {
	useEffect(() => {
		const projectId = process.env.REACT_APP_MS_CLARITY;
		if (projectId) {
			(function (c: Window, l: Document, a: string, r: string, i: string, t?: HTMLScriptElement, y?: HTMLScriptElement) {
				(c as any)[a] = (c as any)[a] || function () {
					((c as any)[a].q = (c as any)[a].q || []).push(arguments);
				};
				t = l.createElement(r) as HTMLScriptElement;
				t.async = true;
				t.src = `https://www.clarity.ms/tag/${i}`;
				y = l.getElementsByTagName(r)[0] as HTMLScriptElement;
				y.parentNode?.insertBefore(t, y);
			})(window, document, 'clarity', 'script', projectId);
			const clarityInterval = setInterval(() => {
				if (typeof (window as any).clarity === 'function') {
					clearInterval(clarityInterval);
					(window as any).clarity('set');
				}
			}, 100);
		}
	},);
	return null;
};
export {MSClarityScript};