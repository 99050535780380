import React, {memo, useEffect, useState} from 'react'
import classNames from 'classnames'
import {Tooltip} from 'reactstrap'
import {v4 as uuidv4} from 'uuid'

interface CardTooltipProps {
	tooltip?: string;
	icon?: string;
}

const CardTooltip = memo((props: CardTooltipProps) => {
	const {tooltip, icon = 'icon-bulb-63'} = props
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const [uniqueIdState, setUniqueIdState] = useState('')
	const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

	useEffect(() => {
		setUniqueIdState(`id-${uuidv4()}`)
	}, [])

	return (
		<div className={'float-right'}>
			{
				uniqueIdState && (
					<>
						<i id={uniqueIdState} className={classNames('tim-icons', icon)}/>
						<Tooltip placement="right" isOpen={tooltipOpen} target={uniqueIdState} toggle={toggleTooltip}>
							{tooltip}
						</Tooltip>
					</>
				)
			}
		</div>
	)
})

export {CardTooltip}
