import React, {memo, useEffect, useState} from 'react'
import {ValueDirectionProps, ValueStatusCard} from '../../../components/Card/ValueStatusCard'
import {refundFilterState, ValueStatus} from '../../../types/refund'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'

const TotalReferred = memo(() => {

	const [valueStatusData, setValueStatusData] = useState<ValueDirectionProps[]>([])
	const {getTotalReferredData} = useRefundApi()
	const {data} = useApiValue(refundFilterState, getTotalReferredData, [] as ValueStatus[])

	useEffect(() => {
		if (data) {
			const next: ValueDirectionProps[] = data?.map((x: ValueStatus, i: number) => {
				const {value, isPositive} = x
				return {
					value: i !== 1 ? value : (`(${value})`),
					direction: i !== 1 ? (isPositive ? 'down' : 'up') : null,
					colour: isPositive ? 'green' : 'red'
				}
			})
			setValueStatusData(next)

		}
	}, [data])

	return (
		<ValueStatusCard
			title={'Total Referred'}
			values={valueStatusData}
		/>
	)
})

export {TotalReferred}