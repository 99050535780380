import React, {memo} from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {formatNumberMember} from '../../../../utils/number'
import useGetSummaryValues from '../../hooks/useGetSummaryValues'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'

const TransactionVolume = memo(() => {
	const {totalTransactionVolumeInsured} = useGetSummaryValues()
	const color = useSummaryCardColor()

	return (
		<ValueCard
			title={`Transaction Volume`}
			value={formatNumberMember(parseInt(totalTransactionVolumeInsured.toString()))}
			icon="icon-chart-bar-32"
			tooltip={'Total number of transactions protected.'}
			iconColor={color}
		/>
	)
})

export {TransactionVolume}
