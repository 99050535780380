import React, {useEffect, useState} from 'react'
import {salesFilterState, selectedMembersState} from '../../../../state/sales'
import {MultiSelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import Select from 'react-select'
import {MultiValue} from 'react-select'
import {FilterCard} from '../../../../components/Card/FilterCard'
import classNames from 'classnames'
import {useRecoilState} from 'recoil'
import {useSalesApi} from '../../../../hooks/api/sales'
import {useApiValue} from '../../../../hooks/api'

const MAX_VISIBLE_MEMBERS = 4

export const MemberFilter = () => {
	const [listOpen, setListOpen] = useState<boolean>(false)
	const {getMembers} = useSalesApi()
	const {data} = useApiValue(salesFilterState, getMembers, [])
	const [selectedMembers, setSelectedMembers] = useRecoilState(selectedMembersState)
	const [items, setItems] = useState<MultiSelectFilterItem[]>([])

	useEffect(() => {
		const next = data?.map(x => ({
			value: x.id,
			label: x.name,
			isSelected: selectedMembers.includes(x.id)
		}))
		setItems(next)
	}, [data, selectedMembers])

	const toggleList = () => setListOpen(curr => !curr)

	const handleSelectChange = (newValue: MultiValue<MultiSelectFilterItem>) => {
		const selectedMembers = (newValue as MultiSelectFilterItem[]).map((item) => Number(item.value))

		setSelectedMembers((currentMembers) => {
			if (!selectedMembers.length && currentMembers.length === 1) {
				return []
			}

			const updatedMembers = Array.from(new Set([...currentMembers, ...selectedMembers]))
			if (!selectedMembers.length && currentMembers.length > 1) {
				return updatedMembers.slice(1)
			}

			return updatedMembers.length > 0 ? updatedMembers : []
		})
	}

	const selectedItems = items.filter(x => x.isSelected)
	const menuItems = items.filter(x => !x.isSelected)

	const getItemCountLabel = () => {
		const totalCount = selectedItems.length
		if (totalCount > 1) {
			return `+${totalCount - 1} items`
		}
		return ''
	}

	return (
		<FilterCard
			title="Members"
		>
			<div>
				<Select
					isMulti
					className="react-select"
					classNamePrefix="react-select"
					options={menuItems}
					value={selectedItems[0]}
					onChange={handleSelectChange}
					components={{}}
				/>
				<div className="multi-value-filter-container w-100">
					<div className="multi-value-filter">
						<div className={classNames('multi-value-pill-container cursor-pointer', {'is-open': !listOpen})}
						     onClick={toggleList}>
							<span className="multi-value-filter-pill">{getItemCountLabel()}</span>
						</div>
						<div className={classNames('multi-value-pill-container cursor-pointer', {'is-open': listOpen})}
						     onClick={toggleList}>
							<div className="d-flex flex-row">
								{
									selectedItems?.slice(1, MAX_VISIBLE_MEMBERS).map((x) => {
										return (
											<div key={x.value} className="multi-value-filter-pill">{x.label}</div>
										)
									})
								}
								{
									(selectedItems && selectedItems.length > MAX_VISIBLE_MEMBERS) && (
										<>
											<div className="multi-value-filter-pill">...</div>
											<div className="multi-value-filter-pill">
												{selectedItems.length - MAX_VISIBLE_MEMBERS}
											</div>
										</>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</FilterCard>
	)
}