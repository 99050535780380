import React from 'react'
import PrimaryValueCard from './Common/Cards/PrimaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewBookingValueSummary = () => {
	const {getBookingValueSummary, getBookingRateTrend} = usePulseApi()
	const value = useApiValue(filterState, getBookingValueSummary, 0)
	const trendRate = useApiValue(filterState, getBookingRateTrend, 0)

	return (
		<PrimaryValueCard
			value={value.data}
			trendValue={trendRate.data}
			header="Gross Booking Sales"
			tooltipText="This refers to the total monetary value of all bookings made,
				it represents the total amount generated from all bookings during a specific period,
				serving as a key indicator of overall business revenue potential.
				It includes bookings with Refund Protect and bookings without Refund Protect."
			valueClassName="text-black"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewBookingValueSummary
