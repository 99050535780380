import {atom, selector} from 'recoil'
import moment, {Moment} from 'moment'
import {dateFormat} from '../utils/date'
import {loggedInUserState, userInsuranceProviderDataIdsState} from './auth'
import {DocumentFilter} from '../types'

const keyPrefix = 'document'

export const selectedDocumentTypeState = atom<number>({
	key: `${keyPrefix}SelectedDocumentTypeState`,
	default: 0
})

export const selectedMemberState = atom<number | null>({
	key: `${keyPrefix}SelectedMemberState`,
	default: null
})

export const selectedInsurerState = atom<number | null>({
	key: `${keyPrefix}SelectedInsurerState`,
	default: null
})

export const selectedActorState = atom<number | null>({
	key: `${keyPrefix}SelectedActorState`,
	default: null
})

export const isSelectedActorInsurer = atom<boolean | null>({
	key: `${keyPrefix}IsSelectedActorInsurer`,
	default: null
})

export const dateFromDocumentState = atom<Moment>({
	key: `${keyPrefix}DateFromState`,
	default: moment().startOf('year')
})

export const dateToDocumentState = atom<Moment>({
	key: `${keyPrefix}DateToState`,
	default: moment()
})

export const formattedDateFromDocumentState = selector<string>({
	key: `${keyPrefix}FormattedDateFromState`,
	get: ({get}) => get(dateFromDocumentState).format(dateFormat)
})

export const formattedDateToDocumentState = selector<string>({
	key: `${keyPrefix}FormattedDateToState`,
	get: ({get}) => get(dateToDocumentState).format(dateFormat)
})

export const documentFilterState = selector<DocumentFilter>({
	key: `${keyPrefix}TableFilterState`,
	get: ({get}) => {
		return {
			dateFrom: get(formattedDateFromDocumentState),
			dateTo: get(formattedDateToDocumentState),
			actorId: get(selectedActorState),
			documentType: get(selectedDocumentTypeState),
			loggedInUser: get(loggedInUserState),
			insuranceProviderDataIds: get(userInsuranceProviderDataIdsState),
			isActorInsurer: get(isSelectedActorInsurer)
		}
	}
})

