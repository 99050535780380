import React, {memo} from 'react';
import {Line} from "react-chartjs-2";
import {FixedHeightChartCard} from "../../../../../components/Card/FixedHeightChartCard";
import {ChartSummaryTable} from "../../../../../components/Dashboard/Charts/ChartSummaryTable";
import {ChartHeightValue, tooltipList} from "../../../../../utils/chart";
import {formatNumberMember} from "../../../../../utils/number";
import {
	insuranceProviderFilterState,
	monthsInDateRangeState
} from "../../../../../state/insurance-provider";
import {useInsuranceApi} from "../../../../../hooks/api/insurance";
import {useApiValue} from "../../../../../hooks/api";
import {defaultChartData} from "../../../../../types/insurance-provider";
import {useGetChartData} from "../../../../../hooks/useGetChartDataSummary";

const TransactionVolumeChart = memo(() => {
	const {getTransactionVolumeChartData} = useInsuranceApi()
	const {data} = useApiValue(insuranceProviderFilterState, getTransactionVolumeChartData, defaultChartData)
	const {
		chartOptions,
		getChartData,
		summaryData
	} = useGetChartData(data, monthsInDateRangeState)
	
	const format = (value: any) => {
		return formatNumberMember(value);
	};
	
	return (
		<FixedHeightChartCard
			title="Transaction Volume"
			titleClass="card-title-info"
			height={ChartHeightValue['small']}
			summary={
				<ChartSummaryTable
					data={summaryData}
					format={format}
				/>
			}
			tooltip={tooltipList.TransactionVolume}
		>
			<Line
				data={getChartData()}
				options={chartOptions}
				className="main-chart-max-height"
			/>
		</FixedHeightChartCard>
	);
});

export {TransactionVolumeChart}
