import {useApiActions} from './index'
import {
	ChartSummaryData,
	Currency,
	Location,
	MainProduct,
	Member,
	MemberFilter,
	ProductType,
	RegionSummary,
	TransactionTableFilter
} from '../../types'
import {
	ConversionByValueBandTableModel,
	LastTransactionDates,
	RiskByRegion,
	SummaryResult,
	TransactionTableDataModel
} from '../../types/member'

export const useMemberApi = () => {
	const {callApi} = useApiActions('member')

	const deleteTransaction = async (transactionId: number, user: string): Promise<any> => {
		return await callApi<any, string>({
			method: 'DELETE',
			path: `transaction/${transactionId}?loggedInUser=${user}`
		})
	}

	const getConversionByValueBand = async (filter: MemberFilter): Promise<ConversionByValueBandTableModel[]> => {
		return await callApi<ConversionByValueBandTableModel[], string>({
			query: filter,
			path: 'value-band-conversion-data'
		})
	}

	const getConversionRateChartData = async (filter: MemberFilter): Promise<any[]> => {
		return await callApi<any[], string>({
			query: filter,
			path: 'conversion-rate-chart-data'
		})
	}

	const getConversionRateChartSummaryData = async (filter: MemberFilter): Promise<ChartSummaryData> => {
		return await callApi<ChartSummaryData, string>({
			query: filter,
			path: 'conversion-rate-chart-summary-data'
		})
	}

	const getCurrencies = async (filter: MemberFilter): Promise<Currency[]> => {
		return await callApi<Currency[], string>({
			query: filter,
			path: 'currencies'
		})
	}

	const getEvents = async (filter: MemberFilter): Promise<any> => {
		return await callApi<any, string>({
			query: filter,
			path: 'events'
		})
	}

	const getLastTransactionDates = async (filter: MemberFilter): Promise<LastTransactionDates> => {
		return await callApi<LastTransactionDates, string>({
			query: filter,
			path: 'last-transaction-dates'
		})
	}

	const getLocations = async (filter: MemberFilter): Promise<Location[]> => {
		return await callApi<Location[], string>({
			query: filter,
			path: 'locations'
		})
	}

	const getMembers = async (filter: MemberFilter): Promise<Member[]> => {
		return await callApi<Member[], string>({
			query: filter,
			path: 'members'
		})
	}

	const getProductTypes = async (filter: MemberFilter): Promise<ProductType[]> => {
		return await callApi<ProductType[], string>({
			query: filter,
			path: 'product-types'
		})
	}

	const getProducts = async (filter: MemberFilter): Promise<MainProduct[]> => {
		return await callApi<MainProduct[], string>({
			query: filter,
			path: 'products'
		})
	}

	const getProtectionCostChartData = async (filter: MemberFilter): Promise<any[]> => {
		return await callApi<any[], string>({
			query: filter,
			path: 'protection-cost-chart-data'
		})
	}

	const getProtectionCostChartSummaryData = async (filter: MemberFilter): Promise<ChartSummaryData> => {
		return await callApi<ChartSummaryData, string>({
			query: filter,
			path: 'protection-cost-chart-summary-data'
		})
	}

	const getRegionData = async (filter: MemberFilter, regionId: number): Promise<RegionSummary> => {
		return await callApi<RegionSummary, string>({
			query: filter,
			path: `region-data/${regionId}`
		})
	}

	const getRiskCoverageByRegion = async (filter: MemberFilter): Promise<RiskByRegion[]> => {
		return await callApi<RiskByRegion[], string>({
			query: filter,
			path: 'risk-by-region'
		})
	}

	const getSummaryData = async (filter: MemberFilter): Promise<SummaryResult> => {
		return await callApi<SummaryResult, string>({
			query: filter,
			path: 'summary-data'
		})
	}

	const getTransactionCsvData = async (filter: MemberFilter): Promise<any[]> => {
		return await callApi<any[], string>({
			query: filter,
			path: 'transaction-csv-data'
		})
	}

	const getTransactionValueChartData = async (filter: MemberFilter): Promise<any[]> => {
		return await callApi<any[], string>({
			query: filter,
			path: 'transaction-value-chart-data'
		})
	}

	const getTransactionValueChartSummaryData = async (filter: MemberFilter): Promise<ChartSummaryData> => {
		return await callApi<ChartSummaryData, string>({
			query: filter,
			path: 'transaction-value-chart-summary-data'
		})
	}

	const getTransactionVolumeChartData = async (filter: MemberFilter): Promise<any[]> => {
		return await callApi<any[], string>({
			query: filter,
			path: 'transaction-volume-chart-data'
		})
	}

	const getTransactionVolumeChartSummaryData = async (filter: MemberFilter): Promise<ChartSummaryData> => {
		return await callApi<ChartSummaryData, string>({
			query: filter,
			path: 'transaction-volume-chart-summary-data'
		})
	}

	const getTransactions = async (filter: TransactionTableFilter): Promise<TransactionTableDataModel> => {
		return await callApi<TransactionTableDataModel, string>({
			query: filter,
			path: 'transactions'
		})
	}

	return {
		deleteTransaction,
		getConversionByValueBand,
		getConversionRateChartData,
		getConversionRateChartSummaryData,
		getCurrencies,
		getEvents,
		getLastTransactionDates,
		getLocations,
		getMembers,
		getProducts,
		getProductTypes,
		getProtectionCostChartData,
		getProtectionCostChartSummaryData,
		getRiskCoverageByRegion,
		getRegionData,
		getSummaryData,
		getTransactions,
		getTransactionCsvData,
		getTransactionValueChartData,
		getTransactionValueChartSummaryData,
		getTransactionVolumeChartData,
		getTransactionVolumeChartSummaryData
	}
}
