import React from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable from 'react-data-table-component'
import {Card, Col, Row} from 'reactstrap'
import {useGetMemberTableData} from '../hooks/useMemberOverviewTableStructure'
import {memberTableCurrentPageNumberState, memberTablePageSizeState} from '../../../state/sales'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {MemberTableCsv} from './MemberTableCSV'

const csvDownloadComponent =
	<Card className={'w-25 ml-auto'}>
		<MemberTableCsv/>
	</Card>

export const MemberTable = () => {

	const currentPageNumberState = useRecoilValue(memberTableCurrentPageNumberState)
	const setMemberTablePageSizeState = useSetRecoilState(memberTablePageSizeState)
	const setMemberTableCurrentPageNumberState = useSetRecoilState(memberTableCurrentPageNumberState)

	function pageNumberChanged(clickedPageNumber: any) {
		setMemberTableCurrentPageNumberState(clickedPageNumber)
	}

	function pageSizeChanged(pageSize: any) {
		setMemberTablePageSizeState(pageSize)
	}

	const {rows, columns, count} = useGetMemberTableData()
	return (
		<TableCard
			title="Member Overview"
			headerContent={csvDownloadComponent}>
			<Row>
				<Col className="border-top w-100">
					<DataTable
						dense
						data={rows}
						columns={columns}
						theme="dark"
						pagination
						onChangePage={pageNumberChanged}
						onChangeRowsPerPage={pageSizeChanged}
						paginationDefaultPage={currentPageNumberState}
						paginationTotalRows={count}
						paginationServer
					/>
				</Col>
			</Row>
		</TableCard>
	)
}