import React from 'react'

const ProgressSpinner = () => {
	return (
		<>
			<span className="animate-spin material-symbols-outlined">
				progress_activity
			</span>
		</>
	)
}

export default ProgressSpinner
