import React from 'react'
import {Col, Row} from 'reactstrap'
import {TotalEvents} from './TotalEvents'
import {TransactionVolume} from './TransactionVolume'
import {TransactionValue} from './TransactionValue'
import {ProtectionRate} from './ProtectionRate'
import {ConversionRate} from './ConversionRate'
import {ProtectionCost} from './ProtectionCost'
import useGetMainProductStates from '../../hooks/useGetMainProductStates'
import {LastTransactionDates} from './LastTransactionDates'

const HeaderInfo = () => {
	const {isProductRp} = useGetMainProductStates()
	return (
		<>
			<Row className="pb-3">
				<Col className="ml-auto col-sm-6">
					<LastTransactionDates/>
				</Col>
			</Row>
			<Row>
				{
					!isProductRp && (
						<Col className="pb-1">
							<TotalEvents/>
						</Col>
					)
				}
				<Col className="pb-1">
					<TransactionVolume/>
				</Col>
				<Col className="pb-1">
					<TransactionValue/>
				</Col>
				<Col className="pb-1">
					<ProtectionRate/>
				</Col>
				{
					isProductRp && (
						<Col className="pb-1">
							<ConversionRate/>
						</Col>
					)
				}
				<Col className="pb-1">
					<ProtectionCost/>
				</Col>
			</Row>
		</>
	)
}

export {HeaderInfo}
