import React from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import {Col, Row} from 'reactstrap'
import {ValueDirectionProps} from '../../../components/Card/ValueStatusCard'
import {ValueStatusTableCell} from '../../../components/Table/ValueStatusTableCell'
import {LeaderboardRowData, ValueStatus} from '../../../types/sales'
import {formatNumberWithoutSeparator} from '../../../utils/number'
import {salesFilterState, selectedCoreCurrencyState} from '../../../state/sales'
import {formatCurrency} from '../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {useSalesApi} from '../../../hooks/api/sales'
import {useApiValue} from '../../../hooks/api'


export const RegionalLeaderboard = () => {
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyState)

	const columns: TableColumn<LeaderboardRowData> [] = [
		{
			name: 'Rank',
			selector: ({rank}) => rank
		},
		{
			name: 'Region',
			selector: ({region}) => region
		},
		{
			name: 'Revenue',
			selector: ({revenue}) => revenue,
			cell: (row: any) => formatCurrency(coreCurrencyState, formatNumberWithoutSeparator(row.revenue))
		},
		{
			name: `Revenue Per ${coreCurrencyState}`,
			selector: ({revenue}) => revenue,
			cell: (row: any) => row.revenuePerDollar.toFixed(2)
		},
		{
			name: 'Conversion',
			selector: ({conversion}) => conversion,
			cell: (row: any) => `${row.conversion.toFixed(0)} %`
		},
		{
			name: 'Average Growth',
			selector: (row: any) => row.averageGrowth,
			cell: (row: LeaderboardRowData) => {
				return (
					<>
						{row.averageGrowth &&
							<ValueStatusTableCell
								values={[getValueObject(row.averageGrowth)]}
							/>
						}
					</>
				)
			}
		}
	]

	const {getRegionalLeaderboardChart} = useSalesApi()
	const {data} = useApiValue(salesFilterState, getRegionalLeaderboardChart, [])

	return (
		<TableCard
			title="Regional Leaderboard">
			<Row className="border-top">
				<Col>
					<DataTable
						data={data}
						columns={columns}
						theme="dark"
					/>
				</Col>
			</Row>
		</TableCard>
	)
}

const defaultValueDirectionProps: ValueDirectionProps = {
	value: '0',
	direction: null,
	colour: null
}

const getValueObject = (row: ValueStatus) => {
	if (row === null) {
		return defaultValueDirectionProps
	}

	const {value, isPositive} = row
	return {
		value: `${value} %`,
		direction: isPositive ? 'down' : 'up',
		colour: isPositive ? 'red' : 'green'
	} as ValueDirectionProps
}



