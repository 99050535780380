import React from "react";
import {Col, Row} from "reactstrap";
import {Filters} from "./components/Filters";
import {Summary} from "../Integration/components/Summaries";
import {Charts} from "./components/Charts";
import {IntegrationTable} from "./components/IntegrationTable";
import {StatusChangesTable} from "./components/StatusChangesTable";

const IntegrationView = () => {
    return (
        <div className="content">
            <Row className="pb-3">
                <Col>
                    <Filters/>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col>
                    <Summary/>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col>
                    <Charts/>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col>
                    <IntegrationTable/>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col>
                    <StatusChangesTable/>
                </Col>
            </Row>
        </div>
    )
}

export default IntegrationView