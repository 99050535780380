import React, {useRef, useState} from 'react'
import {
	Button,
	Card,
	CardBody,
	CardTitle,
	Col,
	Input,
	Label,
	Row,
	Alert,
	Spinner
} from 'reactstrap'
import {
	selectedActionTypeUpload,
	selectedMemberUpload,
	selectedProductTypeUpload,
	selectedTimezoneUpload
} from '../../../state/upload-data'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {loggedInUserState} from '../../../state/auth'
import {UploadTransactionBulkResponse} from '../../../types/upload'
import {UploadCsvResponseTable} from './UploadCsvResponseTable'
import {notificationMessageState} from '../../../state/ui'
import {NotificationMessageType} from '../../../types'
import {DownloadCsvTemplate} from './DownloadCsvTemplate'
import {TimezoneDropdown} from './Filters/TimezoneDropdown'
import {useUploadApi} from '../../../hooks/api/upload'

const MAX_FILE_SIZE_MB = 100

const UploadBulk = () => {
	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [uploadResult, setUploadResult] = useState<UploadTransactionBulkResponse | null>(null)
	const [loadingState, setLoadingState] = useState(false)

	const fileInputRef = useRef<HTMLInputElement>(null)

	const member = useRecoilValue(selectedMemberUpload)
	const timezoneId = useRecoilValue(selectedTimezoneUpload)
	const loggedInUser = useRecoilValue(loggedInUserState)
	const uploadProductType = useRecoilValue(selectedProductTypeUpload)
	const actionType = useRecoilValue(selectedActionTypeUpload)
	const setNotification = useSetRecoilState(notificationMessageState)
	const { uploadTransactionCsv } = useUploadApi()

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0]

			if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
				setErrorMessage(`File size exceeds the maximum limit of ${MAX_FILE_SIZE_MB} MB.`)
				event.target.value = ''
				setSelectedFile(null)
				return
			}

			setErrorMessage(null)
			setSelectedFile(file)
			setUploadResult(null)
		}
	}

	const handleUploadCsv = async () => {
		if (!member?.actorId || member?.actorId === -1) {
			setErrorMessage('Please select Member.')
			return
		}

		if (selectedFile === null) {
			return
		}

		try {
			const data = new FormData()
			data.append('file', selectedFile)
			data.append('memberId', member?.actorId.toString() ?? '')
			data.append('actionType', actionType.toString())
			data.append('loggedInUser', loggedInUser ?? '')
			data.append('productType', String(uploadProductType))
			data.append('timezoneId', timezoneId)

			setLoadingState(true)

			const response = await uploadTransactionCsv(data)

			setUploadResult(response)
			setSelectedFile(null)

			if (fileInputRef.current) {
				fileInputRef.current.value = ''
			}
		} catch (error) {
			setNotification({
				message: `Error uploading file: ${error}`,
				type: NotificationMessageType.error
			})
		} finally {
			setLoadingState(false)
		}
	}

	return (
		<>
			<Card className="p-3">
				<Row>
					<Col>
						<CardBody>
							<CardTitle className="text-uppercase">
								<div className="d-flex justify-content-between align-items-center">
									<div className="w-75">
										<Label htmlFor="fileInput">Choose a CSV or Excel file:</Label>
										<Input
											type="file"
											name="uploadCsv"
											className="custom-form-input"
											accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
											onChange={handleFileChange}
											disabled={loadingState}
											innerRef={fileInputRef}
											id="fileInput"
										/>
										<div className="mt-2 w-50">
											<Label>Timezone:</Label>
											<TimezoneDropdown/>
										</div>
									</div>
									<DownloadCsvTemplate
										loadingState={loadingState}
									/>
								</div>
								{
									errorMessage &&
									<Alert color="danger" className="mt-2">
										{errorMessage}
									</Alert>
								}
							</CardTitle>
						</CardBody>
					</Col>
				</Row>
				<Row>
					<Col>
						<Button
							className="btn btn-primary px-5"
							color="primary"
							onClick={handleUploadCsv}
							disabled={!selectedFile || loadingState}
						>
							{
								loadingState
									? <Spinner size="sm" color="light"/>
									: 'Upload'
							}
						</Button>
					</Col>
				</Row>
			</Card>
			{
				loadingState && (
					<h3 className="text-white p-3 rounded position-absolute">
						Processing the transactions, please do not leave the page or refresh.
					</h3>
				)
			}
			{
				uploadResult && (
					<Card className="mt-4">
						<CardBody>
							<div>
								<div className="mb-3">
									<UploadCsvResponseTable
										uploadResponseData={uploadResult}
									/>
								</div>
							</div>
						</CardBody>
					</Card>
				)
			}
		</>
	)
}

export default UploadBulk
