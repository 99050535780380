import SelectFilterCard, {SelectFilterItem} from '../../../components/Dashboard/SelectFilter'
import React from 'react'

const CountryFilter = () => {
	const handleChange = (value: any) => {}

	return (
		<SelectFilterCard
			title="Country"
			items={countries}
			onChange={handleChange}
			icon=""
		/>
	)
}

export default CountryFilter

const countries: SelectFilterItem[] = [
	{value: 1, label: 'United Kingdom'},
	{value: 2, label: 'France'}
]
