import React from 'react'
import {TableCard} from '../../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import {formatCurrency} from '../../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyStateInsurance} from '../../../../state/insurance-provider-filters'
import {insuranceProviderFilterState} from '../../../../state/insurance-provider'
import {useInsuranceApi} from '../../../../hooks/api/insurance'
import {useApiValue} from '../../../../hooks/api'
import {UpcomingRiskRowData} from '../../../../types/insurance-provider'

const UpcomingRiskTable = () => {
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateInsurance)

	const {getUpcomingRiskTableData} = useInsuranceApi()
	const {data} = useApiValue(insuranceProviderFilterState, getUpcomingRiskTableData, [])

	const columns: TableColumn<UpcomingRiskRowData> [] = [
		{
			name: 'EVENT NAME',
			selector: ({eventName}) => eventName,
			wrap: true
		},
		{
			name: 'EVENT DATE',
			selector: ({eventDate}) => eventDate
		},
		{
			name: 'EVENT TYPE',
			selector: ({eventType}) => eventType
		},
		{
			name: 'COUNTRY',
			selector: ({country}) => country
		},
		{
			name: 'CITY',
			selector: ({city}) => city
		},
		{
			name: 'TRANSACTIONS',
			selector: ({transactions}) => transactions
		},
		{
			name: `VALUE (${coreCurrencyState})`,
			selector: ({value}) => value,
			format: (row: any) => formatCurrency(coreCurrencyState, row.value)
		},
		{
			name: 'RISK COVERAGE',
			selector: ({riskCoverage}) => riskCoverage,
			format: (row: any) => `${row.riskCoverage}%`
		}
	]

	return (
		<TableCard title="Upcoming Risk">
			<DataTable
				data={data}
				columns={columns}
				theme="dark"
				pagination={true}
			/>
		</TableCard>
	)
}

export default UpcomingRiskTable