import React, {useEffect, useState} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {selectedEventsStateMember} from '../../../../state/member-filters'
import {Event} from '../../../../types'
import {Filter, FilterItem} from './Filter'
import {FilterEnum} from '../../Enums/FilterEnum'
import {useMemberApi} from '../../../../hooks/api/member'
import {useApiValue} from '../../../../hooks/api'
import {memberFilterState} from '../../../../state/memberFilterState'

const EventDropdown = () => {
	const {getEvents} = useMemberApi()
	const {data, isLoading} = useApiValue(memberFilterState, getEvents, [])
	const lastClicked = useRecoilValue(selectedEventsStateMember)
	const setLastClickedState = useSetRecoilState(selectedEventsStateMember)
	const [label, setLabel] = useState<string>('Loading...')
	const [menuItems, setMenuItems] = useState<FilterItem[]>([])

	const setNextLabel = () => {
		if (menuItems.length && lastClicked === null) {
			setLabel(menuItems[0].value)
		}

		if (menuItems.length && lastClicked > 0) {
			const next = menuItems.find((x: FilterItem) => x.key === lastClicked)?.value
			if (next) {
				setLabel(next)
			}
		}
	}

	const setNextMenuItems = () => {
		if (data && !isLoading) {
			const next = [
				{key: null, value: FilterEnum.DefaultFilterAll},
				...data.map((x: Event) => (
					{
						key: x.eventId,
						value: x.eventName
					}
				))
			]
			setMenuItems(next)
		}
	}

	useEffect(setNextLabel, [menuItems, lastClicked])

	useEffect(setNextMenuItems, [data, isLoading])

	const handleChange = (item: FilterItem) => setLastClickedState(item.key)

	return (
		<Filter
			title="Event"
      label={label}
      items={menuItems}
      onChange={handleChange}
		/>
	)
}

export {EventDropdown}





