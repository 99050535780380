import {TestResult} from './dynamic-data'

export interface MemberSummary {
	member: string
	status: TestResult
	salesVolume: number
	salesValue: number
	opportunityValue: number
	conversion: number
	revenue: number
	revenueValue: number
	activeTestCount: number
	impactPercentage: number
}

export interface Variable {
	name: string
	values: any[]
}

export interface TimeValueData {
	valueData: ValueData
	timeData: TimeData[]
}

export interface ValueData {
	bandId: number
	key: string
	transactionVolume: number
	transactionValue: number
	totalRevenue: number
	revenueValue: number
	opportunity: number
}

export interface TimeData {
	name: string
	value: string
	variance: number
	rate: number
	price: number
	conversion: number
	sales: number
	revenueValue: number
	status: TestResult
}

export interface TimeValueFilter {
	selectedValueKeys: string[]
	selectedTimeKeys: string[]
}

export enum RateChangeRequestStatus {
	pending = 1,
	approved = 2,
	declined = 3
}

export interface RateChangeRequest {
	rateChangeRequestId: number
	rateId: number
	date: string
	user: string
	test: number
	timeBand: string
	valueBand: string
	currentValue: number
	currentConversion: number
	currentRate: number
	newRate: number
	status: RateChangeRequestStatus
}

export interface RateUpdateRequest {
	rateChangeRequestId: number
	status: RateChangeRequestStatus
	user: string
	declineReason?: string
}
