import {TransactionTableData} from '../../../../../types/pulse-data'
import {useRecoilState, useRecoilValue, useResetRecoilState} from 'recoil'
import {selectedTransactionsState, tablePageSizeState, tablePageState} from '../../../../../state/pulse'
import React, {useEffect} from 'react'

const TransactionPager = (props: { data: TransactionTableData }) => {
	const [page, setPage] = useRecoilState(tablePageState)
	const pageSize = useRecoilValue(tablePageSizeState)
	const resetSelectedTransactions = useResetRecoilState(selectedTransactionsState)

	const handlePageChange = (page: number) => setPage(page)

	useEffect(() => {
		resetSelectedTransactions()
	}, [page, resetSelectedTransactions])

	return (
		<div className="mb-8 flex">
			{page > 1 && (
				<div className="flex items-center ml-4">
					<div
						className="
							mb-2 pl-1 pr-3 py-[2px]
							bg-white
							border border-pulse-icon-gray rounded-full
							text-nowrap text-sm uppercase
							cursor-pointer
							lg:mb-0
							hover:opacity-60"
						onClick={() => handlePageChange(page - 1)}
					>
						<span className="
							align-middle mr-1
							leading-5
							material-symbols-outlined"
						>
							chevron_left
						</span>
						<span className="align-middle leading-5 tracking-[1px]">Previous</span>
					</div>
				</div>
			)}
			{props.data.rows.length >= pageSize && (
				<div className="ml-4 flex items-center">
					<div
						className="
							mb-2 pl-3 pr-1 py-[2px]
							bg-white
							text-nowrap text-sm uppercase
							border border-pulse-icon-gray rounded-full
							cursor-pointer
							lg:mb-0
							hover:opacity-60"
						onClick={() => handlePageChange(page + 1)}
					>
						<span className="align-middle leading-5 tracking-[1px]">Next</span>
						<span className="
							align-middle ml-1
							leading-5
							material-symbols-outlined"
						>
							chevron_right
						</span>
					</div>
				</div>
			)}
		</div>
	)
}

export default TransactionPager
