import {selector} from "recoil";
import {
	loggedInUserState,
	userBrokerDataIdsState,
	userInsuranceProviderDataIdsState
} from "./auth";
import {autoRefreshState} from "./filters";
import {
	dateFromStateInsurance,
	dateToStateInsurance,
	formattedDateFromStateInsurance,
	formattedDateToStateInsurance,
	selectedCoreCurrencyStateInsurance,
	selectedCurrencyStateInsurance,
	selectedEventIdsStateInsurance,
	selectedEventProductTypeStateInsurance,
	selectedInsuranceProviderStateInsurance,
	selectedLocationStateInsurance,
	selectedMainProductIdStateInsurance,
	selectedRefundProductTypeStateInsurance
} from "./insurance-provider-filters";
import {
	InsuranceProviderFilter
} from "../types";
import {monthShortFormat, monthsInRange} from "../utils/date";

const keyPrefix = 'insuranceProvider'

export const insuranceProviderFilterState = selector<InsuranceProviderFilter>({
	key: `${keyPrefix}FilterState`,
	get: ({get}) => {
		return {
			dateFrom: get(formattedDateFromStateInsurance),
			dateTo: get(formattedDateToStateInsurance),
			insuranceProviderId: get(selectedInsuranceProviderStateInsurance),
			productId: get(selectedMainProductIdStateInsurance),
			coreCurrencyCode: get(selectedCoreCurrencyStateInsurance),
			locationId: get(selectedLocationStateInsurance),
			rpTypeId: get(selectedRefundProductTypeStateInsurance),
			epTypeId: get(selectedEventProductTypeStateInsurance),
			eventIds: get(selectedEventIdsStateInsurance),
			currencyCode: get(selectedCurrencyStateInsurance),
			loggedInUser: get(loggedInUserState),
			autoRefresh: get(autoRefreshState),
			insuranceProviderDataIds: get(userInsuranceProviderDataIdsState),
			brokerDataIds: get(userBrokerDataIdsState),
		}
	}
})

export const monthsInDateRangeState = selector<string[]>({
	key: `${keyPrefix}MonthsInRangeState`,
	get: ({get}) => {
		const dateFrom = get(dateFromStateInsurance);
		const dateTo = get(dateToStateInsurance);
		
		return monthsInRange(dateFrom, dateTo, monthShortFormat);
	}
});
