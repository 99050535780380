import {atom, selector, selectorFamily} from 'recoil'
import {Moment} from 'moment'
import {
	dateFormat,
	getDefaultDateFilterToggle,
	monthsInRange,
} from '../utils/date'
import {DateFilterToggle, Member, User} from '../types'
import {millisecondsToMinutes, minutesToMilliseconds} from '../utils/number'
import {loggedInUserState} from './auth'

export const refreshInterval: number = parseInt(process.env.REACT_APP_REFREH_INTERVAL || '60')

export const autoRefreshIntervalState = atom<number>({
	key: 'autoRefreshIntervalState',
	default: minutesToMilliseconds(refreshInterval)
})

export const autoRefreshIntervalMinutesState = selector<number>({
	key: 'autoRefreshIntervalMinutesState',
	get: ({get}) => millisecondsToMinutes(get(autoRefreshIntervalState))
})

export const autoRefreshActiveState = selector<boolean>({
	key: 'autoRefreshActiveState',
	get: ({get}) => get(autoRefreshIntervalMinutesState) > 0
})

export const autoRefreshState = atom<number>({
	key: 'autoRefreshState',
	default: 0
})

export const selectedUserState = atom<User | null>({
	key: 'selectedUserState',
	default: null
})

export const selectedUserNameState = selector<string | undefined>({
	key: 'selectedUserNameState',
	get: ({get}) => {
		const selectedUser = get(selectedUserState)

		if (selectedUser) {
			return selectedUser.name
		}

		return get(loggedInUserState)
	}
})

export const formattedSelectedUserNameState = selector<string | undefined>({
	key: 'formattedSelectedUserNameState',
	get: ({get}) => {
		const userName = get(selectedUserNameState)

		if (!userName) {
			return undefined
		}

		return userName.substring(0, userName.indexOf('@'))
	}
})

export const selectedUserIdState = selector<number | null>({
	key: 'selectedUserIdState',
	get: ({get}) => {
		const user = get(selectedUserState)
		if (user) {
			return user.id
		}
		return null
	}
})

export const selectedMemberState = atom<Member | null>({
	key: 'selectedMemberState',
	default: null
})

export const selectedSalesTeamIdState = atom<number | null>({
	key: 'selectedSalesTeamIdState',
	default: null
})

export const dateFromState = atom<Moment>({
	key: 'dateFromState',
	default: getDefaultDateFilterToggle().dateFrom
})

export const dateToState = atom<Moment>({
	key: 'dateToState',
	default: getDefaultDateFilterToggle().dateTo
})

export const formattedDateFromState = selector<string>({
	key: 'formattedDateFromState',
	get: ({get}) => get(dateFromState).format(dateFormat)
})

export const formattedDateToState = selector<string>({
	key: 'formattedDateToState',
	get: ({get}) => get(dateToState).format(dateFormat)
})

export const selectedDateFilterToggleState = atom<DateFilterToggle>({
	key: 'selectedDateFilterToggleState',
	default: getDefaultDateFilterToggle()
})

export const isDateFilterToggleSelectedState = selectorFamily({
	key: 'isDateFilterToggleSelectedState',
	get: (toggle: any) => ({get}) => {
		const selectedToggle = get(selectedDateFilterToggleState)
		return toggle.label === selectedToggle.label
	}
})

export const monthsInDateRangeState = selector<string[]>({
	key: 'monthsInDateRangeState',
	get: ({get}) => {
		const dateFrom = get(dateFromState)
		const dateTo = get(dateToState)

		return monthsInRange(dateFrom, dateTo)
	}
})
