import classNames from 'classnames'
import React from 'react'
import {TestResult} from '../../../types/dynamic-data'

interface IconTableCellProps {
	status: TestResult
	inactive?: boolean
}

const IconTableCell = (props: IconTableCellProps) => {
	const { status, inactive } = props
	return (
		<div className={classNames('w-100 d-flex justify-content-center align-items-center h-100', {
			'warning': status === TestResult.warning && inactive !== true,
			'danger': status === TestResult.fail && inactive !== true,
			'success': status === TestResult.good && inactive !== true,
			'info': status === TestResult.ok && inactive !== true,
			'inactive': inactive === true
		})}>
			<i className={classNames('tim-icons', 'tim-icons-bg-circle', 'bg-white', {
				'icon-check-2 text-success': [TestResult.ok, TestResult.good].includes(status) && inactive !== true,
				'icon-alert-circle-exc text-error': status === TestResult.fail && inactive !== true,
				'icon-alert-circle-exc text-warning': status === TestResult.warning && inactive !== true
			})}/>
		</div>
	)
}

interface ColouredCellProps {
	status: TestResult
	value: any
	inactive?: boolean
}

const ColouredCell = (props: ColouredCellProps) => {
	const { status, value, inactive } = props
	return (
		<div className={classNames('w-100 d-flex justify-content-center align-items-center h-100', {
			'inactive': inactive === true,
			'warning': status === TestResult.warning,
			'danger': status === TestResult.fail,
			'info': status === TestResult.ok,
			'success': status === TestResult.good
		})}>
			<span>{value}</span>
		</div>
	)
}

export { IconTableCell, ColouredCell }
