import React, {ReactElement, useEffect, useState} from 'react'
import classNames from 'classnames'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import {
	filterState,
	selectedContentState
} from '../../../state/dynamic-performance'
import {toUsd} from '../../../utils/number'
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap'
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import {Content, ContentLanguage, ContentPreview, PricingAndContentData} from '../../../types/dynamic-performance'
import InnerHTML from 'dangerously-set-html-content'
import {IconTableCell} from '../../../components/Dashboard/Dynamic/TableCell'
import {useDynamicPerformanceApi} from '../../../hooks/api/dynamic'
import {useApiValue} from '../../../hooks/api'

const PricingAndContentTestTable = () => {
	const {getPricingAndContentData} = useDynamicPerformanceApi()
	const {data} = useApiValue(filterState, getPricingAndContentData, [])

	return (
		<TableCard
			title="Pricing & Content Tests">
			<div>
				<DataTable
					data={data}
					columns={columns}
					theme="dark"
					pagination={true}
				/>
				<ContentDialog/>
			</div>
		</TableCard>
	)
}

export default PricingAndContentTestTable

interface ContentPanelProps {
	test: number
	contentLanguage: ContentLanguage
}

const ContentPanel = (props: ContentPanelProps) => {
	const {test, contentLanguage: {htmlA, htmlB}} = props
	const html = test === 2 ? htmlB : htmlA
	const label = test === 2 ? 'B' : 'A'

	return (
		<div className="d-flex flex-row">
			<div className="p-1 w-100">
				<h5>{label}</h5>
				<InnerHTML html={html}/>
			</div>
		</div>
	)
}

interface ContentTabsProps {
	test: number
	contentLanguages: ContentLanguage[]
}

const ContentTabs = (props: ContentTabsProps) => {
	const [selectedTab, setSelectedTab] = useState<number>(0)
	const {contentLanguages} = props

	const handleTabChange = (index: number) => () => setSelectedTab(index)

	return (
		<>
			<Nav>
				{
					contentLanguages.map((x, i) => {
						return (
							<NavItem key={`${x.languageCode}-nav-${i}`}>
								<NavLink
									data-toggle="tab"
									className={classNames('cursor-pointer', {
										'active': selectedTab === i
									})}
									onClick={handleTabChange(i)}
								>
									{x.languageCode}
								</NavLink>
							</NavItem>
						)
					})
				}
			</Nav>
			<TabContent activeTab={selectedTab} className="tab-space">
				{
					contentLanguages.map((x, i) => {
						return (
							<TabPane key={`${x.languageCode}-panel-${i}`} tabId={i}>
								<ContentPanel contentLanguage={x} test={props.test}/>
							</TabPane>
						)
					})
				}
			</TabContent>
		</>
	)
}

const ContentDialog = () => {
	const selectedContent = useRecoilValue<ContentPreview | null>(selectedContentState)
	const reset = useResetRecoilState(selectedContentState)
	const {getContent} = useDynamicPerformanceApi()
	const [content, setContent] = useState<Content | null>(null)

	useEffect(() => {
		(async function () {
			if (!selectedContent) {
				return
			}

			try {
				const data = await getContent(selectedContent.contentId)
				setContent(data)
			} catch (e) {
				console.log('content error', e)
			}
		})()
	}, [selectedContent])


	return (
		<Modal isOpen={!!selectedContent} size="xl" centered className="no-scroll">
			<ModalHeader className="modal-title" toggle={reset}>
				{content?.name}
			</ModalHeader>
			<ModalBody>
				{
					content?.contentLanguages && (
						<ContentTabs contentLanguages={content.contentLanguages} test={selectedContent?.test ?? 1}/>
					)
				}
			</ModalBody>
			<ModalFooter>
				<Button onClick={reset}>Close</Button>
			</ModalFooter>
		</Modal>
	)
}

interface ContentPreviewButtonProps {
	data: ContentPreview
}

const ContentPreviewButton = (props: ContentPreviewButtonProps) => {
	const setContentId = useSetRecoilState(selectedContentState)

	const handleClick = () => setContentId(props.data)

	return (
		<span className="cursor-pointer" onClick={handleClick}>View</span>
	)
}

interface MultiCellProps {
	id: number,
	values: any[],
	format?: (value: any) => string,
	render?: (value: any) => ReactElement,
	isIconCell?: boolean
}

const MultiCell = (props: MultiCellProps) => {
	const {id, values, format, render, isIconCell} = props

	const renderValue = (value: any) => {
		if (render) {
			return render(value)
		}
		return (
			<span>{value}</span>
		)
	}

	return (
		<div className="d-flex flex-column w-100 h-100">
			{values.map((x: any, i: number) => {
				let val = x

				if (format) {
					val = format(x)
				}

				return (
					<div
						key={`${id}-${x}-${i}`}
						className={classNames({
							'rdt_StackedCell': isIconCell !== true,
							'h-100': isIconCell === true
						})}
					>
						{renderValue(val)}
					</div>
				)
			})}
		</div>
	)
}

const columns: TableColumn<PricingAndContentData> [] = [
	{
		name: 'Rate Id',
		selector: ({testId}) => testId
	},
	{
		name: 'Transaction Band',
		selector: ({transactionBand}) => transactionBand,
		sortable: true
	},
	{
		name: 'Time Period (Days)',
		selector: ({timePeriod}) => timePeriod,
		sortable: true
	},
	{
		name: 'Test',
		selector: ({test}) => test[0],
		cell: (row: PricingAndContentData) => {
			const {test, testId} = row
			return (
				<MultiCell id={testId} values={test}/>
			)
		}
	},
	{
		name: 'Content',
		selector: ({contentIds}) => contentIds[0],
		cell: (row: PricingAndContentData) => {
			const values: ContentPreview[] = row.contentIds.map((x, i) => {
				return {
					contentId: x,
					test: row.test[i]
				}
			})
			return (
				<MultiCell
					id={row.testId}
					values={values}
					render={(value: ContentPreview) => {
						if (value.contentId === 0) {
							return <span/>
						}
						return <ContentPreviewButton data={value}/>
					}}
				/>
			)
		}
	},
	{
		name: 'Price',
		selector: ({price}) => price[0],
		cell: (row: PricingAndContentData) => {
			const {price, testId} = row
			return (
				<MultiCell id={testId} values={price} format={(value) => toUsd(value)}/>
			)
		},
		sortable: true
	},
	{
		name: 'Rate %',
		selector: ({rate}) => rate[0],
		cell: (row: PricingAndContentData) => {
			const {rate, testId} = row
			return (
				<MultiCell id={testId} values={rate} format={(value) => `${value}%`}/>
			)
		},
		sortable: true
	},
	{
		name: 'Conversion %',
		selector: ({conversion}) => conversion[0],
		cell: (row: PricingAndContentData) => {
			const {conversion, testId} = row
			return (
				<MultiCell id={testId} values={conversion} format={(value) => `${value}%`}/>
			)
		},
		sortable: true
	},
	{
		name: 'Sold',
		selector: ({sold}) => sold[0],
		cell: (row: PricingAndContentData) => {
			const {sold, testId} = row
			return (
				<MultiCell id={testId} values={sold}/>
			)
		},
		sortable: true
	},
	{
		name: 'Not Sold',
		selector: ({notSold}) => notSold[0],
		cell: (row: PricingAndContentData) => {
			const {notSold, testId} = row
			return (
				<MultiCell id={testId} values={notSold}/>
			)
		},
		sortable: true
	},
	{
		name: '$ Revenue',
		selector: ({revenue}) => revenue[0],
		cell: (row: PricingAndContentData) => {
			const {revenue, testId} = row
			return (
				<MultiCell id={testId} values={revenue} format={(value) => toUsd(value, 0)}/>
			)
		},
		sortable: true
	},
	{
		name: '$ Revenue by Unit',
		selector: ({revenueByUnit}) => revenueByUnit[0],
		cell: (row: PricingAndContentData) => {
			const {revenueByUnit, testId} = row
			return (
				<MultiCell id={testId} values={revenueByUnit} format={(value) => value.toFixed(2)}/>
			)
		},
		sortable: true
	},
	{
		name: 'Result',
		selector: ({result}) => result[0],
		cell: (row: PricingAndContentData) => {
			const {result, testId, rate} = row

			const values = result.map((x, i) => {
				return {
					result: x,
					rate: rate[i]
				}
			})

			return (
				<MultiCell
					id={testId}
					values={values}
					isIconCell={true}
					render={({result, rate}) => {
						return (
							<IconTableCell status={result} inactive={rate === 0}/>
						)
					}}
				/>
			)
		}
	},
	{
		name: 'Test Parameters',
		selector: ({testParameters}) => testParameters[0],
		cell: (row: PricingAndContentData) => {
			const {testParameters, testId} = row
			return (
				<MultiCell
					id={testId}
					values={testParameters}
					render={(value: any) => (<a href={value}>View</a>)}
				/>
			)
		}
	}
]
