import React, {memo, useEffect, useState} from 'react'
import {ValueDirectionProps, ValueStatusCard} from '../../../components/Card/ValueStatusCard'
import {refundFilterState, ValueStatus} from '../../../types/refund'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'

const WaitingOnPG = memo(() => {

	const [valueStatusData, setValueStatusData] = useState<ValueDirectionProps[]>([])
	const {getWaitingOnPgData} = useRefundApi()
	const {data} = useApiValue(refundFilterState, getWaitingOnPgData, [] as ValueStatus[])

	useEffect(() => {
		if (data) {
			const next: ValueDirectionProps[] = data?.map((x: ValueStatus, i: number) => {
				const {value, isPositive} = x
				return {
					value: i === 1 ? (`${value} HRS`) : value,
					direction: isPositive ? 'down' : 'up',
					colour: isPositive ? 'green' : 'red'
				}
			})
			setValueStatusData(next)
		}
	}, [data])

	return (
		<ValueStatusCard
			title={'Waiting On PG'}
			values={valueStatusData}
		/>
	)
})

export {WaitingOnPG}