import {useApiActions} from './index'
import {
	ChartData,
	LeaderboardRowData, MemberData,
	MemberOverviewTableData,
	SalesFilter,
	TransactionProtectionRevenueGrowthValueModel
} from '../../types/sales'
import {DropdownData, MainProduct} from '../../types'

export const useSalesApi = () => {
	const {callApi} = useApiActions('sales')

	const getConversion = async (filter: SalesFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'conversion'
		})
	}

	const getConversionChart = async (filter: SalesFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'conversion-chart'
		})
	}

	const getMemberCount = async (filter: SalesFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'member-count'
		})
	}

	const getMemberOverviewTable = async (filter: SalesFilter): Promise<MemberOverviewTableData> => {
		return await callApi<MemberOverviewTableData>({
			query: filter,
			path: 'member-overview'
		})
	}

	const getMemberOverviewTableCsv = async (filter: SalesFilter): Promise<MemberData[]> => {
		return await callApi<MemberData[]>({
			query: filter,
			path: 'member-overview-csv'
		})
	}

	const getMembers = async (filter: SalesFilter): Promise<MainProduct[]> => {
		return await callApi<MainProduct[], string>({
			query: filter,
			path: 'members'
		})
	}

	const getProducts = async (filter: SalesFilter): Promise<MainProduct[]> => {
		return await callApi<MainProduct[], string>({
			query: filter,
			path: 'products'
		})
	}

	const getRegions = async (filter: SalesFilter): Promise<DropdownData[]> => {
		return await callApi<DropdownData[], string>({
			query: filter,
			path: 'regions'
		})
	}

	const getRegionalLeaderboardChart = async (filter: SalesFilter): Promise<LeaderboardRowData[]> => {
		return await callApi<LeaderboardRowData[], string>({
			query: filter,
			path: 'sales-regional-leaderboard'
		})
	}

	const getRevenue = async (filter: SalesFilter): Promise<TransactionProtectionRevenueGrowthValueModel> => {
		return await callApi<TransactionProtectionRevenueGrowthValueModel, string>({
			query: filter,
			path: 'revenue'
		})
	}

	const getRevenueChart = async (filter: SalesFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'revenue-chart'
		})
	}

	const getRevenuePerDollar = async (filter: SalesFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'revenue-per-dollar'
		})
	}

	const getRevenuePerDollarChart = async (filter: SalesFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'revenue-per-dollar-chart'
		})
	}

	const getTerritories = async (filter: SalesFilter): Promise<DropdownData[]> => {
		return await callApi<DropdownData[], string>({
			query: filter,
			path: 'territories'
		})
	}

	const getVerticalLeaderboardChart = async (filter: SalesFilter): Promise<LeaderboardRowData[]> => {
		return await callApi<LeaderboardRowData[], string>({
			query: filter,
			path: 'vertical-leaderboard'
		})
	}

	return {
		getConversion,
		getConversionChart,
		getMemberCount,
		getMemberOverviewTable,
		getMemberOverviewTableCsv,
		getMembers,
		getProducts,
		getRegions,
		getRegionalLeaderboardChart,
		getRevenue,
		getRevenueChart,
		getRevenuePerDollar,
		getRevenuePerDollarChart,
		getTerritories,
		getVerticalLeaderboardChart
	}
}
