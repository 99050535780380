import {atom, selector} from 'recoil'
import {
	formattedDateFromStateMember,
	formattedDateToStateMember,
	selectedCoreCurrencyStateMember,
	selectedCurrencyStateMember,
	selectedEventsStateMember,
	selectedLocationStateMember,
	selectedMainProductIdStateMember,
	selectedMemberStateMember,
	selectedProductTypeCodeStateMember,
	selectedProductTypeStateMember,
	transactionSearchTextState,
	transactionTableColumnSortQueryState,
	transactionTableCurrentPageNumberState,
	transactionTablePageSizeState,
} from './member-filters'
import {loggedInUserState} from './auth'
import {autoRefreshState} from './filters'
import {MemberFilter, TransactionTableFilter} from '../types'

export const memberFilterState = selector<MemberFilter>({
	key: 'memberFilterState',
	get: ({get}) => {
		return {
			dateFrom: get(formattedDateFromStateMember),
			dateTo: get(formattedDateToStateMember),
			memberId: get(selectedMemberStateMember),
			eventId: get(selectedEventsStateMember),
			mainProductId: get(selectedMainProductIdStateMember),
			productId: get(selectedProductTypeStateMember),
			productTypeCode: get(selectedProductTypeCodeStateMember),
			countryCode: get(selectedLocationStateMember),
			currencyCode: get(selectedCurrencyStateMember),
			coreCurrencyCode: get(selectedCoreCurrencyStateMember),
			loggedInUser: get(loggedInUserState),
			autoRefresh: get(autoRefreshState)
		}
	}
})

export const transactionTableUpdateTriggerState = atom<number>({
	key: 'transactionTableUpdateTriggerState',
	default: 0
})

export const memberTransactionTableFilterState = selector<TransactionTableFilter>({
	key: 'memberTransactionTableFilterState',
	get: ({get}) => {
		return {
			autoRefresh: get(transactionTableUpdateTriggerState) + get(autoRefreshState),
			coreCurrencyCode: get(selectedCoreCurrencyStateMember),
			countryCode: get(selectedLocationStateMember),
			currencyCode: get(selectedCurrencyStateMember),
			dateFrom: get(formattedDateFromStateMember),
			dateTo: get(formattedDateToStateMember),
			eventId: get(selectedEventsStateMember),
			loggedInUser: get(loggedInUserState),
			mainProductId: get(selectedMainProductIdStateMember),
			memberId: get(selectedMemberStateMember),
			productId: get(selectedProductTypeStateMember),
			productTypeCode: get(selectedProductTypeCodeStateMember),
			transactionSearchText: get(transactionSearchTextState),
			transactionTableColumnSortQuery: get(transactionTableColumnSortQueryState),
			transactionTableCurrentPageNumber: get(transactionTableCurrentPageNumberState),
			transactionTablePageSize: get(transactionTablePageSizeState)
		}
	}
})
