import React, {memo} from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {integrationFilterState} from '../../../../state/integration'
import {formatCurrency} from '../../../../utils/currency'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'
import {defaultMrrSummary} from '../../../../types/integration'

const ActualMrrSummary = memo(() => {
	const {getMrrSummary} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getMrrSummary, defaultMrrSummary)

	return (
		<ValueCard
			title={`Average Actual MRR`}
			value={formatCurrency('USD', data?.actualMrr ?? 0)}
			icon="icon-money-coins"
			tooltip={'Average Actual MRR'}
		/>
	)
})

export {ActualMrrSummary}
