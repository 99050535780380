import React, {memo, useState, useEffect} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component'
import {formatCurrency} from '../../../utils/currency';
import {formatNumberMember} from "../../../utils/number";
import {Bar} from "react-chartjs-2";
import {useRecoilValue} from "recoil";
import {selectedCoreCurrencyStateMember} from "../../../state/member-filters";
import {TableCard} from "../../../components/Card/TableCard";
import {CSVLink} from "react-csv";
import {canDownloadValueBandDataState} from "../../../state/auth";
import {useMemberApi} from '../../../hooks/api/member'
import {useApiValue} from '../../../hooks/api'
import {memberFilterState} from '../../../state/memberFilterState'
import {ConversionByValueBandTableModel} from '../../../types/member'

const csvHeaders = [
	{label: 'Value Band', key: 'ValueBand'},
	{label: 'Total Volume', key: 'TotalVolume'},
	{label: 'Total Value', key: 'TotalValue'},
	{label: 'Conversion %', key: 'ConversionRate'},
	{label: 'Sold Volume', key: 'SoldVolume'},
	{label: 'Sold Value', key: 'SoldValue'},
	{label: 'Average Value', key: 'AverageValue'},
	{label: 'Protection Cost', key: 'ProtectionCost'}
]

const ConversionByValueBand = memo(() => {
	const {getConversionByValueBand} = useMemberApi()
	const {data, isLoading} = useApiValue(memberFilterState, getConversionByValueBand, [])
	const canDownloadValueBandData = useRecoilValue(canDownloadValueBandDataState);
	const fileName = `PG-Member Conversion By Value ${new Date().toDateString()}.csv`
	const [showPaging, setShowPaging] = useState<boolean>(false);
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateMember);

	useEffect(() => {
		setShowPaging(data.length > 10);
	}, [data]);

	const columns: TableColumn<ConversionByValueBandTableModel> [] = [
		{
			name: 'Value Band',
			selector: ({valueBand}) => valueBand,
			sortable: true,
		},
		{
			name: 'Total Volume',
			selector: ({totalVolume}) => totalVolume,
			sortable: true,
		},
		{
			name: 'Total Value',
			selector: ({totalValue}) => totalValue,
			sortable: true,
			format: (row: any) => formatCurrency(coreCurrencyState, row['TotalValue'])
		},
		{
			name: 'Conversion (%)',
			selector: ({conversionRate}) => conversionRate,
			sortable: true,
			right: true,
			format: (row: any) => row['ConversionRate'] + "%"
		},
		{
			name: 'Sold Volume',
			selector: ({soldVolume}) => soldVolume,
			sortable: true,
			right: true,
		},
		{
			name: 'Sold Value',
			selector: ({soldValue}) => soldValue,
			sortable: true,
			right: true,
			format: (row: any) => formatCurrency(coreCurrencyState, row['SoldValue'])
		},
		{
			name: 'Average Value',
			selector: ({averageValue}) => averageValue,
			sortable: true,
			right: true,
			format: (row: any) => formatNumberMember(row['AverageValue'])
		},
		{
			name: 'Protection Cost',
			selector: ({protectionCost}) => protectionCost,
			sortable: true,
			right: true,
			format: (row: any) => formatCurrency(coreCurrencyState, row['ProtectionCost'])
		},
	];

	const chartData: any = {
		labels: data.map((item: any) => item.ValueBand),
		datasets: [
			{
				data: data.map((item: any) => item.ConversionRate),
				fill: true,
				backgroundColor: 'rgb(255, 99, 132)',
				borderColor: 'rgba(255, 99, 132, 0.2)',
				yAxisID: 'y',
			},
		],
	}

	const options: any = {
		responsive: true,
		scales: {
			y: {
				display: true,
				position: 'left',
				ticks: {
					callback: (label: any) => {
						return label + "%";
					}
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: true,
			},
			tooltip: {
				callbacks: {
					label: function (context: { dataset: { label: string; }; parsed: { y: number | null; }; }) {
						let label = context.dataset.label || '';
						if (label) {
							label += ': ';
						}
						if (context.parsed.y !== null) {
							label += context.parsed.y + "%";
						}
						return label;
					}
				}
			}
		},
	}


	return (
		<TableCard
			title="Conversion By Value Band"
			titleClass="card-title"
			showToggle={true}
			isLoading={isLoading}
		>
			<>
				<Bar
					data={chartData}
					options={options}
				/>
				<DataTable
					data={data}
					columns={columns}
					theme="dark"
					pagination={showPaging}
				/>
				{
					(Object.keys(data).length > 0 && canDownloadValueBandData) && (
						<div className="d-flex flex-fill justify-content-end">
							<CSVLink
								filename={fileName}
								data={data}
								headers={csvHeaders}
								className="ml-2 btn btn-success btn-sm">
								Download
							</CSVLink>
						</div>)
				}
			</>
		</TableCard>
	);
});

export {ConversionByValueBand};
