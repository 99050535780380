import React, {useEffect} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import {TableCard} from '../../../components/Card/TableCard'
import {
	filterState,
	tableFilterState,
	tablePageSizeState,
	tablePageState,
	tableSortColumnState,
	tableSortDirectionState
} from '../../../state/dynamic-performance'
import {TableRow} from '../../../types/dynamic-performance'
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import {toUsd} from '../../../utils/number'
import moment from 'moment'
import {dateFormat} from '../../../utils/date'
import {useDynamicPerformanceApi} from '../../../hooks/api/dynamic'
import {useApiValue} from '../../../hooks/api'

export const TransactionTable = () => {
	const [page, setPage] = useRecoilState(tablePageState)
	const resetPage = useResetRecoilState(tablePageState)
	const setPageSize = useSetRecoilState(tablePageSizeState)
	const setSortColumn = useSetRecoilState(tableSortColumnState)
	const setSortDirection = useSetRecoilState(tableSortDirectionState)
	const filter = useRecoilValue(filterState)
	const {getTransactions} = useDynamicPerformanceApi()
	const {data, isLoading} = useApiValue(tableFilterState, getTransactions, {count: 0, rows: []})

	useEffect(() => {
		resetPage()
	}, [filter, resetPage])

	const handlePageChange = (page: number) => setPage(page)
	const handlePageSizeChange = (pageSize: number) => setPageSize(pageSize)
	const handleSort = ({selector}: TableColumn<TableRow>, sortDirection: string) => {
		if (selector && typeof selector === 'string') {
			setSortColumn(selector)
		}
		setSortDirection(sortDirection)
	}

	return (
		<TableCard title="Transactions" isLoading={isLoading}>
			<>
				<DataTable
					theme="dark"
					columns={columns}
					data={data.rows}
					pagination={true}
					paginationServer={true}
					sortServer={true}
					paginationDefaultPage={page}
					paginationTotalRows={data.count}
					disabled={isLoading}
					paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40, 50, 100]}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handlePageSizeChange}
					onSort={handleSort}
				/>
			</>
		</TableCard>
	)
}

const columns: TableColumn<TableRow>[] = [
	{
		name: 'Id',
		selector: ({platformTransactionId}) => platformTransactionId,
		sortable: true
	},
	{
		name: 'Member',
		selector: ({member}) => member,
		sortable: true
	},
	{
		name: 'Region',
		selector: ({region}) => region,
		sortable: true
	},
	{
		name: 'Date',
		selector: ({transactionDate}) => transactionDate,
		format: ({transactionDate}: TableRow) => moment(transactionDate).format(dateFormat),
		sortable: true
	},
	{
		name: 'Transaction Value',
		selector: ({transactionValue}) => transactionValue,
		center: true,
		format: ({transactionValue}: TableRow) => toUsd(transactionValue.toString(), 2),
		sortable: true
	},
	{
		name: 'Protection Value',
		selector: ({protectionValue}) => protectionValue,
		center: true,
		format: ({protectionValue}: TableRow) => toUsd(protectionValue.toString(), 2),
		sortable: true
	},
	{
		name: 'Protected',
		selector: ({isSold}) => isSold,
		center: true,
		cell: (row: TableRow) => {
			if (row.isSold) {
				return <>Yes</>
			}
			return <>No</>
		},
		sortable: false
	},
	{
		name: 'Currency',
		selector: ({transactionCurrency}) => transactionCurrency,
		center: true,
		sortable: false
	}
]
