import React, {useEffect, useState} from 'react'
import SelectFilterCard, {SelectFilterItem} from '../../../components/Dashboard/SelectFilter'
import {selectedSalesRegionIdState} from '../../../state/dynamic-data'
import {useRecoilState} from 'recoil'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/dynamic-performance'
import {useDynamicDataApi} from '../../../hooks/api/dynamic'

const RegionFilter = () => {
	const [items, setItems] = useState<SelectFilterItem[]>([])
	const {getSalesRegions} = useDynamicDataApi()
	const {data} = useApiValue(filterState, getSalesRegions, [])
	const [regionId, setRegionId] = useRecoilState(selectedSalesRegionIdState)

	useEffect(() => {
		if (data) {
			setItems(data.map(x => ({label: x.name, value: x.salesRegionId})))
		}
	}, [data])

	const handleChange = (item: SelectFilterItem) => setRegionId(item.value)

	return (
		<SelectFilterCard
			title="Region"
			items={items}
			onChange={handleChange}
			value={regionId}
		/>
	)
}

export default RegionFilter
