import {useApiActions} from './index'
import {ChartData, RefundUserTableFilter, TrustPilotViewModel, UserModel, ValueStatus} from '../../types/refund'

export const useRefundApi = () => {
	const {callApi} = useApiActions('refund')

	const getApprovedValueData = async (): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			path: 'approved-value'
		})
	}

	const getApprovedVolumeData = async (): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			path: 'approved-volume'
		})
	}

	const getAverageHandledTimeData = async (): Promise<ValueStatus> => {
		return await callApi<ValueStatus, string>({
			path: 'average-handled-time'
		})
	}

	const getCEFData = async (): Promise<ValueStatus[]> => {
		return await callApi<ValueStatus[], string>({
			path: 'cef'
		})
	}

	const getDeclinedVolumeData = async (): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			path: 'declined-volume'
		})
	}

	const getInsuredData = async (): Promise<ValueStatus[]> => {
		return await callApi<ValueStatus[], string>({
			path: 'insured'
		})
	}

	const getManualHandledData = async (): Promise<ValueStatus[]> => {
		return await callApi<ValueStatus[], string>({
			path: 'manual-handled'
		})
	}

	const getMemberData = async (): Promise<ValueStatus[]> => {
		return await callApi<ValueStatus[], string>({
			path: 'member'
		})
	}

	const getNewVolumeData = async (): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			path: 'new-volume'
		})
	}

	const getOutboundEmailCount = async (): Promise<ValueStatus> => {
		return await callApi<ValueStatus, string>({
			path: 'outbound-email-count'
		})
	}

	const getReceivedNewData = async (): Promise<ValueStatus[]> => {
		return await callApi<ValueStatus[], string>({
			path: 'received-new'
		})
	}

	const getSystemHandledData = async (): Promise<ValueStatus[]> => {
		return await callApi<ValueStatus[], string>({
			path: 'system-handled'
		})
	}

	const getTotalReferredData = async (): Promise<ValueStatus[]> => {
		return await callApi<ValueStatus[], string>({
			path: 'total-referred'
		})
	}

	const getTrustPilotData = async (): Promise<TrustPilotViewModel> => {
		return await callApi<TrustPilotViewModel, string>({
			path: 'trust-pilot'
		})
	}

	const getUserData = async (filter: RefundUserTableFilter): Promise<UserModel> => {
		return await callApi<UserModel, string>({
			query: filter,
			path: 'user-data'
		})
	}

	const getWaitingOnPgData = async (): Promise<ValueStatus[]> => {
		return await callApi<ValueStatus[], string>({
			path: 'waiting-on-pg'
		})
	}

	const getWaitingOnPgReferredData = async (): Promise<ValueStatus[]> => {
		return await callApi<ValueStatus[], string>({
			path: 'waiting-on-pg-referred'
		})
	}

	return {
		getApprovedValueData,
		getApprovedVolumeData,
		getAverageHandledTimeData,
		getCEFData,
		getDeclinedVolumeData,
		getInsuredData,
		getManualHandledData,
		getMemberData,
		getNewVolumeData,
		getOutboundEmailCount,
		getReceivedNewData,
		getSystemHandledData,
		getTotalReferredData,
		getTrustPilotData,
		getUserData,
		getWaitingOnPgData,
		getWaitingOnPgReferredData
	}
}
