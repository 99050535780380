import {atom, selector} from 'recoil'
import {
	ContentPreview,
	DynamicPerformanceFilter,
	DynamicPerformanceTableFilter,
} from '../types/dynamic-performance'
import {autoRefreshState} from './filters'
import {
	selectedMemberIdState,
	selectedSalesRegionIdState,
	formattedDateFromState,
	formattedDateToState
} from './dynamic-data'
import {loggedInUserState} from './auth'

const keyPrefix = 'dynamicPerformance'

export const tablePageState = atom<number>({
	key: `${keyPrefix}TablePageState`,
	default: 1
})

export const tablePageSizeState = atom<number>({
	key: `${keyPrefix}TablePageSizeState`,
	default: 10
})

export const tableSortColumnState = atom<string>({
	key: `${keyPrefix}TableSortColumnState`,
	default: 'transactionId'
})

export const tableSortDirectionState = atom<string>({
	key: `${keyPrefix}TableSortDirectionState`,
	default: 'desc'
})

export const filterState = selector<DynamicPerformanceFilter>({
	key: `${keyPrefix}FilterState`,
	get: ({get}) => {
		get(autoRefreshState)
		return {
			dateFrom: get(formattedDateFromState),
			dateTo: get(formattedDateToState),
			memberId: get(selectedMemberIdState),
			salesRegionId: get(selectedSalesRegionIdState),
			userName: get(loggedInUserState)
		}
	}
})

export const tableFilterState = selector<DynamicPerformanceTableFilter>({
	key: `${keyPrefix}TableFilterState`,
	get: ({get}) => {
		return {
			...get(filterState),
			page: get(tablePageState),
			pageSize: get(tablePageSizeState),
			sortColumn: get(tableSortColumnState),
			sortDirection: get(tableSortDirectionState)
		}
	}
})

export const selectedContentState = atom<ContentPreview|null>({
	key: `${keyPrefix}SelectedContentState`,
	default: null
})
