import {useRecoilValue} from 'recoil'
import {selectedProductState} from '../../../state/sales'
import {productType} from '../../../types'

export default function useGetMainProductStates() {
	const selectedProductValue = useRecoilValue(selectedProductState)
	let isProductAll = false
	let isProductEp = false
	let isProductRp = false

	switch (selectedProductValue) {
		case productType.All:
			isProductAll = true
			break
		case productType.EventProtect:
			isProductEp = true
			break
		case productType.RefundProtect:
			isProductRp = true
			break
	}

	return {isProductAll, isProductEp, isProductRp}
}