import React, {useEffect, useState} from 'react'
import {integrationFilterState} from '../../../../state/integration'
import {selectedCommercialStateMember} from '../../../../state/integration'
import {FilterEnum, Commercial} from '../../../../types/integration'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'

const CommercialDropdown = () => {
	const {getCommercials} = useIntegrationApi()
	const {data, isLoading} = useApiValue(integrationFilterState, getCommercials, [])
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])

	useEffect(() => {
		if (data && data.length > 0 && !isLoading) {
			const next = [
				{value: '', label: FilterEnum.DefaultFilterAll},
				...data.map((commercial: Commercial) => ({
					value: commercial.commercialEmail,
					label: commercial.commercialName
				}))
			]
			setMenuItems(next)
		}
	}, [data, isLoading])

	return (
		<StatefulSelectFilterCard
			state={selectedCommercialStateMember}
			title="Partnership Owners"
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {CommercialDropdown}