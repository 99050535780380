import {isMapToggleSelectedState} from "../state/member-data";
import {useRecoilValue} from "recoil";
import {mapToggleLabels, RegionIds} from '../types/region'

export default function useGetMapToggleStates (region: RegionIds) {
    const isEpSelected = useRecoilValue(isMapToggleSelectedState({region, label: mapToggleLabels.ep}))
    const isRpSelected = useRecoilValue(isMapToggleSelectedState({region, label: mapToggleLabels.rp}))

    return { isEpSelected, isRpSelected }
}
