import React, {ReactElement} from 'react'
import {Route, Routes} from 'react-router-dom'
import {AuthenticatedRoute} from './AuthenticatedRoute'
import {Login, SignInRedirect, SilentRefresh} from '../views/Auth'
import {HomeView} from '../views/Home/HomeView'
import {dashboardPaths, Dashboards} from '../types'
import DynamicPerformanceView from '../views/DynamicPerformance/DynamicPerformanceView'
import MemberView from '../views/Member/MemberView'
import SalesView from '../views/Sales/SalesView'
import InsuranceProviderView from '../views/InsuranceProvider/InsuranceProviderView'
import DocumentView from '../views/Document/DocumentView'
import UploadView from '../views/Upload/UploadView'
import RefundView from '../views/Refund/RefundView'
import ProtectFinancialView from '../views/ProtectFinancial/ProtectFinancialView'
import DynamicPricingAnalysisView from '../views/DynamicPricingAnalysis/DynamicPricingAnalysisView'
import DynamicContentView from '../views/DynamicContent/DynamicContentView'
import LivePerformanceView from '../views/LivePerformance/LivePerformanceView'
import CommissionView from '../views/Commission/CommissionView'
import IntegrationView from '../views/Integration/IntegrationView'
import {ProtectedRoute} from './ProtectedRoute'
import PulseOverviewView from '../views/Pulse/PulseOverviewView'
import PulseRevenueTrendsView from '../views/Pulse/PulseRevenueTrendsView'
import PulseCustomerTrendsView from '../views/Pulse/PulseCustomerTrendsView'
import PulseTransactionsView from '../views/Pulse/PulseTransactionsView'
import PulseSettingsView from '../views/Pulse/PulseSettingsView'
import PulsePartnersView from '../views/Pulse/PulsePartnersView'
import PulseVerticalsView from '../views/Pulse/PulseVerticalsView'

export type RouteConfig = {
	dashboard: Dashboards
	menuIcon: string
	menuTitle: string
	title: string
	view: ReactElement
	showSidebar: boolean
}

export const protectedRoutes: RouteConfig[] = [
	{
		dashboard: Dashboards.Commission,
		menuIcon: 'icon-coins',
		menuTitle: 'Commission',
		title: 'Commission Dashboard',
		view: <CommissionView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.Document,
		menuIcon: 'icon-single-copy-04',
		menuTitle: 'Documents',
		title: 'Documents Dashboard',
		view: <DocumentView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.DynamicContent,
		menuIcon: 'icon-components',
		menuTitle: 'Dynamic Content',
		title: 'Refund Protect - Dynamic Content Dashboard',
		view: <DynamicContentView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.DynamicPerformance,
		menuIcon: 'icon-vector',
		menuTitle: 'Dynamic Performance',
		title: 'Dynamic Performance Dashboard',
		view: <DynamicPerformanceView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.DynamicPricing,
		menuIcon: 'icon-atom',
		menuTitle: 'Dynamic Pricing',
		title: 'Refund Protect - Dynamic Pricing Analysis Dashboard',
		view: <DynamicPricingAnalysisView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.InsuranceProvider,
		menuIcon: 'icon-wallet-43',
		menuTitle: 'Insurance Provider',
		title: 'Insurance Provider Dashboard',
		view: <InsuranceProviderView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.LivePerformance,
		menuIcon: 'icon-trophy',
		menuTitle: 'Live Performance',
		title: 'Live Performance Dashboard',
		view: <LivePerformanceView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.Member,
		menuIcon: 'icon-chart-pie-36',
		menuTitle: 'Members',
		title: 'Member Dashboard',
		view: <MemberView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.ProtectFinancial,
		menuIcon: 'icon-coins',
		menuTitle: 'Protect Financial',
		title: 'Protect Financial Dashboard',
		view: <ProtectFinancialView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.Refund,
		menuIcon: 'icon-notes',
		menuTitle: 'Refund',
		title: 'Refund Dashboard',
		view: <RefundView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.Sales,
		menuIcon: 'icon-chart-bar-32',
		menuTitle: 'Sales',
		title: 'Sales Dashboard',
		view: <SalesView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.Integration,
		menuIcon: 'icon-laptop',
		menuTitle: 'Integration',
		title: 'Integration Dashboard',
		view: <IntegrationView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.Upload,
		menuIcon: 'icon-upload',
		menuTitle: 'Upload',
		title: 'Upload Dashboard',
		view: <UploadView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.PulseOverview,
		menuIcon: 'icon-sound-wave',
		menuTitle: 'Pulse',
		title: 'Pulse Dashboard - Overview',
		view: <PulseOverviewView/>,
		showSidebar: true
	},
	{
		dashboard: Dashboards.PulseRevenueTrends,
		menuIcon: '',
		menuTitle: '',
		title: 'Pulse Dashboard - Revenue Trends',
		view: <PulseRevenueTrendsView/>,
		showSidebar: false
	},
	{
		dashboard: Dashboards.PulseCustomerTrends,
		menuIcon: '',
		menuTitle: '',
		title: 'Pulse Dashboard - Customer Trends',
		view: <PulseCustomerTrendsView/>,
		showSidebar: false
	},
	{
		dashboard: Dashboards.PulseTransactions,
		menuIcon: '',
		menuTitle: '',
		title: 'Pulse Dashboard - Transactions',
		view: <PulseTransactionsView/>,
		showSidebar: false
	},
	{
		dashboard: Dashboards.PulseSettings,
		menuIcon: '',
		menuTitle: '',
		title: 'Pulse Dashboard - Settings',
		view: <PulseSettingsView/>,
		showSidebar: false
	},
	{
		dashboard: Dashboards.PulsePartners,
		menuIcon: '',
		menuTitle: '',
		title: 'Pulse Dashboard - Partners',
		view: <PulsePartnersView/>,
		showSidebar: false
	},
	{
		dashboard: Dashboards.PulseVerticals,
		menuIcon: '',
		menuTitle: '',
		title: 'Pulse Dashboard - Verticals',
		view: <PulseVerticalsView/>,
		showSidebar: false
	},
]

const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/signin-oidc" element={<SignInRedirect/>}/>
			<Route path="/login" element={<Login/>}/>
			<Route path="/silent-refresh" element={<SilentRefresh/>}/>
			<Route path="/" element={<AuthenticatedRoute/>}>
				<Route path="home" element={<HomeView/>}/>
				{
					protectedRoutes.map((route: RouteConfig) => (
						<Route
							element={
								<ProtectedRoute dashboard={route.dashboard} title={route.title}>
									{route.view}
								</ProtectedRoute>
							}
							key={`route-${route.dashboard}`}
							path={dashboardPaths[route.dashboard]}
						/>
					))
				}
			</Route>
		</Routes>
	)
}

export {AppRoutes}
