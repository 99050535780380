export enum ContinentEnum {
	Asia = "Asia",
	Africa = "Africa",
	Europe = "Europe",
	NorthAmerica = "NorthAmerica",
	Oceania = "Oceania",
	SouthAmerica = "SouthAmerica",
	Unknown = "Unknown",
	
	AsiaTwoLetterCode = "AS",
	AfricaTwoLetterCode = "AF",
	EuropeTwoLetterCode = "EU",
	NorthAmericaTwoLetterCode = "NA",
	OceaniaTwoLetterCode = "OC",
	SouthAmericaTwoLetterCode = "SA",
	UnknownTwoLetterCode = "XX"
}

export const continentLabels: Record<string, string> = {
	[ContinentEnum.Asia]: 'ASIA',
	[ContinentEnum.Africa]: 'MIDDLE EAST & AFRICA',
	[ContinentEnum.Europe]: 'EUROPE',
	[ContinentEnum.NorthAmerica]: 'NORTH AMERICA',
	[ContinentEnum.Oceania]: 'OCEANIA',
	[ContinentEnum.SouthAmerica]: 'SOUTH AMERICA'
}

export enum RegionIds {
	Oceania = 2,
	Asia = 3,
	Europe = 4,
	Africa = 6,
	NorthAmerica = 7,
	SouthAmerica = 8
}

export const mapToggleLabels: Record<string, string> = {
	ep: 'EP',
	rp: 'RP'
}

export const regionMapNames: Record<RegionIds, string> = {
	[RegionIds.Africa]: 'africa_me_mill',
	[RegionIds.Asia]: 'asia_wme_mill',
	[RegionIds.Europe]: 'europe_mill',
	[RegionIds.NorthAmerica]: 'north_america_mill',
	[RegionIds.Oceania]: 'oceania_mill',
	[RegionIds.SouthAmerica]: 'south_america_mill'
}

export interface MapProductToggle {
	label: string,
	isSelected: boolean
}
