import React from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'
import {salesFilterState} from '../../../../state/sales'
import {useSalesApi} from '../../../../hooks/api/sales'
import {useApiValue} from '../../../../hooks/api'

export const MembersCount = () => {
	const color = useSummaryCardColor()
	const {getMemberCount} = useSalesApi()
	const {data} = useApiValue(salesFilterState, getMemberCount, 0)

	return (
		<ValueCard
			title={`Number of Members`}
			value={data}
			icon="icon-badge"
			iconColor={color}
		/>
	)
}