import React, {memo, useEffect, useState} from 'react'
import {ValueDirectionProps, ValueStatusCard} from '../../../components/Card/ValueStatusCard'
import {refundFilterState, ValueStatus} from '../../../types/refund'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'

const AvgHandleTime = memo(() => {

	const [valueStatusData, setValueStatusData] = useState<ValueDirectionProps[]>([])
	const {getAverageHandledTimeData} = useRefundApi()
	const {data} = useApiValue(refundFilterState, getAverageHandledTimeData, {} as ValueStatus)

	useEffect(() => {
		if (data) {
			const {value, isPositive} = data
			const next: ValueDirectionProps = {
				value: (`${value} HRS`),
				direction: isPositive ? 'up' : 'down',
				colour: isPositive ? 'red' : 'green'
			}
			setValueStatusData([next])
		}
	}, [data])

	return (
		<ValueStatusCard
			title={'Average Handle Time'}
			values={valueStatusData}
			subTitle="Last 24Hrs"
		/>
	)
})

export {AvgHandleTime}