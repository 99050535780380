import React from 'react'
import SelectFilterCard, {SelectFilterItem} from '../../../components/Dashboard/SelectFilter'

const UserFilter = () => {
	const handleChange = (value: any) => {}

	return (
		<SelectFilterCard
			title="User"
			items={items}
			onChange={handleChange}
		/>
	)
}

export default UserFilter

const items: SelectFilterItem[] = [
	{value: 1, label: 'Bat Man'},
	{value: 2, label: 'Spider Man'}
]
