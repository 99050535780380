import React from "react";
import classNames from "classnames";
import {Card, CardBody, CardTitle} from "reactstrap";

type ArrowDirection = 'up' | 'down' | null
type ArrowColour = 'red' | 'green' | null

export interface ValueDirectionProps {
	value: string
	direction: ArrowDirection
	colour: ArrowColour
	IsPositive?: boolean
}

export interface ValueStatusCardProps {
	values: ValueDirectionProps[]
	title?: string
	divider?: boolean
	subTitle?: string
}

const ValueDirection = (props: ValueDirectionProps) => {
	return (
		<div className="d-flex">
			<span className="mr-1 h6">{props.value}</span>
			<span>
				<i className={classNames('pl-1', 'tim-icons', {
					'icon-minimal-up': props.direction === 'up',
					'icon-minimal-down': props.direction === 'down',
					'text-success': props.colour === 'green',
					'text-error': props.colour === 'red'
				})}/>
			</span>

		</div>
	)
}

export const ValueStatusCard = (props: ValueStatusCardProps) => {
	return (
		<Card className="card-stats pt-2">
			<CardBody className="p-0">
				<div className="d-flex flex-row justify-content-between h-100">
					<div className="numbers w-100 pr-3 d-flex flex-column justify-content-between">
						<div className="numbers w-100 pr-3 d-flex flex-row justify-content-between">
							{
								props?.title &&
									<CardTitle tag="h6" className={`card-category text-uppercase font-weight-bold pb-1 w-100`}>
										{props.title}
									</CardTitle>
							}
						</div>

						<CardTitle tag={"h6"} className="mb-0">
							{
								(props?.subTitle) &&
								(
									<div className="card-category text-uppercase float-left ml-2 pt-1">
										{props.subTitle}
									</div>
								)
							}
							{
								props.values?.map((x, i) => {
									return (
										<div className="d-inline-flex p-1">
											<ValueDirection
												key={`${x.value}-${i}`}
												value={x.value}
												direction={x.direction}
												colour={x.colour}
											/>
											{
												(props?.divider === true && i < props.values.length - 1) &&
												(
													<span className="ml-2">/</span>
												)
											}
										</div>
									)
								})
							}
						</CardTitle>
					</div>
				</div>
			</CardBody>
		</Card>
	)
};