import React from 'react'
import classNames from 'classnames'
import {dateRange} from '../../../../../types/pulse'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {
	dateFromState,
	dateToState,
	selectedDateRangeState,
	selectedMemberIdState
} from '../../../../../state/pulse-filters'

const AllTimeFilter = () => {
	const setDateFrom = useSetRecoilState(dateFromState)
	const setDateTo = useSetRecoilState(dateToState)
	const date = useRecoilValue(selectedDateRangeState)
	const memberId = useRecoilValue(selectedMemberIdState)

	const handleClick = () => {
		setDateFrom(null)
		setDateTo(null)
	}

	return (
		<>
			{memberId !== null && (
				<div
					className={classNames('left-4 mb-2 mr-2 px-3 py-[2px] top-2',
						'text-nowrap text-sm tracking-[1px] uppercase',
						'border border-pulse-icon-gray rounded-full',
						'cursor-pointer',
						'hover:bg-pulse-button-blue',
						'lg:left-0 lg:mb-0 lg:relative lg:top-0', {
							'bg-pulse-button-blue': date === dateRange.AllTime,
							'bg-white': date !== dateRange.AllTime,
							'absolute': date === dateRange.AllTime
						})}
					onClick={handleClick}
				>
					<span
						className="
							align-middle mr-1 mb-[2px]
							leading-5 text-base text-pulse-icon-gray
							material-symbols-outlined"
					>
						start
					</span>
					<span className="align-middle leading-5">All Time</span>
				</div>
			)}
		</>
	)
}

export default AllTimeFilter
