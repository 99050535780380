import React from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import {Button} from 'reactstrap'
import {toUsd} from '../../../utils/number'
import {IconTableCell} from '../../../components/Dashboard/Dynamic/TableCell'
import {MemberSummary} from '../../../types/dynamic-pricing'
import {dynamicPricingFilterState} from '../../../state/dynamic-pricing'
import {useDynamicPricingApi} from '../../../hooks/api/dynamic-pricing'
import {useApiValue} from '../../../hooks/api'

const ExportButton = () => {
	return (
		<div className="d-flex flex-fill justify-content-end align-items-center">
			<Button
				size="sm"
			>
				<i className="tim-icons icon-upload pr-2"/>
				Export
			</Button>
		</div>
	)
}

const MemberPricingSummaryTable = () => {
	const {getMemberSummary} = useDynamicPricingApi()
	const {data} = useApiValue(dynamicPricingFilterState, getMemberSummary, [])

	return (
		<TableCard
			title="Member Pricing Overview"
			headerContent={<ExportButton/>}
		>
			<div>
				<DataTable
					data={data}
					columns={columns}
					theme="dark"
					pagination={true}
				/>
			</div>
		</TableCard>
	)
}

export default MemberPricingSummaryTable

const columns: TableColumn<MemberSummary> [] = [
	{
		name: 'Member',
		selector: ({member}) => member,
		sortable: true
	},
	{
		name: 'Status',
		selector: ({status}) => status,
		center: true,
		cell: (row: MemberSummary) => {
			return (
				<IconTableCell status={row.status}/>
			)
		}
	},
	{
		name: 'Sales Volume',
		selector: ({salesVolume}) => salesVolume,
		center: true,
		sortable: true
	},
	{
		name: 'Sales Value',
		selector: ({salesValue}) => salesValue,
		center: true,
		cell: (row: MemberSummary) => toUsd(row.salesValue.toString()),
		sortable: true
	},
	{
		name: 'Opportunity',
		selector: ({opportunityValue}) => opportunityValue,
		center: true,
		cell: (row: MemberSummary) => toUsd(row.opportunityValue.toString()),
		sortable: true
	},
	{
		name: 'Average Conversion',
		selector: ({conversion}) => conversion,
		center: true,
		cell: (row: MemberSummary) => `${row.conversion.toFixed(2)}%`,
		sortable: true
	},
	{
		name: 'Revenue',
		selector: ({revenue}) => revenue,
		center: true,
		cell: (row: MemberSummary) => toUsd(row.revenue.toString()),
		sortable: true
	},
	{
		name: 'Revenue Per $',
		selector: ({revenueValue}) => revenueValue,
		center: true,
		cell: (row: MemberSummary) => toUsd(row.revenueValue.toString()),
		sortable: true
	},
	{
		name: 'No. of Active Tests',
		selector: ({activeTestCount}) => activeTestCount,
		center: true
	},
	{
		name: 'Impact',
		selector: ({impactPercentage}) => impactPercentage,
		center: true,
		cell: (row: MemberSummary) => `${row.impactPercentage}%`
	}
]
