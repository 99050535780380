import {MutableSnapshot} from 'recoil'
import {defaultRouteState} from './ui'

export const initialState = (snapshot: MutableSnapshot) => {
	const {set} = snapshot
	const defaultRoute = localStorage.getItem('pg-dashboard-default-route')

	if (defaultRoute) {
		set(defaultRouteState, defaultRoute)
	}
}
