import {PulseChartDataModel} from '../../../types/pulse-data'
import {
	graphFilterType,
	graphRevenue,
	graphValueDemographics,
	graphVolumeDemographics
} from '../../../types/pulse'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState, selectedGraphFiltersState} from '../../../state/pulse-filters'
import {formatCurrency} from '../../../utils/currency'
import useGetGraphFilterLabel from './useGetGraphFilterLabel'
import {formatNumberMember} from '../../../utils/number'

export default function useGetCustomerTrendsChartData(recoilValue: { data: PulseChartDataModel, isLoading: boolean }) {
	const graphFilters = useRecoilValue(selectedGraphFiltersState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	const getTitles = () => {
		return graphFilters.map((filter) => getGraphFilterLabel(filter))
	}

	const getColors = () => {
		return [
			'text-black',
			...graphFilters.map((filter) => filter === graphFilterType.YourRevenue
				? 'text-pulse-blue'
				: 'text-black')
		]
	}

	const getData = () => {
		if (!recoilValue.data.countries) {
			return []
		}

		const data: string[][] = []

		for (let i = 0; i < recoilValue.data.countries.length; i++) {
			const country = recoilValue.data.countries[i]

			const record: string[] = []
			record.push(country.countryName || '')

			for (let filter in graphFilters) {
				const graphFilter = graphFilters[filter]
				const value = country.chartData && country.chartData[graphFilter as number]

				if (graphFilter === graphFilterType.AverageConversionRate) {
					record.push(`${value || 0}%`)
					continue
				}

				if (graphValueDemographics.includes(graphFilter)) {
					record.push(formatCurrency(coreCurrency, value || 0))
					continue
				}

				if (graphVolumeDemographics.includes(graphFilter)) {
					record.push(formatNumberMember(value || 0))
					continue
				}

				if (graphRevenue.includes(graphFilter)) {
					record.push(formatCurrency(coreCurrency, value || 0, 0, 4))
				}
			}

			data.push(record)
		}

		return data
	}

	return {
		getColors,
		getData,
		getTitles
	}
}
