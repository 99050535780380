import SelectFilterCard, {SelectFilterItem} from '../../../components/Dashboard/SelectFilter'
import React from 'react'

const RiskTypeFilter = () => {
	const handleChange = (value: any) => {}

	return (
		<SelectFilterCard
			title="Risk Type"
			items={riskTypes}
			onChange={handleChange}
			icon=""
		/>
	)
}

export default RiskTypeFilter

const riskTypes: SelectFilterItem[] = [
	{ value: 1, label: 'Event' },
	{ value: 2, label: 'Flight' },
	{ value: 3, label: 'Hotel' },
	{ value: 4, label: 'Package' },
	{ value: 5, label: 'Ticket' }
]
