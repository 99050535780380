import React from 'react'
import ConfirmModal from '../../../../components/Modal/ConfirmModal'
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import {transactionReferenceIdState} from '../../../../state/member-filters'
import {NotificationMessageType} from '../../../../types'
import {notificationMessageState} from '../../../../state/ui'
import {transactionTableUpdateTriggerState} from '../../../../state/memberFilterState'
import {loggedInUserState} from "../../../../state/auth";
import {useMemberApi} from '../../../../hooks/api/member'

const ConfirmDeleteTransactionModal = () => {
	const {deleteTransaction} = useMemberApi()
	const transactionReferenceId = useRecoilValue(transactionReferenceIdState)
	const userName = useRecoilValue(loggedInUserState) ?? '';
	const setNotification = useSetRecoilState(notificationMessageState)
	const resetTransactionReferenceId = useResetRecoilState(transactionReferenceIdState)
	const setTransactionTableTrigger = useSetRecoilState(transactionTableUpdateTriggerState)

	const cancelTransactionTexts = {
		title: 'Cancel Transaction',
		body: 'You are about to cancel this transaction, are you sure?'
	}

	async function handleConfirm() {
		try {
			const transactionRefId = transactionReferenceId;
			resetTransactionReferenceId()
			await deleteTransaction(transactionRefId ?? 0, userName)
			setNotification({
				message: `Transaction ${transactionRefId} cancelled.`,
				type: NotificationMessageType.success
			})
			setTransactionTableTrigger(curr => curr + 1)
		} catch (error: any) {
			setNotification({
				message: error.response.data,
				type: NotificationMessageType.error
			})
		} finally {
			resetTransactionReferenceId()
		}
	}

	return (
		<ConfirmModal
			titleText={cancelTransactionTexts.title}
			bodyText={cancelTransactionTexts.body}
			isOpen={transactionReferenceId !== null}
			onConfirm={handleConfirm}
			onCancel={resetTransactionReferenceId}
		/>
	)
}

export default ConfirmDeleteTransactionModal
