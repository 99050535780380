import React from 'react'
import {protectFinancialFilterState} from '../../../state/protect-financial'
import {ProtectFinancialTable} from './ProtectFinancialTable'
import {useProtectFinancialApi} from '../../../hooks/api/protect-financial'
import {useApiValue} from '../../../hooks/api'
import {TableColumn} from 'react-data-table-component'
import {OnboardingDataModel} from '../../../types/protect-financial'

const columns: TableColumn<OnboardingDataModel> [] = [
	{
		name: 'BDM',
		selector: ({user}) => user
	},
	{
		name: 'Company Profile',
		selector: ({companyProfile}) => companyProfile
	},
	{
		name: 'Terms Approval',
		selector: ({termsApproved}) => termsApproved
	},
	{
		name: 'Payroll Setup',
		selector: ({payrollSetup}) => payrollSetup
	},
	{
		name: 'Employee Communication',
		selector: (row: any) => row.employeeCommunication,
	}
]

const OnboardingTable = () => {
	const {getOnboardingData} = useProtectFinancialApi()
	const {data} = useApiValue(protectFinancialFilterState, getOnboardingData, [])
	return (
		<ProtectFinancialTable
			title="Onboarding"
			columns={columns}
			selector={data}/>
	)
}

export {OnboardingTable}