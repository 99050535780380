import {Button, ButtonGroup} from 'reactstrap'
import React from 'react'
import {mapToggleColorsState, mapTogglesState} from '../../../../state/member-data'
import {useRecoilState, useRecoilValue} from 'recoil'
import {MapProductToggle, RegionIds} from '../../../../types/region'

interface ProductTogglesProps {
	region: RegionIds
}

const ProductToggles = (props: ProductTogglesProps) => {
	const {region} = props
	const [toggles, setToggles] = useRecoilState(mapTogglesState(region))
	const toggleColors: any = useRecoilValue(mapToggleColorsState(region))

	const handleClick = (label: string) => () => {
		setToggles(curr => {
			const next = [...curr]
			const index = curr.findIndex(toggle => toggle.label === label)
			next[index] = {
				...next[index],
				isSelected: !next[index].isSelected
			}

			return next
		})
	}

	return (
		<div className="text-center">
			<ButtonGroup size="sm">
				{
					toggles.map((toggle: MapProductToggle, i: number) => {
						return (
							<Button
                key={`product-toggle-${region}-${i}`}
								color={toggleColors[i]}
								active={toggle.isSelected}
								onClick={handleClick(toggle.label)}
							>
								{toggle.label}
							</Button>
						)
					})
				}
			</ButtonGroup>
		</div>

	)
}

export {ProductToggles}
