import React, {useEffect, useState} from 'react';
import {Timezone} from '../../../../types';
import {uploadFilterState} from '../../../../state/upload-filter';
import {selectedTimezoneUpload} from '../../../../state/upload-data';
import Select from 'react-select';
import {useRecoilState} from 'recoil';
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter';
import {useUploadApi} from "../../../../hooks/api/upload";
import {useApiValue} from "../../../../hooks/api";

const TimezoneDropdown = () => {
	const {getTimezones} = useUploadApi();
	const {data, isLoading} = useApiValue(uploadFilterState, getTimezones, [])
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([]);
	const [timezoneSelectedState, setTimezoneSelectedState] = useRecoilState(selectedTimezoneUpload);

	useEffect(() => {
		if (data?.length > 0 && !isLoading) {
			const next = data.map((x: Timezone) => ({
				value: x.id,
				label: x.name,
			}));
			setMenuItems(next);
		}
	}, [data, isLoading]);

	const handleTimezoneChange = (selectedOption: SelectFilterItem | null) => {
		setTimezoneSelectedState(selectedOption?.value);
	};

	return (
		<Select
			options={menuItems}
			className="react-select max-width-75"
			classNamePrefix="react-select"
			value={menuItems.find((item) => item.value === timezoneSelectedState)}
			onChange={handleTimezoneChange}
		/>
	);
};

export {TimezoneDropdown}