import {atom, selector} from "recoil";
import moment, {Moment} from "moment/moment";
import {dateFormat} from "../utils/date";
import {
  CurrencyCode, 
  FilterEnum, 
  IdEnum
} from "../types/insurance-provider";

export const selectedMainProductIdStateInsurance = atom<number>({
  key: 'selectedMainProductIdStateInsurance',
  default: IdEnum.DefaultId
});

export const selectedCoreCurrencyStateInsurance = atom<string>({
  key: 'selectedCoreCurrencyStateInsurance',
  default: "USD"
});

export const selectedInsuranceProviderStateInsurance = atom<number>({
  key: 'selectedInsuranceProviderStateInsurance',
  default: IdEnum.DefaultId
});

export const selectedEventStateInsurance = atom<string>({
  key: 'selectedEventStateInsurance',
  default: FilterEnum.DefaultFilterAll
});

export const selectedEventIdsStateInsurance = atom<number[]>({
  key: 'selectedEventIdsStateInsurance',
  default: []
});

export const selectedEventProductTypeStateInsurance = atom<number>({
  key: 'selectedEventProductTypeStateInsurance',
  default: IdEnum.DefaultId
});

export const selectedRefundProductTypeStateInsurance = atom<number>({
  key: 'selectedRefundProductTypeStateInsurance',
  default: IdEnum.DefaultId
});

export const selectedCurrencyStateInsurance = atom<string>({
  key: 'selectedCurrencyStateInsurance',
  default: CurrencyCode.Default  // Equals to "All".
});

export const selectedLocationStateInsurance = atom<string>({
  key: 'selectedLocationStateInsurance',
  default: FilterEnum.DefaultFilterAll
});

export const dateFromStateInsurance = atom<Moment>({
  key: 'dateFromStateInsurance',
  default: moment().startOf('year')
});

export const dateToStateInsurance = atom<Moment>({
  key: 'dateToStateInsurance',
  default: moment()
});

export const formattedDateFromStateInsurance = selector<string>({
  key: 'formattedDateFromStateInsurance',
  get: ({ get }) => get(dateFromStateInsurance).format(dateFormat)
});

export const formattedDateToStateInsurance = selector<string>({
  key: 'formattedDateToStateInsurance',
  get: ({ get }) => get(dateToStateInsurance).format(dateFormat)
});