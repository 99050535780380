// import {useRecoilValue} from 'recoil'
// import {monthsInDateRangeState, selectedUserNameState} from '../../../state/filters'
import {useCommissionApi} from '../../../hooks/api/commission'
import {useApiValue} from '../../../hooks/api'
import {commissionFilterState} from '../../../state/commission-data'

const useCommissionUserData = () => {
	// const months = useRecoilValue(monthsInDateRangeState)
	// const userName = useRecoilValue(selectedUserNameState)
	// const user = useRecoilValue(selectedUserState)
	// const filter = useRecoilValue(commissionFilterState)
	const {getUsers} = useCommissionApi()
	const {data: users} = useApiValue(commissionFilterState, getUsers, [])

	return {
		users,
		userNames: []
	}
}

export {useCommissionUserData}
