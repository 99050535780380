import React from 'react';
import {
	Row, Col
} from 'reactstrap';
import {DateFilters} from './DateFilters';
import {MemberDropdown} from './MemberDropdown';
import {ProductDropdown} from './ProductDropdown';
import {LocationDropdown} from './LocationDropdown';
import {CurrencyDropdown} from './CurrencyDropdown';
import {CoreCurrencyDropdown} from './CoreCurrencyDropdown';
import {TypeDropdown} from './TypeDropdown';
import {EventDropdown} from './EventDropdown';
import useGetMainProductStates from "../../hooks/useGetMainProductStates";
import classNames from "classnames";

const Filters = () => {
	const {isProductAll, isProductEp, isProductRp} = useGetMainProductStates()
	const classNameByProduct = classNames({
		'pb-1': isProductAll,
		'pb-3': isProductEp || isProductRp
	});
	const columnWidth = isProductAll ? 2 : 3
	return (
		<>
			<Row>
				<Col
					className={classNameByProduct}
					md={columnWidth}
				>
					<DateFilters/>
				</Col>
				<Col
					className={classNameByProduct}
					md={columnWidth}
				>
					<ProductDropdown/>
				</Col>
				<Col
					className={classNameByProduct}
					md={columnWidth}
				>
					<MemberDropdown/>
				</Col>
				<Col
					className={classNameByProduct}
					md={columnWidth}
				>
					<LocationDropdown/>
				</Col>
				<Col
					hidden={isProductAll}
					className="pb-3" md="3"
				>
					<TypeDropdown/>
				</Col>
				<Col
					className={classNameByProduct}
					md={columnWidth}
				>
					<CurrencyDropdown/>
				</Col>
				<Col
					className={classNameByProduct}
					md={columnWidth}
				>
					<CoreCurrencyDropdown/>
				</Col>
				<Col
					hidden={!isProductEp}
					className="pb-3" md="3"
				>
					<EventDropdown/>
				</Col>
			</Row>
		</>
	);
};

export {Filters};
