import React from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import classNames from 'classnames'
import {Button} from 'reactstrap'
import {useRecoilValue} from 'recoil'
import {
	canApproveRateChangesState,
	dynamicPricingFilterState
} from '../../../state/dynamic-pricing'
import {RateChangeRequest, RateChangeRequestStatus} from '../../../types/dynamic-pricing'
import moment from 'moment'
import {dateFormat} from '../../../utils/date'
import {useDynamicPricingApi} from "../../../hooks/api/dynamic-pricing";
import {useApiValue} from "../../../hooks/api";

const PriceChangeRequestTable = () => {
	const {getRateChangeRequests} = useDynamicPricingApi();
	const {data} = useApiValue(dynamicPricingFilterState, getRateChangeRequests, [])

	return (
		<TableCard
			title="Price Change Requests"
		>
			<div className="no-coloured-cell-padding">
				<DataTable
					data={data}
					columns={columns}
					theme="dark"
					pagination={true}
				/>
			</div>
		</TableCard>
	)
}

export default PriceChangeRequestTable

interface RequestStatusCellProps {
	status: RateChangeRequestStatus
}

const RequestStatusCell = (props: RequestStatusCellProps) => {
	const canApprove = useRecoilValue(canApproveRateChangesState)
	const {status} = props

	if (status === RateChangeRequestStatus.pending) {
		return (
			<div className="d-flex w-100 align-items-center justify-content-around">
				{
					(canApprove) && (
						<>
							<div>
								<Button color="success" size="sm">
									<i className="tim-icons icon-check-2 pr-2"/>
									Approve
								</Button>
							</div>
							<div>
								<Button color="danger" size="sm">
									<i className="tim-icons icon-simple-remove pr-2"/>
									Decline
								</Button>
							</div>
						</>
					)
				}
			</div>
		)
	}

	return (
		<div className={classNames('w-100 d-flex justify-content-center align-items-center h-100', {
			'danger': status === RateChangeRequestStatus.declined,
			'success': status === RateChangeRequestStatus.approved
		})}>
			<i className={classNames('tim-icons', 'tim-icons-bg-circle', 'bg-white', {
				'icon-check-2 text-success': status === RateChangeRequestStatus.approved,
				'icon-simple-remove text-error': status === RateChangeRequestStatus.declined
			})}/>
		</div>
	)
}

const columns: TableColumn<RateChangeRequest> [] = [
	{
		name: 'Date of Change',
		selector: ({date}) => date,
		center: true,
		cell: (row: RateChangeRequest) => moment(row.date).format(dateFormat),
		sortable: true,
		sortFunction: (a: RateChangeRequest, b: RateChangeRequest) => {
			const dateA = moment(a.date)
			const dateB = moment(b.date)

			if (dateA > dateB) {
				return 1
			}

			if (dateB > dateA) {
				return -1
			}

			return 0
		}
	},
	{
		name: 'Protect User',
		selector: ({user}) => user,
		center: true
	},
	{
		name: 'Value Band',
		selector: ({valueBand}) => valueBand,
		center: true
	},
	{
		name: 'Time Period',
		selector: ({timeBand}) => timeBand,
		center: true
	},
	{
		name: 'Value',
		selector: ({currentValue}) => currentValue,
		center: true,
		sortable: true
	},
	{
		name: 'Conversion',
		selector: ({currentConversion}) => currentConversion,
		center: true,
		cell: (row: RateChangeRequest) => `${row.currentConversion}%`,
		sortable: true
	},
	{
		name: 'Current Rate',
		selector: ({currentRate}) => currentRate,
		center: true,
		cell: (row: RateChangeRequest) => `${row.currentRate}%`,
		sortable: true
	},
	{
		name: 'New Rate',
		selector: ({newRate}) => newRate,
		center: true,
		cell: (row: RateChangeRequest) => `${row.newRate}%`,
		sortable: true
	},
	{
		name: 'Request Status',
		center: true,
		grow: 1,
		minWidth: '250px',
		cell: (row: RateChangeRequest) => {
			const {status} = row
			return (
				<RequestStatusCell status={status}/>
			)
		}
	}
]
