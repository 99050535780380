import React, {useEffect} from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {
	exportDataState,
	memberTransactionsOutOfRangeState,
	titleSuffixState
} from '../../state/pulse'
import H2 from './components/Common/Headers/H2'
import DataAvailabilityWarningOverlay from './components/DataAvailabilityWarningOverlay'
import PartnersTable from './components/PartnersTable'
import useExportPartnersRevenueData from './hooks/useExportPartnersRevenueData'

const PulseTransactionsView = () => {
	const exportData = useExportPartnersRevenueData()
	const titleSuffix = useRecoilValue(titleSuffixState)
	const memberTransactionsOutOfRange = useRecoilValue(memberTransactionsOutOfRangeState)
	const setExportDataState = useSetRecoilState(exportDataState)

	useEffect(() => {
		setExportDataState(exportData)
	}, [exportData])

	return (
		<DashboardPageLayout>
			<>
				<section className="
					max-w-6xl mb-8 px-4 relative w-full
					lg:mx-auto lg:px-10"
				>
					<H2>Partners - {titleSuffix}</H2>
					{!memberTransactionsOutOfRange && (<PartnersTable/>)}
					{memberTransactionsOutOfRange && (<DataAvailabilityWarningOverlay/>)}
				</section>
			</>
		</DashboardPageLayout>
	)
}

export default PulseTransactionsView
