import React, {memo} from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import useGetSummaryValues from '../../hooks/useGetSummaryValues'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'

const ConversionRate = memo(() => {
	const {totalConversionRate} = useGetSummaryValues()
	const color = useSummaryCardColor()

	return (
		<ValueCard
			title={`Conversion`}
			value={parseInt(totalConversionRate.toString()).toFixed(2) + '%'}
			icon="icon-satisfied"
			tooltip={'Overall conversion rate.'}
			iconColor={color}
		/>
	)
})

export {ConversionRate}
