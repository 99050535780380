import {IconTableCell} from '../../../components/Dashboard/Dynamic/TableCell'
import {toUsd} from '../../../utils/number'
import React from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import {Button} from 'reactstrap'

const ExportButton = () => {
	return (
		<div className="d-flex flex-fill justify-content-end align-items-center">
			<Button
				size="sm"
			>
				<i className="tim-icons icon-upload pr-2"/>
				Export
			</Button>
		</div>
	)
}

const MemberContentSummaryTable = () => {
	return (
		<TableCard
			title="Member Content Overview"
			headerContent={<ExportButton/>}
		>
			<div>
				<DataTable
					data={tableData}
					columns={columns}
					theme="dark"
				/>
			</div>
		</TableCard>
	)
}

export default MemberContentSummaryTable

const columns: TableColumn<any> [] = [
	{
		name: 'Member',
		selector: (row: any) => row.member
	},
	{
		name: 'Status',
		selector: (row: any) => row.status,
		center: true,
		cell: (row: any) => {
			const {status} = row
			return (
				<IconTableCell status={status}/>
			)
		}
	},
	{
		name: 'Sales Volume',
		selector: (row: any) => row.salesVolume,
		center: true
	},
	{
		name: 'Sales Value',
		selector: (row: any) => row.salesValue,
		center: true,
		cell: (row: any) => toUsd(row.salesValue)
	},
	{
		name: 'Opportunity',
		selector: (row: any) => row.opportunity,
		center: true,
		cell: (row: any) => toUsd(row.opportunity)
	},
	{
		name: 'Average Conversion',
		selector: (row: any) => row.averageConversion,
		center: true,
		cell: (row: any) => `${row.averageConversion}%`
	},
	{
		name: 'Revenue',
		selector: (row: any) => row.revenue,
		center: true,
		cell: (row: any) => toUsd(row.revenue)
	},
	{
		name: 'Revenue Per $',
		selector: (row: any) => row.revenueValue,
		center: true,
		cell: (row: any) => toUsd(row.revenueValue)
	},
	{
		name: 'No. of Active Tests',
		selector: (row: any) => row.activeTestCount,
		center: true
	},
	{
		name: 'Impact',
		selector: (row: any) => row.impactPercentage,
		center: true,
		cell: (row: any) => `${row.impactPercentage}%`
	}
]

const tableData = [
	{
		member: 'Member A',
		status: 'Good',
		salesVolume: 300,
		salesValue: 35.6,
		opportunity: 45.9,
		averageConversion: 21.9,
		revenue: 600.89,
		revenueValue: 456,
		activeTestCount: 8,
		impactPercentage: 34.2
	},
	{
		member: 'Member B',
		status: 'Bad',
		salesVolume: 300,
		salesValue: 35.6,
		opportunity: 45.9,
		averageConversion: 21.9,
		revenue: 600.89,
		revenueValue: 456,
		activeTestCount: 8,
		impactPercentage: 34.2
	},
	{
		member: 'Member C',
		status: 'Average',
		salesVolume: 300,
		salesValue: 35.6,
		opportunity: 45.9,
		averageConversion: 21.9,
		revenue: 600.89,
		revenueValue: 456,
		activeTestCount: 8,
		impactPercentage: 34.2
	}
]
