import React, {ReactElement, useState} from 'react'
import Tooltip from '../Tooltip/Tooltip'
import {v4 as uuidv4} from 'uuid'
import LoadingSpinner from '../Spinner/LoadingSpinner'
import {Dialog} from '@headlessui/react'
import DefaultButton from '../Buttons/DefaultButton'
import classNames from 'classnames'
import TrendCardText from './TrendCardText'
import {Currency} from '../FormatElements'

interface PrimaryValueCardProps {
	header: string,
	tooltipText: string,
	value?: number,
	valueClassName: string,
	trendValue?: number,
	isLoading: boolean,
	warning?: boolean,
	warningTitle?: string,
	warningPopupTitle?: string,
	warningPopupBody?: ReactElement,
	warningPopupButtons?: ReactElement
}

const PrimaryValueCard = (props: PrimaryValueCardProps) => {
	const id = uuidv4()
	const isLargerValue = props.value && props.value > 1000000000
	const [isOpen, setIsOpen] = useState(false)
	const handleModalOpen = () => setIsOpen(true)
	const handleModalClose = () => setIsOpen(false)

	return (
		<div className="flex h-24 relative">
			<LoadingSpinner
				isLoading={props.isLoading}
				className="w-full"
			>
				<>
					<div className="mr-2 flex-none">
						<div className="flex h-full items-center">
							<span className="
								text-4xl text-pulse-icon-gray
								material-symbols-outlined"
							>
								account_balance_wallet
							</span>
						</div>
					</div>
					<div>
						<div className="
							text-sm text-pulse-label-gray"
						>
							{props.header}
						</div>
						<div className={classNames('mb-1',
							'text-4xl',
							props.valueClassName, {
								'lg:text-3xl': !isLargerValue,
								'lg:text-2xl': isLargerValue
							})}
						>
							<Currency value={props.value}/>
						</div>
						{props.trendValue !== undefined && (<TrendCardText trendValue={props.trendValue}/>)}
						{props.tooltipText && (
							<Tooltip
								id={`${id}-popup`}
								tooltipTitle={props.header}
								tooltipText={props.tooltipText}
								tooltipClassName="absolute -right-3 -top-3"
							/>
						)}
						{props.warning && (
							<>
								<div
									className="
										absolute -bottom-2 -right-3
										text-[.6rem] text-pulse-red
										cursor-pointer"
									onClick={handleModalOpen}>
									<span>
										<span className="
											mr-1
											align-middle text-xs
											cursor-pointer
											material-symbols-outlined"
										>
											error
										</span>
										<span className="
											align-middle leading-5 underline"
										>
											{props.warningTitle}
										</span>
									</span>
								</div>
								<Dialog
									open={isOpen}
									onClose={handleModalClose}
									className="relative z-50"
								>
									<div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
									<div className="
										fixed flex items-center justify-center p-4 w-screen
										inset-0"
									>
										<Dialog.Panel className="
											max-w-sm min-w-[90%] mx-auto px-6 py-12 relative
											bg-white rounded
											md:min-w-[50%]"
										>
											<div
												className="
													absolute right-4 top-4
													cursor-pointer"
												onClick={handleModalClose}
											>
												<span className="material-symbols-outlined">close</span>
											</div>
											<Dialog.Title className="
												mb-6 pb-6
												font-medium
												border-b border-pulse-icon-gray"
											>
												{props.warningPopupTitle}
											</Dialog.Title>
											<Dialog.Description as="div">
												{props.warningPopupBody}
												<p className="flex mt-8 space-x-2">
													{props.warningPopupButtons}
													<DefaultButton text="Close" onClick={handleModalClose}/>
												</p>
											</Dialog.Description>
										</Dialog.Panel>
									</div>
								</Dialog>
							</>
						)}
					</div>
				</>
			</LoadingSpinner>
		</div>
	)
}

export default PrimaryValueCard
