import {useApiActions} from './index'
import {DynamicPerformanceFilter} from '../../types/dynamic-performance'
import {MemberSummary, RateChangeRequest, TimeValueData, Variable} from '../../types/dynamic-pricing'

export const useDynamicPricingApi = () => {
	const {callApi} = useApiActions('dynamic-pricing')

	const getLiveTestCount = async (filter: DynamicPerformanceFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'live-test-count'
		})
	}

	const getMemberSummary = async (filter: DynamicPerformanceFilter): Promise<MemberSummary[]> => {
		return await callApi<MemberSummary[], string>({
			query: filter,
			path: 'member-summary'
		})
	}

	const getRateChangeRequests = async (filter: DynamicPerformanceFilter): Promise<RateChangeRequest[]> => {
		return await callApi<RateChangeRequest[], string>({
			query: filter,
			path: 'price-change-requests'
		})
	}

	const getTimeValueData = async (filter: DynamicPerformanceFilter): Promise<TimeValueData[]> => {
		return await callApi<TimeValueData[], string>({
			query: filter,
			path: 'time-value-data'
		})
	}

	const getVariables = async (filter: DynamicPerformanceFilter): Promise<Variable[]> => {
		return await callApi<Variable[], string>({
			query: filter,
			path: 'variables'
		})
	}

	return {
		getLiveTestCount,
		getMemberSummary,
		getRateChangeRequests,
		getTimeValueData,
		getVariables,
	}
}
