import React from 'react';
import {TableCard} from '../../../../components/Card/TableCard';
import DataTable, {TableColumn} from 'react-data-table-component'
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {toPercentage, toUsd} from "../../../../utils/number";
import {ValueBandsRowData} from '../../../../types/insurance-provider'
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const Title = (props: {mainHeader: string}) => {
	return (
		<div className={'align-items-center bg-transparent'} id={props.mainHeader}>
			<div className={'text-center pt-1 pb-1 px-4'} style={{borderBottom: "solid 1px white"}}>
				{props.mainHeader}
			</div>
			<div className={'d-flex flex-row pt-1 text-center'}>
				<div className={'d-inline-block w-50 text-center border-right px-4 text-nowrap'}>
					Insured
				</div>
				<div className={'d-inline-block w-50 text-center px-4'}>
					CEF
				</div>
			</div>
		</div>
	)
}

const Cell = (props: {
	insuredValue: number,
	cefValue: number,
	format: (value: string) => string
}) => {
	
	return(
		<div className={'d-flex flex-fill'}>
			<div className={'w-50 text-center border-right'}>
				{props.format(props.insuredValue.toString())}
			</div>
			<div className={' pl-2 w-50 text-center'}>
				{props.format(props.cefValue.toString())}
			</div>
		</div>
	)
}

const columns: TableColumn<ValueBandsRowData> []  = [
	{
		name: 'Value Bands',
		selector: ({valueBand}) => valueBand,
		wrap: true
	},
	{
		name: 'Number',
		selector: ({transactionVolume}) => transactionVolume,
		compact:true,
	},
	{
		name: 'Amount',
		selector: ({transactionsValue}) => transactionsValue,
		format: (row: ValueBandsRowData) => toUsd(row.transactionsValue.toString()),
		compact:true,
	},
	{
		name: 'Active',
		selector: ({activeTransactionsValue}) => activeTransactionsValue,
		format: (row: ValueBandsRowData) => toUsd(row.activeTransactionsValue.toString()),
		compact:true,
	},
	{
		name: 'Tagged',
		selector: ({taggedTransactionsValue}) => taggedTransactionsValue,
		format: (row: ValueBandsRowData) => toUsd(row.taggedTransactionsValue.toString()),
		compact:true,
	},
	{
		name: 'Expired',
		selector: ({expiredTransactionsValue}) => expiredTransactionsValue,
		format: (row: ValueBandsRowData) => toUsd(row.expiredTransactionsValue.toString()),
		compact:true,
	},
	{
		name: <Title mainHeader={'Refund'} />,
		selector: ({refundCefValue}) => refundCefValue,
		cell: (row: ValueBandsRowData) =>
			<Cell
				cefValue={row.refundCefValue}
				insuredValue={row.refundInsuredValue}
				format={toUsd}
			/>,
	},
	{
		name: <Title mainHeader={'Premium'} />,
		selector: ({premiumCefValue}) => premiumCefValue,
		cell: (row: ValueBandsRowData) =>
			<Cell
				cefValue={row.premiumCefValue}
				insuredValue={row.premiumInsuredValue}
				format={toUsd}
			/>,
	},
	{
		name: <Title mainHeader={'Loss Ratio'} />,
		selector: ({lossRatioCefValue}) => lossRatioCefValue,
		cell: (row: ValueBandsRowData) =>
			<Cell
				cefValue={row.lossRatioCefValue}
				insuredValue={row.lossRatioInsuredValue}
				format={toPercentage}
			/>,
	}
]

const ValueBandsTable = () => {
	const {getValueBandsTableData} = useInsuranceApi();
	const {data} = useApiValue(insuranceProviderFilterState, getValueBandsTableData, [])

	return (
		<TableCard title={'Value Bands'}>
			<DataTable
				data={data}
				columns={columns}
				theme="dark"
				fixedHeader={true}
				fixedHeaderScrollHeight={"50vh"}
				responsive={true}
			/>
		</TableCard>
	)
}

export { ValueBandsTable };