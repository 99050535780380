import React from 'react'
import {Bar} from 'react-chartjs-2'
import {FixedHeightChartCard} from '../../../../components/Card/FixedHeightChartCard'
import {ChartHeightValue, tooltipList} from '../../../../utils/chart'
import {integrationFilterState} from '../../../../state/integration'
import {useApiValue} from '../../../../hooks/api'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {defaultIntegrationStatusTimeModel} from '../../../../types/integration'
import {useGetStatusTimeChartData} from "../../hooks/useGetStatusTimeChartData"

export const VerticaStatusTimeChart = () => {
    const {getDaysInStatus} = useIntegrationApi()
    const {data} = useApiValue(integrationFilterState, getDaysInStatus, defaultIntegrationStatusTimeModel)
    const {getChartData, barChartOptions} = useGetStatusTimeChartData(data)
    const {verticalData} = getChartData()
    
    return (
        <FixedHeightChartCard
            title={`Status distribution by vertical`}
            titleClass="card-title-info"
            height={ChartHeightValue['small']}
            tooltip={tooltipList.VerticalStatusTime}
        >
            <Bar
                data={verticalData}
                options={barChartOptions}
                className="main-chart-max-height"
            />
        </FixedHeightChartCard>
    )
}

export const RegionStatusTimeChart = () => {
    const {getDaysInStatus} = useIntegrationApi()
    const {data} = useApiValue(integrationFilterState, getDaysInStatus, defaultIntegrationStatusTimeModel)
    const {getChartData, barChartOptions} = useGetStatusTimeChartData(data)
    const {regionData} = getChartData()

    return (
        <FixedHeightChartCard
            title={`Status distribution by region`}
            titleClass="card-title-info"
            height={ChartHeightValue['small']}
            tooltip={tooltipList.RegionStatusTime}
        >
            <Bar
                data={regionData}
                options={barChartOptions}
                className="main-chart-max-height"
            />
        </FixedHeightChartCard>
    )
}
