import React from 'react';
import {Row, Col} from 'reactstrap';
import {InsurancePremiumChart} from "./InsurancePremiumChart";
import {TransactionValueChart} from "./TransactionValueChart";
import {TransactionVolumeChart} from "./TransactionVolumneChart";

const MainCharts = () => {
	
	return (
		<>
			<Row className="pb-1">
				<Col sm={4}>
					<TransactionVolumeChart/>
				</Col>
				<Col sm={4}>
					<TransactionValueChart/>
				</Col>
				<Col sm={4}>
					<InsurancePremiumChart/>
				</Col>
			</Row>
		</>
	);
};

export {MainCharts}