import React from 'react'
import PrimaryVolumeCard from './Common/Cards/PrimaryVolumeCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {Numbers} from './Common/FormatElements'

const OverviewRefundProtectVolumeSummary = () => {
	const {getRefundProtectVolumeSummary, getRefundProtectVolumeTrend} = usePulseApi()
	const value = useApiValue(filterState, getRefundProtectVolumeSummary, 0)
	const trendRate = useApiValue(filterState, getRefundProtectVolumeTrend, 0)
	const title = 'Refund Protect Volume'
	return (
		<PrimaryVolumeCard
			value={<Numbers value={value.data}/>}
			header={<>{title}</>}
			icon="people"
			tooltipTitle={title}
			tooltipText="This refers to the total quantity of transactions or bookings that utilise the Refund Protect service over a specific period.
				This metric encompasses all instances where customers opted for the Refund Protect option at the time of booking."
			trendValue={trendRate.data}
			valueClassName="text-black"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewRefundProtectVolumeSummary
