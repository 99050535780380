import {useRecoilValue} from "recoil";
import {EnumMainProduct} from "../../../types/insurance-provider";
import {selectedMainProductIdStateInsurance} from "../../../state/insurance-provider-filters";

export default function useGetMainProductStates() {
	const selectedProductValue = useRecoilValue(selectedMainProductIdStateInsurance)
	let isProductAll = false
	let isProductEp = false
	let isProductRp = false
	
	switch (selectedProductValue) {
		case EnumMainProduct.All:
			isProductAll = true;
			break;
		case EnumMainProduct.EventProtect:
			isProductEp = true;
			break;
		case EnumMainProduct.RefundProtect:
			isProductRp = true;
			break;
	}
	
	return {isProductAll, isProductEp, isProductRp}
}