import React from 'react'
import {Line} from 'react-chartjs-2'
import {CHART_HEIGHT} from './shared'
import {FixedHeightChartCard} from '../../../components/Card/FixedHeightChartCard'
import {ChartSummaryTable} from '../../../components/Dashboard/Charts/ChartSummaryTable'
import {useGetChartData} from '../../../hooks/useGetChartDataSummary'
import {monthsInDateRangeState} from '../../../state/refund-data'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'
import {defaultChartData, refundFilterState} from '../../../types/refund'

const ApprovedValueChart = () => {
	const {getApprovedValueData} = useRefundApi()
	const {data} = useApiValue(refundFilterState, getApprovedValueData, defaultChartData)
	const {
		chartOptions, getChartData, summaryData, formatSummary
	} = useGetChartData(data, monthsInDateRangeState)

	return (
		<FixedHeightChartCard
			title="Approved Value"
			titleClass="card-title-info"
			height={CHART_HEIGHT}
			summary={
				<ChartSummaryTable
					data={summaryData}
					format={formatSummary}
				/>
			}
		>
			<>
				<Line
					data={getChartData()}
					options={chartOptions}
					className="main-chart-max-height"
				/>
			</>
		</FixedHeightChartCard>
	)
}

export {ApprovedValueChart}
