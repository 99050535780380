import React from 'react'
import {toastTextState} from '../../../state/pulse'
import {useRecoilState} from 'recoil'

const DashboardToast = () => {
	const [toastText, setToastText] = useRecoilState(toastTextState)

	const handleClose = () => {
		setToastText('')
	}

	setTimeout(() => {
		handleClose()
	}, 5000)

	return (
		<>
			{toastText.length > 0 && (
				<div
					className="
						bottom-24 fixed left-0 m-auto max-w-xs right-0 z-100
						bg-gray-800 rounded-lg shadow-lg
						text-sm text-white
						dark:bg-neutral-900"
					role="alert"
				>
					<div className="flex p-6 relative">
						{toastText}
						<div
							className="
								absolute right-1 top-1
								cursor-pointer"
							onClick={handleClose}
						>
							<span className="text-base material-symbols-outlined">close</span>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default DashboardToast
