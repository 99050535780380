import React from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {useRecoilValue} from 'recoil'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'
import {formatCurrency} from '../../../../utils/currency'
import {formatNumberWithoutSeparator} from '../../../../utils/number'
import useRevenueAverageGrowth from '../../hooks/useRevenueAverageGrowth'
import {selectedCoreCurrencyState} from '../../../../state/sales'

export const Revenue = () => {
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyState)
	const color = useSummaryCardColor()
	const {totalRevenue} = useRevenueAverageGrowth()

	return (
		<ValueCard
			title={`Revenue`}
			value={formatCurrency(coreCurrencyState, formatNumberWithoutSeparator(totalRevenue))}
			icon="icon-bag-16"
			iconColor={color}
		/>
	)
}