import classNames from 'classnames'
import React from 'react'

const PricingModelTag = (props: { tag: string }) => {
	const backgroundColor = props.tag === 'Dynamic'
		? 'bg-pulse-green'
		: 'bg-pulse-icon-gray opacity-60'

	return (
		<span className={classNames('align-middle h-3 inline-block mr-2 w-3',
			'rounded-full',
			'text-xs text-white uppercase',
			backgroundColor)}
		/>
	)
}

export default PricingModelTag