import React, {memo} from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyStateMember} from '../../../../state/member-filters'
import {formatCurrency} from '../../../../utils/currency'
import useGetSummaryValues from '../../hooks/useGetSummaryValues'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'

const ProtectionCost = memo(() => {
	const {totalProtectionCost} = useGetSummaryValues()
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateMember)
	const color = useSummaryCardColor()

	return (
		<ValueCard
			title={`Protection Cost`}
			value={formatCurrency(coreCurrencyState, parseInt(totalProtectionCost.toString()))}
			icon="icon-money-coins"
			tooltip={'Total protection cost.'}
			iconColor={color}
		/>
	)
})

export {ProtectionCost}
