import GraphFilterCard from './Common/GraphFilterCard/GraphFilterCard'
import {Line} from 'react-chartjs-2'
import React from 'react'
import {ChartData} from 'chart.js'
import {
	graphRevenue,
	graphValueTrends,
	graphVolumeTrends
} from '../../../types/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState, titleSuffixState} from '../../../state/pulse'
import useGetRevenueTrendsChartData from '../hooks/useGetRevenueTrendsChartData'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import {useRecoilValue} from 'recoil'
import H2 from './Common/Headers/H2'
import H4 from './Common/Headers/H4'

const RevenueTrendsGraph = () => {
	const {getRevenueTrendsChartData} = usePulseApi()
	const value = useApiValue(filterState, getRevenueTrendsChartData, {
		labels: []
	})
	const chartData = useGetRevenueTrendsChartData(value)
	const titleSuffix = useRecoilValue(titleSuffixState)

	const data: ChartData<'line'> = {
		labels: chartData.getLabels(),
		datasets: chartData.getDatasets()
	}

	return (
		<div className="
			mb-0 space-x-3
			md:mb-4 md:flex"
		>
			<div className="
				flex flex-col mb-4 min-w-64 p-4 space-y-3
				bg-pulse-gray rounded-lg
				md:mb-0"
			>
				<H4>Fields</H4>
				<GraphFilterCard title="Value Trends" types={graphValueTrends}/>
				<GraphFilterCard title="Volume Trends" types={graphVolumeTrends}/>
				<GraphFilterCard title="Revenue" types={graphRevenue}/>
			</div>
			<div className="flex-1">
				<H2>Revenue Trends - {titleSuffix}</H2>
				<LoadingSpinner
					isLoading={value.isLoading}
					className="flex items-center justify-center min-h-96 w-full"
				>
					<Line className="max-w-full" data={data} options={chartData.getOptions()}/>
				</LoadingSpinner>
			</div>
		</div>
	)
}

export default RevenueTrendsGraph
