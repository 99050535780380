import {useRecoilValue} from 'recoil'
import {colors} from '../../../utils/chart'
import {formatCurrency} from '../../../utils/currency'
import {selectedCoreCurrencyStateInsurance} from '../../../state/insurance-provider-filters'
import {useCallback, useEffect, useState} from 'react'
import {
	ContinentEnum,
	defaultInsuranceProviderChartData,
	InsuranceProviderChartDataModel
} from '../../../types/insurance-provider'
import {ChartSummaryData, defaultChartSummaryData, InsuranceProviderFilter} from '../../../types'
import {useApiValue} from '../../../hooks/api'
import {insuranceProviderFilterState} from '../../../state/insurance-provider'

export const useGetBarChartData = (
	getData: (filter: InsuranceProviderFilter) => Promise<InsuranceProviderChartDataModel>,
	isStacked?: boolean
) => {
	const {data} = useApiValue(insuranceProviderFilterState, getData, defaultInsuranceProviderChartData)
	const [chartData, setChartData] = useState<Record<string, number[]>>({})
	const [labels, setLabels] = useState<string[]>([])
	const [summaryData, setSummaryData] = useState<ChartSummaryData>(defaultChartSummaryData)
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateInsurance)

	const chartOptions: any = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			y: {
				display: true,
				position: 'left',
				ticks: {
					callback: (label: any) => formatCurrency(coreCurrencyState, label)
				},
				stacked: isStacked
			},
			x: {
				stacked: isStacked
			},
		},
		plugins: {
			legend: {
				display: true
			},
			title: {
				display: true,
			},
			tooltip: {
				callbacks: {
					label: function (context: { dataset: { label: string; }; parsed: { y: number; }; }) {
						return formatCurrency(coreCurrencyState, context.parsed.y)
					}
				}
			}
		},
	}

	const getChartData = useCallback(() => {
		const buildChartData = () => {

			const dataset = Object.keys(chartData)?.map((key: string, index: number) => {
				const chartColors = colors[index]
				const data: any[] = chartData[key]
				return {
					label: key,
					data: [...data],
					backgroundColor: chartColors.borderColor,
					pointBackgroundColor: chartColors.pointBackgroundColor,
					pointBorderColor: 'rgba(255,255,255,0)',
					pointHoverBackgroundColor: chartColors.pointHoverBackgroundColor,
					pointBorderWidth: 20,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 15,
					pointRadius: 4,
				}
			})

			return {
				labels: labels,
				datasets: [...dataset]
			}
		}

		if (Object.keys(chartData).length === 0) {
			return {
				labels: Object.values(ContinentEnum),
				datasets: []
			}
		}

		return buildChartData()
	}, [chartData, labels])

	useEffect(() => {
		if (data) {
			const {
				chartData,
				summary: {totalDtd, totalWtd, totalMtd, totalYtd},
				labels
			} = data
			setChartData(chartData)
			setLabels(labels)
			setSummaryData({totalDtd, totalWtd, totalMtd, totalYtd})
		}
	}, [data])

	const summaryFormat = useCallback((value: any) => {
		return formatCurrency(coreCurrencyState, value)
	}, [coreCurrencyState])

	return {getChartData, chartOptions, summaryData, summaryFormat}
}
