import React from 'react'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {selectedIntegrationTypeState} from '../../../../state/integration'
import {IntegrationType} from '../../../../types/integration'

const IntegrationTypeFilter = () => {

	return (
		<StatefulSelectFilterCard
			state={selectedIntegrationTypeState}
			title="Type"
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {IntegrationTypeFilter}

const menuItems = [
	{value: -1, label: 'All'},
	{value: IntegrationType.Standard, label: 'Standard'},
	{value: IntegrationType.Dynamic, label: 'Dynamic'},
]