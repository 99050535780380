export type LastTransactionDates = {
	ep: string
	rp: string
}

export type SummaryResult = {
	protectedRate: number
	protectedValue: number
	totalConversionRate: number
	totalEventCount: number
	totalProtectionCost: number
	totalProtectionRate: number
	totalTransactionValue: number
	totalTransactionValueInsured: number
	totalTransactionVolume: number
	totalTransactionVolumeInsured: number
	unProtectedValue: number
	unProtectedRate: number
}

export type RiskByRegion = {
	name: string
	transactionValueInsured: number
	value: number
}

export const productTypeDropdownData = [
	{productCode: 'TKT', productName: 'Refund Protect TKT'},
	{productCode: 'PKG', productName: 'Refund Protect PKG'},
	{productCode: 'HTL', productName: 'Refund Protect Hotel'},
]

export interface TransactionTableModel {
	transactionId: number
	memberName: string
	transactionReferenceId: string
	customerName: string
	transactionValue: number
	coreCurrency: string
	originalTransactionValue: number
	originalCurrency: string
	mainProductId: number
	mainProductName: string
	productCode: string
	eventName: string
	transactionDate: string
	transactionEndDate: string
	isInsured: string
	isCancelled: string
	transactionValueAUD: number
	transactionValueBRL: number
	transactionValueCAD: number
	transactionValueEUR: number
	transactionValueGBP: number
	transactionValueUSD: number
	consumerRate?: number
	transactionPaymentValue?: number
}

export interface TransactionTableDataModel {
	count: number
	rows: TransactionTableModel[]
}

export interface ConversionByValueBandTableModel {
	valueBand: string
	conversionRate: number
	totalValue: number
	soldValue: number
	averageValue: number
	totalVolume: number
	soldVolume: number
	protectionCost: number
}

