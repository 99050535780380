import {useApiActions} from './index'
import {
	ForecastDataModel,
	OnboardedDataModel,
	OnboardingDataModel,
	ProtectFinancialFilter, SalesDataModel
} from '../../types/protect-financial'

export const useProtectFinancialApi = () => {
	const {callApi} = useApiActions('pf')

	const getForecastData = async (filter: ProtectFinancialFilter): Promise<ForecastDataModel[]> => {
		return await callApi<ForecastDataModel [], string>({
			query: filter,
			path: 'forecast'
		});
	}

	const getOnboardedData = async (filter: ProtectFinancialFilter): Promise<OnboardedDataModel[]> => {
		return await callApi<OnboardedDataModel[], string>({
			query: filter,
			path: 'onboarded'
		});
	}

	const getOnboardingData = async (filter: ProtectFinancialFilter): Promise<OnboardingDataModel[]> => {
		return await callApi<OnboardingDataModel[], string>({
			query: filter,
			path: 'onboarding'
		});
	}

	const getSalesData = async (filter: ProtectFinancialFilter): Promise<SalesDataModel[]> => {
		return await callApi<SalesDataModel[], string>({
			query: filter,
			path: 'sales'
		});
	}

	return {
		getForecastData,
		getOnboardedData,
		getOnboardingData,
		getSalesData
	}
}
