import {toUsd} from '../../utils/number'
import classNames from 'classnames'
import React from 'react'

interface MonthComparisonCellProps {
	row: any,
	currentMonthSelector: string,
	previousMonthSelector: string,
	showDiff: boolean
}

const MonthComparisonCell = (props: MonthComparisonCellProps) => {
	const {row, currentMonthSelector, previousMonthSelector, showDiff} = props
	const currentValue = row[currentMonthSelector]
	const previousValue = row[previousMonthSelector]
	const parsedCurrentValue = parseFloat(currentValue)
	const parsedPreviousValue = parseFloat(previousValue)
	const hasIncreased = parsedCurrentValue > parsedPreviousValue
	const hasDecreased = parsedCurrentValue < parsedPreviousValue

	return (
		<div className="d-flex align-items-center w-100">
			<span className="w-50">{toUsd(currentValue)}</span>
			{
				(showDiff) && (
					<i className={classNames('tim-icons', {
						'icon-minimal-up': hasIncreased,
						'icon-minimal-down': hasDecreased,
						'up': hasIncreased,
						'down': hasDecreased,
					})}/>
				)
			}
		</div>
	)
}

export {MonthComparisonCell}
