import React from 'react'
import {Col, Row} from 'reactstrap'
import {SalesPresentationTable} from './components/SalesPresentationTable'
import {OnboardingTable} from './components/OnboardingTable'
import {OnboardedTable} from './components/OnboardedTable'
import {ForecastTable} from './components/ForecastTable'
import {UserFilter} from './components/UserFilter'
import {DateFilter} from './components/DateFilter'

const ProtectFinancialView = () => {
	return (
		<div className="content">
			<Row className="pb-4">
				<Col sm={6}>
					<DateFilter/>
				</Col>
				<Col sm={6}>
					<UserFilter/>
				</Col>
			</Row>
			<Row className="pb-4">
				<Col sm={12} md={6}>
					<SalesPresentationTable/>
				</Col>
				<Col sm={12} md={6}>
					<OnboardingTable/>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={6}>
					<OnboardedTable/>
				</Col>
				<Col sm={12} md={6}>
					<ForecastTable/>
				</Col>
			</Row>
		</div>
	)
}

export default ProtectFinancialView