import React from 'react'
import {FixedHeightChartCard} from '../../../components/Card/FixedHeightChartCard'
import {Line} from 'react-chartjs-2'
import {useGetChartData} from '../../../hooks/useGetChartDataSummary'
import {salesFilterState} from '../../../state/sales'
import {ChartHeightValue} from '../../../utils/chart'
import {defaultChartData} from '../../../types/dynamic-performance'
import {useSalesApi} from '../../../hooks/api/sales'
import {useApiValue} from '../../../hooks/api'
import {monthsInDateRangeState} from '../../../state/filters'

export const RevenuePerDollarChart = () => {
	const {getRevenuePerDollarChart} = useSalesApi()
	const {data} = useApiValue(salesFilterState, getRevenuePerDollarChart, defaultChartData)
	const {
		chartOptions,
		getChartData
	} = useGetChartData(data, monthsInDateRangeState)

	return (
		<FixedHeightChartCard
			title="Revenue Per $"
			titleClass="card-title-info"
			height={ChartHeightValue['medium']}
		>
			<Line
				data={getChartData()}
				options={chartOptions}
				className="full-width-chart-max-height"
			/>
		</FixedHeightChartCard>
	)
}
