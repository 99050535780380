import React from 'react'
import * as DashboardDateFilters from '../../../../components/Dashboard/DateFilters'
import {dateFromStateIntegration, dateToStateIntegration} from '../../../../state/integration'
import moment from 'moment'

const DateFilters = () => {
	return (
		<DashboardDateFilters.DateFilters
			fromState={dateFromStateIntegration}
			toState={dateToStateIntegration}
			minDate={moment('2023-01-01')}
		/>
	)
}

export {DateFilters}
