import React, {FC, useEffect} from 'react'
import {signInSilentCallback} from '../../services/user-service'

const SilentRefresh: FC = () => {
	useEffect(() => {
		console.log('sign in silent')
		signInSilentCallback().then(null)
	}, [])

	return <></>
}

export {SilentRefresh}
