import DataTable, {TableColumn} from 'react-data-table-component'
import React, {useEffect, useState} from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import useGetFilteredData from '../hooks/useGetFilteredData'

interface ProtectFinancialTableProps {
	title: string,
	columns: TableColumn<any>[],
	selector: any
}

const ProtectFinancialTable = (props: ProtectFinancialTableProps) => {
	const {title, columns, selector} = props
	const [noDataText, setNoDataText] = useState('Loading...')
	const {filteredData} = useGetFilteredData(selector)
	useEffect(() => {
		if (!filteredData.length) {
			setNoDataText('No records')
		}
	}, [filteredData])

	return (
		<TableCard
			title={title}
		>
			<div>
				<DataTable
					data={filteredData}
					columns={columns}
					theme="dark"
					pagination
					noDataComponent={noDataText}
				/>
			</div>
		</TableCard>
	)
}

export {ProtectFinancialTable}