import React from 'react'
import {Navigate} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {isUserAuthenticatedState} from '../state/auth'
import {Layout} from '../layouts'

const AuthenticatedRoute = () => {
	const isAuthenticated = useRecoilValue(isUserAuthenticatedState)

	if (isAuthenticated) {
		return <Layout/>
	}

	return <Navigate to="/login"/>
}

export {AuthenticatedRoute}
