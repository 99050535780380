import React, {useEffect, useState} from 'react'
import {ValueDirectionProps, ValueStatusCard} from '../../../components/Card/ValueStatusCard'
import {ValueStatus} from '../../../types/refund'
import {useTrustPilotValues} from '../hooks/useTrustPilotValues'

const getValue = (n: number, value: string) => {
	if (n === 0) {
		return value
	}
	return n === 1 ? (`${value}`) : (`@ ${value}%`)
}

const TrustPilot = () => {
	const [valueStatusData, setValueStatusData] = useState<ValueDirectionProps[]>([])
	const [valueStatusBadReviewData, setValueStatusBadReviewData] = useState<ValueDirectionProps[]>([])
	const {dayAverage, reviewCount, reviewRatio, badReviewCount, badReviewRatio} = useTrustPilotValues()

	useEffect(() => {
		if (dayAverage) {
			const next: ValueDirectionProps[] = [dayAverage, reviewCount, reviewRatio].map((x: ValueStatus, i: number) => {
				const {value, isPositive} = x
				return {
					value: getValue(i, value),
					direction: isPositive ? 'down' : 'up',
					colour: isPositive ? 'red' : 'green'
				}
			})
			setValueStatusData(next)

			const badReview: ValueDirectionProps[] = [badReviewCount, badReviewRatio].map((x: ValueStatus, i: number) => {
				const {value, isPositive} = x
				return {
					value: i !== 1 ? value : (`${value}%`),
					direction: isPositive ? 'down' : 'up',
					colour: isPositive ? 'green' : 'red'
				}
			})
			setValueStatusBadReviewData(badReview)
		}
	}, [dayAverage])

	return (
		<div className="stacked-value-status-cards">
			<div className={'d-flex flex-row flex-grow-1 stacked-value-status-card'}>
				<ValueStatusCard
					title={'TrustPilot'}
					values={valueStatusData}
					subTitle="Last 24Hrs"
				/>
			</div>
			<div className={'d-flex flex-row flex-grow-1 stacked-value-status-card'}>
				<ValueStatusCard
					title={''}
					values={valueStatusBadReviewData}
					subTitle="1-3 Stars"
				/>
			</div>
		</div>
	)
}

export {TrustPilot}