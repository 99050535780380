import React from 'react'
import {filterState} from '../../../state/dynamic-performance'
import {ValueCard} from '../../../components/Card/ValueCard'
import {useDynamicPerformanceApi} from '../../../hooks/api/dynamic'
import {useApiValue} from '../../../hooks/api'

const AverageTransactionRate = () => {
	const {getAverageRate} = useDynamicPerformanceApi()
	const {data} = useApiValue(filterState, getAverageRate, 0)

	return (
		<ValueCard
			title="Average Rate"
			value={`${data}%`}
			icon="icon-cart"
			iconColor="rp"
		/>
	)
}

export default AverageTransactionRate
