import {selector} from 'recoil'
import {loggedInUserState} from './auth'
import {UploadFilter} from '../types/upload'

const keyPrefix = 'upload'

export const uploadFilterState = selector<UploadFilter>({
	key: `${keyPrefix}FilterState`,
	get: ({get}) => {
		return {
			loggedInUser: get(loggedInUserState)
		}
	}
})
