import React from 'react'
import SelectFilterCard, {SelectFilterItem} from '../../../components/Dashboard/SelectFilter'

const ValueBandFilter = () => {
	const handleChange = (value: any) => {}

	return (
		<SelectFilterCard
			title="Value Band"
			items={valueBands}
			onChange={handleChange}
			icon=""
		/>
	)
}

export default ValueBandFilter

const valueBands: SelectFilterItem[] = [
	{ value: 1, label: '0 - 50' },
	{ value: 2, label: '51 - 100' },
	{ value: 3, label: '101 - 150' },
	{ value: 4, label: '151 - 250' },
	{ value: 5, label: '251 - 400' },
	{ value: 6, label: '400+' }
]
