import React from 'react'

const Logo = (props: { className?: string }) => {
	return (
		<svg
			className={props.className}
			width="43"
			height="38"
			viewBox="0 0 43 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.8468 37.3575H10.4108C4.67024 37.3575 0 32.653 0 26.8703C0 21.0875 4.67024 16.383 10.4108 16.383H32.5892C35.8165 16.383 38.442 13.7383 38.442 10.4872C38.442 7.23619 35.8165 4.59144 32.5892 4.59144H23.779V14.2364H19.221V8.99772C19.221 4.03595 23.2275 0 28.1532 0H32.5892C38.3298 0 43 4.70451 43 10.4872C43 16.27 38.3298 20.9745 32.5892 20.9745H10.4108C7.18348 20.9745 4.55799 23.6192 4.55799 26.8703C4.55799 30.1213 7.18348 32.7661 10.4108 32.7661H19.221V29.787H23.6668L23.6554 29.8591C22.9315 34.2031 19.2275 37.3559 14.8468 37.3559V37.3575Z"
				fill="#525252"
			/>
			<path
				d="M21.4997 25.6765C20.2439 25.6765 19.2207 24.6474 19.2207 23.3807C19.2207 22.1141 20.2423 21.085 21.4997 21.085C22.7571 21.085 23.7787 22.1141 23.7787 23.3807C23.7787 24.6474 22.7571 25.6765 21.4997 25.6765Z"
				fill="#525252"
			/>
		</svg>
	)
}

export default Logo
