import React from 'react'
import {Row, Col} from 'reactstrap'
import {CompletedIntegrationsChart} from './CompletedIntegrationsChart'
import {DaysIntegratingChart, DaysIntegratingPie} from './DaysIntegratingChart'
import {IntegrationsMrrChart} from './IntegrationsMrrChart'
import {IntegrationGuidesChart} from './IntegrationGuidesChart'
import {IntegrationStatusChart} from './IntegrationStatusChart'
import {RegionStatusTimeChart, VerticaStatusTimeChart} from './IntegrationStatusTimeChart'

const Charts = () => {
	return (
		<>
			<Row className="pb-4">
				<Col sm={12} lg={4}>
					<CompletedIntegrationsChart/>
				</Col>
				<Col sm={12} lg={4}>
					<DaysIntegratingChart/>
				</Col>
				<Col sm={12} lg={4}>
					<IntegrationsMrrChart/>
				</Col>
			</Row>
			<Row className="pb-4">
				<Col sm={12} lg={4}>
					<IntegrationGuidesChart/>
				</Col>
				<Col sm={12} lg={4}>
					<DaysIntegratingPie/>
				</Col>
				<Col sm={12} lg={4}>
					<IntegrationStatusChart/>
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col sm={12} lg={6}>
					<VerticaStatusTimeChart/>
				</Col>
				<Col sm={12} lg={6}>
					<RegionStatusTimeChart/>
				</Col>
			</Row>
		</>
	)
}

export {Charts}