import React from 'react';
import {Row, Col} from 'reactstrap';
import {DateFilters} from './DateFilters';
import {InsuranceProviderDropdown} from './InsuranceProviderDropdown';
import {ProductDropdown} from './ProductDropdown';
import {LocationDropdown} from './LocationDropdown';
import {CurrencyDropdown} from './CurrencyDropdown';
import {CoreCurrencyDropdown} from './CoreCurrencyDropdown';
import {TypeDropdown} from './TypeDropdown';
import {EventDropdown} from './EventDropdown';
import useGetMainProductStates from "../../hooks/useGetMainProductStates";

const Filters = () => {
	const {
		isProductAll,
		isProductEp
	} = useGetMainProductStates()
	
	return (
		<>
			<Row className="pb-3">
				<Col>
					<InsuranceProviderDropdown/>
				</Col>
				<Col>
					<ProductDropdown/>
				</Col>
				<Col>
					<DateFilters/>
				</Col>
				<Col>
					<CoreCurrencyDropdown/>
				</Col>
			</Row>
			<Row>
				<Col>
					<LocationDropdown/>
				</Col>
				{
					!isProductAll &&
					<Col>
						<TypeDropdown/>
					</Col>
				}
				{
					isProductEp &&
					<Col>
						<EventDropdown/>
					</Col>
				}
				<Col>
					<CurrencyDropdown/>
				</Col>
			</Row>
		</>
	);
};

export {Filters}