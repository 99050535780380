import React, {memo, useEffect, useState} from 'react';
import {ValueCard} from '../../../../components/Card/ValueCard';
import useSummaryCardColor from "../../hooks/useSummaryCardColor";
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const InsuranceRateSummary = memo(() => {
  const color = useSummaryCardColor()
  const {getInsuranceRate} = useInsuranceApi();
  const {data, isLoading} = useApiValue(insuranceProviderFilterState, getInsuranceRate, 0)
  const [insuranceRate, setInsuranceRate] = useState(0)
  
  useEffect(() => {
    if(data !== insuranceRate && !isLoading){
      setInsuranceRate(data);
    }
  }, [data, isLoading])
  
  return (
    <ValueCard
      title={`Insurance Rate`}
      value={`${parseFloat(insuranceRate.toString()).toFixed(3)}%`}
      icon="icon-satisfied"
      iconColor={color}
      tooltip={"Overall protection rate."}
    />
  );
});

export {InsuranceRateSummary}