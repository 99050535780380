import React, {useEffect, useState} from 'react'
import {ValueDirectionProps, ValueStatusCard} from '../../../components/Card/ValueStatusCard'
import {refundFilterState, ValueStatus} from '../../../types/refund'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'

export const System = () => {
	const [valueStatusData, setValueStatusData] = useState<ValueDirectionProps[]>([])
	const [valueStatusDataDay, setValueStatusDataDay] = useState<ValueDirectionProps[]>([])
	const {getSystemHandledData} = useRefundApi()
	const {data} = useApiValue(refundFilterState, getSystemHandledData, [] as ValueStatus[])

	useEffect(() => {
		if (data) {
			const nextHour: ValueDirectionProps[] = data.slice(0, 2)?.map((x: ValueStatus, i: number) => {
				const {value, isPositive} = x
				return {
					value: i !== 1 ? value : (`${value}%`),
					direction: i !== 1 ? (isPositive ? 'down' : 'up') : null,
					colour: isPositive ? 'red' : 'green'
				}
			})
			setValueStatusData(nextHour)

			const nextDay: ValueDirectionProps[] = data.slice(2, 4)?.map((x: ValueStatus, i: number) => {
				const {value, isPositive} = x
				return {
					value: i !== 1 ? value : (`${value}%`),
					direction: i !== 1 ? (isPositive ? 'down' : 'up') : null,
					colour: isPositive ? 'red' : 'green'
				}
			})
			setValueStatusDataDay(nextDay)
		}
	}, [data])

	return (
		<div className="stacked-value-status-cards">
			<div className={'d-flex flex-row justify-content-between flex-grow-1 stacked-value-status-card'}>
				<ValueStatusCard
					title={'System'}
					values={valueStatusData}
					subTitle="Last Hour"
				/>
			</div>
			<div className={'d-flex flex-row justify-content-between flex-grow-1 stacked-value-status-card'}>
				<ValueStatusCard
					title={''}
					values={valueStatusDataDay}
					subTitle="Last 24Hrs"
				/>
			</div>
		</div>
	)
}