export enum FilterEnum {
	DefaultFilterAll = "All"
}

export enum IdEnum {
	DefaultId = 0
}

export enum EnumMainProduct {
	All = 0,
	EventProtect = 1,
	RefundProtect = 2,
}

export enum CurrencyCode {
	Default = "All"
}

export interface UpcomingRiskRowData {
	eventName: string,
	eventDate: string,
	eventType: string,
	country: string,
	city: string,
	transactions: number,
	value: number,
	riskCoverage: number,
}

export interface ValueBandsRowData {
	valueBand: string,
	transactionVolume: number,
	transactionsValue: number,
	activeTransactionsValue: number,
	taggedTransactionsValue: number,
	expiredTransactionsValue: number,
	refundInsuredValue: number,
	refundCefValue: number,
	premiumInsuredValue: number,
	premiumCefValue: number,
	lossRatioInsuredValue: number,
	lossRatioCefValue: number
}

export enum ContinentEnum {
	Asia = "AS",
	Africa = "AF",
	Europe = "EU",
	NorthAmerica = "NA",
	Oceania = "UC",
	SouthAmerica = "SA",
	Antarctica = "AN",
	Unknown = "XX"
}

export type LastTransactionDates = {
	ep: string
	rp: string
}

export interface SummaryValues {
	totalMtd: number
	totalWtd: number
	totalYtd: number
	totalDtd: number
}

export interface ChartData {
	summary: ChartSummary,
	chartData: Record<string, number[]>
}

export const defaultChartData: ChartData = {
	chartData: {},
	summary: {
		totalDtd: 0,
		totalMtd: 0,
		totalWtd: 0,
		totalYtd: 0
	}
}

export interface ChartSummary extends SummaryValues {
	totalDtd: number
}

export interface InsuranceProviderChartDataModel{
	labels: string[],
	summary: SummaryValues,
	chartData: Record<string, number[]>
}

export const defaultInsuranceProviderChartData: InsuranceProviderChartDataModel = {
	labels: [],
	summary: {
		totalDtd: 0,
		totalMtd: 0,
		totalWtd: 0,
		totalYtd: 0
	},
	chartData: {}
}

export const defaultColumn = {
	name: '',
	selector:(row: any) => row.label,
	wrap: true
}

export const USD = "USD"