import React, {ReactNode} from 'react'
import classNames from 'classnames'

export const ViewRow = (props: { children: ReactNode, className?: string }) => (
	<div className={classNames('w-full',
		'md:flex md:space-x-4',
		props.className)}
	>
		{props.children}
	</div>
)

export const ViewColumn = (props: { children: ReactNode, className?: string }) => (
	<div className={classNames('w-full',
		'md:flex md:flex-col md:space-y-4',
		props.className)}
	>
		{props.children}
	</div>
)

export const ViewGrid = (props: { children: ReactNode, className?: string }) => (
	<div className={classNames(
		'grid grid-cols-1 gap-4 w-full',
		'md:grid-cols-2 lg:grid-cols-4',
		props.className)}
	>
		{props.children}
	</div>
)