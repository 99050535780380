import React from 'react'
import SecondaryValueCard from './Common/Cards/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {Currency} from './Common/FormatElements'

const OverviewMaxApprovedRefundValueSummary = () => {
	const {getMaxRefundValue} = usePulseApi()
	const value = useApiValue(filterState, getMaxRefundValue, 0)

	return (
		<SecondaryValueCard
			value={<Currency value={value.data} max={4}/>}
			header="Highest Refund Amount"
			tooltipText="The largest single refund approved."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewMaxApprovedRefundValueSummary
