import React from 'react'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {selectedIntegrationGuideState} from '../../../../state/integration'
import {IntegrationGuide} from '../../../../types/integration'

const IntegrationGuideFilter = () => {

	return (
		<StatefulSelectFilterCard
			state={selectedIntegrationGuideState}
			title="Guide"
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {IntegrationGuideFilter}

const menuItems = [
	{value: -1, label: 'All'},
	{value: IntegrationGuide.DynamicAPI, label: 'Dynamic API'},
	{value: IntegrationGuide.DynamicWidget, label: 'Dynamic Widget'},
	{value: IntegrationGuide.DynamicHybrid, label: 'Dynamic Hybrid'},
	{value: IntegrationGuide.StandardRP, label: 'Standard RP'}
]