import React from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {integrationFilterState} from '../../../../state/integration'
import {defaultStatusData} from '../../../../types/integration'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'

const StatusPausedSummary = () => {
    const {getStatusSummary} = useIntegrationApi()
    const {data} = useApiValue(integrationFilterState, getStatusSummary, defaultStatusData)

    return (
        <ValueCard
            title={`Paused integrations`}
            value={data.paused}
            icon="icon-button-pause"
            tooltip={'Total count of paused integrations'}
        />
    )
}

export {StatusPausedSummary}
