import React, {ReactElement} from 'react'
import Tooltip from '../Tooltip/Tooltip'
import {v4 as uuidv4} from 'uuid'
import LoadingSpinner from '../Spinner/LoadingSpinner'
import TrendCardText from './TrendCardText'

interface PrimaryVolumeCardProps {
	header: ReactElement,
	icon: string,
	isLoading: boolean,
	tooltipTitle: string,
	tooltipText: string,
	trendValue?: number,
	value?: ReactElement,
	valueClassName: string
}

const PrimaryVolumeCard = (props: PrimaryVolumeCardProps) => {
	const id = uuidv4()

	return (
		<div className="flex h-24 relative">
			<LoadingSpinner
				isLoading={props.isLoading}
				className="h-full w-full"
			>
				<>
					<div className="mr-2 flex-none">
						<div className="flex h-full items-center">
							<span className="
								text-4xl text-pulse-icon-gray
								material-symbols-outlined"
							>
								{props.icon}
							</span>
						</div>
					</div>
					<div>
						<div className="text-sm text-pulse-label-gray">
							{props.header}
						</div>
						<div className={`
							mb-1 text-2xl
							${props.valueClassName}`}
						>
							{props.value}
						</div>
						{props.trendValue !== undefined && (<TrendCardText trendValue={props.trendValue}/>)}
						{props.tooltipText && (
							<Tooltip
								id={`${id}-popup`}
								tooltipTitle={props.tooltipTitle}
								tooltipText={props.tooltipText}
								tooltipClassName="absolute -right-3 top-2"
							/>
						)}
					</div>
				</>
			</LoadingSpinner>
		</div>
	)
}

export default PrimaryVolumeCard
