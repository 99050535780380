import React, {memo} from 'react'
import {MainChartComponent} from './MainChartComponent'
import useGetCurrencyChartOptions from '../../../hooks/useGetCurrencyChartOptions'
import {tooltipList} from '../../../../../utils/chart'
import {useMemberApi} from '../../../../../hooks/api/member'
import {useApiValue} from '../../../../../hooks/api'
import {memberFilterState} from '../../../../../state/memberFilterState'
import {defaultChartSummaryData} from '../../../../../types'

const ProtectionCostChart = memo(() => {
	const {getProtectionCostChartData, getProtectionCostChartSummaryData} = useMemberApi()
	const {
		data: chartData,
		isLoading: chartLoading
	} = useApiValue(memberFilterState, getProtectionCostChartData, [])
	const {
		data: summaryData,
		isLoading: summaryLoading
	} = useApiValue(memberFilterState, getProtectionCostChartSummaryData, defaultChartSummaryData)
	const options = useGetCurrencyChartOptions()

	return (
		<MainChartComponent
      chartData={chartData}
			isCurrency={true}
      isLoading={chartLoading || summaryLoading}
			options={options}
      summaryData={summaryData}
			title={`protection cost`}
			tooltip={tooltipList.ProtectionCost}
		/>
	)
})

export {ProtectionCostChart}
