import {useCallback} from 'react'
import {IntegrationStatusTimeModel} from "../../../types/integration"

export const useGetStatusTimeChartData = (integrationData: IntegrationStatusTimeModel) => {
    const getChartData = useCallback(() => {
        if (!integrationData) {
            return {
                verticalData: {labels: [], datasets: []},
                regionData: {labels: [], datasets: []}
            }
        }

        const buildChartData = () => {
            const verticalData = {
                labels: integrationData.labels.verticalLabels,
                datasets: Object.keys(integrationData.verticalData).map(key => ({
                    label: key,
                    data: integrationData.verticalData[key]
                }))
            }

            const regionData = {
                labels: integrationData.labels.regionLabels,
                datasets: Object.keys(integrationData.regionData).map(key => ({
                    label: key,
                    data: integrationData.regionData[key]
                }))
            }

            return {verticalData, regionData}
        }

        return buildChartData()
    }, [integrationData])

    return {getChartData, barChartOptions}
}

const barChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            display: true,
            position: 'left',
            stacked: true,
        }
    },
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            text: 'Status Distribution by Vertical'
        },
    },
}
