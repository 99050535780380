import {TestResult} from './dynamic-data'

export interface DynamicPerformanceFilter extends Record<string, any> {
	dateFrom: string | null
	dateTo: string | null
	memberId: number | null
	salesRegionId: number | null
	userName: string | undefined
}

export interface DynamicPerformanceTableFilter extends DynamicPerformanceFilter {
	page: number
	pageSize: number
	sortColumn: string
	sortDirection: string
}

export interface CountryData {
	countryCode: string
	value: number
}

export interface SummaryLabels {
	salesVolume: SummaryValues
	salesValue: SummaryValues
	revenueGenerated: SummaryValues
	revenueValue: SummaryValues
}

export interface SummaryValues {
	totalMtd: number
	totalWtd: number
	totalYtd: number
}

export interface RegionData {
	regionId: number
	countryData: CountryData[]
	summaryData: SummaryLabels
}

export interface ChartData {
	summary: ChartSummary,
	chartData: Record<string, number[]>
}

export const defaultChartData: ChartData = {
	chartData: {},
	summary: {
		totalDtd: 0,
		totalMtd: 0,
		totalWtd: 0,
		totalYtd: 0
	}
}

export interface ChartSummary extends SummaryValues {
	totalDtd: number
}

export interface ContentLanguage {
	htmlA: string
	htmlB: string
	languageCode: string
}

export interface Content {
	name: string
	contentLanguages: ContentLanguage[]
}

export interface PricingAndContentData {
	testId: number
	contentIds: number[]
	conversion: number[]
	notSold: number[]
	price: number[]
	rate: number[]
	result: TestResult[]
	revenue: number[]
	revenueByUnit: number[]
	sold: number[]
	test: number[]
	testParameters: string[]
	timePeriod: string
	transactionBand: string
}

export interface ContentPreview {
	contentId: number
	test: number
}

export interface TableRow {
	transactionId: number
	platformTransactionId: number
	member: string
	region: string
	transactionDate: string
	transactionValue: number
	protectionValue: number
	transactionCurrency: string
	isSold: boolean
}

export interface TransactionTableData {
	count: number,
	rows: TableRow[]
}
