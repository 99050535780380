import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import TrustPilotReviewCard from './Common/TrustPilotReviewCard/TrustPilotReviewCard'
import Tooltip from './Common/Tooltip/Tooltip'

const RefundTrustPilotReviews = () => {
	const {getTrustPilotReviews} = usePulseApi()
	const value = useApiValue(filterState, getTrustPilotReviews, [])

	return (
		<>
			{((value.isLoading || value.data.length > 0) &&
				<h2 className="inline-block relative">
					<span className="
						align-bottom mr-2
						text-pulse-icon-gray text-2xl
						material-symbols-outlined"
					>
						rss_feed
					</span>
					<span className="text-lg">What customers say</span>
					<Tooltip
						id={`trustpilotreview-popup`}
						tooltipTitle="What customers say"
						tooltipText="These are real Trustpilot reviews from your customers, reflecting the customer experience
							they had with Refund Protect."
						tooltipClassName="absolute -right-6 top-0"
					/>
				</h2>
			)}
			<div className="md:flex md:space-x-4">
				{!value.isLoading && value.data.map(((review, index) => (
						<div
							key={`trustpilotreview-${index}`}
							className="
								flex-1 mb-4
								md:mb-0"
						>
							<TrustPilotReviewCard header={review.reviewTitle}
							                      body={review.reviewBody}
							                      stars={review.reviewStars}/>
						</div>)
				))}
				{value.isLoading && (
					<div className="
						flex-1 mb-4
						animate-pulse
						md:mb-0"
					>
						<TrustPilotReviewCard
							header=""
							body=""
							stars={0}
						/>
					</div>
				)}
			</div>
		</>
	)
}

export default RefundTrustPilotReviews
