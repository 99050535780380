import React, {useState, ReactNode} from 'react'
import classNames from 'classnames'
import {Button, Card, CardBody, CardHeader, CardTitle} from 'reactstrap'
import {CardTooltip} from './CardTooltip'

export interface ChartCardProps {
	title: string;
	category?: string;
	children: ReactNode;
	titleClass?: string;
	showToggle?: boolean;
	chartToggles?: ReactNode;
	summary?: ReactNode;
	loading?: boolean;
	tooltip?: string | undefined;
}

export const ChartCard = (props: ChartCardProps) => {
	const {children, title, category, titleClass, showToggle = false, chartToggles, summary, loading, tooltip} = props
	const [chartOpen, setChartOpen] = useState(true)

	const toggleCard = () => setChartOpen(!chartOpen)

	return (
		<Card className={classNames('card-chart', {
			'collapsed': !chartOpen,
			'loading': loading
		})}>
			<CardHeader>
				{
					tooltip && (
						<CardTooltip tooltip={tooltip}/>
					)
				}
				<div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
					{
						showToggle && (
							<div className="d-flex pr-3">
								<Button onClick={toggleCard} className="btn-round btn-icon btn-sm" color="dark">
									<i className={classNames('tim-icons', {
										'icon-minimal-up': chartOpen,
										'icon-minimal-down': !chartOpen
									})}/>
								</Button>
							</div>
						)
					}
					<CardTitle className={classNames('m-0', 'text-uppercase', 'responsive-text-size', titleClass)}
					           tag="h5">{title}</CardTitle>
					{
						category && (
							<div className="d-flex flex-fill justify-content-end">
								<h5 className="card-category">{category}</h5>
							</div>
						)
					}
				</div>
			</CardHeader>
			<CardBody>
				{
					(summary) && (
						<div>
							{summary}
						</div>
					)
				}
				<div className="chart-area">
					{children}
				</div>
				{
					chartToggles && (
						<div className="pt-4">
							{chartToggles}
						</div>
					)
				}
			</CardBody>
		</Card>
	)
}
