import {PulseMapDataModel} from '../../../types/pulse-data'
import {graphFilterType} from '../../../types/pulse'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState, selectedGraphFiltersState} from '../../../state/pulse-filters'
import {formatCurrency} from '../../../utils/currency'
import {Countries, Country} from '../../../types/pulse-countries'
import {selectedRegionFilterState} from '../../../state/pulse'
import useGetGraphFilterLabel from './useGetGraphFilterLabel'
import {formatNumberMember} from '../../../utils/number'

export default function useGetCustomerTrendsMapData(recoilValue: { data: PulseMapDataModel, isLoading: boolean }) {
	const graphFilters = useRecoilValue(selectedGraphFiltersState)
	const selectedRegionFilter = useRecoilValue(selectedRegionFilterState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	const getRegionValues = () => {
		const result: Record<string, number> = {}

		for (let countryCode in recoilValue.data.countries) {
			const value = recoilValue.data.countries[countryCode as any]

			switch (selectedRegionFilter) {
				case graphFilterType.RefundProtectValue:
					if (!value.refundProtectValue) {
						continue
					}
					result[countryCode] = value.refundProtectValue
					break
				case graphFilterType.GrossBookingValue:
					if (!value.grossBookingValue) {
						continue
					}
					result[countryCode] = value.grossBookingValue
					break
				case graphFilterType.AverageConversionRate:
					if (!value.averageConversionRate) {
						continue
					}
					result[countryCode] = value.averageConversionRate
					break
				case graphFilterType.GrossBookingVolume:
					if (!value.grossBookingVolume) {
						continue
					}
					result[countryCode] = value.grossBookingVolume
					break
				case graphFilterType.RevenuePerDollar:
					if (!value.revenuePerDollar) {
						continue
					}
					result[countryCode] = value.revenuePerDollar
					break
			}
		}

		return result
	}

	const getSelectedMarkerFilter = () => {
		if (graphFilters.includes(graphFilterType.YourRevenue)) {
			return graphFilterType.YourRevenue
		}

		if (graphFilters.includes(graphFilterType.RefundProtectVolume)) {
			return graphFilterType.RefundProtectVolume
		}

		return undefined
	}

	const getMarkerValues = () => {
		const values: number[] = []
		const countries: Country[] = []

		for (let countryCode in recoilValue.data.countries) {
			const value = recoilValue.data.countries[countryCode as any]
			const filter = getSelectedMarkerFilter()

			switch (filter) {
				case graphFilterType.YourRevenue:
					if (!value.yourRevenue) {
						continue
					}
					values.push(value.yourRevenue)
					break
				case graphFilterType.RefundProtectVolume:
					if (!value.refundProtectVolume) {
						continue
					}
					values.push(value.refundProtectVolume)
					break
				default:
					continue
			}

			const country = Countries.find(x => x.name === countryCode)

			if (!country) {
				continue
			}

			countries.push(country)
		}

		return {
			countries: countries,
			values: values
		}
	}

	const getMarkerLabel = (index: number) => {
		const {values} = getMarkerValues()
		const filter = getSelectedMarkerFilter()

		if (filter === graphFilterType.YourRevenue) {
			return formatCurrency(coreCurrency, values[index])
		}

		return values[index]
	}

	const getTooltipContent = (countryCode: string) => {
		if (!recoilValue.data.countries) {
			return
		}

		for (let code in recoilValue.data.countries) {
			if (code !== countryCode) {
				continue
			}

			const data = recoilValue.data.countries[countryCode]
			let content = ''

			if (graphFilters.includes(graphFilterType.GrossBookingValue)) {
				content += `<p class="text-sm">
											<span class="font-medium">${getGraphFilterLabel(graphFilterType.GrossBookingValue)}:</span> 
											${formatCurrency(coreCurrency, data.grossBookingValue || 0, 0, 0)}
										</p>`
			}

			if (graphFilters.includes(graphFilterType.RefundProtectValue)) {
				content += `<p class="text-sm">
											<span class="font-medium">${getGraphFilterLabel(graphFilterType.RefundProtectValue)}:</span> 
                      ${formatCurrency(coreCurrency, data.refundProtectValue || 0, 0, 0)}
                      <br/>
										</p>`
			}

			if (graphFilters.includes(graphFilterType.YourRevenue)) {
				content += `<p class="text-sm">
											<span class="font-medium">${getGraphFilterLabel(graphFilterType.YourRevenue)}:</span> 
                      ${formatCurrency(coreCurrency, data.yourRevenue || 0, 0, 0)}
                    </p>`
			}

			if (graphFilters.includes(graphFilterType.AverageConversionRate)) {
				content += `<p class="text-sm">
											<span class="font-medium">${getGraphFilterLabel(graphFilterType.AverageConversionRate)}:</span> 
											${data.averageConversionRate}%
										</p>`
			}

			if (graphFilters.includes(graphFilterType.GrossBookingVolume)) {
				content += `<p class="text-sm">
											<span class="font-medium">${getGraphFilterLabel(graphFilterType.GrossBookingVolume)}:</span> 
                      ${formatNumberMember(data.grossBookingVolume || 0)}
                    </p>`
			}

			if (graphFilters.includes(graphFilterType.RefundProtectVolume)) {
				content += `<p class="text-sm">
											<span class="font-medium">${getGraphFilterLabel(graphFilterType.RefundProtectVolume)}:</span> 
                      ${formatNumberMember(data.refundProtectVolume || 0)}
                    </p>`
			}

			if (graphFilters.includes(graphFilterType.RevenuePerDollar)) {
				content += `<p class="text-sm">
											<span class="font-medium">${getGraphFilterLabel(graphFilterType.RevenuePerDollar)}:</span> 
                      ${formatCurrency(coreCurrency, data.revenuePerDollar || 0, 0, 4)}
                    </p>`
			}

			if (content && data && data.countryName) {
				content = `<h4 class="mb-1 font-medium">${data.countryName}</h4>
										${content}`
			}

			return content
		}
	}

	const getSelectedRegionFilter = () => selectedRegionFilter

	return {
		getMarkerLabel,
		getMarkerValues,
		getRegionValues,
		getSelectedMarkerFilter,
		getSelectedRegionFilter,
		getTooltipContent
	}
}
