import React from 'react'
import {ValueCard} from '../../../components/Card/ValueCard'
import {commissionFilterState} from '../../../state/commission-data'
import {toUsd} from '../../../utils/number'
import {useCommissionApi} from '../../../hooks/api/commission'
import {useApiValue} from '../../../hooks/api'

const TeamCommissionTotal = () => {
	const {getTeamTotalCommission} = useCommissionApi()
	const {data} = useApiValue(commissionFilterState, getTeamTotalCommission, 0)
	return (
		<ValueCard
			title="Team Total"
			value={`${toUsd(data.toString(), 0)}`}
			icon="icon-coins"
		/>
	)
}

export {TeamCommissionTotal}
