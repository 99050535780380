import {Col, Row} from "reactstrap";
import React from "react";
import {MemberDropdown} from "./MemberDropdown";
import {ProductTypeDropdown} from "./ProductTypeDropdown";
import {ActionTypeDropdown} from "./ActionTypeDropdown";
import {UploadTypeDropdown} from "./UploadTypeDropdown";

export const Filters = () => {
	return (
		<Row>
			<Col className="pb-1">
				<MemberDropdown />
			</Col>
			<Col className="pb-1">
				<ProductTypeDropdown />
			</Col>
			<Col className="pb-1">
				<ActionTypeDropdown />
			</Col>
			<Col className="pb-1">
				<UploadTypeDropdown />
			</Col>
		</Row>
	)
}