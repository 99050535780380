import React from 'react'
import {Line} from 'react-chartjs-2'
import {CHART_HEIGHT} from './shared'
import {FixedHeightChartCard} from '../../../components/Card/FixedHeightChartCard'
import {ChartSummaryTable} from '../../../components/Dashboard/Charts/ChartSummaryTable'
import {useGetChartData} from '../../../hooks/useGetChartDataSummary'
import {monthsInDateRangeState} from '../../../state/refund-data'
import {formatNumberMember} from '../../../utils/number'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'
import {defaultChartData, refundFilterState} from '../../../types/refund'

const ApprovedVolumeChart = () => {
	const {getApprovedVolumeData} = useRefundApi()
	const {data} = useApiValue(refundFilterState, getApprovedVolumeData, defaultChartData)
	const {
		chartOptions, getChartData, summaryData
	} = useGetChartData(data, monthsInDateRangeState)
	const format = (value: any) => {
		return formatNumberMember(value)
	}

	return (
		<FixedHeightChartCard
			title="Approved Volume"
			titleClass="card-title-info"
			height={CHART_HEIGHT}
			summary={
				<ChartSummaryTable
					data={summaryData}
					format={format}
				/>
			}
		>
			<>
				<Line
					data={getChartData()}
					options={chartOptions}
					className="main-chart-max-height"
				/>
			</>
		</FixedHeightChartCard>
	)
}

export {ApprovedVolumeChart}
