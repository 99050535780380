import React, {useEffect, useState} from 'react'
import {
	documentFilterState,
	isSelectedActorInsurer,
	selectedActorState,
	selectedInsurerState,
	selectedMemberState
} from '../../../../state/document-filters'
import {FilterEnum} from '../../../Member/Enums/FilterEnum'
import {Actor} from '../../../../types'
import SelectFilterCard, {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {IdEnum} from '../../../../types/insurance-provider'
import {useRecoilState} from 'recoil'
import {useDocumentApi} from '../../../../hooks/api/document'
import {useApiValue} from '../../../../hooks/api'

const MemberDropdown = () => {
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const {getActors} = useDocumentApi()
	const {data} = useApiValue(documentFilterState, getActors, [])
	const [stateValue, setStateValue] = useRecoilState(selectedMemberState)
	const [insurerState, setInsurerState] = useRecoilState(selectedInsurerState)
	const [actor, setActor] = useRecoilState(selectedActorState)
	const [isInsurer, setIsInsurer] = useRecoilState(isSelectedActorInsurer)

	const handleChange = (item: SelectFilterItem) => {
		setStateValue(item.value)
		setActor(item.value)
		setInsurerState(null)
		setIsInsurer(false)
	}

	useEffect(() => {
		if (data) {
			const next = [
				{value: IdEnum.DefaultId, label: FilterEnum.DefaultFilterAll},
				...data.filter(x => x.isMember)
					.map((x: Actor) => ({
						value: x.actorId,
						label: x.actorName
					}))
			]
			setMenuItems(next)
		}
	}, [data])

	return (
		<SelectFilterCard
			title="Members"
			items={menuItems}
			showDefault={false}
			value={stateValue}
			onChange={handleChange}
		/>
	)
}

export {MemberDropdown}