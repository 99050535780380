import {ChartDataset, ChartOptions, TooltipItem} from 'chart.js'
import {PulseTableDataModel} from '../../../types/pulse-data'
import {
	graphFilterColor,
	graphFilterType,
	graphRevenue,
	graphValueTrends
} from '../../../types/pulse'
import {formatCurrency} from '../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState, selectedGraphFiltersState} from '../../../state/pulse-filters'
import useGetGraphFilterLabel from './useGetGraphFilterLabel'
import {formatNumberMember} from '../../../utils/number'

export default function useGetRevenueTrendsChartData(recoilValue: { data: PulseTableDataModel, isLoading: boolean }) {
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const graphFilters = useRecoilValue(selectedGraphFiltersState)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	const getOptions = () => {
		const options: ChartOptions<'line'> = {
			scales: {
				x: {
					grid: {
						display: false
					},
				},
				y0: {
					display: true,
					position: 'left',
					ticks: {
						callback: function (value) {
							if (graphFilters.some(x => graphValueTrends.includes(x))) {
								return formatCurrency(coreCurrency, parseFloat(value.toString()))
							}

							if (graphFilters.some(x => graphRevenue.includes(x))) {
								return formatCurrency(coreCurrency, parseFloat(value.toString()), 0, 4)
							}

							return formatNumberMember(value as any)
						}
					},
					type: 'linear'
				},
				y1: {
					display: graphFilters.some(x => x === graphFilterType.AverageConversionRate),
					position: 'right',
					grid: {
						drawOnChartArea: false
					},
					ticks: {
						callback: function (value) {
							return value + '%'
						}
					},
					type: 'linear',
					suggestedMin: 0
				}
			},
			plugins: {
				legend: {
					labels: {
						usePointStyle: true,
						padding: 30
					}
				},
				tooltip: {
					enabled: true,
					mode: 'index',
					bodySpacing: 8,
					boxPadding: 4,
					padding: 16,
					titleMarginBottom: 8,
					callbacks: {
						label: (tooltip: TooltipItem<'line'>) => {
							if (tooltip.dataset.label === getGraphFilterLabel(graphFilterType.RevenuePerDollar)) {
								return `${tooltip.dataset.label}: ${formatCurrency(coreCurrency, tooltip.raw as any, 0, 4)}`
							}

							if (tooltip.dataset.label === getGraphFilterLabel(graphFilterType.RefundProtectValue) ||
								tooltip.dataset.label === getGraphFilterLabel(graphFilterType.YourRevenue) ||
								tooltip.dataset.label === getGraphFilterLabel(graphFilterType.GrossBookingValue)) {
								return `${tooltip.dataset.label}: ${formatCurrency(coreCurrency, tooltip.raw as any)}`
							}

							if (tooltip.dataset.label?.startsWith(getGraphFilterLabel(graphFilterType.AverageConversionRate))) {
								return `${getGraphFilterLabel(graphFilterType.AverageConversionRate)}: ${tooltip.raw}%`
							}

							if (tooltip.dataset.label === getGraphFilterLabel(graphFilterType.GrossBookingVolume) ||
								tooltip.dataset.label === getGraphFilterLabel(graphFilterType.RefundProtectVolume)) {
								return `${tooltip.dataset.label}: ${formatNumberMember(tooltip.raw as any)}`
							}

							return `${tooltip.dataset.label}: ${tooltip.raw}`
						}
					},
					itemSort: (a, b) => {
						return (b.raw as number) - (a.raw as number)
					}
				}
			}
		}

		return options
	}

	const getLabels = () => recoilValue.data.labels

	const getDatasets = () => {
		const datasets: ChartDataset<'line'>[] = []

		for (const key in recoilValue.data.chartData) {
			const value = recoilValue.data.chartData[parseInt(key)]
			const filterType: graphFilterType = parseInt(key)

			let label = getGraphFilterLabel(filterType)
			let yAxisID = 'y0'

			if (filterType === graphFilterType.AverageConversionRate) {
				label += ' %'
				yAxisID = 'y1'
			}

			datasets.push({
				label: label,
				data: value,
				fill: false,
				borderColor: graphFilterColor[filterType],
				backgroundColor: graphFilterColor[filterType],
				borderWidth: 2,
				pointRadius: 2,
				tension: 0.3,
				yAxisID: yAxisID
			})
		}

		return datasets
	}

	return {
		getDatasets,
		getLabels,
		getOptions
	}
}
