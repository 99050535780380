import React from 'react'
import PrimaryValueCard from './Common/Cards/PrimaryValueCard'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'

const OverviewRefundValueSummary = () => {
	const {getRefundValueSummary, getRefundRateTrend} = usePulseApi()
	const value = useApiValue(filterState, getRefundValueSummary, 0)
	const trendRate = useApiValue(filterState, getRefundRateTrend, 0)

	return (
		<PrimaryValueCard
			value={value.data}
			trendValue={trendRate.data}
			header="Total Refund Applications Value"
			tooltipText="Total value of all refund requests submitted."
			valueClassName="text-black"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewRefundValueSummary
