import React, {useEffect, useState} from 'react'
import SelectFilterCard, {SelectFilterItem} from "../../../../components/Dashboard/SelectFilter";
import {uploadFilterState} from "../../../../state/upload-filter";
import {selectedMemberUpload} from "../../../../state/upload-data";
import {MemberDataModel} from "../../../../types/upload";
import {FilterCard} from "../../../../components/Card/FilterCard";
import {CardBody} from "reactstrap";
import {useRecoilState} from "recoil";
import {useUploadApi} from "../../../../hooks/api/upload";
import {useApiValue} from "../../../../hooks/api";

const MemberDropdown = () => {
	const {getMembers} = useUploadApi();
	const {data, isLoading} = useApiValue(uploadFilterState, getMembers, [])
	const [memberState, setMemberState] = useRecoilState(selectedMemberUpload);
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([]);
	
	useEffect(() => {
		if (data.length > 0 && !isLoading) {
			
			const next = [
				...data?.map((x: MemberDataModel) => (
					{
						value: x.actorId,
						label: x.name
					}
				))
			]
			
			setMenuItems(next)
			
			if (next?.length === 1) {
				setMemberState(data[0])
			}
		}
	}, [data, isLoading])
	
	const handleChange = (item: SelectFilterItem) => {
		const member = data.find((x : MemberDataModel) => x.actorId === item.value);
		setMemberState(member)
	}
	
	if (menuItems.length === 1) {
		return (
			<FilterCard
				title="Member"
			>
				<CardBody>
					{menuItems[0].label}
				</CardBody>
			</FilterCard>
		);
	}
	
	return (
		<SelectFilterCard
			title="Member"
			items={menuItems}
			defaultValue={-1}
			value={memberState?.actorId}
			onChange={handleChange}
		/>
	)
}

export {MemberDropdown}