import React, {ReactNode} from 'react'
import classNames from 'classnames'

interface CardTabProps {
	header: string,
	className?: string,
	bodyClassName?: string,
	children: ReactNode
}

const CardTab = (props: CardTabProps) => {
	return (
		<div className={classNames('flex flex-col h-full',
			'bg-pulse-card-gray rounded-lg',
			props.className)}
		>
			<div className="
				px-4 py-1
				font-semibold text-sm uppercase"
			>
				{props.header}
			</div>
			<div className={classNames('divide-y-2 divide-gray-200 space-y-2 grid grid-cols-1',
				'h-full px-7 py-5 relative',
				'rounded-lg',
				'[&>*:nth-child(2)]:pt-5',
				props.bodyClassName, {
					'bg-pulse-gray': !props.bodyClassName
				})}
			>
				{props.children}
			</div>
		</div>
	)
}

export default CardTab
