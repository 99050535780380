import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import TrustPilotValueCard from './Common/TrustPilotValueCard/TrustPilotValueCard'

const RefundEmailVolumeSummary = () => {
	const {getAverageEmailVolume} = usePulseApi()
	const value = useApiValue(filterState, getAverageEmailVolume, 0)

	return (
		<TrustPilotValueCard
			value={`${value.data}`}
			header="Average Communication Volume"
			tooltipText="The average number of exchanged emails between Protect Group and the customer."
			isLoading={value.isLoading}
		/>
	)
}

export default RefundEmailVolumeSummary
