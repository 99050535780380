import classNames from 'classnames'
import React from 'react'
import {SummaryCardWrapperProps} from './Dynamic/props'

const SummaryCardWrapper = (props: SummaryCardWrapperProps) => {
	const {children, className = '', ...rest} = props

	return (
		<div className={classNames('flex-grow-1', className)} {...rest}>
			{children}
		</div>
	)
}

export default SummaryCardWrapper
