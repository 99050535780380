import React, {useState} from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import {useRecoilValue, useResetRecoilState} from 'recoil'
import {exportDataState, userDetailState} from '../../state/pulse'
import {getSsoUrl, signOut} from '../../services/user-service'
import DashboardSupport from './components/DashboardSupport'
import {settingsPageType} from '../../types/pulse'
import H2 from './components/Common/Headers/H2'
import H4 from './components/Common/Headers/H4'

const PulseSettingsView = () => {
	const resetExportDataState = useResetRecoilState(exportDataState)
	const [settingsPage, setSettingsPage] = useState(settingsPageType.Account)
	const handleLogOut = async () => await signOut()

	resetExportDataState()

	return (
		<DashboardPageLayout>
			<>
				<section className="
					max-w-6xl px-4 relative w-full
					lg:mx-auto lg:px-10"
				>
					<H2>Settings</H2>
					<div className="mb-8 inline-block">
						<div className="mb-4">
							<div
								className="
									inline-block mr-2 pr-2
									border-black border-r
									text-pulse-blue
									cursor-pointer"
								onClick={() => setSettingsPage(settingsPageType.Account)}
							>
								Account
							</div>
							<div
								className="
									inline-block
									cursor-pointer
									hover:opacity-60"
								onClick={handleLogOut}
							>
								<span className="
									align-middle mb-1 mr-1
									text-pulse-icon-gray text-xl
									material-symbols-outlined"
								>
									logout
								</span>
								<span>Logout</span>
							</div>
						</div>
						{settingsPage === settingsPageType.Account && (
							<AccountSection/>
						)}
					</div>
					<div>
						<DashboardSupport/>
					</div>
				</section>
			</>
		</DashboardPageLayout>
	)
}

const AccountSection = () => {
	const userDetail = useRecoilValue(userDetailState)
	const handleEdit = () => window.location.href = `${getSsoUrl()}Account/ChangePassword` ?? '#'

	return (
		<div className="
			min-w-72 p-6
			border border-gray-200 rounded-lg"
		>
			<div className="mb-4">
				<H4>Company</H4>
				<div>{userDetail.memberName}</div>
			</div>
			{userDetail.name && (
				<div className="mb-4">
					<H4>Name</H4>
					<div>{userDetail.name}</div>
				</div>
			)}
			{userDetail.userName && (
				<div className="mb-4">
					<H4>User Name</H4>
					<div>{userDetail.userName}</div>
				</div>
			)}
			<div className="mb-4">
				<H4>Password</H4>
				<div>***********</div>
			</div>
			<div className="flex flex-col items-end">
				<div
					className="
						mb-2 pl-2 pr-2 py-[2px]
						bg-white
						text-nowrap text-sm uppercase
						border border-pulse-icon-gray rounded-full
						cursor-pointer
						hover:bg-pulse-button-blue
						lg:mb-0"
					onClick={handleEdit}
				>
					<span className="
						align-middle mr-1
						leading-5 text-base text-pulse-icon-gray
						material-symbols-outlined">edit</span>
					<span className="leading-5 tracking-[1px] uppercase">Edit</span>
				</div>
			</div>
		</div>)
}

export default PulseSettingsView
