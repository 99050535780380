export default function useGetNumericChartOptions() {
	return {
		spanGaps: true,
		interaction: {
			mode: 'index',
			intersect: false,
		},
		elements: {
			line: {
				tension: 0.3
			}
		},
	}
}
