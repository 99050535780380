import React from 'react'

const DataAvailabilityWarningOverlay = () => {
	return (
		<>
			<p className="
				mb-4 mt-8
				font-semibold"
			>
				No Data Available for Selected Time Range
			</p>
			<p className="max-w-screen-sm mb-4">
				It seems you've selected a date range that predates your partnership with us.
				As a result, we don't have any revenue data to display for this period.
			</p>
			<p className="mb-4">
				Please adjust the time range to your active partnership period to access relevant data.
			</p>
		</>
	)
}

export default DataAvailabilityWarningOverlay
