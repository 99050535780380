import React, {useState} from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import {
	ClientResponseModel, ErrorResponseModel,
	UploadFilter,
	UploadTransactionBulkResponse
} from '../../../types/upload'
import {Button} from 'reactstrap'
import classNames from 'classnames'
import {NotificationMessageType} from '../../../types'
import {CSVLink} from 'react-csv'
import {useSetRecoilState} from 'recoil'
import {notificationMessageState} from '../../../state/ui'
import {useUploadApi} from '../../../hooks/api/upload'

interface UploadCsvResponseTableProps {
	uploadResponseData: UploadTransactionBulkResponse
}

const errorResponseColumns: TableColumn<ErrorResponseModel> [] = [
	{
		name: 'Row',
		selector: ({row}) => row,
		sortable: true
	},
	{
		name: 'Message',
		selector: ({message}) => message,
		cell: (row: any) => <span style={{color: 'red'}}>{row.Message.toString()}</span>
	},
]

const columns: TableColumn<ClientResponseModel> [] = [
	{
		name: 'Row',
		selector: ({row}) => row,
		sortable: true
	},
	{
		name: 'Processed',
		selector: ({processed}) => processed ?? false,
		sortable: true,
		cell: (row: any) => <span style={{color: row.Processed ? 'green' : 'red'}}>{row.Processed.toString()}</span>
	},
	{
		name: 'Message',
		selector: ({message}) => message,
		cell: (row: any) => <span>{constructMessage(row.Message, row.Processed)}</span>
	},
]

const constructMessage = (message: string, processed: boolean) => {
	if (!processed) {
		return message
	}

	const successMessage = 'Transaction uploaded successfully'
	if (message) {
		return `${successMessage}, SaleId: ${message}`
	}

	return successMessage
}
const UploadCsvResponseTable = (props: UploadCsvResponseTableProps) => {
	const {getErrorCsvAsync} = useUploadApi()
	const [csvData, setCsvData] = useState<string>('')

	const setNotification = useSetRecoilState(notificationMessageState)

	const handleErrorCsvDownload = async () => {
		try {

			const filter: UploadFilter = {
				errorId: props.uploadResponseData.ErrorId
			}
			const csvString = await getErrorCsvAsync(filter)
			setCsvData(csvString)
		} catch (error: any) {
			setNotification({
				message: `${error.response.data.message}`,
				type: NotificationMessageType.error
			})
		}
	}

	return (
		<>
			<TableCard
				title={props.uploadResponseData.Message}
				titleClass="card-title"
			>
				<DataTable
					data={!!props.uploadResponseData.ErrorResponse ? props.uploadResponseData.ErrorResponse : props.uploadResponseData.Response}
					columns={!!props.uploadResponseData.ErrorResponse ? errorResponseColumns : columns}
					theme="dark"
					pagination
					highlightOnHover
					noHeader
				/>
			</TableCard>
			{
				props.uploadResponseData.ErrorId && csvData.length === 0 &&
				(
					<Button
						onClick={handleErrorCsvDownload}
						className="btn btn-md float-right btn-danger"
						color="dark"
					>
						Prepare Error Csv
						<i className={classNames('tim-icons', 'icon-cloud-download-93')}/>
					</Button>
				)
			}
			{
				props.uploadResponseData.ErrorId && csvData.length !== 0 &&
				(
					<CSVLink
						filename={`upload-bulk-error-${props.uploadResponseData.ErrorId}.csv`}
						data={csvData}
						className="btn btn-md float-right btn-danger">
						Download
					</CSVLink>
				)
			}
		</>
	)
}

export {UploadCsvResponseTable}