import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'

interface ConfirmModalProps {
	titleText: string
	bodyText: string
	isOpen: boolean
	isDisabled?: boolean
	onConfirm: () => void
	onCancel: () => void
}

const ConfirmModal = (props: ConfirmModalProps) => {
	const {
		titleText,
		bodyText,
		isOpen,
		isDisabled,
		onConfirm,
		onCancel,
	} = props

	return (
		<div>
			<Modal isOpen={isOpen}>
				<ModalHeader toggle={onCancel} className={'modal-title'}>
					{titleText}
				</ModalHeader>
				<ModalBody>
					{bodyText}
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={onConfirm}
						disabled={isDisabled}
					>
						Yes
					</Button>
					<Button onClick={onCancel} disabled={isDisabled}>
						No
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default ConfirmModal
