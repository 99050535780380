import {SelectFilterItem} from "../components/Dashboard/SelectFilter";

export enum IdEnum {
	DefaultId = 0
}

export enum UploadType {
	Single = "Single",
	Bulk = "Bulk"
}

export interface UploadTransaction {
	salesReferenceId: string,
	customerFirstName: string,
	customerLastName: string,
	vendorSalesDateTime: string,
	productCode: string,
	productPrice?: number,
	currencyCode?: string,
	premiumRate?: number,
	offeringMethod?: string,
	sold: boolean,
	eventDateTime?: string,
	quoteId?: string,
	loggedInUser?: string
	actionType?: number,
	uploadProductType?: number,
	actorId?: number,
	timezoneId?: string
}

export interface UploadTransactionBulkResponse {
	Message: string,
	ErrorId: string,
	Response: ClientResponseModel[],
	ErrorResponse: ErrorResponseModel[],
}

export interface ClientResponseModel {
	row: number,
	processed?: boolean,
	message: string
}

export interface ErrorResponseModel {
	row: number,
	message: string
}

export interface MemberDataModel {
	actorId: number,
	name: string,
	isRpEnabled: boolean,
	isDpEnabled: boolean
}

export interface UploadFilter extends Record<string, any> {
	loggedInUser?: string;
	errorId?: string;
}

export const productCodes : SelectFilterItem[] = [
	{
		label: "TKT",
		value: "TKT"
	},
	{
		label: "PKG",
		value: "PKG"
	},
	{
		label: "HTL",
		value: "HTL"
	}
]

export const soldTypes : SelectFilterItem[] = [
	{
		value: true,
		label: "True"
	},
	{
		value: false,
		label: "False"
	}
]

export const uploadProductTypes : SelectFilterItem[] = [
	{
		label: "Refund Protect Standard",
		value: 0
	},
	{
		label: "Refund Protect Dynamic",
		value: 1
	}
]

export const utc = 'UTC'

export const rpStandardProduct  = uploadProductTypes.find(x => x.label === "Refund Protect Standard")
export const rpDynamicProduct  = uploadProductTypes.find(x => x.label === "Refund Protect Dynamic")

export const isMemberInvalid = (actorId : number | undefined) => !actorId  || actorId === -1
export const isProductPriceInvalid = (productPrice : number | undefined, actionType : number) =>
	!productPrice || (productPrice <= 0 && actionType === 0)

export const isPremiumRateInvalid = (premiumRate : number | undefined, actionType : number) =>
	!premiumRate || (premiumRate <= 0 && actionType === 0)

export const isSalesReferenceIdInvalid = (salesReferenceId : string) => salesReferenceId.trim() === ""
	|| salesReferenceId.trim().length > 64

export const isStringEmpty = (name : string | undefined) => !name || name?.trim() === ""