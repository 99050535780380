import React from 'react'
import {Row, Col} from 'reactstrap'
import {DateFilters} from './DateFilters'
import {IntegrationTypeFilter} from './IntegrationTypeFilter'
import {CommercialDropdown} from './CommercialDropdown'
import {RegionFilter} from './RegionFilter'
import {IntegrationGuideFilter} from './IntegrationGuideFilter'
import {IntegrationMigrationFilter} from './IntegrationMigrationFilter'
import {AgreementFilter} from './AgreementFilter'

const Filters = () => {
    return (
            <Row className="pb-3">
                <Col>
                    <IntegrationMigrationFilter/>
                </Col>
                <Col>
                    <DateFilters/>
                </Col>
                <Col>
                    <CommercialDropdown/>
                </Col>
                <Col>
                    <RegionFilter/>
                </Col>
                <Col>
                    <IntegrationTypeFilter/>
                </Col>
                <Col>
                    <IntegrationGuideFilter/>
                </Col>
                <Col>
                    <AgreementFilter/>
                </Col>
            </Row>
    )
}

export {Filters}
