import React, {memo, useEffect, useState} from 'react'
import {ValueDirectionProps, ValueStatusCard} from '../../../components/Card/ValueStatusCard'
import {refundFilterState, ValueStatus} from '../../../types/refund'
import {formatNumberMember} from '../../../utils/number'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'

const Insured = memo(() => {
	const [valueStatusData, setValueStatusData] = useState<ValueDirectionProps[]>([])
	const {getInsuredData} = useRefundApi()
	const {data} = useApiValue(refundFilterState, getInsuredData, [] as ValueStatus[])

	useEffect(() => {
		if (data) {
			const next: ValueDirectionProps[] = data?.map((x: ValueStatus, i: number) => {
				const {value, isPositive} = x
				return {
					value: i !== 1 ? formatNumberMember(parseInt(value)) : `$${formatNumberMember(parseInt(value))}`,
					direction: isPositive ? 'down' : 'up',
					colour: isPositive ? 'red' : 'green'
				}
			})
			setValueStatusData(next)
		}
	}, [data])

	return (
		<>
			<ValueStatusCard
				title={'Insured Approved'}
				values={valueStatusData}
				subTitle="Last 24Hrs"
			/>
		</>
	)
})

export {Insured}