import React, {memo} from 'react'
import {MainChartComponent} from './MainChartComponent'
import useGetPercentageChartOptions from '../../../hooks/useGetPercentageChartOptions '
import {tooltipList} from '../../../../../utils/chart'
import {defaultChartSummaryData} from '../../../../../types'
import {useMemberApi} from '../../../../../hooks/api/member'
import {useApiValue} from '../../../../../hooks/api'
import {memberFilterState} from '../../../../../state/memberFilterState'

const ConversionRateChart = memo(() => {
	const {getConversionRateChartData, getConversionRateChartSummaryData} = useMemberApi()
	const {
		data: chartData,
		isLoading: chartLoading
	} = useApiValue(memberFilterState, getConversionRateChartData, [])
	const {
		data: summaryData,
		isLoading: summaryLoading
	} = useApiValue(memberFilterState, getConversionRateChartSummaryData, defaultChartSummaryData)
	const options = useGetPercentageChartOptions()

	return (
		<MainChartComponent
			chartData={chartData}
			isCurrency={false}
			isLoading={chartLoading || summaryLoading}
			isPercentage={true}
			options={options}
			summaryData={summaryData}
			title={`conversion rate`}
			tooltip={tooltipList.ConversionRate}
		/>
	)
})

export {ConversionRateChart}
