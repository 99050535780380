import React, {useState, memo} from 'react'
import {Button, ButtonGroup} from 'reactstrap'
import classNames from 'classnames'

interface ToggleButtonProps {
	label: string;
	isSelected: boolean;
	select: (value: string) => void;
}

interface CommissionRevenueTogglesProps {
	onSelect: (value: string) => void;
	selectedValue: string;
}

const toggles = ['Commission', 'Revenue']

const ToggleButton = (props: ToggleButtonProps) => {
	const {label, isSelected, select} = props
	const handleClick = () => select(label)

	return (
		<Button
			color="info"
			id="0"
			tag="label"
			className={classNames('btn-simple', 'd-flex', 'align-items-center', {
				active: isSelected
			})}
			onClick={handleClick}
		>
			<input defaultChecked name="options" type="radio"/>
			<span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
        {label}
      </span>
			<span className="d-block d-sm-none">
        <i className="tim-icons icon-single-02"/>
      </span>
		</Button>
	)
}

const CommissionRevenueToggles = memo((props: CommissionRevenueTogglesProps) => {
	const {selectedValue, onSelect} = props
	return (
		<ButtonGroup className="btn-group-toggle">
			{
				toggles.map(key => (
					<ToggleButton
						key={key}
						label={key}
						isSelected={key === selectedValue}
						select={onSelect}/>
				))
			}
		</ButtonGroup>
	)
})

const useCommissionRevenueToggles = () => {
	const [selectedToggle, setSelectedToggle] = useState<string>(toggles[0])
	const component = <CommissionRevenueToggles
		onSelect={value => setSelectedToggle(value)}
		selectedValue={selectedToggle}
	/>

	return {selectedToggle, toggleComponent: component}
}

export {CommissionRevenueToggles, ToggleButton, useCommissionRevenueToggles}
