import {selector} from "recoil";

const keyPrefix = 'refund'

export interface ValueStatus {
	value : string
	isPositive? : boolean
}

export interface TrustPilotViewModel {
	dayAverage : ValueStatus
	reviewCount : ValueStatus
	reviewRatio : ValueStatus
	badReviewCount : ValueStatus
	badReviewRatio : ValueStatus
	mtdAverage : ValueStatus
}

export interface UserDataModel {
	rank : number
	user : string
	weekToDate : ValueStatus
	monthToDate : ValueStatus
	threeMonths : ValueStatus
	today : ValueStatus
	referralQualityWeek : ValueStatus[]
	referralQualityMonth : ValueStatus[]
	approved : ValueStatus
	declined : ValueStatus
}

export interface UserModel{
	count : number
	rows : UserDataModel[]
}

export interface RefundFilter extends Record<string, any> {
}

export interface SummaryValues {
	totalMtd: number
	totalWtd: number
	totalYtd: number
}

export interface ChartData {
	summary: ChartSummary,
	chartData: Record<string, number[]>
}

export const defaultChartData: ChartData = {
	chartData: {},
	summary: {
		totalDtd: 0,
		totalMtd: 0,
		totalWtd: 0,
		totalYtd: 0
	}
}

export interface ChartSummary extends SummaryValues {
	totalDtd: number
}

export interface RefundUserTableFilter extends Record<string, any> {
}

export const refundFilterState = selector<RefundFilter>({
	key: `${keyPrefix}`,
	get: ({get}) => {
		return {
		}
	}
})