import React, {useEffect, useState} from 'react'
import {Currency} from '../../../../types'
import getSymbolFromCurrency from 'currency-symbol-map';
import {FilterEnum} from "../../../../types/insurance-provider";
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {selectedCurrencyStateInsurance} from "../../../../state/insurance-provider-filters";
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const CurrencyDropdown = () => {
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const {getCurrencies} = useInsuranceApi();
	const {data} = useApiValue(insuranceProviderFilterState, getCurrencies, [])

	useEffect(() => {
		if (data) {
			const next = [
				{value: FilterEnum.DefaultFilterAll, label: FilterEnum.DefaultFilterAll},
				...data.map((x: Currency) => (
					{
						value: x.currencyCode,
						label: `${x.currencyCode} (${getSymbolFromCurrency(x.currencyCode)})`
					}
				))
			]
			setMenuItems(next)
		}
	}, [data])
	
	return (
		<StatefulSelectFilterCard
			state={selectedCurrencyStateInsurance}
			title="Currency"
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {CurrencyDropdown}