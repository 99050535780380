import React from 'react'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {selectedCoreCurrencyStateMember} from '../../../../state/member-filters'
import {extendedCoreCurrencyDropdownData} from '../../../../utils/data'
	
const currencies = extendedCoreCurrencyDropdownData
	.map((x) => ({
		value: x.value,
		label: x.value
	}))

const CoreCurrencyDropdown = () => {
	return (
		<StatefulSelectFilterCard
			title="Core Currency"
			items={currencies}
			state={selectedCoreCurrencyStateMember}
			showDefault={false}
		/>
	)
}

export {CoreCurrencyDropdown}




