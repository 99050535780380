import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {graphFilterName, graphFilterType} from '../../../types/pulse'
import {formatCurrency} from '../../../utils/currency'
import {userDetailState} from '../../../state/pulse'

export default function useGetGraphFilterLabel() {
	const userDetail = useRecoilValue(userDetailState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const formattedValue = formatCurrency(coreCurrency, 0)
	const currencySymbol = formattedValue
		.replace(/\d/g, '')
		.replace('.', '')
		.trim()

	return (filter: graphFilterType) => {
		if (filter === graphFilterType.RevenuePerDollar) {
			return `Gross Revenue per ${currencySymbol}`
		}

		if (filter === graphFilterType.YourRevenue) {
			return userDetail.isProtectGroup === true
				? 'Partner\'s Revenue'
				: graphFilterName[graphFilterType.YourRevenue]
		}

		return graphFilterName[filter]
	}
}
