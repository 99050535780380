import {selector} from 'recoil'
import {userInsuranceProviderDataIdsState} from './auth'

const keyPrefix = 'document'

// TODO Need discussion with Suggy regarding below
export const isUserInsurerState = selector<boolean>({
	key: `${keyPrefix}IsUserInsurerState`,
	get: ({get}) => {
		return get(userInsuranceProviderDataIdsState) !== ''
	}
})
