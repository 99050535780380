import React from 'react'
import {Card, CardBody, Col, Row, Table} from 'reactstrap'
import {TableCard} from '../../../components/Card/TableCard'
import RegionMap from './RegionMap'
import {useGetMapData} from '../../../hooks/useGetChartDataSummary'
import {RegionData, SummaryLabels} from '../../../types/dynamic-performance'
import {toUsd} from '../../../utils/number'
import {useGetRegionData} from '../hooks/useGetRegionData'

interface RegionSummaryProps {
	data: SummaryLabels,
	regionId: number
}

const RegionSummary = (props: RegionSummaryProps) => {
	const {data, regionId} = props
	const {getMapLabel} = useGetMapData(regionId)

	return (
		<Table className={'custom-table-hover'}>
			<thead>
			<tr>
				<th><h6>{getMapLabel()}</h6></th>
				<th>WTD</th>
				<th>MTD</th>
				<th>YTD</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<th scope="row">Sales Volume</th>
				{
					Object.values(data.salesVolume).map((value, i) => (
						<td key={`sales-volume-${i}`}>{value}</td>
					))
				}
			</tr>
			<tr>
				<th scope="row">Sales Value</th>
				{
					Object.values(data.salesValue).map((value, i) => (
						<td key={`sales-value-${i}`}>{toUsd(value, 0)}</td>
					))
				}
			</tr>
			<tr>
				<th scope="row">Revenue Generated</th>
				{
					Object.values(data.revenueGenerated).map((value, i) => (
						<td key={`revenue-generated-${i}`}>{toUsd(value, 0)}</td>
					))
				}
			</tr>
			<tr>
				<th scope="row">Revenue Generated per $</th>
				{
					Object.values(data.revenueValue).map((value, i) => (
						<td key={`revenue-value-${i}`}>{value.toFixed(2)}</td>
					))
				}
			</tr>
			</tbody>
		</Table>
	)
}

interface RegionMapProps {
	data: RegionData
}

const RegionMapContainer = (props: RegionMapProps) => {
	const {data: {regionId, countryData, summaryData}} = props
	const {getMapName} = useGetMapData(regionId)
	const map = getMapName()

	const mapData = countryData.reduce((c, n) => {
		return {
			...c,
			[n.countryCode]: n.value
		}
	}, {})

	return (
		<div className="custom-region-border">
			<Card>
				<CardBody>
					<RegionSummary data={summaryData} regionId={regionId}/>
				</CardBody>
				<RegionMap map={map} data={mapData}/>
			</Card>
		</div>
	)
}

const RegionMaps = () => {
	const {data} = useGetRegionData()

	return (
		<TableCard title="Regions" showToggle>
			<Row>
				{
					data?.map((x: RegionData) => {
						return (
							<Col key={x.regionId} xs={12} lg={4} className="pb-3">
								<RegionMapContainer data={x}/>
							</Col>
						)
					})
				}
			</Row>
		</TableCard>
	)
}

export default RegionMaps
