import React, {useState, useEffect, useCallback} from 'react'
import _ from 'underscore'
import {VectorMap} from 'react-jvectormap'
import {scaleLinear} from 'd3-scale'
import {continentColorScales} from '../../../utils/chart'
import {usePerformanceApi} from "../../../hooks/api/live-performance";
import {useStatelessApiValue} from "../../../hooks/api";

const PerformanceVectorMap = () => {
    const [mapData, setMapData] = useState({})
    const [markerData, setMarkerData] = useState({})
    const [mapNode, setMapNode] = useState(null)
    const mapRef = useCallback(node => {
        if (node) {
            setMapNode(node)
        }
    }, [])

    const {getPerformanceMapData, getMemberMarkers} = usePerformanceApi()
    const {data} = useStatelessApiValue(getPerformanceMapData, [])
    const {data: members} = useStatelessApiValue(getMemberMarkers, [])

    useEffect(() => {
        const continentGroups = _.groupBy(data, x => {
            const {continentCode} = x
            return continentCode
        })

        const ranges = Object.keys(continentGroups).reduce((result, key) => {
            const data = continentGroups[key]
            const values = _.sortBy(data.map(x => {
                const {revenue} = x
                return revenue
            }), num => num)

            const domain = [values[0], values[values.length - 1]]
            const range = continentColorScales[key]

            return {
                ...result,
                [key]: {
                    colorScale: (value) => scaleLinear().domain(domain).range(range)(value)
                }
            }
        }, {})

        const mapValues = data.reduce((result, item) => {
            const {continentCode, countryCode, revenue} = item
            const {colorScale} = ranges[continentCode]

            return {
                ...result,
                [countryCode]: colorScale(revenue)
            }
        }, {})

        setMapData(mapValues)
    }, [data])

    useEffect(() => {
        const next = members.map(member => {
            const {name, lat, long, isNew} = member
            return {
                name,
                latLng: [lat, long],
                style: {
                    fill: isNew ? '#30f23a' : '#ff0000',
                    r: isNew ? 7 : 5
                }
            }
        })
        setMarkerData(next)
    }, [members])

    useEffect(() => {
        if (mapNode) {
            const mapObj = mapNode.getMapObject()
            mapObj.series.regions[0].setValues(mapData)
        }
    }, [mapNode, mapData])


    return (
        <VectorMap
            ref={mapRef}
            backgroundColor="#3b96ce"
            markers={markerData}
            containerStyle={{
                width: '100%',
                height: '100%'
            }}
            markerStyle={{
                hover: {
                    stroke: 'black',
                    "stroke-width": 2,
                    cursor: 'pointer'
                },
                selected: {
                    fill: 'blue'
                },
                selectedHover: {}
            }}
            containerClassName="map"
            map="world_mill"
            series={{
                regions: [{
                    normalizeFunction: 'polynomial'
                }]
            }}
            zoomOnScroll={false}
        />
    )
}

export {PerformanceVectorMap}
