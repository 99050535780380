export const coreCurrencyDropdownData = [
	{id: '0', value: 'GBP', name: 'Great Britain Pound'},
	{id: '1', value: 'EUR', name: 'Euro'},
	{id: '2', value: 'USD', name: 'United States Dollar'},
	{id: '3', value: 'AUD', name: 'Australian Dollar'},
	{id: '4', value: 'CAD', name: 'Canadian Dollar'},
	{id: '5', value: 'BRL', name: 'Brazilian Real'}
]

export const extendedCoreCurrencyDropdownData = [
	...coreCurrencyDropdownData,
	{id: '6', value: 'CNY', name: 'Chinese Yuan Renminbi'},
	{id: '7', value: 'INR', name: 'Indian Rupee'},
	{id: '8', value: 'JPY', name: 'Japanese Yen'},
	{id: '9', value: 'KRW', name: 'South Korean Won'},
	{id: '10', value: 'MYR', name: 'Malaysian Ringgit'},
	{id: '11', value: 'PHP', name: 'Philippine Peso'},
	{id: '12', value: 'RUB', name: 'Russian Ruble'}
]

export const refundsCoreCurrencyDropdownData = [
	{id: '0', value: 'GBP', name: 'Great Britain Pound'},
	{id: '2', value: 'USD', name: 'United States Dollar'}
]