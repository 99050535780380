import React, {memo} from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import {integrationFilterState} from '../../../state/integration'
import {useApiValue} from '../../../hooks/api'
import {useIntegrationApi} from '../../../hooks/api/integration'
import {IntegrationTableData, IntegrationTableModel} from '../../../types/integration'

const IntegrationTable = memo(() => {
	const {getIntegrationData} = useIntegrationApi()
	const {data, isLoading} = useApiValue(integrationFilterState, getIntegrationData, {} as IntegrationTableData)
	return (
		<TableCard
			title="Integrations In Progress"
			titleClass="card-title"
			showToggle={true}
			isLoading={isLoading}
		>
			<DataTable
				data={data.rows}
				columns={columns}
				theme="dark"
				pagination={true}
				highlightOnHover={true}
				disabled={isLoading}
			/>
		</TableCard>
	)
})

export {IntegrationTable}

const columns: TableColumn<IntegrationTableModel> [] = [
	{
		name: 'Member',
		selector: ({memberName}) => memberName,
		sortable: true,
	},
	{
		name: 'Status',
		selector: ({status}) => status,
		sortable: true,
		right: true,
	},
	{
		name: 'Product',
		selector: ({productName}) => productName,
		sortable: true,
		right: true,
	},
	{
		name: 'Type',
		selector: ({integrationType}) => integrationType,
		sortable: false,
		right: true,
	},
	{
		name: 'Guides',
		selector: ({integrationGuide}) => integrationGuide,
		sortable: true,
		right: true,
	},
	{
		name: 'Days Integrating',
		selector: ({integrationTime}) => integrationTime,
		sortable: false,
		right: true,
	},
	{
		name: 'Commercial Manager',
		selector: ({commercialName}) => commercialName,
		sortable: true,
		right: true,
	},
	{
		name: 'Region',
		selector: ({regionName}) => regionName,
		sortable: true,
		right: true,
	},
	{
		name: 'Member Type',
		selector: ({memberType}) => memberType,
		sortable: false,
		right: true,
	},
]