import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import ListValueCard from './Common/Cards/ListValueCard'

const OverviewTopApprovedRefundReasons = () => {
	const {getTopApprovedRefundReasons} = usePulseApi()
	const value = useApiValue(filterState, getTopApprovedRefundReasons, [])

	return (
		<ListValueCard
			value={value.data}
			header="Top Approved Refund Reasons"
			tooltipText="Most common reasons for approved refunds under the Refund Protect policy."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewTopApprovedRefundReasons
