import React, {memo} from 'react'
import {MainChartComponent} from './MainChartComponent'
import useGetNumericChartOptions from '../../../hooks/useGetNumericChartOptions'
import {tooltipList} from '../../../../../utils/chart'
import {useMemberApi} from '../../../../../hooks/api/member'
import {useApiValue} from '../../../../../hooks/api'
import {memberFilterState} from '../../../../../state/memberFilterState'
import {defaultChartSummaryData} from '../../../../../types'

const TransactionVolumeChart = memo(() => {
	const {getTransactionVolumeChartData, getTransactionVolumeChartSummaryData} = useMemberApi()
	const {
		data: chartData, isLoading: chartLoading
	} = useApiValue(memberFilterState, getTransactionVolumeChartData, [])
	const {
		data: summaryData, isLoading: summaryLoading
	} = useApiValue(memberFilterState, getTransactionVolumeChartSummaryData, defaultChartSummaryData)
	const options = useGetNumericChartOptions()

	return (
		<MainChartComponent
			chartData={chartData}
			isCurrency={false}
			isLoading={chartLoading || summaryLoading}
			isPercentage={false}
			options={options}
			summaryData={summaryData}
			title={`transaction volume`}
			tooltip={tooltipList.TransactionVolume}
		/>
	)
})

export {TransactionVolumeChart}
