import {atom, selector} from 'recoil'
import moment, {Moment} from 'moment'
import {dateFormat} from '../utils/date'
import {ProtectFinancialFilter} from '../types/protect-financial'

export const userFilterState = atom<string>({
	key: 'pfUserFilterState',
	default: ''
})

export const dateFromState = atom<Moment>({
	key: 'pfDateFromState',
	default: moment().startOf('year')
})

export const dateToState = atom<Moment>({
	key: 'pfDateToState',
	default: moment()
})

const formattedDateFromState = selector<string>({
	key: 'pfFormattedDateFromState',
	get: ({get}) => get(dateFromState).format(dateFormat)
})

const formattedDateToState = selector<string>({
	key: 'pfFormattedDateToState',
	get: ({get}) => get(dateToState).format(dateFormat)
})

export const protectFinancialFilterState = selector<ProtectFinancialFilter>({
	key: 'pfDateFilters',
	get: ({get}) => ({
		dateFrom: get(formattedDateFromState),
		dateTo: get(formattedDateToState)
	})
})
