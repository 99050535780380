import {ValueCard} from '../../Card/ValueCard'
import React from 'react'
import {filterState} from '../../../state/dynamic-performance'
import {toUsd} from '../../../utils/number'
import {useDynamicPerformanceApi} from '../../../hooks/api/dynamic'
import {useApiValue} from '../../../hooks/api'

const RevenueValueSummary = () => {
	const {getRevenueValueSummary} = useDynamicPerformanceApi()
	const { data } = useApiValue(filterState, getRevenueValueSummary, 0)

	return (
		<ValueCard
			title="Revenue Per $"
			value={toUsd(data.toString(), 2)}
			icon="icon-money-coins"
			iconColor="rp"
		/>
	)
}

export default RevenueValueSummary
