import React from 'react';
import {
    dateFromStateMember,
    dateToStateMember,
} from '../../../../state/member-filters';
import * as DashboardDateFilters from '../../../../components/Dashboard/DateFilters'

const DateFilters = () => {
  return (
    <DashboardDateFilters.DateFilters fromState={dateFromStateMember} toState={dateToStateMember}/>
  );
};

export {DateFilters};
