import {UserManager} from 'oidc-client';

const config = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SCOPE,
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI
};

export const userManager = new UserManager({...config});

export const getUser = () => userManager.getUser();
export const signInRedirect = () => userManager.signinRedirect();
export const signInRedirectCallback = () => userManager.signinRedirectCallback();
export const signInSilent = () => userManager.signinSilent();
export const signInSilentCallback = () => userManager.signinSilentCallback();
export const signOut = () => userManager.signoutRedirect();
export const getSsoUrl = () => config.authority;
