import React from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'
import {toUsd} from '../../../../utils/number'
import {salesFilterState} from '../../../../state/sales'
import {useSalesApi} from '../../../../hooks/api/sales'
import {useApiValue} from '../../../../hooks/api'

export const RevenuePerDollar = () => {
	const {getRevenuePerDollar} = useSalesApi()
	const {data} = useApiValue(salesFilterState, getRevenuePerDollar, 0.00)
	const color = useSummaryCardColor()

	return (
		<ValueCard
			title={`Revenue Per $`}
			value={toUsd(data.toString(), 2)}
			icon="icon-money-coins"
			iconColor={color}
		/>
	)
}