import React from "react";
import {Col, Row} from "reactstrap";
import {useRecoilValue} from "recoil";
import {selectedProductTypeUpload, selectedUploadTypeUpload} from "../../state/upload-data";
import {
	rpDynamicProduct,
	rpStandardProduct,
	UploadType
} from "../../types/upload";
import UploadBulk from "./components/UploadBulk";
import {Filters} from "./components/Filters";
import UploadSingleStandard from "./components/UploadSingleStandard";
import UploadSingleDynamic from "./components/UploadSingleDynamic";

const UploadView = () => {
	
	const uploadType = useRecoilValue(selectedUploadTypeUpload);
	const productUploadType = useRecoilValue(selectedProductTypeUpload);
	
	return (
		<div className="content">
			<Row className="pb-3">
				<Col>
					<Filters />
				</Col>
			</Row>
			
			{
				(uploadType === UploadType.Single && productUploadType === rpStandardProduct?.value) &&
				<Row>
					<Col>
						<UploadSingleStandard />
					</Col>
				</Row>
			}
			{
				(uploadType === UploadType.Single && productUploadType === rpDynamicProduct?.value) &&
				<Row>
					<Col>
						<UploadSingleDynamic />
					</Col>
				</Row>
			}
			{
				uploadType === UploadType.Bulk &&
				<UploadBulk />
			}
			
		</div>
	)
}

export default UploadView