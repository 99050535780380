export interface ValueStatus {
	value: number,
	isPositive?: boolean
}

export interface LeaderboardRowData {
	rank: number,
	region: string,
	vertical: string,
	revenue: number,
	conversion: number,
	averageGrowth: ValueStatus
}

export enum FilterEnum {
	DefaultFilterAll = "All"
}

export enum IdEnum {
	DefaultId = 0
}

export interface SalesFilter extends Record<string, any> {
	dateFrom: string,
	dateTo: string,
	territoryId: string,
	regionId: string,
	coreCurrencyCode: string,
	productId: number,
	memberIds: number[]
	loggedInUser: string | undefined
}

export interface TransactionProtectionRevenueGrowthValueModel {
	currentYearValue: number,
	previousYearValue: number
}

export interface MemberRow {
	member: string,
	day: number,
	mtd: number,
	ytd: number,
	mrr: number,
	months: MonthValue[],
	growth: Growth[]
}

export interface MonthValue {
	month: string,
	value: number
}

export interface Growth {
	label: string,
	count: number,
	percent: number,
	isPositive: boolean
}

export interface MemberOverviewTableData {
	count: number,
	rows: MemberRow[]
}

export interface MemberData {
	Member: string;
	MemberId: number;
	Day: number;
	Mtd: number;
	Ytd: number;
	Months: { Month: string; Value: number }[];
	Growth: { Label: string; Count: number; Percent: number; IsPositive: boolean; Member: any }[];
}

export interface SummaryValues {
	totalMtd: number
	totalWtd: number
	totalYtd: number
}

export interface ChartSummary extends SummaryValues {
	totalDtd: number
}

export interface ChartData {
	summary: ChartSummary,
	chartData: Record<string, number[]>
}