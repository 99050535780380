import React, {ReactElement} from 'react'
import DashboardHeader from './DashboardHeader'
import DashboardFooter from './DashboardFooter'
import DashboardNavBar from './DashboardNavBar'
import DashboardToast from './DashboardToast'
import DashboardState from './DashboardState'
import {useRecoilValue} from 'recoil'
import {dashboardLoadingState} from '../../../state/pulse'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'

export interface DashboardPageLayoutProps {
	children: ReactElement;
}

const DashboardPageLayout = (props: DashboardPageLayoutProps) => {
	const dashboardLoading = useRecoilValue(dashboardLoadingState)

	return (
		<>
			<DashboardState/>
			<section className="flex flex-col min-h-screen">
				<DashboardHeader/>
				<LoadingSpinner isLoading={dashboardLoading} className="m-auto">
					<>
						{props.children}
						<DashboardFooter/>
					</>
				</LoadingSpinner>
				<DashboardNavBar/>
				<DashboardToast/>
			</section>
		</>
	)
}

export default DashboardPageLayout
