import React, {memo, useEffect} from 'react'
import {FilterCard} from '../../../components/Card/FilterCard'
import Select, {OnChangeValue} from 'react-select'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {isSalesTeamSelectedState} from '../../../state/filter-data'
import {selectedSalesTeamIdState, selectedUserState} from '../../../state/filters'
import {Button, ButtonGroup} from 'reactstrap'
import {SalesTeam} from '../../../types'
import classNames from 'classnames'
import {loggedInUserState} from '../../../state/auth'
import {useCommissionApi} from '../../../hooks/api/commission'
import {useApiValue} from '../../../hooks/api'
import {commissionFilterState} from '../../../state/commission-data'

interface SalesTeamButtonProps {
	team: SalesTeam
}

const SalesTeamButton = (props: SalesTeamButtonProps) => {
	const {team} = props
	const isSelected = useRecoilValue(isSalesTeamSelectedState(team))
	const setTeam = useSetRecoilState(selectedSalesTeamIdState)

	const handleClick = () => setTeam(team.id)

	return (
		<Button
			color="info"
			size="sm"
			tag="label"
			className={classNames('btn-simple', {
				active: isSelected
			})}
			onClick={handleClick}
		>
			<input defaultChecked name="options" type="radio"/>
			<span className="d-block">
        {team.name}
      </span>
		</Button>
	)
}

const SalesTeamButtons = memo(() => {
	const {getUserSalesTeams} = useCommissionApi()
	const {data: salesTeams} = useApiValue(commissionFilterState, getUserSalesTeams, [])
	const setSalesTeam = useSetRecoilState(selectedSalesTeamIdState)

	useEffect(() => {
		if (salesTeams.length === 1) {
			setSalesTeam(salesTeams[0].id)
		}
	}, [salesTeams, setSalesTeam])

	return (
		<ButtonGroup className="btn-group-toggle d-block flex-wrap">
			{salesTeams.map(team => <SalesTeamButton key={team.id} team={team}/>)}
		</ButtonGroup>
	)
})

const UserFilter = memo(() => {
	const {getUsers, getUserSalesTeams} = useCommissionApi()
	const {data: users} = useApiValue(commissionFilterState, getUsers, [])
	const {data: salesTeams} = useApiValue(commissionFilterState, getUserSalesTeams, [])
	const [selectedUser, setUser] = useRecoilState(selectedUserState)
	const loggedInUser = useRecoilValue(loggedInUserState)

	useEffect(() => {
		if (users.length > 1 && selectedUser === null) {
			const user = users.find(x => x.name === loggedInUser)
			if (user) {
				setUser(user)
			}
		}
	}, [loggedInUser, users, selectedUser, setUser])

	const handleChange = (option: OnChangeValue<any, any>) => {
		setUser(users.find(user => user.id === option.value))
	}

	return (
		<FilterCard
			title="User"
			icon="icon-single-02"
			iconColor="warning"
		>
			<>
				<div>
					{users.length === 1 && (
						<div className="d-flex justify-content-end">
							<h5>{users[0].name}</h5>
						</div>
					)}

					{users.length > 1 && (
						<Select
							className="react-select info"
							classNamePrefix="react-select"
							name="singleSelect"
							isSearchable
							value={{
								value: selectedUser?.id,
								label: selectedUser?.name ?? 'Select User'
							}}
							options={[
								{
									value: undefined,
									label: 'Select User...'
								},
								...users.map(user => ({
									value: user.id,
									label: user.name
								}))
							]}
							placeholder="Select User"
							blurInputOnSelect={true}
							onChange={handleChange}
						/>
					)}
				</div>
				{salesTeams.length > 0 && (
					<div className="d-flex justify-content-end">
						<SalesTeamButtons/>
					</div>
				)}
			</>
		</FilterCard>
	)
})

export {UserFilter}
