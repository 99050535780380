import React, {memo} from 'react'
import {Doughnut} from 'react-chartjs-2'
import {formatCurrency} from '../../../../utils/currency'
import {selectedCoreCurrencyStateMember} from '../../../../state/member-filters'
import {useRecoilValue} from 'recoil'
import {ChartCard} from '../../../../components/Card/ChartCard'
import {Badge, Card, Col, Row} from 'reactstrap'
import classNames from 'classnames'
import {useMemberApi} from '../../../../hooks/api/member'
import {useApiValue} from '../../../../hooks/api'
import {memberFilterState} from '../../../../state/memberFilterState'
import {SummaryResult} from '../../../../types/member'

const RiskCoverageChart = memo(() => {
	const {getSummaryData} = useMemberApi()
	const {data, isLoading} = useApiValue(memberFilterState, getSummaryData, {} as SummaryResult)
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateMember)

	const chartData = {
		labels: ['Protected', 'Unprotected'],
		datasets: [
			{
				data: [data.protectedRate, data.unProtectedRate],
				backgroundColor: [
					'rgb(255, 99, 132)',
					'rgb(54, 162, 235)',
				],
				hoverOffset: 2,
			}]
	}

	const options: any = {
		spanGaps: true,
		interaction: {
			mode: 'index',
			intersect: false,
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: function (context: { label: string; parsed: number | null }) {
						let label = context.label || ''
						if (label) {
							label += ': '
						}
						if (context.parsed !== null) {
							label += Number(context.parsed).toFixed(2) + '%'
						}
						return label
					}
				}
			},
			legend: {
				display: false
			}
		}
	}

	return (
		<Card className={classNames({
			'loading': isLoading,
			'pt-3': true
		})}>
			<ChartCard title="RISK COVERAGE">
				<div className="d-flex justify-content-center">
					<div className="d-inline-block">
						<div className="header">
							<Row>
								<Col>
									<Badge color="dark">
										Protected:
									</Badge><br/>
									<Badge color="light" className="font-weight-bold">
										{formatCurrency(coreCurrencyState, data.protectedValue)} / {Number(data.protectedRate).toFixed(2)} %
									</Badge>
								</Col>
								<Col>
									<Badge color="dark">
										Unprotected:
									</Badge><br/>
									<Badge color="light">
										{formatCurrency(coreCurrencyState, data.unProtectedValue)} / {Number(data.unProtectedRate).toFixed(2)} %
									</Badge>
								</Col>
							</Row>
						</div>
						<div style={{maxWidth: '270px'}} className="mx-auto">
							<Doughnut
								data={chartData}
								options={options}
							/>
						</div>
					</div>
				</div>
			</ChartCard>
		</Card>
	)
})

export {RiskCoverageChart}
