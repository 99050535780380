import {Badge} from 'reactstrap'
import React from 'react'

interface ChartSummaryPillProps {
	label: string
	value: number
	format: (value: number) => string
}

const ChartSummaryPill = (props: ChartSummaryPillProps) => {
	const {value, label, format} = props

	return (
		<small className="text-muted d-flex chart-summary-pill">
			<small className="text-white chart-summary-badge-radius chart-summary-responsive-text">
				<Badge>{label}</Badge>
				<Badge>{format(value)}</Badge>
			</small>
		</small>
	)
}

export default ChartSummaryPill
