import React, {useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo} from 'react'
import _ from 'underscore'
import {
    PerformanceCard,
    PerformanceCardValue
} from '../../../components/Card/PerformanceCard'
import {toPosition, toUsd} from '../../../utils/number'
import {animated, useTransition, useSpring} from 'react-spring'
import {useCountUp} from 'react-countup'
import {continentColorScales} from '../../../utils/chart'
import classNames from 'classnames'
import {usePerformanceApi} from "../../../hooks/api/live-performance";
import {useStatelessApiValue} from "../../../hooks/api";

const getSortedData = (data) => {
    return _.sortBy(data, region => {
        const {monthTotal} = region
        return monthTotal
    })
        .reverse()
        .filter(region => {
            const {code} = region
            return continentColorScales[code] !== undefined
        })
}

const RegionCardValue = ({title, value, newValue, compareValue}) => {
    const {countUp, update} = useCountUp({end: value, separator: ','})

    useEffect(() => {
        update(value)
    }, [value, update])

    const getValueIcon = useMemo(() => {
        if (!compareValue || value === compareValue) {
            return null
        }

        return (
            <i className={classNames('tim-icons', {
                'icon-minimal-up': value > compareValue,
                'icon-minimal-down': value < compareValue,
                'up': value > compareValue,
                'down': value < compareValue
            })}/>
        )
    }, [compareValue, value])

    return (
        <>
            <PerformanceCardValue
                title={title}
                value={`(${toUsd(newValue, 0)}) ${toUsd(countUp.toString())}`}
                valueIcon={getValueIcon}
            />
        </>
    )
}

const RegionCard = ({position, region, index, previousData, ...rest}) => {
    const {
        name, code, weekTotal, monthTotal, yearTotal,
        previousMonthTotal, previousWeekTotal, weekTotalNew, monthTotalNew, yearTotalNew
    } = region
    const currentRegion = previousData[index]?.name
    const hasChanged = currentRegion && currentRegion !== name
    const previousIndex = _.findIndex(previousData, x => x.name === name)
    const movedUp = hasChanged && index < previousIndex
    const movedDown = hasChanged && index > previousIndex

    const defaultColor = 'black'
    const headerColor = continentColorScales[code][1]

    const props = useSpring({
        from: {color: defaultColor, zIndex: 1},
        to: async next => {
            let background = defaultColor

            if (movedUp) {
                background = '#09c202'
            }

            if (movedDown) {
                background = '#a60d02'
            }

            await next({color: background, zIndex: 100})
            await next({color: defaultColor, zIndex: 1})
        }
    })

    return (
        <PerformanceCard
            title={name}
            position={toPosition(position)}
            inverse
            headerColor={headerColor}
            {...rest}
        >
            <animated.div style={props} className="animated-card">
                <div className="d-flex flex-column justify-content-around h-100">
                    <RegionCardValue title="WTD" value={weekTotal} newValue={weekTotalNew}
                                     compareValue={previousWeekTotal}/>
                    <RegionCardValue title="MTD" value={monthTotal} newValue={monthTotalNew}
                                     compareValue={previousMonthTotal}/>
                    <RegionCardValue title="YTD" value={yearTotal} newValue={yearTotalNew}/>
                </div>
            </animated.div>
        </PerformanceCard>
    )
}

const defaultCardHeight = 125

const RegionCards = () => {
    const {getPerformanceMapData} = usePerformanceApi()
    const {data} = useStatelessApiValue(getPerformanceMapData, {})
    const [cardHeight, setCardHeight] = useState(defaultCardHeight)
    const [sortedData, setSortedData] = useState([])
    const [dataLength, setDataLength] = useState(0)
    const [containerNode, setContainerNode] = useState(null)
    const containerRef = useCallback(node => {
        if (node) {
            setContainerNode(node)
        }
    }, [])

    const previousDataRef = useRef([])

    useEffect(() => {
        previousDataRef.current = sortedData
    })

    const previousData = previousDataRef.current

    useEffect(() => {
        if (data.length) {
            setSortedData(getSortedData(data))
            setDataLength(data.length)
        }
    }, [data])

    useLayoutEffect(() => {
        if (containerNode) {
            const updateCardHeight = () => {
                const height = containerNode.getBoundingClientRect().height

                setCardHeight(curr => {
                    const cardHeightTotal = dataLength * curr
                    const nextCardSpacing = (height - cardHeightTotal) / dataLength

                    let nextCardHeight = defaultCardHeight + nextCardSpacing

                    if (nextCardHeight < defaultCardHeight) {
                        nextCardHeight = defaultCardHeight
                    }

                    if (nextCardSpacing !== Infinity) {
                        return nextCardHeight
                    }

                    return curr
                })
            }

            if (dataLength > 0) {
                updateCardHeight()
            }

            window.addEventListener('resize', updateCardHeight)

            return () => {
                window.removeEventListener('resize', updateCardHeight)
            }

        }
    }, [containerNode, dataLength])

    const transitions = useTransition(
        sortedData.map((item, i) => ({...item, y: i * cardHeight, height: cardHeight})),
        d => d.name,
        {
            from: {height: 0},
            leave: {height: 0},
            enter: ({y, height}) => {
                return {
                    y,
                    height,
                    opacity: 1
                }
            },
            update: ({y, height}) => ({y, height})
        }
    )

    return (
        <div className="d-flex flex-column justify-content-between region-transition-container" ref={containerRef}>
            {
                transitions.map(({item, props: {y, ...rest}, key}, index) => {
                    const zIndex = data.length - index
                    return (
                        <animated.div
                            key={key}
                            className="card-wrapper"
                            style={{
                                zIndex: zIndex,
                                transform: y.interpolate(y => `translate3d(0, ${y}px, 0)`),
                                ...rest
                            }}
                        >
                            <RegionCard
                                position={index + 1}
                                region={item}
                                index={index}
                                previousData={previousData}
                                className="card"
                            />
                        </animated.div>
                    )
                })
            }
        </div>
    )
}

export {RegionCards}
