import React, {memo} from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import {
	useRecoilValue,
	useSetRecoilState
} from 'recoil'
import DataTable, {TableColumn} from 'react-data-table-component'
import {
	transactionTableColumnSortQueryState,
	transactionReferenceIdState,
	transactionTableCurrentPageNumberState,
	transactionTablePageSizeState
} from '../../../state/member-filters'
import useGetMainProductStates from '../hooks/useGetMainProductStates'
import {formatCurrency} from '../../../utils/currency'
import {selectedCoreCurrencyStateMember} from '../../../state/member-filters'
import {TransactionsTableHeaderActions} from './TransactionsTableHeaderActions'
import {Button, Card} from 'reactstrap'
import ConfirmDeleteTransactionModal from './ConfirmDeleteTransactionModal'
import ReactTooltip from 'react-tooltip'
import {useMemberApi} from '../../../hooks/api/member'
import {useApiValue} from '../../../hooks/api'
import {memberTransactionTableFilterState} from '../../../state/memberFilterState'
import {TransactionTableDataModel, TransactionTableModel} from '../../../types/member'

const TransactionsTable = memo(() => {
	const {getTransactions} = useMemberApi()
	const {data: {count, rows}, isLoading} = useApiValue(memberTransactionTableFilterState, getTransactions, {} as TransactionTableDataModel)
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateMember)
	const setTransactionReferenceId = useSetRecoilState(transactionReferenceIdState)
	const currentPageNumberState = useRecoilValue(transactionTableCurrentPageNumberState)
	const setTransactionTablePageSizeState = useSetRecoilState(transactionTablePageSizeState)
	const setTransactionTableCurrentPageNumberState = useSetRecoilState(transactionTableCurrentPageNumberState)
	const setTransactionTableColumnSortQueryState = useSetRecoilState(transactionTableColumnSortQueryState)
	const {isProductAll, isProductEp, isProductRp} = useGetMainProductStates()

	function pageNumberChanged(clickedPageNumber: any) {
		setTransactionTableCurrentPageNumberState((curr: any) => {
			if (clickedPageNumber === curr) {
				return curr
			}
			return clickedPageNumber
		})
	}

	function pageSizeChanged(pageSize: any) {
		setTransactionTablePageSizeState((curr: any) => {
			if (pageSize === curr) {
				return curr
			}
			return pageSize
		})
	}

	function handleSort(sortDirection: any, event: any) {
		//NOTE: "event" is a value that varies between asc and desc.
		//NOTE: sortDirection.selector is key value of clicked column. It's equals to database table columns.
		setTransactionTableColumnSortQueryState((curr) => {
			if (!sortDirection.selector || !event) {
				return curr
			}
			return `${sortDirection.selector} ${event}`
		})
	}

	const columns: TableColumn<TransactionTableModel> [] = [
		{
			name: '',
			selector: ({transactionId}) => transactionId,
			omit: true
		},
		{
			name: 'Member',
			selector: ({memberName}) => memberName,
			sortable: true,
		},
		{
			name: 'Booking Reference',
			selector: ({transactionReferenceId}) => transactionReferenceId,
			sortable: true,
			format: (row: any) => (
				<>
					<span data-tip data-for={`${row['transactionReferenceId']}`}>{row['transactionReferenceId']}</span>
					<ReactTooltip id={`${row['transactionReferenceId']}`}>
						<span>{row['transactionReferenceId']}</span>
					</ReactTooltip>
				</>
			)
		},
		{
			name: 'Customer Name',
			selector: ({customerName}) => customerName,
			sortable: true,
			right: true,
		},
		{
			name: 'Value',
			selector: ({transactionValue}) => transactionValue,
			sortable: true,
			right: true,
			format: (row: any) => formatCurrency(coreCurrencyState, row['transactionValue'])
		},
		{
			name: 'Received',
			selector: ({transactionPaymentValue}) => transactionPaymentValue ?? 0,
			sortable: false,
			right: true,
			format: (row: any) => formatCurrency(coreCurrencyState, row['transactionPaymentValue'])
		},
		{
			name: 'Transaction Date',
			selector: ({transactionDate}) => transactionDate,
			sortable: false,
			right: true,
		},
		{
			name: 'Protected',
			selector: ({isInsured}) => isInsured,
			sortable: true,
			right: true,
		},
		{
			name: 'Cancelled',
			selector: ({isCancelled}) => isCancelled,
			sortable: true,
			right: true,
		},
		{
			name: 'Cancel',
			selector: () => '',
			sortable: false,
			right: true,
			cell: (row: any) => row['isCancelled'] === 'No' &&
				<Button
					onClick={function cancelClick() {
						setTransactionReferenceId(row['transactionId'])
					}}
					className="btn btn-sm btn-danger"
					id={row.ID}
				>
					Cancel
				</Button>
		},
	]

	//if Main Product is All, Show "Product" and "Event Date" Columns
	if (isProductAll) {
		columns.splice(4, 0, {
			name: 'Product',
			selector: ({mainProductName}) => mainProductName,
			sortable: true,
			right: true,
		})
		columns.splice(7, 0, {
			name: 'Event Date',
			selector: ({transactionEndDate}) => transactionEndDate,
			sortable: true,
			right: true,
		})
	}
	//if Main Product is EventProtect, Show "Event Name" and "Event Date" Columns
	if (isProductEp) {
		columns.splice(6, 0, {
			name: 'Event Name',
			selector: ({eventName}) => eventName,
			sortable: true,
			right: true,
		})
		columns.splice(7, 0, {
			name: 'Event Date',
			selector: ({transactionEndDate}) => transactionEndDate,
			sortable: true,
			right: true,
		})
	}

	if (isProductRp) {
		columns.splice(6, 0, {
			name: 'Event Date',
			selector: ({transactionEndDate}) => transactionEndDate,
			sortable: true,
			right: true,
		})
	}

	return (
		<TableCard
			title="Transactions"
			titleClass="card-title"
			showToggle={true}
			headerContent={
				<Card className={'w-25 ml-auto'}>
					<TransactionsTableHeaderActions loading={isLoading}/>
				</Card>
			}
			isLoading={isLoading}
		>
			<>
				<ConfirmDeleteTransactionModal/>
				<DataTable
					data={rows}
					columns={columns}
					theme="dark"
					pagination={true}
					paginationServer={true}
					paginationDefaultPage={currentPageNumberState}
					paginationTotalRows={count}
					onChangePage={pageNumberChanged}
					onChangeRowsPerPage={pageSizeChanged}
					onSort={handleSort}
					highlightOnHover={true}
					disabled={isLoading}
					sortServer={true}
					paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40, 50, 100]}
				/>
			</>
		</TableCard>
	)
})

export {TransactionsTable}
