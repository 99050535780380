import React from 'react'
import {protectFinancialFilterState} from '../../../state/protect-financial'
import {ProtectFinancialTable} from './ProtectFinancialTable'
import {useProtectFinancialApi} from '../../../hooks/api/protect-financial'
import {useApiValue} from '../../../hooks/api'

const columns = [
	{
		name: 'BDM',
		selector: (row: any) => row.user
	},
	{
		name: 'Scheduled',
		selector: (row: any) => row.scheduled
	},
	{
		name: 'Decline',
		selector: (row: any) => row.decline
	},
	{
		name: 'Proceed',
		selector: (row: any) => row.proceed
	},
	{
		name: 'Conversion',
		selector: (row: any) => row.conversion,
		format: (row: any) => `${row['conversion']}%`
	}
]

const SalesPresentationTable = () => {
	const {getSalesData} = useProtectFinancialApi()
	const {data} = useApiValue(protectFinancialFilterState, getSalesData, [])
	return (
		<ProtectFinancialTable
			title="Sales Presentation"
			columns={columns}
			selector={data}/>
	)
}

export {SalesPresentationTable}