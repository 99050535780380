import React, {memo} from 'react';
import {ValueCard} from '../../../../components/Card/ValueCard';
import {formatCurrency} from '../../../../utils/currency';
import {useRecoilValue} from 'recoil';
import {selectedCoreCurrencyStateInsurance} from "../../../../state/insurance-provider-filters";
import useSummaryCardColor from "../../hooks/useSummaryCardColor";
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {formatNumberWithoutSeparator} from "../../../../utils/number";
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const InsuranceValueSummary = memo(() => {
  const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateInsurance)
  const color = useSummaryCardColor()
  const {getInsuranceValue} = useInsuranceApi();
  const {data} = useApiValue(insuranceProviderFilterState, getInsuranceValue, 0)

  return (
    <ValueCard
      title={`Insurance Value`}
      value={formatCurrency(coreCurrencyState, formatNumberWithoutSeparator(data))}
      icon="icon-wallet-43"
      iconColor={color}
      tooltip={"Total value of transactions protected."}
    />
  );
});

export {InsuranceValueSummary}