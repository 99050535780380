import {atom, selector} from 'recoil'
import {loggedInUserState} from './auth'
import {autoRefreshState} from './filters'
import {FilterEnum, SalesFilter} from '../types/sales'
import moment, {Moment} from 'moment'
import {productType} from '../types'
import {dateFormat} from '../utils/date'

const keyPrefix = 'sales'

export const salesFilterState = selector<SalesFilter>({
	key: `${keyPrefix}FilterState`,
	get: ({get}) => {
		return {
			dateFrom: get(formattedDateFromStateSales),
			dateTo: get(formattedDateToStateSales),
			regionId: get(selectedRegionState),
			territoryId: get(selectedTerritoryState),
			coreCurrencyCode: get(selectedCoreCurrencyState),
			productId: get(selectedProductState),
			memberIds: get(selectedMembersState),
			loggedInUser: get(loggedInUserState),
			autoRefresh: get(autoRefreshState),
			memberTableCurrentPageNumber: get(memberTableCurrentPageNumberState),
			memberTablePageSize: get(memberTablePageSizeState),
		}
	}
})

export const dateFromState = atom<Moment>({
	key: `${keyPrefix}DateFromState`,
	default: moment().startOf('year')
})

export const dateToState = atom<Moment>({
	key: `${keyPrefix}DateToState`,
	default: moment()
})

export const selectedRegionState = atom<string>({
	key: `${keyPrefix}RegionPicker`,
	default: FilterEnum.DefaultFilterAll
})

export const selectedTerritoryState = atom<string>({
	key: `${keyPrefix}TerritoryPicker`,
	default: FilterEnum.DefaultFilterAll
})

export const selectedCoreCurrencyState = atom<string>({
	key: `${keyPrefix}SelectedCoreCurrencyState`,
	default: 'USD'
})

export const selectedProductState = atom<number>({
	key: `${keyPrefix}ProductPicker`,
	default: productType.All
})

export const selectedMembersState = atom<number[]>({
	key: `${keyPrefix}SelectedMemberState`,
	default: []
})

export const formattedDateFromStateSales = selector<string>({
	key: `${keyPrefix}FormattedDateFromState`,
	get: ({get}) => get(dateFromState).format(dateFormat)
})

export const formattedDateToStateSales = selector<string>({
	key: `${keyPrefix}FormattedDateToState`,
	get: ({get}) => get(dateToState).format(dateFormat)
})

export const memberTablePageSizeState = atom<number>({
	key: `${keyPrefix}MemberTablePageSizeState`,
	default: 10
})

export const memberTableCurrentPageNumberState = atom<number>({
	key: `${keyPrefix}MemberTableCurrentPageNumberState`,
	default: 1
})
