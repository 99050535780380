import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {verticalsRevenueTableFilterState} from '../../../state/pulse'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import {CardColumn, CardHeader, TableColumn, TableHeader} from './Common/TableElements'
import {Currency} from './Common/FormatElements'
import VerticalsSortColumnDropdown from './Common/Verticals/VerticalsSortColumnDropdown'
import H2 from './Common/Headers/H2'
import {Pie} from 'react-chartjs-2'
import useGetVerticalsRevenueChartData from '../hooks/useGetVerticalsRevenueChartData'
import VerticalsBreakdownColumnDropdown from './Common/Verticals/VerticalsBreakdownColumnDropdown'

const VerticalsTable = () => {
	const {getVerticalsRevenueTableData} = usePulseApi()
	const tableValue = useApiValue(verticalsRevenueTableFilterState, getVerticalsRevenueTableData, {
		verticals: []
	})
	const pieChartData = useGetVerticalsRevenueChartData(tableValue)

	return (
		<>
			<LoadingSpinner
				isLoading={tableValue.isLoading}
				className="flex items-center justify-center min-h-60 w-full"
			>
				<>
					<div className="flex my-4 space-x-4">
						<VerticalsSortColumnDropdown/>
					</div>
					<div className="
						w-full
						text-sm
						lg:hidden"
					>
						{tableValue.data.verticals && tableValue.data.verticals
							.map((row, index) => (
								<div
									key={index}
									className="flex items-center mb-4 space-x-4"
								>
									<div className="
										gap-3 grid grid-cols-[175px_auto] pb-4 w-full
										border-b border-gray-200"
									>
										<CardHeader
											title="Verticals"
										/>
										<CardColumn
											value={<span className="align-middle mr-3">{row.verticalName}</span>}
										/>
										<CardHeader
											title="PG Revenue"
											className="text-pulse-blue"
										/>
										<CardColumn
											value={<Currency value={row.protectionValue}/>}
											className="text-pulse-blue"
										/>
										<CardHeader
											title="RP Sales"
										/>
										<CardColumn
											value={<Currency value={row.refundProtectValue}/>}
										/>
										<CardHeader
											title="Partner's Revenue"
										/>
										<CardColumn
											value={<Currency value={row.profitValue}/>}
										/>
										<CardHeader
											title="Paid Refunds"
											className="text-pulse-red"
										/>
										<CardColumn
											value={<Currency value={row.approvedRefundsValue}/>}
											className="text-pulse-red"
										/>
										<CardHeader
											title="Loss Ratio"
											className="text-pulse-orange"
										/>
										<CardColumn
											value={<>TBC</>}
											className="text-pulse-orange"
										/>
									</div>
								</div>
						))}
					</div>
					<table className="
						hidden mb-4 table-fixed w-full
						text-sm
						lg:table"
					>
						<thead>
						<tr className="
							mb-2
							border-b border-gray-200"
						>
							<TableHeader title="Verticals"/>
							<TableHeader title="PG Revenue" className="text-pulse-blue w-[14%]"/>
							<TableHeader title="RP Sales" className="w-[14%]"/>
							<TableHeader title="Partner's Revenue" className="w-[14%]"/>
							<TableHeader title="Paid Refunds" className="text-pulse-red w-[14%]"/>
							<TableHeader title="Loss Ratio" className="text-pulse-orange w-[14%]"/>
						</tr>
						</thead>
						<tbody>
						{tableValue.data.verticals && tableValue.data.verticals
							.map((row, index) => (
								<tr
									key={index}
									className="border-b border-gray-200"
								>
									<TableColumn
										value={<span className="align-middle mr-3">{row.verticalName}</span>}
									/>
									<TableColumn
										value={<Currency value={row.protectionValue}/>}
										className="text-pulse-blue"
									/>
									<TableColumn
										value={<Currency value={row.refundProtectValue}/>}
									/>
									<TableColumn
										value={<Currency value={row.profitValue}/>}
									/>
									<TableColumn
										value={<Currency value={row.approvedRefundsValue}/>}
										className="text-pulse-red"
									/>
									<TableColumn
										value={<>TBC</>}
										className="text-pulse-orange"
									/>
								</tr>
						))}
						</tbody>
					</table>
					{tableValue.data && tableValue.data.verticals && (
						<>
							<H2>Breakdown by metric</H2>
							<div className="flex my-4 space-x-4">
								<VerticalsBreakdownColumnDropdown/>
							</div>
							<div className="flex items-center justify-center">
								<Pie
									className="lg:max-w-md max-h-96"
									options={pieChartData.getOptions()}
									data={{
										datasets: pieChartData.getDatasets(),
										labels: pieChartData.getLabels()
									}}
								/>
							</div>
						</>
					)}
				</>
			</LoadingSpinner>
		</>
	)
}

export default VerticalsTable
