import {VectorMap} from 'react-jvectormap'
import React, {useEffect} from 'react'
import './WorldMap.scss'
import useGetGraphFilterLabel from '../../../hooks/useGetGraphFilterLabel'

const WorldMap = (props) => {
	const markerColor = '#0DFF9A'
	const markerBorderColor = '#3A4A43'
	const regionStartColor = '#99B2FC'
	const regionEndColor = '#164FF8'
	const selectedMarkerFilter = props.mapData.getSelectedMarkerFilter()
	const selectedRegionFilter = props.mapData.getSelectedRegionFilter()
	const markerValues = props.mapData.getMarkerValues()
	const getGraphFilterLabel = useGetGraphFilterLabel()

	useEffect(() => {
		const elements = document.getElementsByClassName('jvectormap-tip')
		Array.from(elements).forEach((el) => {
			el.style.display = 'none'
		})
	})

	function showToolTip(_, map, regionCode) {
		const content = props.mapData.getTooltipContent(regionCode)

		if (!content) {
			return false
		}

		map.html(content)
	}

	function showMarkerTip(_, map) {
		const regionCode = map.text()
		const content = props.mapData.getTooltipContent(regionCode)

		if (!content) {
			return false
		}

		map.html(content)
	}

	return (
		<>
			<VectorMap
				backgroundColor="#FFFFFF"
				containerClassName="world-map"
				map={'world_mill'}
				markerStyle={{
					initial: {
						fill: markerColor,
						stroke: markerBorderColor,
						'stroke-width': 1
					},
					hover: {
						fill: markerColor,
						stroke: markerBorderColor,
						'fill-opacity': 0.85,
						'stroke-width': 1,
						'stroke-opacity': 0.85
					}
				}}
				regionStyle={{
					initial: {
						fill: '#BBBBBB',
						stroke: '#BBBBBB'
					}
				}}
				series={{
					regions: [{
						values: props.mapData.getRegionValues(),
						scale: [regionStartColor, regionEndColor]
					}],
					markers: [{
						values: markerValues.values,
						scale: [markerColor]
					},
						{
							attribute: 'r',
							scale: [8, 16],
							values: markerValues.values
						}]
				}}
				zoomOnScroll={false}
				zoomButtons={true}
				onRegionTipShow={showToolTip}
				onMarkerTipShow={showMarkerTip}
				markers={markerValues.countries}
			/>
			<div className="flex mt-4 space-x-4">
				{selectedMarkerFilter >= 0 && (
					<div className="flex items-center space-x-2">
						<div className="rounded-full w-4 h-4" style={{ backgroundColor: markerColor }}></div>
						<div className="text-sm">{getGraphFilterLabel(selectedMarkerFilter)}</div>
					</div>
				)}
				{selectedRegionFilter >= 0 && (
					<div className="flex items-center space-x-2">
						<div className="rounded h-4 w-12" style={{
							backgroundImage: `linear-gradient(to right, ${regionStartColor}, ${regionEndColor})`
						}}></div>
						<div className="text-sm">{getGraphFilterLabel(selectedRegionFilter)}</div>
					</div>
				)}
			</div>
		</>
	)
}

export default WorldMap
