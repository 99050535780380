export const rgbColors = [
    'rgb(255, 99, 132)',
    'rgb(54, 162, 235)',
    'rgb(255, 205, 86)',
    'rgb(86, 255, 204)',
    'rgb(153, 102, 255)',
    'rgb(255, 159, 64)',
    'rgb(75, 192, 192)',
    'rgb(201, 203, 207)',
    'rgb(255, 99, 71)',
    'rgb(144, 238, 144)',
    'rgb(173, 216, 230)',
    'rgb(221, 160, 221)',
    'rgb(240, 128, 128)',
];

export const getColorPalette = (numColors: number) => {
    if (numColors <= rgbColors.length) {
        return rgbColors.slice(0, numColors);
    }
    return Array.from({ length: numColors }, (_, i) => rgbColors[i % rgbColors.length]);
};