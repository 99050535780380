import React from 'react'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {integrationAgreementState} from '../../../../state/integration'
import {IntegrationAgreement} from '../../../../types/integration'

const AgreementFilter = () => {
    return (
        <StatefulSelectFilterCard
                state={integrationAgreementState}
                title={'Agreement'}
                items={menuItems}
                showDefault={false}
        />
    )
}

export {AgreementFilter}

const menuItems = [
    {value: -1, label: 'All'},
    {value: IntegrationAgreement.Signed, label: 'Signed'},
    {value: IntegrationAgreement.NotSigned, label: 'Not Signed'},
]
