import React from 'react'

const DefaultButton = (props: { text: string, onClick: () => void }) => {
	return (
		<span
			className="
				px-3 py-[4px]
				leading-5 text-sm tracking-[1px] uppercase
				border border-pulse-icon-gray rounded-full
				cursor-pointer
				hover:opacity-60"
			onClick={props.onClick}
		>
			{props.text}
		</span>
	)
}

export default DefaultButton
