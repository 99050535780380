import React, {FC} from 'react'
import {Nav} from 'reactstrap'
import {NavLink} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {canUserSeeLinkState} from '../../state/auth'
import {dashboardPaths, Dashboards} from '../../types'
import classNames from 'classnames'
import {protectedRoutes} from '../../routes'

interface LinkProps {
	dashboard: Dashboards
	title: string
	icon: string
}

const Link = (props: LinkProps) => {
	const {dashboard, title, icon} = props
	const canViewLink = useRecoilValue(canUserSeeLinkState(dashboard))

	if (!canViewLink) {
		return null
	}

	return (
		<li>
			<NavLink
				className={({isActive}) => [
					isActive ? 'active' : 'inactive'
				].join(' ')}
				to={dashboardPaths[dashboard]}>
				<i className={classNames('tim-icons', icon)}/>
				<p>{title}</p>
			</NavLink>
		</li>
	)
}

const Sidebar: FC = () => {
	return (
		<div className="sidebar h-100">
			<div className="sidebar-wrapper">
				<Nav>
					{
						protectedRoutes
							.filter(route => route.showSidebar)
							.map(route => (
								<Link
									dashboard={route.dashboard}
									icon={route.menuIcon}
									key={`link-${route.dashboard}`}
									title={route.menuTitle}
								/>)
							)
					}
				</Nav>
			</div>
		</div>
	)
}

export {Sidebar}
