import React, {useEffect} from 'react'
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import {
	dashboardLoadingState,
	filterState,
	membersState,
	memberTransactionsOutOfRangeState,
	salesRegionsState,
	supportDetailState,
	userDetailState,
	userFilterState
} from '../../../state/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'
import {selectedCoreCurrencyState, selectedMemberIdState} from '../../../state/pulse-filters'
import {useMemberApi} from '../../../hooks/api/member'
import {MemberFilter, Roles} from '../../../types'
import {userHasRoleState} from '../../../state/auth'

const DashboardState = () => {
	const filter = useRecoilValue(filterState)
	const userFilter = useRecoilValue(userFilterState)
	const {
		getSalesRegions,
		getUserDetail,
		getSupportDetail
	} = usePulseApi()
	const {getMembers} = useMemberApi()
	const setUserDetailState = useSetRecoilState(userDetailState)
	const setSupportDetailState = useSetRecoilState(supportDetailState)
	const setMembersState = useSetRecoilState(membersState)
	const setSalesRegionsState = useSetRecoilState(salesRegionsState)
	const [dashboardLoading, setDashboardLoadingState] = useRecoilState(dashboardLoadingState)
	const setSelectedCoreCurrency = useSetRecoilState(selectedCoreCurrencyState)
	const setSelectedMemberId = useSetRecoilState(selectedMemberIdState)
	const resetMemberTransactionsOutOfRangeState = useResetRecoilState(memberTransactionsOutOfRangeState)
	const setMemberTransactionsOutOfRange = useSetRecoilState(memberTransactionsOutOfRangeState)
	const userPulseLeadershipRole = useRecoilValue(userHasRoleState(Roles.PulseDashboardLeadershipUser))

	useEffect(() => {
		getMembers(getMemberFilter())
			.then(members => setMembersState(members))
			.catch(error => {
				console.error('Unable to fetch Members:', error)
			})

		getSalesRegions(filter)
			.then(salesRegions => setSalesRegionsState(salesRegions))
			.catch(error => {
				console.error('Unable to fetch SalesRegions:', error)
			})

		if (filter.memberId) {
			resetMemberTransactionsOutOfRangeState()

			getSupportDetail(filter)
				.then(supportDetail => {
					setSupportDetailState(supportDetail)
					setMemberTransactionsOutOfRange(supportDetail.showDataAvailabilityMessage || false)
				})
				.catch(error => {
					console.error('Unable to fetch SupportDetail:', error)
				})
		}
	}, [filter])

	useEffect(() => {
		getUserDetail(userFilter)
			.then(userDetail => {
				setUserDetailState(userDetail)

				if (dashboardLoading) {
					if (!userPulseLeadershipRole && userDetail?.coreCurrencyCode) {
						setSelectedCoreCurrency(userDetail.coreCurrencyCode)
					}

					if (userDetail?.memberId) {
						setSelectedMemberId(userDetail.memberId)
					}
				}
			})
			.catch(error => {
				console.error('Unable to fetch UserDetail:', error)
			})
			.finally(() => setDashboardLoadingState(false))
	}, [userFilter])

	const getMemberFilter = (): MemberFilter => {
		return {
			coreCurrencyCode: '',
			countryCode: '',
			currencyCode: '',
			dateFrom: filter.dateFrom || '',
			dateTo: filter.dateTo || '',
			eventId: undefined,
			loggedInUser: filter.userName,
			mainProductId: 0,
			productId: 0,
			productTypeCode: '',
			memberId: filter.memberId || undefined
		}
	}

	return (<></>)
}

export default DashboardState
