import React from 'react'
import PrimaryVolumeCard from './Common/Cards/PrimaryVolumeCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import {userHasRoleState} from '../../../state/auth'
import {Roles} from '../../../types'
import PrimaryValueCard from './Common/Cards/PrimaryValueCard'
import {Currency} from './Common/FormatElements'

const OverviewProtectionValueSummary = () => {
	const {getProtectionValueSummary, getProtectionValueTrend} = usePulseApi()
	const value = useApiValue(filterState, getProtectionValueSummary, 0)
	const trendRate = useApiValue(filterState, getProtectionValueTrend, 0)
	const userPulseLeadershipRole = useRecoilValue(userHasRoleState(Roles.PulseDashboardLeadershipUser))

	const title = 'PG Revenue'
	const tooltipText = 'This refers to the share of revenue that PG receives from the Refund Protect service.'

	return (
		<div className="relative">
			{!userPulseLeadershipRole && (
				<PrimaryVolumeCard
					value={<Currency value={value.data}/>}
					header={
						<div className="flex">
							<div className="
								mr-2
								leading-5 whitespace-nowrap"
							>
								{title}
							</div>
							<div>
							<span className="
								overflow-hidden px-1
								bg-pulse-red
								text-xs uppercase whitespace-nowrap
								rounded-full"
							>
								<span className="
									align-middle mb-[2px] mr-1
									leading-3 text-sm text-white
									material-symbols-outlined"
								>
									visibility
								</span>
								<span className="
									align-middle inline-block mb-[2px]
									text-white text-[.5rem]"
								>
									PG Only
								</span>
							</span>
							</div>
						</div>
					}
					icon="account_balance_wallet"
					tooltipTitle={title}
					tooltipText={tooltipText}
					trendValue={trendRate.data}
					valueClassName="text-pulse-green"
					isLoading={value.isLoading || trendRate.isLoading}
				/>
			)}
			{userPulseLeadershipRole && (
				<PrimaryValueCard
					value={value.data}
					header={title}
					tooltipText={tooltipText}
					trendValue={trendRate.data}
					valueClassName="text-pulse-blue"
					isLoading={value.isLoading || trendRate.isLoading}
				/>
			)}
		</div>
	)
}

export default OverviewProtectionValueSummary
