import React from 'react'

const H2 = (props: {children: React.ReactNode}) => {
	return (
		<h2 className="
			mb-2
			font-semibold text-lg uppercase"
		>
			{props.children}
		</h2>
	)
}

export default H2
