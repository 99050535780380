import React, {memo} from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {formatCurrency} from '../../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyStateMember} from '../../../../state/member-filters'
import useGetSummaryValues from '../../hooks/useGetSummaryValues'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'

const TransactionValue = memo(() => {
	const {totalTransactionValueInsured} = useGetSummaryValues()
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateMember)
	const color = useSummaryCardColor()

	return (
		<ValueCard
			title={`Transaction Value`}
			value={formatCurrency(coreCurrencyState, parseInt(totalTransactionValueInsured.toString()))}
			icon="icon-wallet-43"
			tooltip={'Total value of transactions protected.'}
			iconColor={color}
		/>
	)
})

export {TransactionValue}
