import {useEffect, FC} from 'react';
import ReactGA from "react-ga4"

const GAnalyticsScript: FC = () => {
	useEffect(() => {
		const measurementId = process.env.REACT_APP_G_ANALYTICS;
		if (measurementId) {
			ReactGA.initialize(measurementId)
			ReactGA.send({
				hitType: 'pageview',
				page: window.location.pathname,
				title: document.title,
				location: window.location.href,
			});
		}
	}, []);
	return null;
};
export {GAnalyticsScript};