import React, {memo} from 'react'
import classNames from 'classnames'
import {Card, CardBody, CardTitle} from 'reactstrap'
import {CardTooltip} from './CardTooltip'

interface CardIconProps {
	icon?: string
	iconColor?: string
	tooltip?: string
}

interface ValueCardProps {
	title: string
	value: any
	footer?: string
	footerIcon?: string
	iconColor?: string
	icon?: string
	tooltip?: string
}

const CardIcon = memo((props: CardIconProps) => {
	const {icon, iconColor = 'dark', tooltip} = props

	if (!icon) {
		return null
	}

	return (
		<div className={classNames(
			'icon-section', 'd-flex', 'justify-content-center', 'align-items-center', iconColor)}>
			{
				(tooltip) && (
					<CardTooltip tooltip={tooltip} icon={icon}/>
				)
			}
			{
				(!tooltip) &&
				(
					<i className={classNames('tim-icons', icon)}/>
				)
			}
		</div>
	)
})

const ValueCard = (props: ValueCardProps) => {
	const {title, value, icon, tooltip, iconColor} = props

	return (
		<Card className={`card-stats`}>
			<CardBody className="p-0">
				<div className="d-flex flex-row justify-content-between h-100">
					<div className="d-none d-lg-flex justify-content-center align-items-center">
						<CardIcon icon={icon} tooltip={tooltip} iconColor={iconColor}/>
					</div>
					<div className="numbers w-100 pr-3 py-1 d-flex flex-column justify-content-between">
						<CardTitle tag="h6" className="card-category text-uppercase pt-1">{title}</CardTitle>
						<CardTitle tag="h5" className="mb-0 responsive-text-size">{value}</CardTitle>
					</div>
				</div>
			</CardBody>
		</Card>
	)
}

export {ValueCard}
