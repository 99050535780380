import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { EnumMainProduct } from '../../Enums/MainProductEnum'
import { useRecoilValue } from 'recoil'
import { selectedMainProductIdStateMember } from '../../../../state/member-filters'
import { selectedCoreCurrencyStateMember } from '../../../../state/member-filters'
import { formatCurrency } from '../../../../utils/currency'
import useGetMapToggleStates from '../../../../hooks/useGetMapToggleStates'

const RegionMapCountryTable = ({ regionData, region }) => {
	const { isEpSelected, isRpSelected } = useGetMapToggleStates(region)
	const mainProductFilterData = useRecoilValue(selectedMainProductIdStateMember)
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateMember)
	const [tableData, setTableData] = useState([])

	useEffect(() => {
		const getCountryData = (countryData) => {
			const {
				countryName,
				epTransactionVolumeInsured,
				epTransactionValueInsured,
				epTransactionProtectionValue,
				rpTransactionVolumeInsured,
				rpTransactionValueInsured,
				rpTransactionProtectionValue
			} = countryData

			const getCombinedData = () => {
				const getVolume = () => {
					if (isEpSelected && isRpSelected) {
						return epTransactionVolumeInsured + rpTransactionVolumeInsured
					}

					if (isEpSelected) {
						return epTransactionVolumeInsured
					}

					if (isRpSelected) {
						return rpTransactionVolumeInsured
					}

					return 0
				}

				const getValue = () => {
					if (isEpSelected && isRpSelected) {
						return epTransactionValueInsured + rpTransactionValueInsured
					}

					if (isEpSelected) {
						return epTransactionValueInsured
					}

					if (isRpSelected) {
						return rpTransactionValueInsured
					}

					return 0
				}

				const getCost = () => {
					if (isEpSelected && isRpSelected) {
						return epTransactionProtectionValue + rpTransactionProtectionValue
					}

					if (isEpSelected) {
						return epTransactionProtectionValue
					}

					if (isRpSelected) {
						return rpTransactionProtectionValue
					}

					return 0
				}

				const volume = getVolume()
				const value = formatCurrency(coreCurrencyState, getValue())
				const cost = formatCurrency(coreCurrencyState, getCost())

				return {
					country: countryName,
					volume,
					value,
					cost
				}
			}

			const getEpData = () => {
				return {
					country: countryName,
					volume: epTransactionVolumeInsured,
					value: formatCurrency(coreCurrencyState, epTransactionValueInsured),
					cost: formatCurrency(coreCurrencyState, epTransactionProtectionValue)
				}
			}

			const getRpData = () => {
				return {
					country: countryName,
					volume: rpTransactionVolumeInsured,
					value: formatCurrency(coreCurrencyState, rpTransactionValueInsured),
					cost: formatCurrency(coreCurrencyState, rpTransactionProtectionValue)
				}
			}

			const dataFn = {
				[EnumMainProduct.All]: getCombinedData,
				[EnumMainProduct.EventProtect]: getEpData,
				[EnumMainProduct.RefundProtect]: getRpData
			}

			return dataFn[mainProductFilterData]()
		}

		const next = regionData
			.map(getCountryData)
			.filter(x => {
				const { volume } = x
				return volume > 0
			})

		setTableData(next)
	}, [mainProductFilterData, isRpSelected, isEpSelected, regionData, coreCurrencyState])

	return (
		<Table className={'custom-table-hover'}>
			<thead>
			<tr>
				<th>Country</th>
				<th>Volume</th>
				<th>Value</th>
				<th>Cost</th>
			</tr>
			</thead>
			<tbody>
			{
				tableData.map((x, i) =>
					(
						<tr key={`map-country-${i}`}>
							<td>{x.country}</td>
							<td>{x.volume}</td>
							<td>{x.value}</td>
							<td>{x.cost}</td>
						</tr>
					)
				)
			}
			</tbody>
		</Table>
	)
}

export default RegionMapCountryTable
