import React, {memo} from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import useGetSummaryValues from '../../hooks/useGetSummaryValues'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'

const TotalEvents = memo(() => {
	const {totalEventCount} = useGetSummaryValues()
	const color = useSummaryCardColor()

	return (
		<ValueCard
			title={`Events`}
			value={totalEventCount}
			icon="icon-book-bookmark"
			tooltip={'Total Count of Events.'}
			iconColor={color}
		/>
	)
})

export {TotalEvents}
