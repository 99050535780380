import {DropdownData} from "./index"

export enum FilterEnum {
	DefaultFilterAll = "All"
}

export interface IntegrationFilter extends Record<string, any> {
	dateFrom: string
	dateTo: string
	regions: number[]
	commercial: string
	integrationType: number
	loggedInUser: string | undefined
}

export interface Commercial extends DropdownData {
	commercialName: string
	commercialEmail: string
}

export enum IntegrationType {
	Standard = 1,
	Dynamic = 2,
}

export enum IntegrationGuide {
	DynamicWidget = 1,
	DynamicAPI = 2,
	DynamicHybrid = 3,
	StandardRP = 4,
}

export enum IntegrationMigration {
	Migration = 1,
	NewIntegration = 2
}

export enum IntegrationAgreement {
	Signed = 1,
	NotSigned = 2
}

export interface IntegrationStatusData {
	completed: number
	inProgress: number
	paused: number
	cancelled: number
}

export interface IntegrationTableData {
	count: number
	rows: IntegrationTableModel[]
}

export interface IntegrationTableModel {
	memberName: string
	status: string
	productName: string
	integrationType: string
	integrationTime: number
	commercialName: string
	regionName: string
	memberType: string
	integrationGuide: string
}

export const defaultIntegrationStatusTimeModel: IntegrationStatusTimeModel = {
	labels: {
		verticalLabels: [],
		regionLabels: []
	},
	verticalData: {},
	regionData: {}
}

export interface IntegrationStatusTimeModel {
	labels: ChartLabels
	verticalData: Record<string, number[]>
	regionData: Record<string, number[]>
}

export interface ChartLabels {
	verticalLabels: string[]
	regionLabels: string[]
}

export interface StatusChangesData {
	member: string
	partnership: string
	date: string
	oldStatus: string
	newStatus: string
}

export const defaultStatusData: IntegrationStatusData = {
	completed: 0,
	inProgress: 0,
	paused: 0,
	cancelled: 0
}

export interface MrrSummary {
	actualMrr: number
	estimatedMrr: number
}

export const defaultMrrSummary: MrrSummary = {
	actualMrr: 0,
	estimatedMrr: 0
}

export interface IntegrationTimeChartModel {
	label: string
	value: number
}

export interface ChartData {
	summary: ChartSummary,
	chartData: Record<string, number[]>
}

export interface IntegrationChartDataModel {
	chartData: ChartData,
	pieChartData: IntegrationTimeChartModel []
}

export interface ChartSummary extends SummaryValues {
	totalDtd: number
}

export interface SummaryValues {
	totalMtd: number
	totalWtd: number
	totalYtd: number
}

export const defaultIntegrationChartData: IntegrationChartDataModel = {
	chartData: {
		summary: {
			totalDtd: 0,
			totalMtd: 0,
			totalWtd: 0,
			totalYtd: 0
		},
		chartData: {}
	},
	pieChartData: []
}

export const defaultChartData: ChartData = {
	chartData: {},
	summary: {
		totalDtd: 0,
		totalMtd: 0,
		totalWtd: 0,
		totalYtd: 0
	}
}
