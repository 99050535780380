import React from 'react'
import SelectFilterCard, {SelectFilterItem} from '../../../components/Dashboard/SelectFilter'

const TimeBandFilter = () => {
	const handleChange = (value: any) => {}

	return (
		<SelectFilterCard
			title="Time Band"
			items={timeBandValues}
			onChange={handleChange}
		/>
	)
}

export default TimeBandFilter

const timeBandValues: SelectFilterItem[] = [
	{ value: 1, label: '1 - 30' }
]
