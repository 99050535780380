import React from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'
import {salesFilterState} from '../../../../state/sales'
import {useSalesApi} from '../../../../hooks/api/sales'
import {useApiValue} from '../../../../hooks/api'

export const Conversion = () => {
	const color = useSummaryCardColor()
	const {getConversion} = useSalesApi()
	const {data} = useApiValue(salesFilterState, getConversion, 0)

	return (
		<ValueCard
			title={`Conversion`}
			value={`${data} %`}
			icon="icon-refresh-02"
			iconColor={color}
		/>
	)
}