import React from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import {useRecoilValue} from 'recoil'
import {userHasRoleState} from '../../state/auth'
import {Roles} from '../../types'
import OverviewStandardView from './components/OverviewStandardView'
import OverviewLeadershipView from './components/OverviewLeadershipView'

const PulseOverviewView = () => {
	const userPulseLeadershipRole = useRecoilValue(userHasRoleState(Roles.PulseDashboardLeadershipUser))

	return (
		<DashboardPageLayout>
			<>
				{!userPulseLeadershipRole && (<OverviewStandardView/>)}
				{userPulseLeadershipRole && (<OverviewLeadershipView/>)}
			</>
		</DashboardPageLayout>
	)
}

export default PulseOverviewView
