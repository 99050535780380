import React from 'react';
import * as DashboardDateFilters from '../../../../components/Dashboard/DateFilters'
import {dateFromStateInsurance, dateToStateInsurance} from "../../../../state/insurance-provider-filters";

const DateFilters = () => {
  return (
    <DashboardDateFilters.DateFilters
      fromState={dateFromStateInsurance}
      toState={dateToStateInsurance}
    />
  );
};

export {DateFilters}