import React, {useEffect, useState} from 'react'
import {Doughnut} from 'react-chartjs-2'
import {ChartCard} from '../../../../components/Card/ChartCard'
import {integrationFilterState} from '../../../../state/integration'
import {IntegrationChartDataModel} from '../../../../types/integration'
import {getColorPalette} from '../../../../utils/colors'
import {useApiValue} from '../../../../hooks/api'
import {useIntegrationApi} from '../../../../hooks/api/integration'

export const IntegrationGuidesChart = () => {
    const [pieData, setPieData] = useState<number[]>([])
    const [pieLabels, setPieLabels] = useState<string[]>([])
    const {getGuidesChartData} = useIntegrationApi()
    const {data} = useApiValue(integrationFilterState, getGuidesChartData, {} as IntegrationChartDataModel)

    useEffect(() => {
        if (data && data.pieChartData) {
            const labels = data.pieChartData.map(item => item.label)
            const values = data.pieChartData.map(item => item.value)
            setPieLabels(labels)
            setPieData(values)
        }
    }, [data])

    const chartData = {
        labels: pieLabels,
        datasets: [{
            label: 'Members',
            data: pieData,
            backgroundColor: getColorPalette(pieLabels.length),
            hoverOffset: 4
        }]
    }

	return (
		<ChartCard
			title="Guides distribution"
			titleClass="card-title-warning"
		>
			<Doughnut
				options={{maintainAspectRatio: false}}
				height={400}
				width={400}
				data={chartData}
			/>
		</ChartCard>
	)
}
