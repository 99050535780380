import React from 'react'
import {Col, Row} from 'reactstrap'
import {AutoRefresh} from '../../components/AutoRefresh'
import {ReceivedNew} from './components/ReceivedNew'
import {Handled} from './components/Handled'
import {System} from './components/System'
import {AvgHandleTime} from './components/AvgHandleTime'
import {WaitingOnPG} from './components/WaitingOnPG'
import {WaitingOnPGReferred} from './components/WaitingOnPGReferred'
import {TrustPilot} from './components/TrustPilot'
import {ApprovedVolumeChart} from './components/ApprovedVolumeChart'
import {ApprovedValueChart} from './components/ApprovedValueChart'
import {Member} from './components/Member'
import {CEF} from './components/CEF'
import {Insured} from './components/Insured'
import {DeclinedVolume} from './components/DeclinedVolume'
import {UserData} from './components/UserData'
import {TotalReferred} from './components/TotalReferred'
import {NewVolumeChart} from './components/NewVolumeChart'
import {TrustPilotSummary} from './components/TrustPilotSummary'
import {OutboundEmailSummary} from './components/OuboundEmailSummary'

const BottomHeader = () => {
	return (
		<div className="d-flex justify-content-between">
			<Row className={'d-flex flex-row justify-content-between flex-grow-1 pb-1'}>
				<Col className={'col-sm ml-1'}>
					<AvgHandleTime/>
				</Col>
				<Col className={'col-sm'}>
					<WaitingOnPG/>
				</Col>
				<Col className={'col-sm'}>
					<WaitingOnPGReferred/>
				</Col>
				<Col className={'col-sm mr-1'}>
					<TotalReferred/>
				</Col>
			</Row>
		</div>
	)
}

const RefundView = () => {
	return (
		<div className="content">
			<AutoRefresh/>
			<Row className={'d-flex flex-row justify-content-between flex-grow-1 pb-2'}>
				<Col className={'col-sm ml-1'}>
					<ReceivedNew/>
				</Col>
				<Col className={'col-sm'}>
					<Handled/>
				</Col>
				<Col className={'col-sm'}>
					<System/>
				</Col>
				<Col className={'col-sm mr-1'}>
					<TrustPilot/>
				</Col>
			</Row>
			<Row className="pb-2">
				<Col>
					<BottomHeader/>
				</Col>
			</Row>
			<Row className={'d-flex flex-row justify-content-between flex-grow-1 pb-3'}>
				<Col className={'col-sm ml-1'}>
					<CEF/>
				</Col>
				<Col className={'col-sm'}>
					<Insured/>
				</Col>
				<Col className={'col-sm'}>
					<Member/>
				</Col>
				<Col className={'col-sm'}>
					<Row>
						<Col className={'col-md'}>
							<TrustPilotSummary/>
						</Col>
						<Col className={'col-md'}>
							<OutboundEmailSummary/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className="pb-3">
				<Col sm={12} lg={3}>
					<NewVolumeChart/>
				</Col>
				<Col sm={12} lg={3}>
					<ApprovedVolumeChart/>
				</Col>
				<Col sm={12} lg={3}>
					<ApprovedValueChart/>
				</Col>
				<Col sm={12} lg={3}>
					<DeclinedVolume/>
				</Col>
			</Row>
			<Row className="pb-3 ml-1 mr-1">
				<UserData/>
			</Row>
		</div>
	)
}

export default RefundView
