import React from 'react'
import {Button, ButtonGroup} from 'reactstrap'
import {Bar} from 'react-chartjs-2'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {
	commissionFilterState,
	isUserSelectedState,
} from '../../../state/commission-data'
import {monthsInDateRangeState, selectedUserNameState, selectedUserState} from '../../../state/filters'
import {buildChartData, userChartOptions} from '../../../utils/chart'
import classNames from 'classnames'
import {User} from '../../../types'
import {ChartCard} from '../../../components/Card/ChartCard'
import {withoutEmail} from '../../../utils/string'
import {useCommissionApi} from '../../../hooks/api/commission'
import {useApiValue} from '../../../hooks/api'
import {useCommissionUserData} from '../hooks'
import {UserChartData} from '../../../types/commission'

const defaultUserChartData: UserChartData = {
	commission: [],
	revenue: []
}

interface ChartUserButtonProps {
	user: User
}

const ChartUserButton = (props: ChartUserButtonProps) => {
	const {user} = props
	const isSelected = useRecoilValue(isUserSelectedState(user))
	const setUser = useSetRecoilState(selectedUserState)

	const handleClick = () => setUser(user)

	return (
		<Button
			color="info"
			size="sm"
			tag="label"
			className={classNames('btn-simple', {
				active: isSelected
			})}
			onClick={handleClick}
		>
			<input defaultChecked name="options" type="radio"/>
			<span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
        {withoutEmail(user.name)}
      </span>
			<span className="d-block d-sm-none">
        <i className="tim-icons icon-single-02"/>
      </span>
		</Button>
	)
}

const ChartUserButtons = () => {
	const { users } = useCommissionUserData()

	if (users.length < 2) {
		return null
	}

	return (
		<ButtonGroup className="btn-group-toggle d-none d-lg-block flex-wrap">
			{users.map(user => <ChartUserButton key={user.id} user={user}/>)}
		</ButtonGroup>
	)
}

const UserCommissionChart = () => {
	const months = useRecoilValue(monthsInDateRangeState)
	const userName = useRecoilValue(selectedUserNameState)
	const {getUserChartData} = useCommissionApi()
	const {data} = useApiValue(commissionFilterState, getUserChartData, defaultUserChartData)

	const chartData = buildChartData(data, userName, months)

	return (
		<ChartCard
			category={`${withoutEmail(userName ?? '')}`}
			title="Revenue & Commission"
			titleClass="card-title-warning"
			showToggle
			chartToggles={<ChartUserButtons/>}
		>
			<Bar
				data={chartData}
				options={userChartOptions}
			/>
		</ChartCard>
	)
}

export {UserCommissionChart}
