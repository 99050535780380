import React, {useEffect, useState} from 'react'
import {toUsd} from '../../../utils/number'
import {usePerformanceApi} from "../../../hooks/api/live-performance";
import {useStatelessApiValue} from "../../../hooks/api";

interface FooterCardProps {
	title: string
	values: string[]
}

const FooterCard = (props: FooterCardProps) => {
	return (
		<div className="footer-card d-flex flex-column align-items-center">
			<div className="title">
				<span className="text-uppercase">{props.title}</span>
			</div>
			<div className="value">
				{
					props.values.map((x, i) => {
						return (
							<span key={`${x}-${i}`} className="value-block">{x}</span>
						)
					})
				}
			</div>
		</div>
	)
}

const LiveClientCountCard = () => {
	const {getTransactingMemberCount} = usePerformanceApi()
	const {data: value} = useStatelessApiValue(getTransactingMemberCount, 0)
	const [values, setValues] = useState<string[]>([])

	useEffect(() => {
		setValues(value.toString().split(''))
	}, [value])

	return (
		<FooterCard title="Live Transacting Clients" values={values}/>
	)
}

const AverageRevenueCard = () => {
	const {getAverageMemberRevenue} = usePerformanceApi()
	const {data: value} = useStatelessApiValue(getAverageMemberRevenue, 0)
	const [values, setValues] = useState<string[]>([])

	useEffect(() => {
		setValues([toUsd(value.toString(), 0)])
	}, [value])

	return (
		<FooterCard title="Average MRR" values={values}/>
	)
}

const FooterCards = () => {
	return (
		<div className="footer-card-container d-flex flex-row w-100 justify-content-end">
			<div className="pr-3">
				<LiveClientCountCard/>
			</div>
			<div>
				<AverageRevenueCard/>
			</div>
		</div>
	)
}

export default FooterCards
