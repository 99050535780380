import { selectedMainProductIdStateMember } from "../../../state/member-filters";
import {useRecoilValue} from "recoil";
import {EnumMainProduct} from '../Enums/MainProductEnum';

export default function useGetMainProductStates() {
    const selectedProductValue = useRecoilValue(selectedMainProductIdStateMember)
    let isProductAll = false
    let isProductEp = false
    let isProductRp = false

    switch(selectedProductValue) {
        case EnumMainProduct.All:
            isProductAll = true;
            break;
        case EnumMainProduct.EventProtect:
            isProductEp = true;
            break;
        case EnumMainProduct.RefundProtect:
            isProductRp = true;
            break;
    }


    return { isProductAll, isProductEp, isProductRp }
}
