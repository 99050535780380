import {FilterCard} from '../../../components/Card/FilterCard'
import {FormGroup} from 'reactstrap'
import ReactDatetime from 'react-datetime'
import {dateFormat} from '../../../utils/date'
import React from 'react'
import moment, {Moment} from 'moment'
import {useRecoilState} from 'recoil'
import {dateFromState, dateToState} from '../../../state/protect-financial'

const DateFilter = () => {
	const [dateFrom, setDateFrom] = useRecoilState(dateFromState)
	const [dateTo, setDateTo] = useRecoilState(dateToState)

	const handleDateFromChange = (date: Moment | string) => setDateFrom(moment(date))
	const handleDateToChange = (date: Moment | string) => setDateTo(moment(date))

	return (
		<FilterCard
			title="From / To"
			iconColor="warning"
			icon="icon-calendar-60"
		>
			<div className="d-flex flex-row justify-content-end">
				<FormGroup className="pr-2 mb-0">
					<ReactDatetime
						inputProps={{
							className: 'form-control',
							placeholder: 'Date From'
						}}
						value={dateFrom}
						onChange={handleDateFromChange}
						closeOnSelect={true}
						dateFormat={dateFormat}
						timeFormat={false}
					/>
				</FormGroup>
				<FormGroup className="mb-0">
					<ReactDatetime
						inputProps={{
							className: 'form-control',
							placeholder: 'Date To'
						}}
						value={dateTo}
						onChange={handleDateToChange}
						closeOnSelect={true}
						dateFormat={dateFormat}
						timeFormat={false}
					/>
				</FormGroup>
			</div>
		</FilterCard>
	)
}

export {DateFilter}