import {RecoilState, useRecoilState} from 'recoil'
import moment, {Moment} from 'moment'
import {FilterCard} from '../Card/FilterCard'
import {FormGroup} from 'reactstrap'
import ReactDatetime from 'react-datetime'
import {dateFormat as defaultDateFormat} from '../../utils/date'
import React, {ReactNode} from 'react'

interface DateFilterProps {
	fromState: RecoilState<Moment>
	toState: RecoilState<Moment>
	dateFormat?: string
	footer?: ReactNode
	showIcon?: boolean
	minDate?: Moment
}

const DateFilters = (props: DateFilterProps) => {
	const {fromState, dateFormat = defaultDateFormat, toState, footer, showIcon, minDate} = props

	const [dateFrom, setDateFrom] = useRecoilState(fromState)
	const [dateTo, setDateTo] = useRecoilState(toState)

	const handleDateFromChange = (date: Moment | string) => {
		const newDateFrom = moment(date)
		const dateToSet = minDate && newDateFrom.isBefore(minDate) ? minDate : newDateFrom
		setDateFrom(dateToSet)
	}

	const handleDateToChange = (date: Moment | string) => {
		const newDateTo = moment(date)
		const dateToSet = minDate && newDateTo.isBefore(minDate) ? minDate : newDateTo
		setDateTo(dateToSet)
	}

	return (
		<FilterCard
			title="From / To"
			iconColor="warning"
			icon={showIcon ? 'icon-calendar-60' : ''}
		>
			<>
				<div className="d-flex flex-row justify-content-end">
					<FormGroup className="pr-2 mb-0">
						<ReactDatetime
							inputProps={{
								className: 'form-control custom-date-picker',
								placeholder: 'Date From'
							}}
							value={dateFrom}
							onChange={handleDateFromChange}
							closeOnSelect={true}
							dateFormat={dateFormat}
							timeFormat={false}
							className="d-inline-block"
						/>
					</FormGroup>
					<FormGroup className="mb-0">
						<ReactDatetime
							inputProps={{
								className: 'form-control custom-date-picker',
								placeholder: 'Date To'
							}}
							value={dateTo}
							onChange={handleDateToChange}
							closeOnSelect={true}
							dateFormat={dateFormat}
							timeFormat={false}
							initialViewMode="months"
						/>
					</FormGroup>
				</div>
				{
					(footer) && (
						<div className="d-flex justify-content-end">
							{footer}
						</div>
					)
				}
			</>
		</FilterCard>
	)
}

export {DateFilters}
