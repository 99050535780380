import React, {FC, useEffect, useCallback} from 'react'
import {useSetRecoilState} from 'recoil'
import {signInSilent, signOut, userManager} from '../../services/user-service'
import {authenticatedUserState} from '../../state/auth'

const TokenManager: FC = () => {
	const setUser = useSetRecoilState(authenticatedUserState)

	const handleAccessTokenExpiring = useCallback(async () => {
		try {
			const user = await signInSilent()
			setUser(user)
		} catch (e) {
			console.log('sign in silent failed.', e)
			await signOut()
		}

	}, [setUser])

	const handleAccessTokenExpired = useCallback(async () => {
		await signOut()
	}, [])

	useEffect(() => {
		userManager.events.addAccessTokenExpiring(handleAccessTokenExpiring)
		userManager.events.addAccessTokenExpired(handleAccessTokenExpired)

		return () => {
			userManager.events.removeAccessTokenExpired(handleAccessTokenExpiring)
			userManager.events.removeAccessTokenExpired(handleAccessTokenExpired)
		}

	}, [handleAccessTokenExpiring, handleAccessTokenExpired])

	return <></>
}

export {TokenManager}
