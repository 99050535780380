import React from 'react'
import {
	Row, Col
} from 'reactstrap'
import useGetMainProductStates from '../../../hooks/useGetMainProductStates'
import {TransactionVolumeChart} from './TransactionVolumeChart'
import {ConversionRateChart} from './ConversionRateChart'
import {ProtectionCostChart} from './ProtectionCostChart'
import {TransactionValueChart} from './TransactionValueChart'

const MainCharts = () => {
	const {isProductRp} = useGetMainProductStates()
	return (
		<>
			{
				!isProductRp && (
					<Row className="pb-4">
						<Col sm={4}>
							<TransactionVolumeChart/>
						</Col>
						<Col sm={4}>
							<TransactionValueChart/>
						</Col>
						<Col sm={4}>
							<ProtectionCostChart/>
						</Col>
					</Row>
				)
			}

			{
				isProductRp && (
					<Row className="pb-4">
						<Col sm={4}>
							<TransactionValueChart/>
						</Col>
						<Col sm={4}>
							<ConversionRateChart/>
						</Col>
						<Col sm={4}>
							<ProtectionCostChart/>
						</Col>
					</Row>
				)
			}
		</>
	)
}

export {MainCharts}
