import React from 'react'
import {Col, Row} from 'reactstrap'
import {TransactionsTable} from './components/TransactionsTable'
import {RiskCoverageChart} from './components/Charts/RiskCoverageChart'
import {RiskCoverageByRegionChart} from './components/Charts/RiskCoverageByRegionChart'
import {ConversionByValueBand} from './components/ConversionByValueBand'
import {RegionVectorMaps} from './components/RegionMaps/'
import {HeaderInfo} from './components/HeaderInfo/'
import {Filters} from './components/Filters'
import {AutoRefresh} from '../../components/AutoRefresh'
import useGetMainProductStates from './hooks/useGetMainProductStates'
import {MainCharts} from './components/Charts/MainCharts'

const MemberView = () => {
	const {isProductEp, isProductRp} = useGetMainProductStates()

	return (
		<div className="content">
			<AutoRefresh/>

			<Row className="pb-4">
				<Col>
					<HeaderInfo/>
				</Col>
			</Row>

			<Row className="pb-3">
				<Col>
					<Filters/>
				</Col>
			</Row>

			<Row className="pb-3">
				<Col>
					<MainCharts/>
				</Col>
			</Row>

			<Row className="pb-3">
				<Col>
					<TransactionsTable/>
				</Col>
			</Row>

			{
				isProductRp && (
					<>
						<ConversionByValueBand/>
					</>
				)
			}
			{
				!isProductRp && (
					<div className="mt-4">
						<RegionVectorMaps/>
					</div>)
			}

			{
				isProductEp && (
					<Row className="pb-4">
						<Col sm={4} className="pb-3">
							<RiskCoverageChart/>
						</Col>
						<Col sm={8} className="pb-3">
							<RiskCoverageByRegionChart/>
						</Col>
					</Row>
				)
			}
		</div>
	)
}

export default MemberView
