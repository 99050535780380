import React, {useEffect, useState} from 'react'
import {ValueDirectionProps, ValueStatusCard} from '../../../components/Card/ValueStatusCard'
import {useTrustPilotValues} from '../hooks/useTrustPilotValues'

export const TrustPilotSummary = () => {
	const [valueStatusData, setValueStatusData] = useState<ValueDirectionProps[]>([])
	const {mtdAverage} = useTrustPilotValues()

	useEffect(() => {
		if (mtdAverage) {
			const {value} = mtdAverage
			const next: ValueDirectionProps = {
				value: `${value}`,
				direction: null,
				colour: null,
			}
			setValueStatusData([next])
		}
	}, [mtdAverage])

	return (
		<>
			<ValueStatusCard
				title={'TrustPilot'}
				values={valueStatusData}
				subTitle="MTD"
			/>
		</>
	)
}