import {ValueCard} from '../../Card/ValueCard'
import React from 'react'
import {filterState} from '../../../state/dynamic-performance'
import {toUsd} from '../../../utils/number'
import {useDynamicPerformanceApi} from '../../../hooks/api/dynamic'
import {useApiValue} from '../../../hooks/api'

const RevenueGeneratedSummary = () => {
	const {getRevenueGeneratedSummary} = useDynamicPerformanceApi()
	const {data} = useApiValue(filterState, getRevenueGeneratedSummary, 0)

	return (
		<ValueCard
			title="Revenue Generated"
			value={toUsd(data.toString(), 0)}
			icon="icon-money-coins"
			iconColor="rp"
		/>
	)
}

export default RevenueGeneratedSummary
