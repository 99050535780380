import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {Line} from 'react-chartjs-2'
import {
	backgroundColorList,
	backgroundColorListRp,
	borderColorList,
	borderColorListRp,
	productChartBorderColors,
	productChartColors
} from '../../../../../utils/chart'
import useGetMainProductStates from '../../../hooks/useGetMainProductStates'
import {FixedHeightChartCard} from '../../../../../components/Card/FixedHeightChartCard'
import {ChartSummaryTable} from '../../../../../components/Dashboard/Charts/ChartSummaryTable'
import {formatCurrency} from '../../../../../utils/currency'
import {selectedCoreCurrencyStateMember} from '../../../../../state/member-filters'
import {useRecoilValue} from 'recoil'
import {formatNumberMember} from '../../../../../utils/number'
import {FilterEnum} from '../../../Enums/FilterEnum'
import {ChartSummaryData} from '../../../../../types'

const borderValues = {
	borderWidth: 2,
	pointHoverRadius: 4,
	pointHoverBorderWidth: 15,
	pointRadius: 4,
}

const defaultChartData: any = {
	datasets: [],
	labels: []
}

const useGetEpProductChartData = (chartData: any) => {
	const [data, setData] = useState(defaultChartData)

	const setChartData = useCallback(() => {
		let i: number
		let k: number
		const labels: string[] = []
		const keyValues_ = new Set<string>()

		for (i = 0; i < chartData.length; i++) {
			const keys = Object.keys(chartData[i])
			for (k = 0; k < keys.length; k++) {
				if (keys[k] === 'Date')
					labels.push(chartData[i][keys[k]])
				else
					keyValues_.add(keys[k])
			}
		}

		const keyValues = Array.from(keyValues_).sort()
		const datasets = keyValues.map(keyValue => {
			let values: Number[] = []
			return {keyValue, values}
		})
		for (i = 0; i < chartData.length; i++) {
			for (k = 0; k < keyValues.length; k++) {
				const keyValue = keyValues[k]
				const item = datasets.find(item => keyValue === item.keyValue)
				item?.values.push(chartData[i][keyValues[k]])
			}
		}

		const next = {
			labels: labels,
			datasets: datasets?.map((data, index) => {
				return {
					...borderValues,
					label: data.keyValue,
					data: data.values,
					fill: false,
					backgroundColor: backgroundColorList[index],
					borderColor: borderColorList[index],
					yAxisID: 'y',
				}
			}),
		}
		setData(next)
	}, [chartData])

	useEffect(setChartData, [setChartData])

	return {data}
}

const useGetRpProductChartData = (chartData: any) => {
	const [data, setData] = useState(defaultChartData)

	const setChartData = useCallback(() => {
		setData((curr: any) => {
			if (!chartData || !chartData.length) {
				return curr
			}

			const labels = chartData.map((x: any) => {
				const {Date} = x
				return Date.split(' ').pop()
			})

			const keys = Object.keys(chartData[0]).slice(1)

			const datasets = keys.map((key: string) => {
				return {
					backgroundColor: backgroundColorListRp[key],
					borderColor: borderColorListRp[key],
					...borderValues,
					data: chartData.map((row: any) => row[key]),
					fill: false,
					label: key,
					yAxisID: 'y'
				}
			})

			return {
				datasets,
				labels
			}
		})
	}, [chartData])

	useEffect(setChartData, [setChartData])

	return {data}
}

const useGetAllProductChartData = (chartData: any) => {
	const [data, setData] = useState(defaultChartData)

	const setChartData = useCallback(() => {
		setData((curr: any) => {
			if (!chartData || !chartData.length) {
				return curr
			}

			let filteredChartData: any = []
			for (let i = 0; i < chartData.length; i++) {
				const {Date, EP, RP} = chartData[i]
				filteredChartData.push({
					Date,
					EP,
					RP,
					All: EP + RP
				})
			}

			return {
				labels: filteredChartData.map((item: any) => {
					const {Date} = item
					return Date.split(' ').pop()
				}),
				datasets: [
					{
						...borderValues,
						label: 'EP',
						data: filteredChartData.map((item: any) => item.EP),
						fill: false,
						backgroundColor: productChartColors.EP,
						borderColor: productChartBorderColors.EP,
						fontColor: productChartColors.EP,
						yAxisID: 'y',
					},
					{
						...borderValues,
						label: 'RP',
						data: filteredChartData.map((item: any) => item.RP),
						fill: false,
						backgroundColor: productChartColors.RP,
						borderColor: productChartBorderColors.RP,
						fontColor: productChartColors.RP,
						yAxisID: 'y',
					},
					{
						...borderValues,
						label: FilterEnum.DefaultFilterAll,
						data: filteredChartData.map((item: any) => item.All),
						fill: false,
						backgroundColor: productChartColors.All,
						borderColor: productChartBorderColors.All,
						fontColor: productChartColors.All,
						yAxisID: 'y',
					}
				]
			}
		})
	}, [chartData])

	useEffect(setChartData, [setChartData])

	return {data}
}

interface ChartProps {
	data: any;
	options: any;
}

const Chart = memo((props: ChartProps) => {
	const {data, options} = props

	return (
		<Line
			data={data}
			options={options}
			className="main-chart-max-height"
		/>
	)
})

interface ProductChartProps {
	chartData: any
	chartOptions: any
}

const AllProductChart = memo((props: ProductChartProps) => {
	const {chartData, chartOptions} = props
	const {data} = useGetAllProductChartData(chartData)
	return (
		<Chart data={data} options={chartOptions}/>
	)
})

const EpProductChart = memo((props: ProductChartProps) => {
	const {chartData, chartOptions} = props
	const {data} = useGetEpProductChartData(chartData)
	return (
		<Chart data={data} options={chartOptions}/>
	)
})

const RpProductChart = memo((props: ProductChartProps) => {
	const {chartData, chartOptions} = props
	const {data} = useGetRpProductChartData(chartData)
	return (
		<Chart data={data} options={chartOptions}/>
	)
})

const renderAllProductChart = (chartData: any, options: any) =>
	<AllProductChart
		chartData={chartData}
		chartOptions={options}
	/>

const renderEpProductChart = (chartData: any, options: any) =>
	<EpProductChart
		chartData={chartData}
		chartOptions={options}
	/>

const renderRpProductChart = (chartData: any, options: any) =>
	<RpProductChart
		chartData={chartData}
		chartOptions={options}
	/>

interface MainChartComponentProps {
	chartData: any
	isCurrency: boolean
	isLoading: boolean
	isPercentage?: boolean
	options: any
	summaryData: ChartSummaryData
	title: any
	tooltip: string
}

const MainChartComponent = memo((props: MainChartComponentProps) => {
	const {
		chartData,
		isCurrency,
		isLoading,
		isPercentage,
		options,
		summaryData,
		title,
		tooltip
	} = props
	const {isProductAll, isProductEp, isProductRp} = useGetMainProductStates()
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateMember)

	const renderChart = useMemo(() => {
		if (isProductAll) {
			return renderAllProductChart(chartData, options)
		}
		if (isProductEp) {
			return renderEpProductChart(chartData, options)
		}
		if (isProductRp) {
			return renderRpProductChart(chartData, options)
		}
		return null
	}, [isProductEp, isProductRp, isProductAll, chartData, options])

	const format = useCallback((value: any) => {
		if (isCurrency) {
			return formatCurrency(coreCurrencyState, value)
		}
		const suffix = isPercentage ? '%' : ''
		return `${formatNumberMember(value)}${suffix}`
	}, [isCurrency, isPercentage, coreCurrencyState])

	return (
		<FixedHeightChartCard
			title={title}
			titleClass="card-title-info"
			height={315}
			loading={isLoading}
			tooltip={tooltip}
			summary={
				<ChartSummaryTable
					data={summaryData}
					format={format}
				/>
			}
		>
			<>
				{renderChart}
			</>
		</FixedHeightChartCard>
	)
})

export {MainChartComponent}
