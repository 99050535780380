import React, {memo} from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {integrationFilterState} from '../../../../state/integration'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'

const TimeSummary = memo(() => {
	const {getDaysIntegrating} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getDaysIntegrating, 0)

	return (
		<ValueCard
			title={`Average time in days`}
			value={data}
			icon="icon-time-alarm"
			tooltip={'Average time in days to complete an integration'}
		/>
	)
})

export {TimeSummary}
