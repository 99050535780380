import {FilterCard, FilterCardDisplayProps} from '../Card/FilterCard'
import Select from 'react-select'
import React, {useCallback, useEffect, useMemo, useState} from 'react'

export interface SelectFilterItem {
	value: any
	label: string
}

export interface MultiSelectFilterItem extends SelectFilterItem {
	isSelected: boolean
}

export interface SelectFilterProps extends FilterCardDisplayProps {
	title: string
	items: SelectFilterItem[]
	value?: any
	onChange?: (value: any) => void
	showDefault?: boolean
	defaultValue?: any
}

const SelectFilterCard = (props: SelectFilterProps) => {
	const {title, icon, items, value, onChange, showDefault = true, defaultValue = ''} = props
	const defaultSelectedValue = useMemo(() => ({
		value: defaultValue,
		label: 'Select'
	}), [defaultValue])
	const [selectedValue, setSelectedValue] = useState<SelectFilterItem>(defaultSelectedValue)
	const [options, setOptions] = useState<SelectFilterItem[]>([])

	const loadOptions = useCallback(() => {
		let next: SelectFilterItem[] = []

		if (showDefault) {
			next = [{value: defaultValue, label: 'Select'}]
		}

		next = [
			...next,
			...items.map(x => ({
				value: x.value,
				label: x.label
			}))
		]

		setOptions(next)

	}, [items, showDefault, defaultValue])

	useEffect(loadOptions, [loadOptions])

	useEffect(() => {
		if (items.length) {
			const next = items.find((x: SelectFilterItem) => x.value === value) ?? defaultSelectedValue
			setSelectedValue(next)
		}
	}, [value, items, defaultSelectedValue])

	const handleChange = (value: any) => {
		if (onChange) {
			onChange(value)
		}
	}

	return (
		<FilterCard
			title={title}
			icon={icon}
			iconColor="warning"
		>
			<div>
				<Select
					className="react-select"
					classNamePrefix="react-select"
					name="singleSelect"
					isSearchable
					value={selectedValue}
					options={options}
					placeholder={title}
					blurInputOnSelect={true}
					onChange={handleChange}
				/>
			</div>
		</FilterCard>
	)
}

export default SelectFilterCard
