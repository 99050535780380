import {autoRefreshState} from './filters'
import {atom, selector} from 'recoil'
import {DynamicPerformanceFilter} from '../types/dynamic-performance'
import {TimeValueFilter} from '../types/dynamic-pricing'
import {
	formattedDateFromState,
	formattedDateToState,
	selectedMemberIdState,
	selectedSalesRegionIdState
} from './dynamic-data'
import {loggedInUserState, userHasRoleState} from './auth'
import {Roles} from '../types'

const keyPrefix = 'dynamicPricing'

export const dynamicPricingFilterState = selector<DynamicPerformanceFilter>({
	key: `${keyPrefix}FilterState`,
	get: ({get}) => {
		get(autoRefreshState)
		return {
			dateFrom: get(formattedDateFromState),
			dateTo: get(formattedDateToState),
			memberId: get(selectedMemberIdState),
			salesRegionId: get(selectedSalesRegionIdState),
			userName: get(loggedInUserState)
		}
	}
})

export const timeValueFilterState = atom<TimeValueFilter|null>({
	key: `${keyPrefix}TimeValueFilterState`,
	default: null
})

export const canApproveRateChangesState = selector<boolean>({
	key: `${keyPrefix}CanApproveRateChangesState`,
	get: ({get}) => get(userHasRoleState(Roles.DynamicDashboardAdmin))
})

export const pagingState = atom({
	key: `${keyPrefix}ValuePagingState`,
	default: {
		pageNumber: 1,
		pageSize: 10
	}
})
