import React, {useEffect, useState} from 'react'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {FilterEnum, IdEnum} from "../../../../types/insurance-provider";
import {
	selectedEventProductTypeStateInsurance,
	selectedRefundProductTypeStateInsurance
} from "../../../../state/insurance-provider-filters";
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {Type} from "../../../../types";
import useGetMainProductStates from "../../hooks/useGetMainProductStates";
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const TypeDropdown = () => {
	
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const {getProductTypes} = useInsuranceApi();
	const {data, isLoading} = useApiValue(insuranceProviderFilterState, getProductTypes, [])
	const {isProductRp, isProductEp} = useGetMainProductStates()
	
	useEffect(() => {
		if (data && !isLoading) {
			const next = [
				{value: IdEnum.DefaultId, label: FilterEnum.DefaultFilterAll},
				...data.map((x: Type) => ({
					value: x.id,
					label: x.name
				}))
			]
			setMenuItems(next)
		}
	}, [data, isLoading])
	
	return (
		<>
			{
				isProductEp &&
				<StatefulSelectFilterCard
					title="Type"
					items={menuItems}
					state={selectedEventProductTypeStateInsurance}
					showDefault={false}
				/>
			}
			{
				isProductRp &&
				<StatefulSelectFilterCard
					title="Type"
					items={menuItems}
					state={selectedRefundProductTypeStateInsurance}
					showDefault={false}
				/>
			}
		</>
	)
}

export {TypeDropdown}