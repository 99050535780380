import React from 'react';
import {RegionCards} from './components/RegionCards';
import {SalesCards} from './components/SalesCards';
import {AutoRefresh} from '../../components/AutoRefresh';
import {PerformanceVectorMap} from './components/PerformanceVectorMap';
import FooterCards from './components/FooterCards'

const LivePerformanceView = () => {
  return (
    <>
      <div className="content d-flex d-lg-none flex-column">
        <h3 className="mt-4">The Live Performance Dashboard is designed for larger screens only</h3>
        <h5 className="mt-4">If you're on a tablet, you can view it in portrait mode</h5>
      </div>
      <div className="content d-none d-lg-block">
        <AutoRefresh/>
        <div className="d-flex flex-row live-performance-container">
          <div className="d-flex flex-column region-container">
            <RegionCards/>
          </div>
          <div className="d-flex flex-column w-100 justify-content-between">
            <div className="d-flex flex-row w-100 league-container">
              <SalesCards/>
            </div>
            <div className="d-flex flex-grow-1 map-container">
              <PerformanceVectorMap/>
            </div>
            <div className="d-flex flex-row w-100 justify-content-end">
              <FooterCards/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LivePerformanceView
