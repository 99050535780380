import React, {useState, ReactNode} from 'react'
import classNames from 'classnames'
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle
} from 'reactstrap'

interface TableCardProps {
	title: string;
	category?: string;
	children: ReactNode;
	titleClass?: string;
	showToggle?: boolean;
	headerToggle?: ReactNode;
	headerContent?: false | ReactNode;
	isLoading?: boolean;
	defaultOpen?: boolean;
}

const TableCard = (props: TableCardProps) => {
	const {
		children,
		title,
		category,
		titleClass,
		showToggle = false,
		headerToggle,
		headerContent,
		isLoading,
		defaultOpen = true
	} = props

	const [cardOpen, setCardOpen] = useState(defaultOpen)

	const toggleCard = () => setCardOpen(!cardOpen)

	return (
		<Card className={classNames('table-card', {
			'collapsed': !cardOpen,
			'loading': isLoading,
			'overlay': isLoading
		})}>
			<CardHeader className="p-3">
				<div className="d-flex flex-row align-items-center">
					{
						showToggle && (
							<div className="d-flex pr-3">
								<Button onClick={toggleCard} className={`btn-round btn-icon btn-sm btn-toggle`}>
									<i className={classNames('tim-icons', {
										'icon-minimal-up': cardOpen,
										'icon-minimal-down': !cardOpen
									})}/>
								</Button>
							</div>
						)
					}
					<div className="d-flex flex-column">
						{category && <h5 className="card-category">{category}</h5>}
						<CardTitle
							className={classNames('text-uppercase', 'mb-0', titleClass)}
							tag="h5"
						>
							{title}
						</CardTitle>
					</div>
					<>
						{headerContent}
					</>
					{
						headerToggle && (
							<div className="d-flex flex-fill justify-content-end">
								{headerToggle}
							</div>
						)
					}
				</div>
			</CardHeader>
			<CardBody>
				{children}
			</CardBody>
		</Card>
	)
}

export {TableCard}
