import {useRecoilValue, useSetRecoilState} from 'recoil'
import {autoRefreshActiveState, autoRefreshIntervalState, autoRefreshState} from '../state/filters'
import {useInterval} from 'react-interval-hook'
import React from 'react'

const AutoRefresh = () => {
	const setAutoRefresh = useSetRecoilState(autoRefreshState)
	const autoRefreshActive = useRecoilValue(autoRefreshActiveState)
	const autoRefreshInterval = useRecoilValue(autoRefreshIntervalState)

	const update = () => setAutoRefresh(current => current + 1)

	useInterval(update, autoRefreshInterval, {autoStart: autoRefreshActive})

	return <></>
}

export {AutoRefresh}
