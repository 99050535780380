import {ChartCard} from '../../../components/Card/ChartCard'
import {Bar} from 'react-chartjs-2'
import React from 'react'
import {useGetBarChartData} from '../../../hooks/useGetChartDataSummary'
import {filterState} from '../../../state/dynamic-performance'
import {useDynamicPerformanceApi} from '../../../hooks/api/dynamic'
import {useApiValue} from '../../../hooks/api'

const RevenueByValueChart = () => {
	const {getRevenueByValueBand} = useDynamicPerformanceApi()
	const {data} = useApiValue(filterState, getRevenueByValueBand, {})
	const {getChartData, barChartOptions} = useGetBarChartData(data)

	return (
		<ChartCard title="Revenue Generated by Value Band" showToggle>
			<div className="h-100">
				<Bar
					data={getChartData()}
					options={barChartOptions}
				/>
			</div>
		</ChartCard>
	)
}

export default RevenueByValueChart
