import {useApiActions} from './index'
import {
	Currency,
	InsuranceProvider,
	InsuranceProviderFilter,
	Location,
	MainProduct,
	Type
} from '../../types'
import {
	ChartData,
	EnumMainProduct,
	InsuranceProviderChartDataModel,
	LastTransactionDates,
	UpcomingRiskRowData,
	ValueBandsRowData
} from '../../types/insurance-provider'

export const useInsuranceApi = () => {
	const {callApi} = useApiActions('insurance-provider')

	const getCurrencies = async (filter: InsuranceProviderFilter): Promise<Currency[]> => {
		return await callApi<Currency[], string>({
			query: filter,
			path: 'currencies'
		})
	}

	const getEvents = async (filter: InsuranceProviderFilter): Promise<[]> => {
		return await callApi<[], string>({
			query: filter,
			path: 'events'
		})
	}

	const getEventsSummary = async (filter: InsuranceProviderFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'events-summary'
		})
	}

	const getInsurancePremiumChartData = async (filter: InsuranceProviderFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'insurance-premium-chart'
		})
	}

	const getInsurancePremiumValue = async (filter: InsuranceProviderFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'insurance-premium-value'
		})
	}

	const getInsuranceProviders = async (filter: InsuranceProviderFilter): Promise<InsuranceProvider[]> => {
		return await callApi<InsuranceProvider[], string>({
			query: filter,
			path: 'insurers'
		})
	}

	const getInsuranceRate = async (filter: InsuranceProviderFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'insurance-rate'
		})
	}

	const getInsuranceValue = async (filter: InsuranceProviderFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'insurance-value'
		})
	}

	const getLastTransactionDate = async (filter: InsuranceProviderFilter): Promise<LastTransactionDates> => {
		return await callApi<LastTransactionDates, string>({
			query: filter,
			path: 'last-transaction-date'
		})
	}

	const getLocations = async (filter: InsuranceProviderFilter): Promise<Location[]> => {
		return await callApi<Location[], string>({
			query: filter,
			path: 'locations'
		})
	}

	const getLossTableData = async (filter: InsuranceProviderFilter): Promise<any> => {
		return await callApi<any, string>({
			query: filter,
			path: 'loss-table'
		})
	}

	const getPremiumByRegionChartData = async (filter: InsuranceProviderFilter): Promise<InsuranceProviderChartDataModel> => {
		return await callApi<InsuranceProviderChartDataModel, string>({
			query: filter,
			path: 'premium-by-region'
		})
	}

	const getProductTypes = async (filter: InsuranceProviderFilter): Promise<Type[]> => {
		return await callApi<Type[], string>({
			query: filter,
			path: filter.productId === EnumMainProduct.RefundProtect ? 'refund-product-types' : 'event-product-types'
		})
	}

	const getProducts = async (filter: InsuranceProviderFilter): Promise<MainProduct[]> => {
		return await callApi<MainProduct[], string>({
			query: filter,
			path: 'products'
		})
	}

	const getRefundTableData = async (filter: InsuranceProviderFilter): Promise<any> => {
		return await callApi<any, string>({
			query: filter,
			path: 'refund-table'
		})
	}

	const getRiskByRegionChartData = async (filter: InsuranceProviderFilter): Promise<InsuranceProviderChartDataModel> => {
		return await callApi<InsuranceProviderChartDataModel, string>({
			query: filter,
			path: 'risk-by-region'
		})
	}

	const getRollingRiskChartData = async (filter: InsuranceProviderFilter): Promise<InsuranceProviderChartDataModel> => {
		return await callApi<InsuranceProviderChartDataModel>({
			query: filter,
			path: 'rolling-risk'
		})
	}

	const getRollingRiskTableData = async (filter: InsuranceProviderFilter): Promise<any> => {
		return await callApi<any, string>({
			query: filter,
			path: 'rolling-risk-table'
		})
	}

	const getTransactionValueChartData = async (filter: InsuranceProviderFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'transaction-value-chart'
		})
	}

	const getTransactionVolumeChartData = async (filter: InsuranceProviderFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'transaction-volume-chart'
		})
	}

	const getTransactionsSummary = async (filter: InsuranceProviderFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'transactions-summary'
		})
	}

	const getUpcomingRiskTableData = async (filter: InsuranceProviderFilter): Promise<UpcomingRiskRowData[]> => {
		return await callApi<UpcomingRiskRowData[], string>({
			query: filter,
			path: 'upcoming-risk'
		})
	}

	const getValueBandsTableData = async (filter: InsuranceProviderFilter): Promise<ValueBandsRowData[]> => {
		return await callApi<ValueBandsRowData[], string>({
			query: filter,
			path: 'value-bands-table'
		})
	}

	return {
		getCurrencies,
		getEvents,
		getEventsSummary,
		getInsurancePremiumChartData,
		getInsurancePremiumValue,
		getInsuranceProviders,
		getInsuranceRate,
		getInsuranceValue,
		getLastTransactionDate,
		getLocations,
		getLossTableData,
		getPremiumByRegionChartData,
		getProducts,
		getProductTypes,
		getRefundTableData,
		getRiskByRegionChartData,
		getRollingRiskChartData,
		getRollingRiskTableData,
		getTransactionsSummary,
		getTransactionValueChartData,
		getTransactionVolumeChartData,
		getUpcomingRiskTableData,
		getValueBandsTableData
	}
}
