import React from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import {TableCard} from '../../../components/Card/TableCard'
import {ValueStatusTableCell} from '../../../components/Table/ValueStatusTableCell'
import {UserDataModel, UserModel, ValueStatus} from '../../../types/refund'
import {
	userTableColumnSortQueryState,
	userTableCurrentPageNumberState, userTableFilterState,
	userTablePageSizeState
} from '../../../state/refund-data'
import {ValueDirectionProps} from '../../../components/Card/ValueStatusCard'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {formatNumberMember} from '../../../utils/number'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'

const UserData = () => {
	const {getUserData} = useRefundApi()
	const {data, isLoading} = useApiValue(userTableFilterState, getUserData, {} as UserModel)

	const currentPageNumberState = useRecoilValue(userTableCurrentPageNumberState)
	const setUserTablePageSizeState = useSetRecoilState(userTablePageSizeState)
	const setUserTableCurrentPageNumberState = useSetRecoilState(userTableCurrentPageNumberState)
	const setUserTableColumnSortQueryState = useSetRecoilState(userTableColumnSortQueryState)

	function pageNumberChanged(clickedPageNumber: any) {
		setUserTableCurrentPageNumberState(clickedPageNumber)
	}

	function pageSizeChanged(pageSize: any) {
		setUserTablePageSizeState(pageSize)
	}

	function handleSort(sortDirection: any, event: any) {
		setUserTableColumnSortQueryState(`${sortDirection.selector} ${event}`)
	}

	return (
		<TableCard
			title="">
			<div>
				<DataTable
					fixedHeader={true}
					data={data.rows}
					columns={columns}
					pagination={true}
					paginationServer={true}
					paginationDefaultPage={currentPageNumberState}
					paginationTotalRows={data.count}
					onChangePage={pageNumberChanged}
					onChangeRowsPerPage={pageSizeChanged}
					onSort={handleSort}
					theme="dark"
					disabled={isLoading}
					paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40, 50, 100]}
				/>
			</div>
		</TableCard>
	)
}

export {UserData}

const defaultValueDirectionProps: ValueDirectionProps = {
	value: '0',
	direction: null,
	colour: null
}

const getValueObject = (row: ValueStatus, isPercent?: boolean) => {

	if (row === null && isPercent) {
		return {
			...defaultValueDirectionProps,
			value: `${0}%`
		}
	}

	if (row === null) {
		return defaultValueDirectionProps
	}

	const {value, isPositive} = row
	return {
		value: isPercent === true ? `${value}%` : formatNumberMember(parseInt(value)),
		direction: isPositive ? 'down' : 'up',
		colour: isPositive ? 'red' : 'green'
	} as ValueDirectionProps
}

const columns: TableColumn<UserDataModel> [] = [
	{
		name: 'Rank #',
		selector: ({rank}) => rank
	},
	{
		name: 'User',
		selector: ({user}) => user
	},
	{
		name: 'Today',
		selector: (row: any) => row.today,
		sortable: true,
		cell: (row: any) => {
			return (
				<ValueStatusTableCell
					values={[getValueObject(row.today)]}
				/>
			)
		}
	},
	{
		name: 'WTD',
		selector: (row: any) => row.weekToDate,
		sortable: true,
		cell: (row: any) => {
			return (
				<ValueStatusTableCell
					values={[getValueObject(row.weekToDate)]}
				/>
			)
		}
	},
	{
		name: 'MTD',
		selector: (row: any) => row.monthToDate,
		sortable: true,
		cell: (row: any) => {
			return (
				<ValueStatusTableCell
					values={[getValueObject(row.monthToDate)]}
				/>
			)
		}
	},
	{
		name: '3 MTHS',
		selector: (row: any) => row.threeMonths,
		sortable: true,
		cell: (row: any) => {
			return (
				<ValueStatusTableCell
					values={[getValueObject(row.threeMonths)]}
				/>
			)
		}
	},
	{
		name: 'Referral Quality WTD',
		selector: (row: any) => row.referralQualityWeek,
		sortable: true,
		cell: (row: any) => {
			const data = row.referralQualityWeek
			if (data) {
				const cellData = data.map((x: ValueStatus, i: number) => getValueObject(x, i !== 0))
				return (
					<ValueStatusTableCell
						values={cellData}
					/>
				)
			}
		}
	},
	{
		name: 'Referral Quality MTD',
		selector: (row: any) => row.referralQualityMonth,
		sortable: true,
		cell: (row: any) => {
			const data = row.referralQualityMonth
			if (data) {
				const cellData = data.map((x: ValueStatus, i: number) => getValueObject(x, i !== 0))
				return (
					<ValueStatusTableCell
						values={cellData}
					/>
				)
			}
		}
	},
	{
		name: 'Approved',
		selector: (row: any) => row.approved,
		cell: (row: any) => {
			return (
				<ValueStatusTableCell
					values={[getValueObject(row.approved, true)]}
				/>
			)
		}
	},
	{
		name: 'Declined',
		selector: (row: any) => row.declined,
		cell: (row: any) => {
			return (
				<ValueStatusTableCell
					values={[getValueObject(row.declined, true)]}
				/>
			)
		}
	}
]