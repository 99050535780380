import {RecoilValueReadOnly, useRecoilValue} from 'recoil'
import {colors} from '../utils/chart'
import {useCallback} from 'react'
import {monthsInDateRangeState} from '../state/filters'
import {toUsd} from '../utils/number'
import {formatCurrency} from '../utils/currency'
import {ChartData} from '../types/dynamic-performance'

const chartOptions: any = {
	responsive: true,
	maintainAspectRatio: false,
	spanGaps: true,
	interaction: {
		intersect: false,
	},
	elements: {
		line: {
			tension: 0.3
		}
	}
}

const barChartOptions: any = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		y: {
			display: true,
			position: 'left',
			ticks: {
				callback: (label: any) => formatCurrency('USD', label)
			}
		}
	},
	plugins: {
		legend: {
			display: false
		},
		title: {
			display: true,
		},
		tooltip: {
			callbacks: {
				label: function (context: { dataset: { label: string; }; parsed: { y: number; }; }) {
					return formatCurrency('USD', context.parsed.y)
				}
			}
		}
	},
}

export const useGetChartData = (
	data: ChartData,
	monthsInRangeSelector?: RecoilValueReadOnly<string[]>) => {
	const months = useRecoilValue(monthsInRangeSelector ?? monthsInDateRangeState)

	const formatSummary = useCallback((value: number | string) => {
		return toUsd(value.toString(), 0)
	}, [])

	const getChartData = useCallback((): any => {
		const buildChartData = () => {
			const chartData = data.chartData
			const datasets = Object.keys(chartData).map((key: string, index: number) => {
				const chartColors = colors[index]
				const data: any[] = chartData[key]

				return {
					label: key,
					fill: false,
					backgroundColor: chartColors.borderColor,
					borderColor: chartColors.borderColor,
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					pointBackgroundColor: chartColors.pointBackgroundColor,
					pointBorderColor: 'rgba(255,255,255,0)',
					pointHoverBackgroundColor: chartColors.pointHoverBackgroundColor,
					pointBorderWidth: 20,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 15,
					pointRadius: 4,
					data: [...data]
				}
			})

			return {
				labels: months,
				datasets: [...datasets]
			}
		}

		if (Object.keys(data.chartData).length === 0) {
			return {
				labels: months,
				datasets: []
			}
		}

		return buildChartData()
	}, [data, months])

	return {chartOptions, getChartData, formatSummary, summaryData: data.summary}
}

export const useGetBarChartData = (chartData: Record<string, any>) => {
	const getChartData = useCallback(() => {
		const buildChartData = () => {
			const labels = Object.keys(chartData)
			const data: any[] = labels.map(x => chartData[x])
			const chartColors = colors[1]

			const dataset = {
				label: '',
				fill: true,
				backgroundColor: chartColors.backgroundColor,
				borderColor: chartColors.borderColor,
				borderWidth: 2,
				borderDash: [],
				borderDashOffset: 0.0,
				pointBackgroundColor: chartColors.pointBackgroundColor,
				pointBorderColor: 'rgba(255,255,255,0)',
				pointHoverBackgroundColor: chartColors.pointHoverBackgroundColor,
				pointBorderWidth: 20,
				pointHoverRadius: 4,
				pointHoverBorderWidth: 15,
				pointRadius: 4,
				data: data
			}

			return {
				labels,
				datasets: [dataset]
			}
		}

		return buildChartData()
	}, [chartData])

	return {getChartData, barChartOptions}
}

const mapsByRegionId: Record<number, string> = {
	2: 'oceania_mill',
	3: 'asia_wme_mill',
	4: 'europe_mill',
	6: 'africa_me_mill',
	7: 'north_america_mill',
	8: 'south_america_mill'
}

const mapLabelsByRegionId: Record<number, string> = {
	2: 'OCEANA',
	3: 'ASIA',
	4: 'EUROPE',
	6: 'AFRICA & MIDDLE EAST',
	7: 'NORTH AMERICA',
	8: 'SOUTH AMERICA'
}

export const useGetMapData = (regionId: number) => {
	const getMapName = useCallback(() => {
		return mapsByRegionId[regionId]
	}, [regionId])

	const getMapLabel = useCallback(() => {
		return mapLabelsByRegionId[regionId]
	}, [regionId])

	return {getMapName, getMapLabel}
}

