import React, {useEffect, useState} from 'react'
import {selectedCurrencyStateMember} from '../../../../state/member-filters'
import {Currency} from '../../../../types'
import getSymbolFromCurrency from 'currency-symbol-map'
import {FilterEnum} from '../../Enums/FilterEnum'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {useMemberApi} from '../../../../hooks/api/member'
import {useApiValue} from '../../../../hooks/api'
import {memberFilterState} from '../../../../state/memberFilterState'

const CurrencyDropdown = () => {
	const {getCurrencies} = useMemberApi()
	const {data, isLoading} = useApiValue(memberFilterState, getCurrencies, [])
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])

	useEffect(() => {
		if (data.length > 0 && !isLoading) {
			const next = [
				{value: FilterEnum.DefaultFilterAll, label: FilterEnum.DefaultFilterAll},
				...data?.map((x: Currency) => (
					{
						value: x.currencyCode,
						label: `${x.currencyCode} (${getSymbolFromCurrency(x.currencyCode)})`
					}
				))
			]
			setMenuItems(next)
		}
	}, [data, isLoading])

	return (
		<StatefulSelectFilterCard
			state={selectedCurrencyStateMember}
			title="Currency"
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {CurrencyDropdown}
