import React, {memo} from 'react'
import {ValueCard} from '../../../components/Card/ValueCard'
import {commissionFilterState} from '../../../state/commission-data'
import {toPosition} from '../../../utils/number'
import {useCommissionApi} from '../../../hooks/api/commission'
import {useApiValue} from '../../../hooks/api'

const CompanyRanking = memo(() => {
	const {getCompanyRanking} = useCommissionApi()
	const {data} = useApiValue(commissionFilterState, getCompanyRanking, 0)

	return (
		<ValueCard
			title="Company Ranking"
			value={toPosition(data)}
			icon="icon-trophy"
		/>
	)
})

export {CompanyRanking}
