import classNames from 'classnames'
import React, {ReactElement} from 'react'

export const TableHeader = (props: { title: string, className?: string }) => {
	return (
		<th className={classNames('px-2 py-2',
			'font-medium text-left',
			props.className)}
		>
			{props.title}
		</th>
	)
}

export const CardHeader = (props: { title: string, className?: string }) => {
	return (
		<div className={classNames('font-medium text-left',
			props.className)}
		>
			{props.title}
		</div>
	)
}

export const TableColumn = (props: { value: ReactElement, className?: string }) => {
	return (
		<td className={classNames('px-2 py-2',
			'text-left',
			props.className)}
		>
			{props.value}
		</td>
	)
}

export const CardColumn = (props: {
	value: ReactElement,
	style?: React.CSSProperties | undefined,
	className?: string
}) => {
	return (
		<div
			className={classNames('text-left',
				props.className)}
			style={props.style}
		>
			{props.value}
		</div>
	)
}