import React, {FC, useEffect} from 'react'
import {signInRedirect} from '../../services/user-service'

const Login: FC = () => {

	useEffect(() => {
		signInRedirect()
			.then(null)
	}, [])

	return (
		<>
		</>
	)
}

export {Login}
