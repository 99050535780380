import React, {useEffect, useState} from 'react'
import {ValueDirectionProps, ValueStatusCard} from '../../../components/Card/ValueStatusCard'
import {refundFilterState, ValueStatus} from '../../../types/refund'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'

export const Handled = () => {
	const [valueStatusData, setValueStatusData] = useState<ValueDirectionProps[]>([])
	const [valueStatusDataDay, setValueStatusDataDay] = useState<ValueDirectionProps[]>([])
	const {getReceivedNewData} = useRefundApi()
	const {data} = useApiValue(refundFilterState, getReceivedNewData, [] as ValueStatus[])

	useEffect(() => {
		if (data) {
			const nextHour: ValueDirectionProps[] = data.slice(0, 1)?.map((x: ValueStatus) => {
				const {value, isPositive} = x
				return {
					value,
					direction: isPositive ? 'down' : 'up',
					colour: isPositive ? 'red' : 'green'
				}
			})
			setValueStatusData(nextHour)

			const nextDay: ValueDirectionProps[] = data.slice(1, 2)?.map((x: ValueStatus) => {
				const {value, isPositive} = x
				return {
					value,
					direction: isPositive ? 'down' : 'up',
					colour: isPositive ? 'red' : 'green'
				}
			})
			setValueStatusDataDay(nextDay)
		}
	}, [data])

	return (
		<div className="stacked-value-status-cards">
			<div className={'d-flex flex-row justify-content-between flex-grow-1 stacked-value-status-card'}>
				<ValueStatusCard
					title={'Handled'}
					values={valueStatusData}
					divider
					subTitle="Last Hour"
				/>
			</div>
			<div className={'d-flex flex-row justify-content-between flex-grow-1 stacked-value-status-card'}>
				<ValueStatusCard
					title={''}
					values={valueStatusDataDay}
					divider
					subTitle="Last 24Hrs"
				/>
			</div>
		</div>
	)
}