import React, {memo, useCallback} from 'react';
import {Line} from "react-chartjs-2";
import {FixedHeightChartCard} from "../../../../../components/Card/FixedHeightChartCard";
import {ChartSummaryTable} from "../../../../../components/Dashboard/Charts/ChartSummaryTable";
import {ChartHeightValue, tooltipList} from "../../../../../utils/chart";
import {formatCurrency} from "../../../../../utils/currency";
import {useRecoilValue} from "recoil";
import {selectedCoreCurrencyStateInsurance} from "../../../../../state/insurance-provider-filters";
import {
	insuranceProviderFilterState,
	monthsInDateRangeState
} from "../../../../../state/insurance-provider";
import {useInsuranceApi} from "../../../../../hooks/api/insurance";
import {useApiValue} from "../../../../../hooks/api";
import {defaultChartData} from "../../../../../types/insurance-provider";
import {useGetChartData} from "../../../../../hooks/useGetChartDataSummary";

const TransactionValueChart = memo(() => {
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateInsurance)
	const {getTransactionValueChartData} = useInsuranceApi()
	const {data} = useApiValue(insuranceProviderFilterState, getTransactionValueChartData, defaultChartData)
	const {
		chartOptions,
		getChartData,
		summaryData
	} = useGetChartData(data, monthsInDateRangeState)

	const format = useCallback((value: number | string) => {
		return formatCurrency(coreCurrencyState, parseInt(value.toString()))
	}, [coreCurrencyState])
	
	return (
		<FixedHeightChartCard
			title={`Transactions Value (${coreCurrencyState})`}
			titleClass="card-title-info"
			height={ChartHeightValue['small']}
			summary={
				<ChartSummaryTable
					data={summaryData}
					format={format}
				/>
			}
			tooltip={tooltipList.TransactionValue}
		>
			<Line
				data={getChartData()}
				options={chartOptions}
				className="main-chart-max-height"
			/>
		</FixedHeightChartCard>
	);
});

export {TransactionValueChart}
