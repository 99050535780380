import {atom, selector} from 'recoil'
import {monthsInRange} from '../utils/date'
import {Moment} from 'moment'
import moment from 'moment/moment'

const keyPrefix = 'refund'

export const dateFromState = atom<Moment>({
	key: `${keyPrefix}DateFromState`,
	default: moment().subtract(5, 'months')
})

export const dateToState = atom<Moment>({
	key: `${keyPrefix}DateToState`,
	default: moment()
})

export const monthsInDateRangeState = selector<string[]>({
	key: `${keyPrefix}MonthsInRangeState`,
	get: ({get}) => {
		const dateFrom = get(dateFromState)
		const dateTo = get(dateToState)

		return monthsInRange(dateFrom, dateTo)
	}
})

export const userTablePageSizeState = atom<number>({
	key: 'userTablePageSizeState',
	default: 10
})

export const userTableCurrentPageNumberState = atom<number>({
	key: 'userTableCurrentPageNumberState',
	default: 1
})

export const userTableColumnSortQueryState = atom<string>({
	key: 'userTableColumnSortQueryState',
	default: 'weekToDate desc'
})

export const userTableFilterState = selector({
	key: 'userTableFilterState',
	get: ({get}) => {
		return {
			userTableCurrentPageNumber: get(userTableCurrentPageNumberState),
			userTablePageSize: get(userTablePageSizeState),
			userTableColumnSortQuery: get(userTableColumnSortQueryState),
		}
	}
})
