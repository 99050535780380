import React, {memo} from 'react'
import {Bar} from 'react-chartjs-2'
import {formatCurrency} from '../../../../utils/currency'
import {selectedCoreCurrencyStateMember} from '../../../../state/member-filters'
import {useRecoilValue} from 'recoil'
import {Card, CardBody} from 'reactstrap'
import {TableCard} from '../../../../components/Card/TableCard'
import classNames from 'classnames'
import {ContinentEnum} from '../../../../types/region'
import {useMemberApi} from '../../../../hooks/api/member'
import {useApiValue} from '../../../../hooks/api'
import {memberFilterState} from '../../../../state/memberFilterState'
import {RiskByRegion} from '../../../../types/member'

const labels: Record<string, string> = {
	[ContinentEnum.AsiaTwoLetterCode]: ContinentEnum.Asia,
	[ContinentEnum.AfricaTwoLetterCode]: ContinentEnum.Africa,
	[ContinentEnum.EuropeTwoLetterCode]: ContinentEnum.Europe,
	[ContinentEnum.NorthAmericaTwoLetterCode]: ContinentEnum.NorthAmerica,
	[ContinentEnum.OceaniaTwoLetterCode]: ContinentEnum.Oceania,
	[ContinentEnum.SouthAmericaTwoLetterCode]: ContinentEnum.SouthAmerica,
	[ContinentEnum.UnknownTwoLetterCode]: ContinentEnum.Unknown
}

const RiskCoverageByRegionChart = memo(() => {
	const {getRiskCoverageByRegion} = useMemberApi()
	const {data: chartData, isLoading} = useApiValue(memberFilterState, getRiskCoverageByRegion, [])
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateMember)

	const filteredChartData: RiskByRegion[] = chartData.map((x: RiskByRegion) => {
		return {
			...x,
			name: labels[x.name]
		}
	})

	const data: any = {
		labels: filteredChartData.map(item => item.name),
		datasets: [
			{
				label: '',
				data: filteredChartData.map(item => item.value),
				fill: false,
				backgroundColor: 'rgb(255, 99, 132)',
				borderColor: 'rgba(255, 99, 132, 0.2)',
				yAxisID: 'y-axis-2',
			}
		],
	}

	const options: any = {
		indexAxis: 'y-axis-2',
		interaction: {
			mode: 'index'
		},
		responsive: true,
		spanGaps: true,
		scales: {
			x: {
				display: true,
				position: 'left',
				ticks: {
					callback: (label: any) => {
						return `${label}%`
					}
				}
			}
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: function (context: { dataset: { label: string; }; parsed: { x: number | null; }; }) {
						let label = context.dataset.label || ''

						if (label) {
							label += ': '
						}
						if (context.parsed.x !== null) {
							const item = filteredChartData.find(item => item.value === context.parsed.x)
							label += formatCurrency(coreCurrencyState, Number(item?.transactionValueInsured))
							label += ` Protected ${context.parsed.x}%`
						}
						return label
					}
				}
			},
			legend: {
				display: false
			}
		}
	}

	return (
		<Card className={classNames('pt-3', {
			'loading': isLoading
		})}>
			<TableCard
				title="risk coverage by region"
				titleClass="card-title">
				<CardBody>
					<Bar
						data={data}
						options={options}
					/>
				</CardBody>
			</TableCard>
		</Card>
	)
})

export {RiskCoverageByRegionChart}
