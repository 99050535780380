import {Button, FormGroup, Spinner} from 'reactstrap'
import React, {useEffect, useState} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {notificationMessageState} from '../../../state/ui'
import {NotificationMessageType} from '../../../types'
import {CSVLink} from 'react-csv'
import {salesFilterState} from '../../../state/sales'
import {useSalesApi} from '../../../hooks/api/sales'
import {useApiValue} from '../../../hooks/api'

const headers = [
	{label: 'Member Name', key: 'Member'},
	{label: 'Member Id', key: 'MemberId'},
	{label: 'Day', key: 'Day'},
	{label: 'MTD', key: 'Mtd'},
	{label: 'YTD', key: 'Ytd'}
]

let allHeaders: any = []

export const MemberTableCsv = () => {
	const [isLoading, setIsLoading] = useState(false)
	const filter = useRecoilValue(salesFilterState)
	const setNotification = useSetRecoilState(notificationMessageState)

	const [csvData, setCsvData] = useState<any>([])

	const {getMemberOverviewTableCsv} = useSalesApi()
	const {data} = useApiValue(salesFilterState, getMemberOverviewTableCsv, [])

	async function getCsvData() {
		setIsLoading(true)

		try {
			const growthValueYears = Array.from(new Set(data.flatMap(item => item.Growth.map(g => g.Label))))
			const growthPercentYears = Array.from(new Set(data.flatMap(item => item.Growth.map(g => g.Label))))
			const monthData = Array.from(new Set(data.flatMap(item => item.Months.map(m => m.Month))))

			const dynamicHeaders = [
				...monthData.map(month => ({label: month, key: month})),
				...growthValueYears.map(year => ({label: `Growth_Value_${year}`, key: `Growth_Value_${year}`})),
				...growthPercentYears.map(year => ({label: `Growth_Percent_${year}`, key: `Growth_Percent_${year}`}))
			]

			allHeaders = headers.concat(dynamicHeaders)

			const modifiedData = data.map(item => {
				const growthValue: { [key: string]: string } = {}
				item.Growth.forEach(growth => {
					growthValue[`Growth_Value_${growth.Label}`] = `${growth.Count}`
				})

				const growthPercent: { [key: string]: string } = {}
				item.Growth.forEach(growth => {
					growthPercent[`Growth_Percent_${growth.Label}`] = `${growth.Percent}%`
				})

				const monthlyData: { [key: string]: string } = {}
				item.Months.forEach(month => {
					monthlyData[month.Month] = `${month.Value}`
				})

				return {
					...item,
					...monthlyData,
					...growthValue,
					...growthPercent
				}
			})

			setCsvData(modifiedData)
		} catch {
			setNotification({
				message: 'Unable to retrieve data',
				type: NotificationMessageType.error
			})
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		setCsvData([])
	}, [filter])

	const CSVFileName = () => `Sales-Member-Overview ${new Date().toDateString()}.csv`

	return (
		<div className="d-flex justify-content-end">
			{!isLoading && (
				<FormGroup className="d-flex pb-1">
					{csvData.length === 0 && (
						<Button className="ml-2 btn btn-sm my-0" onClick={getCsvData}>
							Prepare (CSV File)
						</Button>
					)}
					{csvData.length > 0 && (
						<CSVLink
							filename={CSVFileName()}
							data={csvData}
							headers={allHeaders}
							className="ml-2 btn btn-success btn-sm"
						>
							Download
						</CSVLink>
					)}
				</FormGroup>
			)}
			{isLoading && <Spinner className="ml-2 mb-2" color="white"/>}
		</div>
	)
}
