import React, {useEffect, useState} from 'react'
import {useRecoilValue} from 'recoil'
import {selectedMainProductIdStateMember} from '../../../../state/member-filters'
import {VectorMap} from 'react-jvectormap'
import useGetMapToggleStates from '../../../../hooks/useGetMapToggleStates'
import useGetMainProductStates from '../../hooks/useGetMainProductStates'

const RegionMap = ({regionData, mapName, region}) => {
    const mainProductFilterData = useRecoilValue(selectedMainProductIdStateMember)
    const {isEpSelected, isRpSelected} = useGetMapToggleStates(region)
    const [mapData, setMapData] = useState([])
    const {isProductAll, isProductEp, isProductRp} = useGetMainProductStates()

    useEffect(() => {
        const next = regionData.map(country => {
            const {epTransactionProtectionValue: ep, rpTransactionProtectionValue: rp} = country

            const getValue = () => {
                if (isProductEp) {
                    return ep
                }
                if (isProductRp) {
                    return rp
                }
                if (isProductAll) {
                    if (isEpSelected && isRpSelected) {
                        return ep + rp
                    }
                    if (isRpSelected) {
                        return rp
                    }
                    if (isEpSelected) {
                        return ep
                    }
                    return 0
                }
                return 0
            }

            const value = getValue()

            if (value === 0) {
                return null
            }

            return {
                [country.countryCodeTwoLetter]: value
            }
        }).filter(x => x !== null)

        setMapData(Object.assign({}, ...next))
    }, [mainProductFilterData, regionData, isEpSelected, isRpSelected, isProductAll, isProductEp, isProductRp])

    function showToolTip(code, multiMap) {
        const {defaultProjection} = multiMap
        return `${String(code).toLowerCase()}_${defaultProjection}_en`
    }

    return (
        <VectorMap
            backgroundColor="#3b96ce"
            containerStyle={{
                width: '100%',
                height: '100vh',
                maxHeight: '300px'
            }}
            onRegionTipShow={showToolTip}
            containerClassName="map"
            map={mapName}
            series={{
                regions: [{
                    values: mapData,
                    scale: (isEpSelected && isRpSelected && isProductAll) ? ['#9966fc', '#e27cf1']
                        : isEpSelected ? ['#9a183b', '#d12151']
                            : ['#0e5f81', '#1382b2']
                }]
            }}
            zoomOnScroll={true}
        />
    )
}

export default RegionMap
