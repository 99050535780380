import {Badge, Col, Row} from "reactstrap";
import moment from "moment";
import React, {memo} from "react";
import {productChartColors} from "../../../../utils/chart";
import {useRecoilValue} from "recoil";
import {autoRefreshIntervalState} from "../../../../state/filters";
import Countdown from "react-countdown";
import useGetMainProductStates from "../../hooks/useGetMainProductStates";
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {dateTimeFormat} from "../../../../utils/date";
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const LastTransactionDates = memo(() => {
	const {getLastTransactionDate} = useInsuranceApi();
	const {data} = useApiValue(insuranceProviderFilterState, getLastTransactionDate, {ep: '', rp: ''})
	const autoRefreshValue = useRecoilValue(autoRefreshIntervalState)
	const {
		isProductEp,
		isProductRp,
		isProductAll
	} = useGetMainProductStates()
	
	let layoutScale = 6
	isProductAll && (layoutScale = 4)
	
	return (
		<Row>
			{
				(isProductEp || isProductAll) &&
				<Col xs="12" s="6" md={layoutScale} lg={layoutScale}>
					<h6 className="d-flex text-white chart-summary-badge-radius">
						<Badge style={{background: productChartColors.EP}} className={"w-100"}>
							Last EP Transaction:
						</Badge>
						<Badge color="dark" className={"w-100"}>
							{
								moment.utc(data.ep).local().format(dateTimeFormat)
							}
						</Badge>
					</h6>
				</Col>
			}
			{
				(isProductRp || isProductAll) &&
				<Col xs="12" s="6" md={layoutScale} lg={layoutScale}>
					<h6 className="d-flex text-white chart-summary-badge-radius">
						<Badge style={{background: productChartColors.RP}} className={"w-100"}>
							Last RP Transaction:
						</Badge>
						<Badge color="dark" className={"w-100"}>
							{
								moment.utc(data.rp).local().format(dateTimeFormat)
							}
						</Badge>
					</h6>
				</Col>
			}
			<Col xs="12" s="6" md={layoutScale} lg={layoutScale}>
				<h6 className="d-flex text-white chart-summary-badge-radius">
					<Badge style={{background: productChartColors.All}} className={"w-100"}>
						Auto-Refresh in:
					</Badge>
					<Badge color="dark" className={"w-100"}>
						<Countdown
							daysInHours={true}
							date={(Date.now() + autoRefreshValue)}
						/>
					</Badge>
				</h6>
			</Col>
		</Row>
	);
});

export {LastTransactionDates};