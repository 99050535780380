import {atom, selector} from 'recoil'
import {Moment} from 'moment/moment'
import {dateFormat, monthsInRange} from '../utils/date'
import moment from 'moment'

const keyPrefix = 'dynamicData'

export const dateFromState = atom<Moment>({
	key: `${keyPrefix}DateFromState`,
	default: moment().startOf('year')
})

export const dateToState = atom<Moment>({
	key: `${keyPrefix}DateToState`,
	default: moment()
})

export const formattedDateFromState = selector<string>({
	key: `${keyPrefix}FormattedDateFromState`,
	get: ({get}) => get(dateFromState).format(dateFormat)
})

export const formattedDateToState = selector<string>({
	key: `${keyPrefix}FormattedDateToState`,
	get: ({get}) => get(dateToState).format(dateFormat)
})

export const monthsInDateRangeState = selector<string[]>({
	key: `${keyPrefix}MonthsInRangeState`,
	get: ({get}) => {
		const dateFrom = get(dateFromState)
		const dateTo = get(dateToState)

		return monthsInRange(dateFrom, dateTo)
	}
})

export const selectedMemberIdState = atom<number | null>({
	key: `${keyPrefix}SelectedMemberIdState`,
	default: null
})

export const selectedSalesRegionIdState = atom<number | null>({
	key: `${keyPrefix}SelectedSalesRegionIdState`,
	default: null
})

