import {Line} from 'react-chartjs-2'
import React from 'react'
import {ChartData} from 'chart.js'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {overviewLeadershipRefundChartFilterState} from '../../../state/pulse'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import useGetOverviewLeadershipRefundChartData from '../hooks/useGetOverviewLeadershipRefundChartData'

const OverviewLeadershipRefundGraphChart = () => {
	const {getRevenueTrendsChartData} = usePulseApi()
	const value = useApiValue(overviewLeadershipRefundChartFilterState, getRevenueTrendsChartData, {
		labels: []
	})
	const chartData = useGetOverviewLeadershipRefundChartData(value)

	const data: ChartData<'line'> = {
		labels: chartData.getLabels(),
		datasets: chartData.getDataset()
	}

	return (
		<LoadingSpinner
			isLoading={value.isLoading}
			className="flex items-center justify-center min-h-44 w-full"
		>
			<div className="
				flex-1 p-5 mb-4
				bg-white
				border border-gray-200 rounded-lg
				lg:mb-0"
			>
				<div className="
					text-pulse-label-gray text-xs"
				>
					Protect Group Profit Trend
				</div>
				<div className="
					relative w-full
					font-medium text-xs text-pulse-red"
				>
					<span className="
						m-auto w-24
						absolute left-0 right-0 top-0"
					>
						Click to toggle
					</span>
				</div>
				<Line
					className="max-w-full"
					data={data}
					options={chartData.getOptions()}
				/>
			</div>
		</LoadingSpinner>
	)
}

export default OverviewLeadershipRefundGraphChart
