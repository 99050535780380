import React from 'react'
import { VectorMap } from 'react-jvectormap'

const RegionMap = ({map, data}) => {
	return (
		<VectorMap
			backgroundColor="#3b96ce"
			containerStyle={{
				width: '100%',
				height: '100vh',
				maxHeight: '300px'
			}}
			containerClassName="map"
			map={map}
			series={{
				regions: [{
					values: data,
					scale: ['#9966fc', '#e27cf1']
				}]
			}}
			zoomOnScroll={true}
		/>
	)
}

export default RegionMap
