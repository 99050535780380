import React from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import useRevenueAverageGrowth from '../../hooks/useRevenueAverageGrowth'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'

export const AverageGrowth = () => {
	const color = useSummaryCardColor()
	const {averageGrowth} = useRevenueAverageGrowth()
	return (
		<ValueCard
			title={`Average Growth`}
			value={`${averageGrowth.toFixed(0)} %`}
			icon="icon-minimal-up"
			iconColor={color}
		/>
	)
}
