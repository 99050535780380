import React from 'react';
import {Col, Row} from 'reactstrap';
import {AutoRefresh} from "../../components/AutoRefresh";
import {HeaderInfo} from "./components/HeaderInfo";
import {Filters} from "./components/Filters";
import {MainCharts} from "./components/Charts/MainCharts";
import useGetMainProductStates from "./hooks/useGetMainProductStates";
import {RiskByRegionChart} from "./components/Charts/RiskByRegionChart";
import {PremiumByRegionChart} from "./components/Charts/PremiumByRegionChart";
import {RollingRiskChart} from "./components/Charts/RollingRiskChart";
import UpcomingRiskTable from "./components/Tables/UpcomingRiskTable";
import {RollingRiskTable} from "./components/Tables/RollingRiskTable";
import {RefundTable} from "./components/Tables/RefundTable";
import {LossTable} from "./components/Tables/LossTable";
import {ValueBandsTable} from "./components/Tables/ValueBandsTable";

const InsuranceProviderView = () => {
	const {isProductEp, isProductRp} = useGetMainProductStates()

	return (
		<div className="content">
			<AutoRefresh/>

			<Row className="pb-3">
				<Col>
					<HeaderInfo/>
				</Col>
			</Row>

			<Row className="pb-3">
				<Col>
					<Filters/>
				</Col>
			</Row>

			<Row className="pb-3">
				<Col>
					<MainCharts/>
				</Col>
			</Row>

			{
				(isProductEp || isProductRp) &&
				<Row className="pb-3">
					<Col sm={6}>
						<RiskByRegionChart/>
					</Col>
					<Col sm={6}>
						<PremiumByRegionChart/>
					</Col>
				</Row>
			}
			{
				(isProductEp || isProductRp) &&
				<>
					<Row className="pb-3">
						<Col>
							<RollingRiskChart/>
						</Col>
					</Row>
					<Row className="pb-3">
						<Col>
							<RollingRiskTable/>
						</Col>
					</Row>
					{
						isProductRp &&
						<>
							<Row className="pb-3">
								<Col>
									<RefundTable/>
								</Col>
							</Row>
							<Row className="pb-3">
								<Col>
									<LossTable/>
								</Col>
							</Row>
							<Row className="pb-3">
								<Col>
									<ValueBandsTable/>
								</Col>
							</Row>
						</>
					}
				</>
			}

			{
				isProductEp &&
				<UpcomingRiskTable />
			}
		</div>
	);
};

export default InsuranceProviderView;
