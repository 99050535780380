import React, {useEffect, useState} from 'react'
import {useRecoilState} from 'recoil'
import {selectedTransactionsState} from '../../../../../state/pulse'
import {Checkbox} from '@headlessui/react'

const TransactionCheckbox = (props: { transactionId: number, isCancelled: boolean }) => {
	const [enabled, setEnabled] = useState(false)
	const [selectedTransactions, setSelectedTransactions] = useRecoilState(selectedTransactionsState)

	const handleOnChange = (value: boolean) => {
		let transactions = [...selectedTransactions]

		if (value && !transactions.includes(props.transactionId)) {
			transactions.push(props.transactionId)
		} else if (transactions.includes(props.transactionId)) {
			transactions.splice(transactions.indexOf(props.transactionId), 1)
		}

		setSelectedTransactions(transactions)
		setEnabled(value)
	}

	useEffect(() => {
		setEnabled(selectedTransactions.includes(props.transactionId))
	}, [selectedTransactions, props.transactionId])

	return (
		<Checkbox
			checked={enabled}
			disabled={props.isCancelled}
			onChange={handleOnChange}
			className="
				block size-4
				bg-white
				border-2 border-black rounded
				cursor-pointer group
				data-[checked]:bg-white"
		>
			<svg
				className="
					opacity-0 stroke-black
					group-data-[checked]:opacity-100"
				viewBox="0 0 14 14"
				fill="none"
			>
				<path
					d="M3 8L6 11L11 3.5"
					strokeWidth={2}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</Checkbox>
	)
}

export default TransactionCheckbox
