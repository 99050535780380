import React from 'react'
import {selector, selectorFamily} from 'recoil'
import {
	autoRefreshState,
	formattedDateFromState,
	formattedDateToState,
	monthsInDateRangeState,
	selectedSalesTeamIdState,
	selectedUserIdState,
	selectedUserState
} from './filters'
import {loggedInUserState} from './auth'
import {toUsd} from '../utils/number'
import ReactTooltip from 'react-tooltip'
import {monthShortYearFormat} from '../utils/date'
import moment from 'moment'
import {MonthComparisonCell} from '../components/Table/MonthComparisonCell'
import {CommissionFilter} from '../types'

const monthSelector = (month: string): string => month.replace(' ', '')
const keyPrefix = 'commission'

export const commissionFilterState = selector<CommissionFilter>({
	key: `${keyPrefix}FilterState`,
	get: ({get}) => {
		return {
			dateFrom: get(formattedDateFromState),
			dateTo: get(formattedDateToState),
			userId: get(selectedUserIdState),
			memberId: null,
			loggedInUser: get(loggedInUserState),
			autoRefresh: get(autoRefreshState),
			salesTeamId: get(selectedSalesTeamIdState),
		}
	}
})

export const isUserSelectedState = selectorFamily({
	key: 'isUserSelectedState',
	get: (user: any) => ({get}) => {
		return user?.id === get(selectedUserState)?.id
	}
})

export const commissionTableColumnsState = selectorFamily({
	key: 'commissionTableColumnsState',
	get: (type: string) => ({get}) => {
		const months = get(monthsInDateRangeState)
		const nextMonth = moment().add(1, 'M').format(monthShortYearFormat)

		return [
			{
				name: 'Client Name',
				selector: (row: any) => row.clientName,
				format: (row: any) => (
					<>
						<span data-tip data-for={`${type}${row['clientName']}`}>{row['clientName']}</span>
						<ReactTooltip id={`${type}${row['clientName']}`}>
							<span>{row['clientName']}</span>
						</ReactTooltip>
					</>
				)
			},
			...months.map((month, i) => {
				const currentMonth = `${type}${monthSelector(month)}`
				const previousMonth = i === 0 ? currentMonth : `${type}${monthSelector(months[i - 1])}`

				return {
					name: month,
					selector: () => `${type}${monthSelector(month)}`,
					cell: (row: any) => (
						<MonthComparisonCell
							row={row}
							currentMonthSelector={currentMonth}
							previousMonthSelector={previousMonth}
							showDiff={month !== nextMonth}
						/>
					)
				}
			}),
			{
				name: 'Total',
				selector: () => `${type}YearToDate`,
				format: (row: any) => toUsd(row[`${type}YearToDate`], 0)
			},
		]
	}
})

export const commissionSummaryTableColumnsState = selectorFamily({
	key: 'commissionSummaryTableColumnsState',
	get: (type: string) => ({get}) => {
		const months = get(monthsInDateRangeState)

		return [
			{
				name: '',
				selector: (row: any) => row.Total
			},
			...months.map(month => ({
				name: '',
				selector: () => `${type}${monthSelector(month)}`,
				format: (row: any) => toUsd(row[`${type}${monthSelector(month)}`], 0)
			})),
			{
				name: '',
				selector: () => `${type}YearToDate`,
				format: (row: any) => toUsd(row[`${type}YearToDate`], 0)
			},
		]
	}
})
