import {salesFilterState} from '../../../state/sales'
import React, {useEffect, useState} from 'react'
import {useCountUp} from 'react-countup'
import {useApiValue} from '../../../hooks/api'
import {useSalesApi} from '../../../hooks/api/sales'
import {TransactionProtectionRevenueGrowthValueModel} from '../../../types/sales'

export default function useRevenueAverageGrowth() {
	const {getRevenue} = useSalesApi()
	const {
		data,
		isLoading
	} = useApiValue(salesFilterState, getRevenue, {} as TransactionProtectionRevenueGrowthValueModel)
	const [revenue, setRevenueState] = useState(0)
	const [growthRevenue, setGrowthRevenueState] = useState(0)
	const [averageGrowth, setAverageGrowthState] = useState(0)

	const {
		countUp: totalRevenue,
		update: updateRevenue
	} = useCountUp({end: revenue})

	const {
		countUp: totalGrowthRevenue,
		update: updateGrowthRevenue
	} = useCountUp({end: growthRevenue})

	useEffect(() => {
		if (data && !isLoading) {
			const {
				currentYearValue,
				previousYearValue
			} = data
			setRevenueState(currentYearValue)
			setGrowthRevenueState(currentYearValue - previousYearValue)
			setAverageGrowthState(previousYearValue === 0 ? 0 : (currentYearValue - previousYearValue) / previousYearValue * 100)
		}

	}, [isLoading, data])

	useEffect(() => {
		updateRevenue(revenue)
	}, [revenue, updateRevenue])

	useEffect(() => {
		updateGrowthRevenue(growthRevenue)
	}, [growthRevenue, updateGrowthRevenue])

	return {
		totalRevenue,
		totalGrowthRevenue,
		averageGrowth
	}
}