import React from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import {integrationFilterState} from '../../../state/integration'
import {useApiValue} from '../../../hooks/api'
import {useIntegrationApi} from '../../../hooks/api/integration'
import {StatusChangesData} from '../../../types/integration'

const StatusChangesTable = () => {
	const {getStatusChanges} = useIntegrationApi()
	const {data, isLoading} = useApiValue(integrationFilterState, getStatusChanges, [] as StatusChangesData[])
	return (
		<TableCard
			title="Last status changes"
			titleClass="card-title"
			showToggle={true}
			isLoading={isLoading}
		>
			<DataTable
				data={data}
				columns={columns}
				theme="dark"
				pagination={true}
				highlightOnHover={true}
				disabled={isLoading}
			/>
		</TableCard>
	)
}

export {StatusChangesTable}

const columns: TableColumn<StatusChangesData> [] = [
	{
		name: 'Member',
		selector: ({member}) => member,
		sortable: true,
	},
	{
		name: 'Partnership',
		selector: ({partnership}) => partnership,
		sortable: true,
	},
	{
		name: 'Old Status',
		selector: ({oldStatus}) => oldStatus,
		sortable: true,
		right: true,
	},
	{
		name: 'New Status',
		selector: ({newStatus}) => newStatus,
		sortable: true,
		right: true,
	},
	{
		name: 'Date',
		selector: ({date}) => date,
		sortable: false,
		right: true,
	}
]