import {useEffect, useState} from 'react'
import {refundFilterState, TrustPilotViewModel, ValueStatus} from '../../../types/refund'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'

export function useTrustPilotValues() {
	const {getTrustPilotData} = useRefundApi()
	const {data, isLoading} = useApiValue(refundFilterState, getTrustPilotData, {} as TrustPilotViewModel)
	const [dayAverageState, setDayAverage] = useState({} as ValueStatus)
	const [reviewCountState, setReviewCount] = useState({} as ValueStatus)
	const [reviewRatioState, setReviewRatio] = useState({} as ValueStatus)
	const [badReviewCountState, setBadReviewCount] = useState({} as ValueStatus)
	const [badReviewRatioState, setBadReviewRatio] = useState({} as ValueStatus)
	const [mtdAverageState, setMtdAverage] = useState({} as ValueStatus)

	useEffect(() => {
		if (data && !isLoading) {
			const {dayAverage, reviewCount, reviewRatio, badReviewCount, badReviewRatio, mtdAverage} = data
			setDayAverage(dayAverage)
			setReviewCount(reviewCount)
			setReviewRatio(reviewRatio)
			setBadReviewCount(badReviewCount)
			setBadReviewRatio(badReviewRatio)
			setMtdAverage(mtdAverage)
		}
	}, [data, isLoading])

	return {
		dayAverage: dayAverageState,
		reviewCount: reviewCountState,
		reviewRatio: reviewRatioState,
		badReviewCount: badReviewCountState,
		badReviewRatio: badReviewRatioState,
		mtdAverage: mtdAverageState
	}
}
