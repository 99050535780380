export interface PulseFilter extends Record<string, any> {
	dateFrom: string | null
	dateTo: string | null
	memberId: number | null
	regionId: number | null
	userName: string | undefined
	coreCurrencyCode: string | null
	dateRange: string | null,
	graphFilters: graphFilterType[] | null
}

export enum dateRange {
	Month,
	Year,
	YearToDate,
	Custom,
	AllTime
}

export interface PulseTableFilter extends PulseFilter {
	page: number
	pageSize: number
	sortColumn: string
	sortDirection: string
	isInsured: boolean
}

export interface PulseTransactionFilter extends PulseUserFilter {
	transactionIds: number[]
}

export interface PulseUserFilter extends Record<string, any> {
	userName: string | undefined
}

export enum graphFilterType {
	GrossBookingValue,
	RefundProtectValue,
	YourRevenue,
	AverageConversionRate,
	GrossBookingVolume,
	RefundProtectVolume,
	RevenuePerDollar,
	ProtectGroupProfit,
	ApprovedRefunds
}

export const graphFilterName: Record<graphFilterType, string> = {
	[graphFilterType.RefundProtectValue]: 'Refund Protect Sales',
	[graphFilterType.YourRevenue]: 'Your Revenue',
	[graphFilterType.GrossBookingValue]: 'Gross Booking Sales',
	[graphFilterType.RefundProtectVolume]: 'Refund Protect Volume',
	[graphFilterType.GrossBookingVolume]: 'Gross Booking Volume',
	[graphFilterType.RevenuePerDollar]: 'Gross Revenue Per $',
	[graphFilterType.AverageConversionRate]: 'Conversion Rate',
	[graphFilterType.ProtectGroupProfit]: 'PG Profit',
	[graphFilterType.ApprovedRefunds]: 'Approved Refunds',
}

export const graphFilterColor: Record<graphFilterType, string> = {
	[graphFilterType.RefundProtectValue]: '#0084C4',
	[graphFilterType.YourRevenue]: '#23B77D',
	[graphFilterType.GrossBookingValue]: '#9D9DA4',
	[graphFilterType.RefundProtectVolume]: '#0084C4',
	[graphFilterType.GrossBookingVolume]: '#9D9DA4',
	[graphFilterType.RevenuePerDollar]: '#9D9DA4',
	[graphFilterType.AverageConversionRate]: '#FB4B4E',
	[graphFilterType.ProtectGroupProfit]: '#23B77D',
	[graphFilterType.ApprovedRefunds]: '#FB4B4E',
}

export const graphValueTrends = [
	graphFilterType.YourRevenue,
	graphFilterType.RefundProtectValue,
	graphFilterType.GrossBookingValue,
	graphFilterType.AverageConversionRate
]

export const graphValueDemographics = [
	...graphValueTrends
]

export const graphVolumeTrends = [
	graphFilterType.RefundProtectVolume,
	graphFilterType.GrossBookingVolume
]

export const graphVolumeDemographics = [
	...graphVolumeTrends
]

export const graphRevenue = [
	graphFilterType.RevenuePerDollar
]

export enum settingsPageType {
	Account
}

export enum batchAction {
	None,
	Cancel
}

export const batchActionName: Record<batchAction, string> = {
	[batchAction.None]: 'Actions',
	[batchAction.Cancel]: 'Cancel Selected'
}

export interface PulsePartnersRevenueTableFilter extends PulseFilter {
	page: number
	pageSize: number
	sortColumn: partnersRevenueTableSortColumn
	pricingModel: partnersRevenueTablePricingModel
}

export enum partnersRevenueTableSortColumn {
	MemberName,
	RefundProtectValue,
	ProfitValue,
	ProtectionValue,
	ApprovedRefundsValue
}

export const partnersRevenueTableSortColumnName: Record<partnersRevenueTableSortColumn, string> = {
	[partnersRevenueTableSortColumn.MemberName]: 'Partner',
	[partnersRevenueTableSortColumn.RefundProtectValue]: 'RP Sales',
	[partnersRevenueTableSortColumn.ProfitValue]: 'Partner\'s Revenue',
	[partnersRevenueTableSortColumn.ProtectionValue]: 'PG Revenue',
	[partnersRevenueTableSortColumn.ApprovedRefundsValue]: 'Paid Refunds',
}

export enum partnersRevenueTablePricingModel {
	All,
	Dynamic,
	Static
}

export const partnersRevenueTablePricingModelName: Record<partnersRevenueTablePricingModel, string> = {
	[partnersRevenueTablePricingModel.All]: 'All',
	[partnersRevenueTablePricingModel.Dynamic]: 'Dynamic',
	[partnersRevenueTablePricingModel.Static]: 'Static',
}

export interface PulseVerticalsRevenueTableFilter extends PulseFilter {
	sortColumn: verticalsRevenueTableSortColumn
}

export enum verticalsRevenueTableSortColumn {
	VerticalName,
	RefundProtectValue,
	ProfitValue,
	ProtectionValue,
	ApprovedRefundsValue
}

export const verticalsRevenueTableSortColumnName: Record<verticalsRevenueTableSortColumn, string> = {
	[verticalsRevenueTableSortColumn.VerticalName]: 'Verticals',
	[verticalsRevenueTableSortColumn.RefundProtectValue]: 'RP Sales',
	[verticalsRevenueTableSortColumn.ProfitValue]: 'Partner\'s Revenue',
	[verticalsRevenueTableSortColumn.ProtectionValue]: 'PG Revenue',
	[verticalsRevenueTableSortColumn.ApprovedRefundsValue]: 'Paid Refunds',
}

export enum verticalsRevenueTableBreakdownColumn {
	RefundProtectValue,
	ProfitValue,
	ProtectionValue,
	ApprovedRefundsValue
}

export const verticalsRevenueTableBreakdownColumnName: Record<verticalsRevenueTableBreakdownColumn, string> = {
	[verticalsRevenueTableBreakdownColumn.RefundProtectValue]: 'RP Sales',
	[verticalsRevenueTableBreakdownColumn.ProfitValue]: 'Partner\'s Revenue',
	[verticalsRevenueTableBreakdownColumn.ProtectionValue]: 'PG Revenue',
	[verticalsRevenueTableBreakdownColumn.ApprovedRefundsValue]: 'Paid Refunds',
}