import SelectFilterCard, {SelectFilterItem, SelectFilterProps} from './SelectFilter'
import {RecoilState, useRecoilState} from 'recoil'
import React from 'react'

interface StatefulSelectFilterCardProps extends SelectFilterProps {
	state: RecoilState<any>
}

const StatefulSelectFilterCard = (props: StatefulSelectFilterCardProps) => {
	const {state, ...rest} = props
	const [stateValue, setStateValue] = useRecoilState(state)

	const handleChange = (item: SelectFilterItem) => setStateValue(item.value)

	return (
		<SelectFilterCard {...rest} value={stateValue} onChange={handleChange}/>
	)
}

export default StatefulSelectFilterCard
