import React from 'react'
import SecondaryValueCard from './Common/Cards/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {Currency} from './Common/FormatElements'

const OverviewRevenuePerTransactionSummary = () => {
	const {getAverageTransactionRevenue} = usePulseApi()
	const value = useApiValue(filterState, getAverageTransactionRevenue, 0)

	return (
		<SecondaryValueCard
			value={<Currency value={value.data} max={4}/>}
			header="Average Gross Revenue per Transaction"
			tooltipText="This represents the average amount of Refund Protect fees received for each transaction where the customer has opted into the Refund Protect service.
				It is calculated by dividing the total revenue generated from Refund Protect fees by the total number of transactions."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewRevenuePerTransactionSummary
