import React, {useEffect} from 'react'
import {useRecoilState, useResetRecoilState} from 'recoil'
import {
	selectedTransactionsState,
	tableIsInsuredState,
	tablePageState
} from '../../../../../state/pulse'
import classNames from 'classnames'

const TransactionFlags = () => {
	const [tableIsInsured, setTableIsInsured] = useRecoilState(tableIsInsuredState)
	const resetSelectedTransactions = useResetRecoilState(selectedTransactionsState)
	const resetPage = useResetRecoilState(tablePageState)

	const handleClick = (value: boolean) => {
		resetPage()
		setTableIsInsured(value)
	}

	useEffect(() => {
		resetSelectedTransactions()
	}, [tableIsInsured, resetSelectedTransactions])

	return (
		<div className="
			flex flex-1 relative
			lg:justify-end"
		>
			<div
				className={classNames('mr-2 px-3 py-[2px]',
					'text-nowrap text-sm tracking-[1px] uppercase',
					'border border-pulse-icon-gray rounded-full',
					'cursor-pointer',
					'hover:bg-pulse-button-blue', {
						'bg-pulse-button-blue': tableIsInsured,
						'bg-white': !tableIsInsured
					})}
				onClick={() => handleClick(true)}
			>
					<span
						className="
							align-middle mr-1
							leading-5 text-base text-pulse-icon-gray
							material-symbols-outlined"
					>
						check
					</span>
				<span className="align-middle leading-5">Opted-In</span>
			</div>
			<div
				className={classNames('mr-2 px-3 py-[2px]',
					'text-nowrap text-sm tracking-[1px] uppercase',
					'border border-pulse-icon-gray rounded-full',
					'cursor-pointer',
					'hover:bg-pulse-button-blue', {
						'bg-pulse-button-blue': !tableIsInsured,
						'bg-white': tableIsInsured
					})}
				onClick={() => handleClick(false)}
			>
					<span
						className="
							align-middle mr-1
							leading-5 text-base text-pulse-icon-gray
							material-symbols-outlined"
					>
						close
					</span>
				<span className="align-middle leading-5">Non Opted-In</span>
			</div>
		</div>
	)
}

export default TransactionFlags
