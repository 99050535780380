import React, {useEffect, useState} from 'react'
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardTitle,
	Col,
	Input,
	Row
} from 'reactstrap'
import moment, {Moment} from 'moment'
import Select from 'react-select'
import {
	isMemberInvalid,
	isSalesReferenceIdInvalid,
	isStringEmpty,
	productCodes,
	soldTypes,
	UploadTransaction,
	utc
} from '../../../types/upload'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {
	selectedCurrencyUpload,
	selectedMemberUpload,
	selectedTimezoneUpload,
	uploadConfirmModalOpen
} from '../../../state/upload-data'
import ReactDatetime from 'react-datetime'
import ConfirmUploadTransaction from './ConfirmUploadTransaction'
import {
	fullDateTimeFormat,
	isValidDate,
	nowDateTime
} from '../../../utils/date'
import {TimezoneDropdown} from './Filters/TimezoneDropdown'

const defaultUploadTransactionModel: UploadTransaction = {
	salesReferenceId: '',
	customerLastName: '',
	vendorSalesDateTime: nowDateTime,
	productCode: 'TKT',
	sold: true,
	customerFirstName: '',
	quoteId: '',
	timezoneId: utc
}

const UploadSingleStandard = () => {

	const [uploadFormState, setUploadFormState] = useState<UploadTransaction>(defaultUploadTransactionModel)
	const [uploadButtonClicked, setUploadButtonClicked] = useState(false)

	const timezone = useRecoilValue(selectedTimezoneUpload)
	const currencyCode = useRecoilValue(selectedCurrencyUpload)
	const setOpenConfirmUploadTransactionState = useSetRecoilState(uploadConfirmModalOpen)
	const member = useRecoilValue(selectedMemberUpload)

	const setUploadFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = event.target
		setUploadFormState((curr) => ({
			...curr,
			[name]: value
		}))
	}

	const handleDropdownChange = (fieldName: any, selectedValue: any) => {
		setUploadFormState((curr) => ({
			...curr,
			[fieldName]: selectedValue.value,
		}))
	}

	const handleDateChange = (key: string, date: Moment | string) => {
		setUploadFormState((curr) => ({
			...curr,
			[key]: moment(date).format(fullDateTimeFormat),
		}))
	}

	const isFormDataInvalid: boolean = isSalesReferenceIdInvalid(uploadFormState.salesReferenceId)
		|| isStringEmpty(uploadFormState.customerFirstName)
		|| isStringEmpty(uploadFormState.customerLastName)
		|| isStringEmpty(uploadFormState.quoteId)
		|| !isValidDate(uploadFormState.vendorSalesDateTime)
		|| isStringEmpty(uploadFormState.productCode)
		|| isMemberInvalid(member?.actorId)

	const handleUploadClick = () => {

		setUploadButtonClicked(true)

		if (!isFormDataInvalid) {
			setOpenConfirmUploadTransactionState(true)
		}
	}

	const resetForm = () => {
		setUploadButtonClicked(false)
		setUploadFormState(defaultUploadTransactionModel)
	}

	useEffect(() => {
		setUploadFormState((curr) => ({
			...curr,
			currencyCode: currencyCode,
			timezoneId: timezone
		}))
	}, [currencyCode, timezone])

	return (
		<div className="w-100 p-3">
			{
				isMemberInvalid(member?.actorId) && uploadButtonClicked
				&&
				(
					<Alert color="danger" className="mt-2">
						Please select Member
					</Alert>
				)
			}
			<Card className="w-100 p-3">
				<CardBody>
					<Row>
						<Col>
							<Row className="pb-2 pl-2">
								<CardBody>
									<CardTitle className="text-uppercase">
										<span>Sales Reference Id</span>
									</CardTitle>
									<Input
										type="text"
										name="salesReferenceId"
										value={uploadFormState.salesReferenceId}
										className="custom-form-input"
										onChange={setUploadFormData}
										maxLength={64}
									/>
									{
										(uploadButtonClicked && isSalesReferenceIdInvalid(uploadFormState.salesReferenceId))
										&&
										<span className="text-danger">
											Sales Reference Id is required (max length: 64)
										</span>
									}
								</CardBody>
							</Row>
							<Row className="pb-2 pl-2">
								<CardBody>
									<CardTitle className="text-uppercase">
										<span>Quote Id</span>
									</CardTitle>
									<Input
										type="text"
										name="quoteId"
										value={uploadFormState.quoteId}
										className="custom-form-input"
										onChange={setUploadFormData}
										maxLength={60}
									/>
									{
										(uploadButtonClicked && isStringEmpty(uploadFormState.quoteId))
										&&
										<span className="text-danger">
											Quote Id is required
										</span>
									}
								</CardBody>
							</Row>
							<Row className="pb-2 pl-2">
								<CardBody>
									<CardTitle className="text-uppercase">Customer First Name</CardTitle>
									<Input
										type="text"
										name="customerFirstName"
										value={uploadFormState.customerFirstName}
										className="custom-form-input"
										onChange={setUploadFormData}
										maxLength={255}
									/>
									{
										(uploadButtonClicked && isStringEmpty(uploadFormState.customerFirstName))
										&&
										<span className="text-danger">
											Customer First Name is required
										</span>
									}
								</CardBody>
							</Row>
							<Row className="pb-2 pl-2">
								<CardBody>
									<CardTitle className="text-uppercase">Customer Last Name</CardTitle>
									<Input
										type="text"
										name="customerLastName"
										value={uploadFormState.customerLastName}
										className="custom-form-input"
										onChange={setUploadFormData}
										maxLength={255}
									/>
									{
										(uploadButtonClicked && isStringEmpty(uploadFormState.customerLastName))
										&&
										<span className="text-danger">
											Customer Last Name is required
										</span>
									}
								</CardBody>
							</Row>
						</Col>
						<Col>
							<Row className="pb-2 pl-2">
								<CardBody>
									<CardTitle className="text-uppercase">Vendor Sales Date/Time</CardTitle>
									<ReactDatetime
										inputProps={{
											className: 'form-control custom-form-input',
											placeholder: fullDateTimeFormat
										}}
										value={uploadFormState.vendorSalesDateTime}
										onChange={(e) => handleDateChange('vendorSalesDateTime', e)}
										closeOnSelect={true}
										dateFormat="MM-DD-YYYY"
										timeFormat="HH:mm:ss"
									/>
									{
										(uploadButtonClicked && !isValidDate(uploadFormState.vendorSalesDateTime))
										&&
										<span className="text-danger">
											Invalid Vendor Sales Date/Time (required)
										</span>
									}
								</CardBody>
							</Row>
							<Row className="pb-2 pl-2">
								<CardBody>
									<CardTitle className="text-uppercase">Product Code</CardTitle>
									<Select
										options={productCodes}
										className="react-select max-width-75"
										classNamePrefix="react-select"
										name="productCode"
										value={productCodes.find((item) => item.value === uploadFormState.productCode)}
										onChange={(e) => handleDropdownChange('productCode', e)}
									/>
								</CardBody>
							</Row>
							<Row className="pb-2 pl-2">
								<CardBody>
									<CardTitle className="text-uppercase">Sold</CardTitle>
									<Select
										options={soldTypes}
										className="react-select max-width-75"
										classNamePrefix="react-select"
										value={soldTypes.find((item) => item.value === uploadFormState.sold)}
										onChange={(e) => handleDropdownChange('sold', e)}
									/>
								</CardBody>
							</Row>
							<Row className="pb-2 pl-2">
								<CardBody>
									<CardTitle className="text-uppercase">Timezone</CardTitle>
									<TimezoneDropdown/>
								</CardBody>
							</Row>
						</Col>
					</Row>
				</CardBody>
				<Row>
					<Col>
						<Button
							onClick={handleUploadClick}
							className="btn py-2 mt-3 ml-2 btn-success px-5"
							disabled={isFormDataInvalid && uploadButtonClicked}
							name="uploadTransaction"
							id="uploadTransaction"
						>
							Upload
						</Button>
					</Col>
				</Row>
			</Card>
			<ConfirmUploadTransaction
				uploadFormData={uploadFormState}
				reset={resetForm}
			/>
		</div>
	)
}

export default UploadSingleStandard