import React from 'react'

const H4 = (props: {children: React.ReactNode}) => {
	return (
		<h4 className="text-pulse-label-gray">
			{props.children}
		</h4>
	)
}

export default H4
