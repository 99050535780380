import {useEffect, useState} from 'react'
import useGetMainProductStates from './useGetMainProductStates'

// TODO This can just be a recoil selector, much simpler than 2 hooks
export default function useSummaryCardColor() {
	const [color, setColor] = useState<string>('dark')
	const {isProductRp, isProductEp} = useGetMainProductStates()

	useEffect(() => {
		let next = 'dark'

		if (isProductRp) {
			next = 'rp'
		}

		if (isProductEp) {
			next = 'ep'
		}

		setColor(next)
	}, [isProductEp, isProductRp])

	return color
}
