import React, {useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import classNames from 'classnames'
import {useLocation} from 'react-router-dom'
import {
	Navbar,
	Container,
	NavbarBrand,
	Button,
	Nav,
	Collapse,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	NavLink,
	DropdownItem
} from 'reactstrap'
import {dashboardTitleState, isSidebarOpenState} from '../../state/ui'
import {LoggedInUser} from '../Auth/LoggedInUser'
import {getSsoUrl} from '../../services/user-service'
import logoUrl from '../../assets/img/PG_Logo_white.svg'


const DashboardNavbar = () => {
	const [collapseOpen, setCollapseOpen] = useState<boolean>(false)
	const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState)
	const dashboardTitle = useRecoilValue(dashboardTitleState)
	const location = useLocation()

	const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen)
	const toggleCollapse = () => setCollapseOpen(!collapseOpen)
	const profileRedirect = () => {
		window.location.href = getSsoUrl() ?? '#'
	}
	const setDefaultRoute = () => {
		localStorage.setItem('pg-dashboard-default-route', location.pathname)
	}

	return (
		<>
			<Navbar
				className={classNames('navbar-absolute', 'bg-dark', {
					'navbar-transparent': !collapseOpen,
					'bg-white': collapseOpen
				})}
				style={{position: 'fixed'}}
				expand="lg"
			>
				<Container fluid>
					<div className="navbar-wrapper">
						<div className="navbar-minimize d-inline">
							<Button
								className="minimize-sidebar btn-just-icon"
								color="link"
								onClick={toggleSidebar}
							>
								<i className={classNames('tim-icons', 'icon-align-center', {
									'd-none': !isSidebarOpen,
									'd-inline': isSidebarOpen
								})}/>
								<i className={classNames('tim-icons', 'icon-bullet-list-67', {
									'd-none': isSidebarOpen,
									'd-inline': !isSidebarOpen
								})}/>
							</Button>
						</div>
						<div
							className={classNames('navbar-toggle d-inline', {
								toggled: isSidebarOpen
							})}
						>
							<button
								className="navbar-toggler"
								type="button"
								onClick={toggleSidebar}
							>
								<span className="navbar-toggler-bar bar1"/>
								<span className="navbar-toggler-bar bar2"/>
								<span className="navbar-toggler-bar bar3"/>
							</button>
						</div>
						<NavbarBrand href="/">
							<img src={logoUrl} alt="Protect Group" height={50} width={100}/>
						</NavbarBrand>
						<NavbarBrand className="d-none d-md-inline text-white">
							{dashboardTitle}
						</NavbarBrand>
					</div>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navigation"
						aria-expanded="false"
						aria-label="Toggle navigation"
						onClick={toggleCollapse}
					>
						<span className="navbar-toggler-bar navbar-kebab"/>
						<span className="navbar-toggler-bar navbar-kebab"/>
						<span className="navbar-toggler-bar navbar-kebab"/>
					</button>
					<Collapse navbar isOpen={collapseOpen}>
						<Nav className="ml-auto" navbar>
							<UncontrolledDropdown nav>
								<DropdownToggle
									caret
									nav
									color="default"
									data-toggle="dropdown"
								>
									<LoggedInUser/>
								</DropdownToggle>
								<DropdownMenu className="dropdown-navbar" right tag="ul">
									<NavLink tag="li">
										<DropdownItem
											className="nav-item"
											onClick={profileRedirect}
										>
											Profile
										</DropdownItem>
										<DropdownItem
											className="nav-item"
											onClick={setDefaultRoute}
										>
											Set this as your home page
										</DropdownItem>
									</NavLink>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		</>
	)
}

export {DashboardNavbar}
