import React from 'react'
import {toGbp} from '../../../utils/number'
import {protectFinancialFilterState} from '../../../state/protect-financial'
import {ProtectFinancialTable} from './ProtectFinancialTable'
import {useProtectFinancialApi} from '../../../hooks/api/protect-financial'
import {useApiValue} from '../../../hooks/api'
import {TableColumn} from 'react-data-table-component'
import {OnboardedDataModel} from '../../../types/protect-financial'

const columns: TableColumn<OnboardedDataModel> [] = [
	{
		name: 'BDM',
		selector: ({user}) => user
	},
	{
		name: 'Company',
		selector: ({members}) => members
	},
	{
		name: 'Employees Enrolled',
		selector: ({enrolledUsers}) => enrolledUsers
	},
	{
		name: 'Monthly Gross Salary',
		selector: ({monthlyGrossSalary}) => monthlyGrossSalary,
		format: (row: any) => toGbp(row['monthlyGrossSalary'])
	},
	{
		name: 'Monthly Revenue',
		selector: ({monthlyRevenue}) => monthlyRevenue,
		format: (row: any) => toGbp(row['monthlyRevenue'])
	},
	{
		name: 'YTD Revenue',
		selector: ({yearToDateRevenue}) => yearToDateRevenue,
		format: (row: any) => toGbp(row['yearToDateRevenue'])
	}
]

const OnboardedTable = () => {
	const {getOnboardedData} = useProtectFinancialApi()
	const {data} = useApiValue(protectFinancialFilterState, getOnboardedData, [])
	return (
		<ProtectFinancialTable
			title="Live - Actual"
			columns={columns}
			selector={data}/>
	)
}

export {OnboardedTable}