import React, {useCallback, useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {selectedMemberIdState} from '../../../../../state/pulse-filters'
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/react'
import {membersState, userDetailState} from '../../../../../state/pulse'
import {debounce} from 'underscore'
import {userHasRoleState} from '../../../../../state/auth'
import {Roles} from '../../../../../types'

const MemberFilter = () => {
	const members = useRecoilValue(membersState)
	const [memberId, setMemberId] = useRecoilState(selectedMemberIdState)
	const selectedMember = members.filter(x => x.id === memberId)
	const userDetail = useRecoilValue(userDetailState)
	const [filter, setFilter] = useState('')
	const [tempFilter, setTempFilter] = useState('')
	const userPulseLeadershipRole = useRecoilValue(userHasRoleState(Roles.PulseDashboardLeadershipUser))

	const handleFilterChange = (event: any) => {
		setTempFilter(event.target.value)
		debounceFilter(event.target.value)
	}

	const debounceFilter = useCallback(
		debounce((_searchVal: string) => {
			setFilter(_searchVal)
		}, 1000),
		[]
	)

	const tierMembers = members
		.filter((member) =>
			member.id !== userDetail.memberId &&
			member.name.toLowerCase().includes(filter.toLowerCase()) &&
			member.tierId === 1)

	return (<>
			<Popover className="w-full">
				<PopoverButton className="
					items-center inline-flex justify-center max-w-[400px] w-full
					outline-none"
				>
					<div className="
						flex mb-2 mt-4 pl-3 pr-2 py-[3px] w-full
						bg-neutral-200
						text-nowrap text-sm tracking-[1px] uppercase
						rounded-full
						cursor-pointer
						hover:bg-pulse-button-blue
						lg:mb-0"
					>
						{memberId && members && (
							<span className="
								flex-1
								leading-5 text-left text-sm uppercase"
							>
								{selectedMember.length > 0 && selectedMember[0].name}
							</span>
						)}
						<span className="
							align-middle ml-1
							leading-5
							material-symbols-outlined"
						>
							keyboard_arrow_down
						</span>
					</div>
				</PopoverButton>
				<PopoverPanel anchor="bottom start" className="z-20 text-xs">
					{({close}) => (
						<div className="
							flex flex-col m-[2px] mt-[5px] h-[400px] relative w-[var(--button-width)]
							bg-white
							border border-pulse-popup-border-gray rounded-lg"
						>
							<div className="inline-block mb-2 mt-3 w-full">
								<div className="mx-2 relative">
									<span className="
										absolute bottom-0 right-3 top-1
										text-2xl text-pulse-icon-gray
										material-symbols-outlined"
									>
										search
									</span>
									<input
										type="text"
										id="search"
										value={tempFilter}
										onChange={handleFilterChange}
										className="
											pl-4 pr-4 py-2 w-full
											bg-[#f9f9f9]
											text-sm text-nowrap uppercase
											border border-pulse-popup-border-gray outline-none rounded-full
											lg:mb-0"
										autoComplete="off"
									/>
								</div>
							</div>
							<div className="
								flex flex-col flex-1 min-w-44
								overflow-y-auto
								uppercase text-sm tracking-[1px]"
							>
								{userDetail.memberId && (
									<div
										onClick={() => {
											setMemberId(userDetail.memberId || 0)
											close()
										}}
										className="
											px-6 mb-1
											cursor-pointer
											hover:bg-pulse-button-blue"
									>
										<div className="
											py-1
											border-b border-pulse-icon-gray"
										>
											<div>{userDetail.memberName}</div>
											<div className="
												mb-1
												text-pulse-icon-gray text-xs"
											>
												[All Revenue]
											</div>
										</div>
									</div>
								)}

								{tierMembers.length > 0 && userPulseLeadershipRole && (
									<>
										<div className="py-1 text-pulse-blue">
											{tierMembers
												.map((member) => (
													<div
														key={member.id}
														onClick={() => {
															setMemberId(member.id)
															close()
														}}
														className="
														px-6 py-1
														cursor-pointer
														hover:bg-pulse-button-blue"
													>
														{member.name}
													</div>
												))
											}
										</div>
										<div className="mb-3 mx-6 pt-1 border-b border-pulse-icon-gray" />
									</>
								)}

								{members
									.filter((member) =>
										member.id !== userDetail.memberId &&
										member.name
											.toLowerCase()
											.includes(filter.toLowerCase()))
									.map((member) => (
										<div
											key={member.id}
											onClick={() => {
												setMemberId(member.id)
												close()
											}}
											className="
												px-6 py-1
												cursor-pointer
												hover:bg-pulse-button-blue"
										>
											{member.name}
										</div>
									))}
							</div>
						</div>
					)}
				</PopoverPanel>
			</Popover>
		</>
	)
}

export default MemberFilter
