import axios, {AxiosError, AxiosInstance, RawAxiosRequestHeaders} from 'axios'
import {RecoilValueReadOnly, useRecoilValue} from 'recoil'
import {useEffect, useState} from 'react'
import queryString from 'query-string'

const allCapsAlpha = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']
const allLowerAlpha = [...'abcdefghijklmnopqrstuvwxyz']
const allNumbers = [...'0123456789']

const base = [...allCapsAlpha, ...allNumbers, ...allLowerAlpha]

const generator = (base: any[], len: number): string => {
	return [...Array(len)]
		.map(_ => base[Math.random() * base.length | 0])
		.join('')
}

export const generateSessionKey = () => generator(base, 32)

let axiosClient: AxiosInstance | null = null
let tokenState: ApiTokenState | undefined

export const apiHost = process.env.REACT_APP_API_HOST

interface ApiRequest<TRequest = any> {
	readonly headers?: RawAxiosRequestHeaders
	readonly method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
	readonly query?: Record<string, any>
	readonly path: string
	readonly requestData?: TRequest
	readonly signal?: AbortSignal
}

interface ApiActions {
	callApi: <TResponse = any, TRequest = any>(request: ApiRequest<TRequest>) => Promise<TResponse>
}

interface ApiCore {
	getUser: () => string
	setUser: (user: string) => void
}

interface ApiTokenState {
	token: string,
	sessionKey: string,
	user: string
}

const useAxiosClient = (): AxiosInstance => {
	const {getUser} = useApiCore()

	if (axiosClient) {
		return axiosClient
	}

	axiosClient = axios.create({
		baseURL: apiHost,
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
		}
	})

	axiosClient.interceptors.request.use(async (config) => {
		const authTokenFactory = async (): Promise<ApiTokenState | undefined> => {
			if (tokenState) {
				return tokenState
			}

			try {
				const sessionKey = generateSessionKey()
				const user = getUser()
				const response = await fetch(
					`${apiHost}/api/token`,
					{
						body: JSON.stringify({
							vendor: user,
							session: sessionKey
						}),
						method: 'POST'
					}
				)

				return {
					sessionKey,
					user,
					token: await response.text()
				}
			} catch (err) {
				console.error('Unable to retrieve token')
				return undefined
			}
		}

		tokenState = await authTokenFactory()

		if (tokenState && config?.headers) {
			config.headers.Authorization = `Bearer ${tokenState.token}`
			config.headers['x-session'] = tokenState.sessionKey
			config.headers['x-vendor'] = tokenState.user
		}

		return config
	})

	return axiosClient
}

const prefixRoute = (basePath: string, route: string): string => {
	return `${apiHost}/api/${basePath}/${route}`
}

const buildPath = <TRequest = any>(basePath: string, request: ApiRequest<TRequest>): string => {
	if (!!request.query) {
		return `${prefixRoute(basePath, request.path)}?${queryString.stringify(request.query)}`
	}

	return prefixRoute(basePath, request.path)
}

export const useApiActions = (basePath: string): ApiActions => {
	const client = useAxiosClient()

	const callApi = <TResponse = any, TRequest = any>(request: ApiRequest<TRequest>): Promise<TResponse> => {
		return new Promise((resolve, reject) => {
			client
				.request<TResponse>({
					url: buildPath(basePath, request),
					method: request.method ?? 'GET',
					data: request.requestData,
					responseType: 'json',
					signal: request.signal,
					headers: request.headers
				})
				.then((response) =>
					response?.status && response.status >= 200 && response.status < 400
						? resolve(response?.data)
						: reject(response?.data)
				)
				.catch((error: AxiosError) => reject(error.response ?? error.message))
		})
	}

	return {callApi}
}

export const useApiCore = (): ApiCore => {
	const getUser = (): string => unHash(sessionStorage.getItem('pgd:u') ?? '')
	const setUser = (user: string) => sessionStorage.setItem('pgd:u', hash(user.trim()))
	return {getUser, setUser}
}

const hash = (value: string): string => {
	const encoded = encodeURI(value)
	return btoa(encoded)
}

const unHash = (value: string): string => {
	const val = atob(value)
	return decodeURI(val)
}

export const useApiValue = <TState, TResult>(
	state: RecoilValueReadOnly<TState>,
	request: (state: TState) => Promise<TResult>,
	initialValue: TResult): {data: TResult, isLoading: boolean} => {

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [data, setData] = useState<TResult>(initialValue)
	const stateVal = useRecoilValue<TState>(state)

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true)
				const response = await request(stateVal)
				setData(response)
			} catch (err) {
				console.log(err)
			} finally {
				setIsLoading(false)
			}
		})()
	}, [stateVal])

	return {data, isLoading}
}

export const useStatelessApiValue = <TValue, TResult>(
	request: (value?: TValue) => Promise<TResult>,
	initialValue: TResult,
	value?: TValue
): {data: TResult, isLoading: boolean} => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [data, setData] = useState<TResult>(initialValue)

	useEffect(() => {
		(async () => {
			setIsLoading(true)
			try {
				const response = await request(value)
				setData(response)
			} catch (err) {
				console.error(err)
			} finally {
				setIsLoading(false)
			}
		})()
	}, [])
	
	return {data, isLoading}
}

