import React, {useState} from 'react'
import {useRecoilValue} from 'recoil'
import {Dialog} from '@headlessui/react'
import DefaultButton from './Common/Buttons/DefaultButton'
import {supportDetailState} from '../../../state/pulse'

const UpsellOverlay = () => {
	const [isOpen, setIsOpen] = useState(false)
	const supportDetail = useRecoilValue(supportDetailState)
	const commercialEmail = supportDetail.commercialEmail || process.env.REACT_APP_PULSE_SUPPORT_COMMERCIAL_EMAIL
	const handleModalOpen = () => setIsOpen(true)
	const handleModalClose = () => setIsOpen(false)

	const handleCommercialClick = () => {
		window.location.href = `mailto:${commercialEmail}?subject=Why is the Customer Trends Feature Not Visible?`
	}

	return (
		<>
			<div className="
				absolute bottom-0 left-0 right-0 top-0
				backdrop-blur-sm"
			>
				<div className="flex h-full items-center justify-center w-full">
					<div className="flex flex-col items-center justify-center">
						<p className="
							mb-3 w-10/12
							font-medium text-center text-sm"
						>
							Want to take your revenue to the next level?
						</p>
						<div
							className="
								flex items-center justify-center px-4 py-2
								bg-pulse-blue rounded-full
								text-white uppercase
								cursor-pointer"
							onClick={handleModalOpen}
						>
							<span className="mr-2 material-symbols-outlined ">lock</span>
							<span className="text-sm w-20">Unlock Feature</span>
						</div>
					</div>
				</div>
			</div>
			<Dialog
				open={isOpen}
				onClose={handleModalClose}
				className="relative z-50"
			>
				<div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
				<div className="
					fixed flex items-center justify-center p-4 w-screen
					inset-0"
				>
					<Dialog.Panel className="
						max-w-sm min-w-[90%] mx-auto px-6 py-12 relative
						bg-white rounded
						md:min-w-[50%]"
					>
						<div
							className="
								absolute right-4 top-4
								cursor-pointer"
							onClick={handleModalClose}
						>
							<span className="material-symbols-outlined">close</span>
						</div>
						<Dialog.Title className="
							mb-6 pb-6
							font-medium
							border-b border-pulse-icon-gray"
						>
							Why is the Customer Trends Feature Not Visible?
						</Dialog.Title>
						<Dialog.Description as="div">
							<p className="mb-4">
								The map feature is hidden because we haven’t received the geolocation data (e.g. customer city) from
								your transactions. This data is essential for displaying revenue markers on the map which unlocks
								valuable regional insights.
							</p>
							<p className="mb-4 font-bold">
								Why is this important?
							</p>
							<p className="mb-2">
								By providing location data, you can:
								<ul className="list-disc p-4">
									<li>Understand customer demand and segment data according to countries and regions.</li>
									<li>Tailor your offerings based on regional trends and preferences.</li>
									<li>Spot growth opportunities by identifying high-performing locations.</li>
								</ul>
							</p>
							<p>
								To enable this feature, please ensure your transaction data includes geolocation details.
								If you need assistance, contact our friendly Partnerships Team.
							</p>
							<p className="flex mt-8 space-x-2">
								<DefaultButton text="Contact Us" onClick={handleCommercialClick}/>
								<DefaultButton text="Close" onClick={handleModalClose}/>
							</p>
						</Dialog.Description>
					</Dialog.Panel>
				</div>
			</Dialog>
		</>
	)
}

export default UpsellOverlay
