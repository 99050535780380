import React, {ReactElement, useEffect} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {canUserSeeLinkState} from '../state/auth';
import {Dashboards} from '../types';
import {dashboardTitleState} from '../state/ui'
import {Navigate} from 'react-router-dom'

const ProtectedRoute = ({
                          children,
                          dashboard,
                          title
                        }: { children: ReactElement, dashboard: Dashboards, title: string }) => {
  const isAllowed = useRecoilValue(canUserSeeLinkState(dashboard))
  const setDashboardTitle = useSetRecoilState(dashboardTitleState)

  useEffect(() => {
    document.title = `Protect Group - ${title}`
    setDashboardTitle(title)
  }, [title])

  if (!isAllowed) {
    return <Navigate to="/login"/>
  }

  return children
}

export {ProtectedRoute};
