import {FixedHeightChartCard} from '../../../components/Card/FixedHeightChartCard'
import {ChartSummaryTable} from '../../../components/Dashboard/Charts/ChartSummaryTable'
import React from 'react'
import {Line} from 'react-chartjs-2'
import {useGetChartData} from '../../../hooks/useGetChartDataSummary'
import {filterState} from '../../../state/dynamic-performance'
import {CHART_HEIGHT} from './shared'
import {monthsInDateRangeState} from '../../../state/dynamic-data'
import {defaultChartData} from '../../../types/dynamic-performance'
import {useDynamicPerformanceApi} from '../../../hooks/api/dynamic'
import {useApiValue} from '../../../hooks/api'

const RevenueGeneratedChart = () => {
	const {getRevenueGeneratedData} = useDynamicPerformanceApi()
	const {data} = useApiValue(filterState, getRevenueGeneratedData, defaultChartData)
	const {
		chartOptions, getChartData, summaryData, formatSummary
	} = useGetChartData(data, monthsInDateRangeState)

	return (
		<FixedHeightChartCard
			title="Revenue Generated"
			titleClass="card-title-info"
			height={CHART_HEIGHT}
			summary={
				<ChartSummaryTable
					data={summaryData}
					format={formatSummary}
				/>
			}
		>
			<>
				<Line
					data={getChartData()}
					options={chartOptions}
					className="main-chart-max-height"
				/>
			</>
		</FixedHeightChartCard>
	);
}

export default RevenueGeneratedChart
