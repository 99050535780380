import {atom} from 'recoil'
import {
	IdEnum,
	MemberDataModel,
	UploadType,
	utc
} from '../types/upload'

const keyPrefix = 'upload'

export const selectedMemberUpload = atom<MemberDataModel | undefined>({
	key: `${keyPrefix}SelectedMember`,
	default: {} as MemberDataModel
})

export const selectedCurrencyUpload = atom<string>({
	key: `${keyPrefix}SelectedCurrency`,
	default: ''
})

export const selectedTimezoneUpload = atom<string>({
	key: `${keyPrefix}selectedTimezone`,
	default: utc
})

export const selectedProductTypeUpload = atom<number>({
	key: `${keyPrefix}SelectedProductType`,
	default: -1
})

export const selectedActionTypeUpload = atom<number>({
	key: `${keyPrefix}SelectedActionType`,
	default: IdEnum.DefaultId
})

export const selectedUploadTypeUpload = atom<UploadType>({
	key: `${keyPrefix}SelectedUploadType`,
	default: UploadType.Single
})

export const uploadConfirmModalOpen = atom<boolean>({
	key: `${keyPrefix}UploadConfirmModalOpen`,
	default: false
})
