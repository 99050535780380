import React from 'react'
import classNames from 'classnames'
import {Card, CardBody, CardTitle, CardFooter} from 'reactstrap'

export enum FilterCardTitlePosition {
	right,
	left
}

export interface FilterCardDisplayProps {
	title?: string;
	iconColor?: string;
	icon?: string;
	titlePosition?: FilterCardTitlePosition;
}

export interface FilterCardProps extends FilterCardDisplayProps {
	children: JSX.Element;
	footer?: React.ReactNode;
}

const FilterCard = (props: FilterCardProps) => {
	const {
		children, title, icon, iconColor = 'icon-primary', footer, titlePosition = FilterCardTitlePosition.left
	} = props

	return (
		<Card className="filter-card">
			<CardBody className="p-0">
				<div className="d-flex flex-row justify-content-between h-100">
					{
						(icon) && (
							<div className="d-none d-lg-flex justify-content-center align-items-center">
								<div className={classNames(
									'icon-section', 'd-flex', 'justify-content-center', 'align-items-center', iconColor)}>
									<i className={classNames('tim-icons', icon)}/>
								</div>
							</div>
						)
					}
					<div
						className={classNames(
							'px-3', 'py-1', 'd-flex', 'flex-fill', 'flex-column', 'align-items-start', {
								'align-items-md-end': titlePosition === FilterCardTitlePosition.right
							})}>
						<CardTitle className="text-uppercase">{title}</CardTitle>
						<div className="w-100">
							{children}
						</div>
					</div>
				</div>
			</CardBody>
			{footer && (
				<CardFooter>
					{footer}
				</CardFooter>
			)}
		</Card>
	)
}

export {FilterCard}
