import React, {useEffect, useState} from 'react'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {UploadType} from "../../../../types/upload";
import {selectedUploadTypeUpload} from "../../../../state/upload-data";

const UploadTypeDropdown = () => {
	
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	
	const next = Object.values(UploadType).map((value) => ({
		value,
		label: value
	}));
	
	useEffect(() => {
		setMenuItems(next)
	}, [])
	
	return (
		<StatefulSelectFilterCard
			title="Upload Type"
			items={menuItems}
			state={selectedUploadTypeUpload}
			showDefault={false}
		/>
	)
}

export {UploadTypeDropdown}