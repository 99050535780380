import {mapDetailColorState} from '../../../../state/member-data'
import {RegionSummary} from '../../../../types'
import React, {useEffect, useState} from 'react'
import {Button, Card, CardBody, Collapse} from 'reactstrap'
import RegionMapTable from './RegionMapTable'
import {ProductToggles} from './ProductToggles'
import RegionMap from './RegionMap'
import RegionMapCountryTable from './RegionMapCountryTable'
import useGetMainProductStates from '../../hooks/useGetMainProductStates'
import classNames from 'classnames'
import {useRecoilValue} from 'recoil'
import {useMemberApi} from '../../../../hooks/api/member'
import {memberFilterState} from '../../../../state/memberFilterState'
import {RegionIds, regionMapNames} from '../../../../types/region'

interface RegionMapLayoutProps {
	region: RegionIds,
}

const icons: Record<string, string> = {
	'true': 'icon-minimal-up',
	'false': 'icon-minimal-down'
}

const RegionMapLayout = (props: RegionMapLayoutProps) => {
	const {getRegionData} = useMemberApi()
	const {region} = props
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [mapData, setMapData] = useState<RegionSummary>({} as RegionSummary)
	const {isProductAll} = useGetMainProductStates()
	const filter = useRecoilValue(memberFilterState)
	const [iconNameState, setIconNameState] = useState('icon-minimal-down')
	const buttonColor: string = useRecoilValue(mapDetailColorState(region))

	const toggle = () => setIsOpen(!isOpen)

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true)
				const data = await getRegionData(filter, props.region)
				setMapData(data)
			} catch (err) {
				console.error(err)
			} finally {
				setIsLoading(false)
			}
		})()
	}, [filter, props.region])

	useEffect(() => {
		const next = icons[isOpen.toString()]
		setIconNameState(next)
	}, [isOpen])

	return (
		<div className={'custom-region-border'}>
			<Card className={classNames({
				'loading': isLoading
			})}>
				<CardBody>
					<RegionMapTable regionData={mapData} region={region}/>
					{
						isProductAll && (
							<ProductToggles region={region}/>
						)
					}
				</CardBody>
				{
					mapData?.countriesData && (
						<RegionMap
							regionData={mapData.countriesData}
							region={region}
							mapName={regionMapNames[region]}/>
					)
				}
				{
					(mapData?.countriesData && mapData.countriesData.length > 0) && (
						<CardBody>
							<div className="text-center">
								<Button
									color={buttonColor}
									onClick={toggle}
									className="mb-2"
									size="sm">Details <i className={`tim-icons ${iconNameState}`}/>
								</Button>
							</div>
							<Collapse isOpen={isOpen}>
								<RegionMapCountryTable
									regionData={mapData.countriesData}
									region={region}
								/>
							</Collapse>
						</CardBody>
					)
				}
			</Card>
		</div>
	)
}

export {RegionMapLayout}
