import {selector, selectorFamily} from 'recoil'
import {DateFilterToggle} from '../types'
import {selectedSalesTeamIdState} from './filters'
import {dateFilterToggles} from '../utils/date'

export const isSalesTeamSelectedState = selectorFamily({
	key: 'isSalesTeamSelectedState',
	get: (team: any) => ({get}) => {
		const selectedTeamId = get(selectedSalesTeamIdState)
		return selectedTeamId === team?.id
	}
})

export const dateFilterTogglesState = selector<DateFilterToggle[]>({
	key: 'dateFilterTogglesState',
	get: () => dateFilterToggles()
})
