import classNames from 'classnames'
import React from 'react'
import {FilterCardWrapperProps} from './props'

const FilterCardWrapper = (props: FilterCardWrapperProps) => {
	const {children, className = '', ...rest} = props

	return (
		<div className={classNames('flex-grow-1', className)} {...rest}>
			{children}
		</div>
	)
}

export default FilterCardWrapper
