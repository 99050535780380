import {usePulseApi} from '../../../hooks/api/pulse'
import {filterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {formatCurrency} from '../../../utils/currency'
import {ExportData} from '../../../types/pulse-data'
import {useExportData} from './useExportData'
import useGetGraphFilterLabel from './useGetGraphFilterLabel'
import {graphFilterType} from '../../../types/pulse'

export default function useExportOverviewData(): ExportData {
	const {
		getBookingValueSummary,
		getRefundProtectValueSummary,
		getProfitValueSummary,
		getAverageConversionRate,
		getAverageTransactionRate,
		getTransactionRevenuePerDollar,
		getAverageTransactionRevenue
	} = usePulseApi()
	const filter = useRecoilValue(filterState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	const getData = async (): Promise<any> => {
		return [
			{
				Property: 'Gross Booking Sales',
				Value: formatCurrency(coreCurrency, await getBookingValueSummary(filter))
			},
			{
				Property: 'Refund Protect Sales',
				Value: formatCurrency(coreCurrency, await getRefundProtectValueSummary(filter))
			},
			{
				Property: getGraphFilterLabel(graphFilterType.YourRevenue),
				Value: formatCurrency(coreCurrency, await getProfitValueSummary(filter))
			},
			{
				Property: 'Conversion Rate',
				Value: `${await getAverageConversionRate(filter)}%`
			},
			{
				Property: 'Average Price to Customers',
				Value: `${await getAverageTransactionRate(filter)}%`
			},
			{
				Property: 'Average Gross Revenue per Transaction',
				Value: formatCurrency(coreCurrency, await getAverageTransactionRevenue(filter), 0, 2)
			},
			{
				Property: getGraphFilterLabel(graphFilterType.RevenuePerDollar),
				Value: formatCurrency(coreCurrency, await getTransactionRevenuePerDollar(filter), 0, 4)
			},
		]
	}

	const getFileName = () => `Overview-${moment().toISOString()}`
	const getHeaders = () => ['Property', 'Value']

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
