import React, {memo} from 'react'
import {Bar} from 'react-chartjs-2'
import {useRecoilValue} from 'recoil'
import {ChartHeightValue, tooltipList} from '../../../../utils/chart'
import {ChartCard} from '../../../../components/Card/ChartCard'
import {selectedCoreCurrencyStateInsurance} from '../../../../state/insurance-provider-filters'
import {useGetBarChartData} from '../../hooks/useGetBarChartData'
import {ChartSummaryTable} from '../../../../components/Dashboard/Charts/ChartSummaryTable'
import {useInsuranceApi} from '../../../../hooks/api/insurance'

const PremiumByRegionChart = memo(() => {
	const {getPremiumByRegionChartData} = useInsuranceApi()
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateInsurance)
	const {
		getChartData,
		chartOptions,
		summaryData,
		summaryFormat
	} = useGetBarChartData(getPremiumByRegionChartData)

	return (
		<ChartCard
			title={`Premium by region (${coreCurrencyState})`}
			titleClass="card-title"
			tooltip={tooltipList.TransactionValue}
			summary={
				<ChartSummaryTable
					data={summaryData}
					format={summaryFormat}
				/>
			}
		>
			<Bar
				data={getChartData()}
				width={100}
				height={ChartHeightValue['small']}
				options={chartOptions}
			/>
		</ChartCard>
	)
})

export {PremiumByRegionChart}
