import React from 'react'
import {createRoot} from 'react-dom/client'
import './index.css'
import './assets/css/member.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './assets/css/nucleo-icons.css'

const element = document.getElementById('root')

if (element) {
	const root = createRoot(element)
	root.render(
		<React.StrictMode>
			<App/>
		</React.StrictMode>
	)
}

serviceWorker.unregister()
