import {Col, Row} from 'reactstrap'
import React from 'react'
import {Revenue} from './Revenue'
import {Conversion} from './Conversion'
import {GrowthRevenue} from './GrowthRevenue'
import {MembersCount} from './MembersCount'
import {AverageGrowth} from './AverageGrowth'
import {RevenuePerDollar} from './RevenuePerDollar'

export const Summary = () => {
	return (
		<>
			<Row>
				<Col className="pb-1">
					<Revenue/>
				</Col>
				<Col className="pb-1">
					<Conversion/>
				</Col>
				<Col className="pb-1">
					<GrowthRevenue/>
				</Col>
				<Col className="pb-1">
					<MembersCount/>
				</Col>
				<Col className="pb-1">
					<AverageGrowth/>
				</Col>
				<Col className="pb-1">
					<RevenuePerDollar/>
				</Col>
			</Row>
		</>
	)
}