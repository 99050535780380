import ReactTooltip from 'react-tooltip'
import React, {useState} from 'react'

interface TooltipProps {
	id: string,
	tooltipTitle: string,
	tooltipText: string
	tooltipClassName?: string
}

const Tooltip = (props: TooltipProps) => {
	// react js tooltip bug: Depending on use case the tooltip may not disappear onMouseLeave.
	// the following is a fix to work around this issue.
	const [tooltip, showTooltip] = useState(true)
	const tooltipClassName = props.tooltipClassName || 'absolute right-[6px] top-[6px]'

	return (<>
		<span
			data-tip=""
			data-for={props.id}
			className={tooltipClassName}
			onMouseEnter={() => showTooltip(true)}
			onMouseLeave={() => {
				showTooltip(false)
				setTimeout(() => showTooltip(true), 50)
			}}
		>
        <span className="
          text-base text-pulse-icon-gray
          cursor-pointer
          material-symbols-outlined"
        >
	        info
				</span>
    </span>
		{tooltip && (
			<ReactTooltip
				id={props.id}
				className="overflow-hidden whitespace-pre-wrap rounded-lg"
			>
				<p className="font-medium my-2 w-[200px]">{props.tooltipTitle}</p>
				<p className="my-2 w-[200px]">{props.tooltipText}</p>
			</ReactTooltip>)}
	</>)
}

export default Tooltip
