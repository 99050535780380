import React, {memo} from 'react'
import {ValueCard} from '../../../components/Card/ValueCard'
import {commissionFilterState} from '../../../state/commission-data'
import {useRecoilValue} from 'recoil'
import {formattedSelectedUserNameState} from '../../../state/filters'
import {toUsd} from '../../../utils/number'
import {useCommissionApi} from '../../../hooks/api/commission'
import {useApiValue} from '../../../hooks/api'

const UserCommissionTotal = memo(() => {
	const userName = useRecoilValue(formattedSelectedUserNameState)
	const {getUserTotalCommission} = useCommissionApi()
	const {data} = useApiValue(commissionFilterState, getUserTotalCommission, 0)

	return (
		<ValueCard
			title={`${userName} Total`}
			value={`${toUsd(data.toString(), 0)}`}
			icon="icon-coins"
		/>
	)
})

export {UserCommissionTotal}
