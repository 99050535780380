import React, {useEffect, useState} from 'react'
import {graphFilterType} from '../../../../../types/pulse'
import {useRecoilValue} from 'recoil'
import {selectedGraphFiltersState} from '../../../../../state/pulse-filters'
import GraphFilterField from './GraphFilterField'

interface GraphFilterCardProps {
	title: string,
	types: graphFilterType[]
}

const GraphFilterCard = (props: GraphFilterCardProps) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const graphFilters = useRecoilValue(selectedGraphFiltersState)

	useEffect(() => {
		props.types.forEach((type) => {
			if (graphFilters.includes(type)) {
				setIsExpanded(true)
			}
		})
	}, [graphFilters])

	return (<>
		<div className="cursor-pointer font-medium text-black" onClick={() => setIsExpanded(!isExpanded)}>
			{isExpanded && (
				<span className="
					align-middle mr-2
					text-base
					material-symbols-outlined"
				>
					remove
				</span>)
			}
			{!isExpanded && (
				<span className="
					align-middle mr-2
					text-base
					material-symbols-outlined"
				>
					add
				</span>
			)}
			<span className="
				align-middle
				text-sm uppercase"
			>
				{props.title}
			</span>
		</div>
		{isExpanded && (
			<div className="
				p-3 relative space-y-5 w-full
				bg-neutral-200
				text-sm
				rounded-lg"
			>
				<div className="flex-col gap-x-6 space-y-2">
					{props.types.map(type => (
						<GraphFilterField key={type.toString()} type={type} types={props.types}/>
					))}
				</div>
			</div>
		)}
	</>)
}

export default GraphFilterCard
