import React from 'react'
import Tooltip from '../Tooltip/Tooltip'
import {v4 as uuidv4} from 'uuid'
import LoadingSpinner from '../Spinner/LoadingSpinner'

interface TrustPilotValueCardProps {
	header: string,
	tooltipText: string,
	value: string,
	isLoading: boolean
}

const TrustPilotValueCard = (props: TrustPilotValueCardProps) => {
	const id = uuidv4()

	return (
		<div className="
			flex flex-col h-full min-h-20 px-5 py-3 relative
			bg-white
			border border-gray-200 rounded-lg"
		>
			<LoadingSpinner isLoading={props.isLoading} className="h-full w-full">
				<>
					<div className="
						flex-1 mb-1
						text-pulse-label-gray text-xs"
					>
						{props.header}
					</div>
					<div className="flex">
						<div>
							<div className="
								mb-1
								text-bold text-pulse-blue text-xl"
							>
								{props.value}
							</div>
							<Tooltip
								id={`${id}-popup`}
								tooltipTitle={props.header}
								tooltipText={props.tooltipText}
							/>
						</div>
					</div>
				</>
			</LoadingSpinner>
		</div>
	)
}

export default TrustPilotValueCard
