import React, {useEffect, useState} from 'react'
import {selectedMemberStateMember} from '../../../../state/member-filters'
import {Member} from '../../../../types'
import {FilterEnum} from '../../Enums/FilterEnum'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {useMemberApi} from '../../../../hooks/api/member'
import {useApiValue} from '../../../../hooks/api'
import {memberFilterState} from '../../../../state/memberFilterState'

const MemberDropdown = () => {
	const {getMembers} = useMemberApi()
	const {data, isLoading} = useApiValue(memberFilterState, getMembers, [])
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])

	useEffect(() => {
		if (data.length > 0 && !isLoading) {
			const next: SelectFilterItem[] = [
				{label: 'All', value: FilterEnum.DefaultFilterAll},
				...data?.map((x: Member) => (
					{
						value: x.id,
						label: x.name
					}
				))
			]
			setMenuItems(next)
		}
	}, [data, isLoading])

	return (
		<StatefulSelectFilterCard
			state={selectedMemberStateMember}
			title="Member"
			items={menuItems}
			defaultValue={-1}
		/>
	)
}

export {MemberDropdown}
