export interface Member {
	memberId: number
	memberName: string
}

export interface SalesRegion {
	salesRegionId: number
	name: string
}

export enum TestResult {
	good = 1,
	ok = 2,
	warning = 3,
	fail = 4
}
