import React, {useEffect, useState} from 'react'
import SelectFilterCard, {SelectFilterItem} from '../../../components/Dashboard/SelectFilter'
import {useRecoilState} from 'recoil'
import {selectedMemberIdState} from '../../../state/dynamic-data'
import {useDynamicDataApi} from '../../../hooks/api/dynamic'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/dynamic-performance'

const MemberFilter = () => {
	const [items, setItems] = useState<SelectFilterItem[]>([])
	const {getMembers} = useDynamicDataApi()
	const {data} = useApiValue(filterState, getMembers, [])
	const [memberId, setMemberId] = useRecoilState(selectedMemberIdState)

	useEffect(() => {
		if (data) {
			setItems(data.map(x => ({label: x.memberName, value: x.memberId})))
		}
	}, [data])

	const handleChange = (item: SelectFilterItem) => setMemberId(item.value)

	return (
		<SelectFilterCard
			title="Member"
			items={items}
			onChange={handleChange}
			value={memberId}
		/>
	)
}

export default MemberFilter
