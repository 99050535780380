import React from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {integrationFilterState} from '../../../../state/integration'
import {formatCurrency} from '../../../../utils/currency'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'
import {defaultMrrSummary} from '../../../../types/integration'

const ExpectedMrrSummary = () => {
	const {getMrrSummary} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getMrrSummary, defaultMrrSummary)

	return (
		<ValueCard
			title={`Average Expected MRR`}
			value={formatCurrency('USD', data.estimatedMrr ?? 0)}
			icon="icon-money-coins"
			tooltip={'Average Expected MRR'}
		/>
	)
}

export {ExpectedMrrSummary}
