import React from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {integrationFilterState} from '../../../../state/integration'
import {defaultStatusData} from '../../../../types/integration'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'

const StatusCancelledSummary = () => {
    const {getStatusSummary} = useIntegrationApi()
    const {data} = useApiValue(integrationFilterState, getStatusSummary, defaultStatusData)

    return (
        <ValueCard
            title={`Cancelled integrations`}
            value={data.cancelled}
            icon="icon-check-2"
            tooltip={'Total count of cancelled integrations'}
        />
    )
}

export {StatusCancelledSummary}
