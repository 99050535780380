import React from 'react'
import SecondaryValueCard from './Common/Cards/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {Currency} from './Common/FormatElements'

const OverviewApprovedAverageRefundValueSummary = () => {
	const {getAverageApprovedRefundValue} = usePulseApi()
	const value = useApiValue(filterState, getAverageApprovedRefundValue, 0)

	return (
		<SecondaryValueCard
			value={<Currency value={value.data} max={4}/>}
			header="Average Refund Amount"
			tooltipText="Average value of approved refunds."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewApprovedAverageRefundValueSummary
