import React from 'react'
import {ChartCard, ChartCardProps} from './ChartCard'

interface FixedHeightChartCardProps extends ChartCardProps {
	height: number,
}

const FixedHeightChartCard = (props: FixedHeightChartCardProps) => {
	const {height, children, ...rest} = props
	return (
		<div style={{
			height: height
		}}>
			<ChartCard {...rest}>
				{children}
			</ChartCard>
		</div>
	)
}

export {FixedHeightChartCard}
