import {ChartDataset, ChartOptions, TooltipItem} from 'chart.js'
import {PulseTableDataModel} from '../../../types/pulse-data'
import {graphFilterColor, graphFilterType} from '../../../types/pulse'
import {formatCurrency} from '../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import useGetGraphFilterLabel from './useGetGraphFilterLabel'
import {formatNumberMember} from '../../../utils/number'

export default function useGetOverviewChartData(recoilValue: { data: PulseTableDataModel, isLoading: boolean }) {
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	const getRevenueConversionRateOptions = () => {
		const options: ChartOptions<'line'> = {
			scales: {
				x: {
					grid: {
						display: false
					},
				},
				y0: {
					display: true,
					position: 'left',
					ticks: {
						callback: function (value) {
							return formatCurrency(coreCurrency, parseFloat(value.toString()))
						}
					},
					type: 'linear'
				},
				y1: {
					display: true,
					position: 'right',
					grid: {
						drawOnChartArea: false
					},
					ticks: {
						callback: function (value) {
							return value + '%'
						}
					},
					type: 'linear',
					suggestedMin: 0
				}
			},
			plugins: {
				legend: {
					labels: {
						usePointStyle: true,
						padding: 30
					}
				},
				tooltip: {
					enabled: true,
					mode: 'index',
					bodySpacing: 8,
					boxPadding: 4,
					padding: 16,
					titleMarginBottom: 8,
					callbacks: {
						label: (tooltip: TooltipItem<'line'>) => {
							if (tooltip.dataset.label === getGraphFilterLabel(graphFilterType.YourRevenue)) {
								return `${tooltip.dataset.label}: ${formatCurrency(coreCurrency, tooltip.raw as any)}`
							}

							if (tooltip.dataset.label?.startsWith(getGraphFilterLabel(graphFilterType.AverageConversionRate))) {
								return `${getGraphFilterLabel(graphFilterType.AverageConversionRate)}: ${tooltip.raw}%`
							}

							return `${tooltip.dataset.label}: ${tooltip.raw}`
						}
					}
				}
			}
		}

		return options
	}

	const getVolumeOptions = () => {
		const options: ChartOptions<'line'> = {
			scales: {
				x: {
					grid: {
						display: false
					},
				}
			},
			plugins: {
				legend: {
					labels: {
						usePointStyle: true,
						padding: 30
					}
				},
				tooltip: {
					enabled: true,
					mode: 'index',
					bodySpacing: 8,
					boxPadding: 4,
					padding: 16,
					titleMarginBottom: 8,
					callbacks: {
						label: (tooltip: TooltipItem<'line'>) => {
							return `${tooltip.dataset.label}: ${formatNumberMember(tooltip.raw as any)}`
						}
					}
				}
			}
		}

		return options
	}

	const getLabels = () => recoilValue.data.labels

	const buildDataset = (filterType: graphFilterType,
	                      chartData: Record<number, number[]>,
	                      color: string = '',
	                      label: string = ''): ChartDataset<'line'> => {
		return {
			label: label ? label : getGraphFilterLabel(filterType),
			data: chartData[filterType],
			fill: false,
			borderColor: color ? color : graphFilterColor[filterType],
			backgroundColor: color ? color : graphFilterColor[filterType],
			borderWidth: 2,
			pointRadius: 2,
			tension: 0.3
		}
	}

	const getRevenueConversionRateDataset = () => {
		const datasets: ChartDataset<'line'>[] = []

		if (!recoilValue.data.chartData) {
			return []
		}

		datasets.push({
			...buildDataset(graphFilterType.YourRevenue, recoilValue.data.chartData),
			yAxisID: `y0`
		})

		datasets.push({
			...buildDataset(graphFilterType.AverageConversionRate,
				recoilValue.data.chartData,
				'#0084C4',
				`${getGraphFilterLabel(graphFilterType.AverageConversionRate)} %`),
			yAxisID: `y1`
		})

		return datasets
	}

	const getVolumeDataset = () => {
		const datasets: ChartDataset<'line'>[] = []

		if (!recoilValue.data.chartData) {
			return []
		}

		datasets.push(buildDataset(graphFilterType.GrossBookingVolume, recoilValue.data.chartData))
		datasets.push(buildDataset(graphFilterType.RefundProtectVolume, recoilValue.data.chartData, '#23B77D'))

		return datasets
	}

	return {
		getLabels,
		getRevenueConversionRateDataset,
		getRevenueConversionRateOptions,
		getVolumeDataset,
		getVolumeOptions
	}
}
