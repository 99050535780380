import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {selectedCoreCurrencyState} from '../../../../state/sales'
import React from 'react'
import {coreCurrencyDropdownData} from '../../../../utils/data'

const menuItems = [
	...coreCurrencyDropdownData.map((x) => ({
		value: x.value,
		label: x.value
	}))
]

export const CurrencyFilter = () => {
	return (
		<StatefulSelectFilterCard
			title="Core Currency"
			items={menuItems}
			state={selectedCoreCurrencyState}
			showDefault={false}
		/>
	)
}
