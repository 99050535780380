import React, {useEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import {TableCard} from '../../../../components/Card/TableCard'
import {insuranceProviderFilterState} from '../../../../state/insurance-provider'
import {useInsuranceApi} from '../../../../hooks/api/insurance'
import {useApiValue} from '../../../../hooks/api'
import {defaultColumn} from '../../../../types/insurance-provider'
import {formatNumber} from '../../../../utils/number'

const LossTable = () => {
	const [columnsState, setColumnState] = useState([defaultColumn])
	const [tableData, setTableData] = useState<any[]>([])
	const {getLossTableData} = useInsuranceApi()
	const {data, isLoading} = useApiValue(insuranceProviderFilterState, getLossTableData, {})

	useEffect(() => {
		if (!isLoading
			&& typeof data.tableData !== 'undefined'
			&& typeof data.labels !== 'undefined') {
			const {
				tableData,
				labels
			} = data

			const tableKeys = Object.keys(tableData)
			const next = tableKeys?.map((x: any) => {
				return {
					label: x,
					...tableData[x].map((val: any) => {
						return `${formatNumber(val, 2)}%`
					})
				}
			})
			setTableData(next)

			const columns = [
				defaultColumn,
				...labels?.map((month: string, i: number) => {
					return {
						name: month,
						selector: (row: any) => row[i]
					}
				})
			]
			setColumnState(columns)
		}
	}, [data, isLoading])

	return (
		<TableCard
			title="Loss (USD)"
			titleClass="card-title"
			showToggle={true}
			defaultOpen={false}
		>
			<DataTable
				data={tableData}
				columns={columnsState}
				theme="dark"
			/>
		</TableCard>
	)
}

export {LossTable}