import {
	ChartData,
	Content,
	DynamicPerformanceFilter,
	DynamicPerformanceTableFilter,
	PricingAndContentData,
	RegionData,
	TransactionTableData
} from '../../types/dynamic-performance'
import {useApiActions} from '../api'
import {Member, SalesRegion} from '../../types/dynamic-data'

export const useDynamicPerformanceApi = () => {
	const {callApi} = useApiActions('dynamic-performance')

	const getAverageConversion = async (filter: DynamicPerformanceFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'average-conversion'
		})
	}

	const getAverageRate = async (filter: DynamicPerformanceFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'average-rate'
		})
	}

	const getContent = async (contentId: number): Promise<Content> => {
		return await callApi<Content, string>({
			path: `content/${contentId}`
		})
	}

	const getOpportunityValueData = async (filter: DynamicPerformanceFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'opportunity-value-data'
		})
	}

	const getOpportunityValueSummary = async (filter: DynamicPerformanceFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'opportunity-value-summary'
		})
	}

	const getPricingAndContentData = async (filter: DynamicPerformanceFilter): Promise<PricingAndContentData[]> => {
		return await callApi<PricingAndContentData[], string>({
			query: filter,
			path: 'pricing-and-content'
		})
	}

	const getRegionData = async (filter: DynamicPerformanceFilter, regionId: number): Promise<RegionData> => {
		return await callApi<RegionData, string>({
			query: filter,
			path: `region-data/${regionId}`
		})
	}

	const getRevenueByTimeBand = async (filter: DynamicPerformanceFilter): Promise<Record<string, any>> => {
		return await callApi<Record<string, any>>({
			query: filter,
			path: 'revenue-by-time-band'
		})
	}

	const getRevenueByValueBand = async (filter: DynamicPerformanceFilter): Promise<Record<string, any>> => {
		return await callApi<Record<string, any>, string>({
			query: filter,
			path: 'revenue-by-value-band'
		})
	}

	const getRevenueGeneratedData = async (filter: DynamicPerformanceFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'revenue-generated-data'
		})
	}

	const getRevenueGeneratedSummary = async (filter: DynamicPerformanceFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'revenue-generated-summary'
		})
	}

	const getRevenueValueData = async (filter: DynamicPerformanceFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'revenue-value-data'
		})
	}

	const getRevenueValueSummary = async (filter: DynamicPerformanceFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'revenue-value-summary'
		})
	}

	const getSalesValueData = async (filter: DynamicPerformanceFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'sales-value-data'
		})
	}

	const getSalesValueSummary = async (filter: DynamicPerformanceFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'sales-value-summary'
		})
	}

	const getSalesVolumeData = async (filter: DynamicPerformanceFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'sales-volume-data'
		})
	}

	const getSalesVolumeSummary = async (filter: DynamicPerformanceFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'sales-volume-summary'
		})
	}

	const getTransactions = async (filter: DynamicPerformanceTableFilter): Promise<TransactionTableData> => {
		return await callApi<TransactionTableData, string>({
			query: filter,
			path: 'transactions'
		})
	}

	return {
		getAverageConversion,
		getAverageRate,
		getContent,
		getOpportunityValueData,
		getOpportunityValueSummary,
		getPricingAndContentData,
		getRegionData,
		getRevenueByTimeBand,
		getRevenueByValueBand,
		getRevenueGeneratedData,
		getRevenueGeneratedSummary,
		getRevenueValueData,
		getRevenueValueSummary,
		getSalesValueData,
		getSalesValueSummary,
		getSalesVolumeData,
		getSalesVolumeSummary,
		getTransactions
	}
}

export const useDynamicDataApi = () => {
	const {callApi} = useApiActions('dynamic')

	const getMembers = async (filter: DynamicPerformanceFilter): Promise<Member[]> => {
		return await callApi<Member[], string>({
			query: filter,
			path: 'members'
		})
	}

	const getSalesRegions = async (filter: DynamicPerformanceFilter): Promise<SalesRegion[]> => {
		return await callApi<SalesRegion[], string>({
			query: filter,
			path: 'sales-regions'
		})
	}

	return {
		getMembers,
		getSalesRegions
	}
}
