import {useEffect, useState} from 'react'
import {useRecoilValue} from 'recoil'
import {userFilterState} from '../../../state/protect-financial'

export default function useGetFilteredData(data: any[]) {
	const filter = useRecoilValue(userFilterState)
	const [filteredData, setFilteredData] = useState<any[]>([])

	useEffect(() => {
		let next = data
		if (filter.length > 1 && data.length) {
			next = data.filter(x => {
				const {user} = x
				return user.toLowerCase().indexOf(filter.toLowerCase()) > -1
			})
		}
		setFilteredData(next)
	}, [data, filter])

	useEffect(() => {
		setFilteredData(data)
	}, [data])

	return {filteredData}
}