import {useApiActions} from './index'
import {Actor, DocumentFilter, DropdownData} from '../../types'

export const useDocumentApi = () => {
	const {callApi} = useApiActions('document')

	const getActors = async (filter: DocumentFilter): Promise<Actor[]> => {
		return await callApi<Actor[], string>({
			query: filter,
			path: 'actors'
		})
	}

	const getBlobInfo = async (filter: DocumentFilter): Promise<any> => {
		return await callApi<any, string>({
			query: filter,
			path: 'blobinfo'
		})
	}

	const getDocuments = async (filter: DocumentFilter): Promise<any> => {
		return await callApi<any, string>({
			query: filter,
			path: 'documents'
		})
	}

	const getDocumentTypes = async (filter: DocumentFilter): Promise<DropdownData[]> => {
		return await callApi<DropdownData[], string>({
			query: filter,
			path: 'document-types'
		})
	}

	const getMemberIds = async (filter: DocumentFilter): Promise<number[]> => {
		return await callApi<number[], string>({
			query: filter,
			path: 'memberIds'
		})
	}

	return {
		getActors,
		getBlobInfo,
		getDocuments,
		getDocumentTypes,
		getMemberIds
	}
}
