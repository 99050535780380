import React from 'react'
import {Col, Row} from 'reactstrap'
import {TypeDropdown} from './TypeDropdown'
import * as DashboardDateFilters from '../../../../components/Dashboard/DateFilters'
import {dateFromDocumentState, dateToDocumentState, documentFilterState} from '../../../../state/document-filters'
import {useRecoilValue} from 'recoil'
import {MemberDropdown} from './MemberDropdown'
import {InsurerDropdown} from './InsurerDropdown'
import {isUserInsurerState} from '../../../../state/document-data'
import {useDocumentApi} from '../../../../hooks/api/document'
import {useApiValue} from '../../../../hooks/api'

const Filters = () => {
	const isUserInsurer = useRecoilValue(isUserInsurerState)
	const {getMemberIds} = useDocumentApi()
	const {data} = useApiValue(documentFilterState, getMemberIds, [])

	return (
		<Row>
			<Col>
				<TypeDropdown/>
			</Col>
			{
				data?.length !== 0 &&
				<Col>
					<MemberDropdown/>
				</Col>
			}
			{
				isUserInsurer &&
				<Col>
					<InsurerDropdown/>
				</Col>
			}
			<Col>
				<DashboardDateFilters.DateFilters
					fromState={dateFromDocumentState}
					toState={dateToDocumentState}
				/>
			</Col>
		</Row>
	)
}

export {Filters}