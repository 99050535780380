import React from 'react'
import SelectFilterCard, {SelectFilterItem} from '../../../components/Dashboard/SelectFilter'

const AgeDemographicFilter = () => {
	const handleChange = (value: any) => {}

	return (
		<SelectFilterCard
			title="Age Demographic"
			items={items}
			onChange={handleChange}
		/>
	)
}

export default AgeDemographicFilter

const items: SelectFilterItem[] = [
	{value: 1, label: '18-25'},
	{value: 2, label: '26-40'}
]
