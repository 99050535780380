import {toGbp} from '../../../utils/number'
import React from 'react'
import {ProtectFinancialTable} from './ProtectFinancialTable'
import {protectFinancialFilterState} from '../../../state/protect-financial'
import {useProtectFinancialApi} from '../../../hooks/api/protect-financial'
import {useApiValue} from '../../../hooks/api'
import {TableColumn} from 'react-data-table-component'
import {ForecastDataModel} from '../../../types/protect-financial'

const columns: TableColumn<ForecastDataModel> [] = [
	{
		name: 'BDM',
		selector: ({user}) => user
	},
	{
		name: 'Company',
		selector: ({members}) => members
	},
	{
		name: 'Employees Enrolled',
		selector: ({enrolledUsers}) => enrolledUsers
	},
	{
		name: 'Monthly Gross Salary',
		selector: ({monthlyGrossSalary}) => monthlyGrossSalary,
		format: (row: any) => toGbp(row['monthlyGrossSalary'])
	},
	{
		name: 'Monthly Revenue',
		selector: ({monthlyRevenue}) => monthlyRevenue,
		format: (row: any) => toGbp(row['monthlyRevenue'])
	}
]

const ForecastTable = () => {
	const {getForecastData} = useProtectFinancialApi()
	const {data} = useApiValue(protectFinancialFilterState, getForecastData, [])
	return (
		<ProtectFinancialTable
			title="Forecast - Future"
			columns={columns}
			selector={data}/>
	)
}

export {ForecastTable}