import React, {useEffect, useState} from 'react'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {selectedActionTypeUpload, selectedProductTypeUpload} from "../../../../state/upload-data";
import {uploadFilterState} from "../../../../state/upload-filter";
import {UploadActionType} from "../../../../types";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {rpDynamicProduct} from "../../../../types/upload";
import {FilterCard} from "../../../../components/Card/FilterCard";
import {CardBody} from "reactstrap";
import {useUploadApi} from "../../../../hooks/api/upload";
import {useApiValue} from "../../../../hooks/api";

const ActionTypeDropdown = () => {

	const {getActionTypes} = useUploadApi();
	const {data, isLoading} = useApiValue(uploadFilterState, getActionTypes, [])
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const productType = useRecoilValue(selectedProductTypeUpload)
	const setActionTypeUpload = useSetRecoilState(selectedActionTypeUpload)
	
	useEffect(() => {
		let next;
		if (data.length > 0 && !isLoading) {
			if(productType !== rpDynamicProduct?.value) {
				next = [
					...data?.map((x: UploadActionType) => (
						{
							value: x.id,
							label: x.name
						}
					))
				]
				setMenuItems(next);
				return;
			}
			const postAction = data?.find((x: UploadActionType) => x.name === "New (POST)");
			next = {
				value: postAction?.id,
				label: postAction?.name
			} as SelectFilterItem;
			setActionTypeUpload(next.value);
			setMenuItems([next]);
		}
	}, [data, isLoading, productType])
	
	if (menuItems.length === 1) {
		return (
			<FilterCard
				title="Action Type"
			>
				<CardBody>
					{menuItems[0].label}
				</CardBody>
			</FilterCard>
		);
	}
	
	return (
		<StatefulSelectFilterCard
			state={selectedActionTypeUpload}
			title="Action Type"
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {ActionTypeDropdown}