import React from 'react'
import {
	partnersRevenueTablePricingModel,
	partnersRevenueTablePricingModelName
} from '../../../../../types/pulse'
import {useRecoilState, useResetRecoilState} from 'recoil'
import {
	partnersRevenueTablePageState,
	partnersRevenueTablePricingModelState
} from '../../../../../state/pulse'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'

const PartnersPricingModelDropdown = () => {
	const [pricingModel, setPricingModel] = useRecoilState(partnersRevenueTablePricingModelState)
	const resetPageState = useResetRecoilState(partnersRevenueTablePageState)

	const handleClick = (value: partnersRevenueTablePricingModel) => {
		setPricingModel(value)
		resetPageState()
	}

	return (
		<Listbox value={pricingModel} onChange={(value) => handleClick(value)}>
			<ListboxButton>
				<div className="
					mb-2 pl-3 pr-2 py-[2px]
					bg-white
					text-nowrap text-sm uppercase
					border border-pulse-icon-gray rounded-full
					cursor-pointer
					hover:bg-pulse-button-blue
					lg:mb-0"
				>
					<span className="leading-5 tracking-[1px]">Type</span>
					<span className="
						align-middle ml-1
						leading-5
						material-symbols-outlined"
					>
						keyboard_arrow_down
					</span>
				</div>
			</ListboxButton>
			<ListboxOptions
				className="
					min-w-[var(--button-width)] ml-4 mt-[5px] py-2 w-11/12 z-50
					bg-white
					border border-pulse-popup-border-gray rounded-lg
					lg:w-auto lg:ml-0"
				anchor="bottom start"
			>
				<DropdownOption pricingModel={partnersRevenueTablePricingModel.All}/>
				<DropdownOption pricingModel={partnersRevenueTablePricingModel.Dynamic}/>
				<DropdownOption pricingModel={partnersRevenueTablePricingModel.Static}/>
			</ListboxOptions>
		</Listbox>
	)
}

const DropdownOption = (props: {pricingModel: partnersRevenueTablePricingModel}) => {
	return (
		<ListboxOption
			value={props.pricingModel}
			className="
				px-4 py-2
				text-sm text-black tracking-[1px] uppercase
				cursor-pointer
				data-[focus]:bg-pulse-button-blue"
		>
			{partnersRevenueTablePricingModelName[props.pricingModel]}
		</ListboxOption>
	)
}

export default PartnersPricingModelDropdown
