import React from 'react'
import PrimaryValueCard from './Common/Cards/PrimaryValueCard'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'

const OverviewApprovedRefundValueSummary = () => {
	const {getApprovedRefundValueSummary, getApprovedRefundRateTrend} = usePulseApi()
	const value = useApiValue(filterState, getApprovedRefundValueSummary, 0)
	const trendRate = useApiValue(filterState, getApprovedRefundRateTrend, 0)

	return (
		<PrimaryValueCard
			value={value.data}
			trendValue={trendRate.data}
			header="Paid Refunds"
			tooltipText="Total amount paid out in refunds to customers."
			valueClassName="text-pulse-red"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewApprovedRefundValueSummary
