import React from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {useRecoilValue} from 'recoil'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'
import {formatCurrency} from '../../../../utils/currency'
import {formatNumberWithoutSeparator} from '../../../../utils/number'
import {selectedCoreCurrencyState} from '../../../../state/sales'
import useRevenueAverageGrowth from '../../hooks/useRevenueAverageGrowth'

export const GrowthRevenue = () => {
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyState)
	const color = useSummaryCardColor()
	const {totalGrowthRevenue} = useRevenueAverageGrowth()

	return (
		<ValueCard
			title={`Growth Revenue`}
			value={formatCurrency(coreCurrencyState, formatNumberWithoutSeparator(totalGrowthRevenue))}
			icon="icon-sound-wave"
			iconColor={color}
		/>
	)
}