import React from 'react'
import classNames from 'classnames'
import {RecoilRoot, useRecoilValue} from 'recoil'
import {BrowserRouter} from 'react-router-dom'
import {isSidebarOpenState} from './state/ui'
import {AppRoutes} from './routes'
import './assets/styles/App.scss'
import {initialState} from './state/initial'
import {MSClarityScript} from "./MSClarity";
import {GAnalyticsScript} from "./GAnalytics";

const Main = () => {
	const isSidebarOpen = useRecoilValue<boolean>(isSidebarOpenState)

	return (
		<div
			className={classNames('perfect-scrollbar-on', {
				'sidebar-mini': !isSidebarOpen,
				'nav-open': isSidebarOpen
			})}
		>
			<BrowserRouter>
				<AppRoutes/>
			</BrowserRouter>
		</div>
	)
}

function App() {
	return (
		<RecoilRoot initializeState={initialState}>
			<Main/>
			<MSClarityScript/>
			<GAnalyticsScript/>
		</RecoilRoot>
	)
}

export default App
