import React from 'react'
import {FilterCard} from '../../../components/Card/FilterCard'
import {Input} from 'reactstrap'
import {useRecoilState} from 'recoil'
import {userFilterState} from '../../../state/protect-financial'

const UserFilter = () => {
	const [filter, setFilter] = useRecoilState(userFilterState)

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)

	return (
		<FilterCard title="Filter Users" icon="icon-single-02">
			<div>
				<Input
					value={filter}
					onChange={handleChange}
				/>
			</div>
		</FilterCard>
	)
}

export {UserFilter}