import { ChartData } from '../types';
import {toUsd} from './number'
import {formatCurrency} from './currency'

export const defaultOptions = {
  maintainAspectRatio: false,
  legend: {
    display: true
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest'
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: '#9a9a9a'
        }
      }
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.1)',
          zeroLineColor: 'transparent'
        },
        ticks: {
          padding: 20,
          fontColor: '#9a9a9a'
        }
      }
    ]
  }
};

export const userChartOptions: any = {
  maintainAspectRatio: false,
  legend: {
    display: true
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
    callbacks: {
      label: (tooltipItem: any) => {
        return toUsd(tooltipItem.value, 0);
      }
    }
  },
  responsive: true,
  scales: {
    y: {
      display: true,
      position: 'left',
      ticks: {
        callback: (label: any) => formatCurrency('USD', label)
      }
    }
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context: { dataset: { label: string; }; parsed: { y: number; }; }) {
          return ` ${context.dataset.label}:  ${formatCurrency('USD', context.parsed.y)}`
        }
      }
    }
  }
};

type ChartColorsType = {
  backgroundColor: any,
  borderColor: string,
  pointBackgroundColor: string,
  pointHoverBackgroundColor: string
}

const blueColors: ChartColorsType = {
    backgroundColor: 'transparent',
    borderColor: '#1f8ef1',
    pointBackgroundColor: '#1f8ef1',
    pointHoverBackgroundColor: '#00d6b4'
}

const purpleColors: ChartColorsType = {
    backgroundColor: 'transparent',
    borderColor: '#8307f7',
    pointBackgroundColor: '#8307f7',
    pointHoverBackgroundColor: '#8307f7'
}

const greenColors: ChartColorsType = {
    backgroundColor: 'transparent',
    borderColor: '#00d6b4',
    pointBackgroundColor: '#00d6b4',
    pointHoverBackgroundColor: '#00d6b4'
}

const orangeColors: ChartColorsType = {
    backgroundColor: 'transparent',
    borderColor: '#F39F39',
    pointBackgroundColor: '#F39F39',
    pointHoverBackgroundColor: '#F39F39'
}

const pinkColors: ChartColorsType = {
    backgroundColor: 'transparent',
    borderColor: '#f562d5',
    pointBackgroundColor: '#f562d5',
    pointHoverBackgroundColor: '#f562d5'
}

const yellowColors: ChartColorsType = {
    backgroundColor: 'transparent',
    borderColor: '#d3f707',
    pointBackgroundColor: '#d3f707',
    pointHoverBackgroundColor: '#d3f707'
}

const redColors: ChartColorsType = {
    backgroundColor: 'transparent',
    borderColor: '#dc2424',
    pointBackgroundColor: '#dc2424',
    pointHoverBackgroundColor: '#dc2424'
}

const camouflageColors: ChartColorsType = {
    backgroundColor: 'transparent',
    borderColor: '#766D31',
    pointBackgroundColor: '#766D31',
    pointHoverBackgroundColor: '#766D31'
}

export const colors: Record<number, ChartColorsType> = {
  0: blueColors,
  1: purpleColors,
  2: greenColors,
  3: orangeColors,
  4: pinkColors,
  5: yellowColors,
  6: redColors,
  7: camouflageColors
};

export const buildChartData = (chartData: ChartData, member: string | undefined, months: string[]) => {
  const getDataset = (data: number[], label: string, colorIndex: number) => {
    const chartColors = colors[colorIndex];
    return {
      label: `${label}`,
      fill: true,
      backgroundColor: chartColors.backgroundColor,
      borderColor: chartColors.borderColor,
      borderWidth: 2,
      borderDash: [],
      borderDashOffset: 0.0,
      pointBackgroundColor: chartColors.pointBackgroundColor,
      pointBorderColor: 'rgba(255,255,255,0)',
      pointHoverBackgroundColor: chartColors.pointHoverBackgroundColor,
      pointBorderWidth: 20,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 15,
      pointRadius: 4,
      data: [...data]
    };
  };

  return {
    labels: months,
    datasets: [
      getDataset(chartData.revenue, 'Revenue', 0),
      getDataset(chartData.commission, 'Commission', 1)
    ]
  };
};

export const continentColorScales: Record<string, string[]> = {
  AF: ['#FFE9D4', '#FD932A'],
  AS: ['#FFE0E5', '#FA505D'],
  EU: ['#DBEDFF', '#0A51A4'],
  NA: ['#F9E3FF', '#E58CF5'],
  OC: ['#E0FEFF', '#39E8EF'],
  XX: ['#F9ECF9', '#951776'],
  SA: ['#D8F7F0', '#1EC39A'],
  AN: ['#FFE9D4', '#8639D9'],
};

export const backgroundColorList = [
  '#d12151',
  '#1382b2',
  '#9966fc',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)']


export const borderColorList = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)']

export const productChartColors = {
  EP: backgroundColorList[0],
  RP: backgroundColorList[1],
  All: backgroundColorList[2]
};

export const productChartBorderColors = {
  EP: borderColorList[0],
  RP: borderColorList[1],
  All: borderColorList[4]
};

export const borderColorListRp: Record<string, string> = {
  HTL: borderColorList[0],
  PKG: borderColorList[1],
  TKT: borderColorList[5],
  AVG: borderColorList[3],
  All: borderColorList[4],
};

export const backgroundColorListRp: Record<string, string> = {
  HTL: backgroundColorList[0],
  PKG: backgroundColorList[1],
  TKT: backgroundColorList[5],
  AVG: backgroundColorList[3],
  All: backgroundColorList[2],
};

export type ChartHeight = 'small' | 'medium' | 'large'

export const ChartHeightValue: Record<ChartHeight, number> = {
  'small': 315,
  'medium': 450,
  'large': 550
}

export const tooltipList = {
  ConversionRate: "This chart displays your customer conversion rate per month within the confines of the applied filter settings.",
  ProtectionCost: "This chart displays the protection cost that has occurred within the confines of the applied filter settings.",
  TransactionValue: "This chart displays your customer transaction value per month within the confines of the applied filter settings.",
  TransactionVolume: "This chart displays the volume of transactions that have occurred within the confines of the applied filter settings.",
  InsurancePremium: "This chart displays the insurance premium value per month within the confines of the applied filter settings.",
  RiskByRegion: "This chart displays the amount of risk by region. Risk is the premium for transactions on events that have not occured yet. These values will be filtered",
  PremiumByRegion: "This chart displays the amount of premium by region. Premium is the premium charged on transactions for events that have now passed. These values will be filtered",
  RollingRisk: "This chart displays the rolling risk  from this month forward including the expired risk for each month. These will be filtered by default filters for the product excepted date",
  CompletedIntegrations: 'This chart displays the cumulative number of completed integrations by month for each region.',
  IntegratingDays: 'This chart displays the number of days it takes on average to integrate a member for each region.',
  IntegrationsMrr: 'This chart displays the average actual MRR for each region',
  VerticalStatusTime: 'This chart displays the average days on each status by sector',
  RegionStatusTime: 'This chart displays the average days on each status by region'
};