import {Card, CardTitle} from "reactstrap";
import React from "react";
import Select from "react-select";

export interface FilterItem {
    key: any,
    value: any
}

interface FilterProps {
    title: string,
    label: string,
    items: FilterItem[],
    onChange: (item: FilterItem) => void
}

export const Filter = (props: FilterProps) => {
    const {title, label, items, onChange} = props

    const handleChange = (option: any) => {
        onChange(option);
    };

    return (
            <Card body>
                <CardTitle tag="h6" className="responsive-text-size">{title}</CardTitle>
                <Select
                    className="react-select member-default-color"
                    classNamePrefix="react-select"
                    isSearchable={true}
                    options={[
                        ...items.map((item: FilterItem) => ({
                            key: item.key,
                            value: item.value,
                            label: item.value,
                        }))
                    ]}
                    onChange={handleChange}
                    placeholder={label}
                    blurInputOnSelect={true}
                />
            </Card>
    )
}