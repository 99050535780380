import React from 'react'
import classNames from 'classnames'
import {dateRange} from '../../../../../types/pulse'
import moment from 'moment/moment'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {dateFromState, dateToState, selectedDateRangeState} from '../../../../../state/pulse-filters'
import {calendarDatesState} from '../../../../../state/pulse'

const YearFilter = () => {
	const currentDate = moment()
	const date = useRecoilValue(selectedDateRangeState)
	const setDateFrom = useSetRecoilState(dateFromState)
	const setDateTo = useSetRecoilState(dateToState)
	const setCalendarDates = useSetRecoilState(calendarDatesState)

	const handleYearClick = () => {
		const from = moment().add(-1, 'year').startOf('year')
		const to = moment().add(-1, 'year').endOf('year')

		setCalendarDates([from.toDate(), to.toDate()])
		setDateFrom(from)
		setDateTo(to)
	}

	return (
		<div
			className={classNames('left-4 mb-2 mr-2 px-3 py-[2px] top-2',
				'cursor-pointer',
				'border border-pulse-icon-gray rounded-full',
				'text-nowrap text-sm tracking-[1px] uppercase',
				'hover:bg-pulse-button-blue',
				'lg:left-0 lg:mb-0 lg:relative lg:top-0', {
					'bg-pulse-button-blue': date === dateRange.Year,
					'bg-white': date !== dateRange.Year,
					'absolute': date === dateRange.Year
				})}
			onClick={handleYearClick}
		>
			<span className="align-middle leading-5">
				{currentDate.add(-1, 'year').format('YYYY')}
			</span>
		</div>
	)
}

export default YearFilter
