import {ChartDataset, ChartOptions, TooltipItem} from 'chart.js'
import {PulseVerticalsRevenueTableDataModel} from '../../../types/pulse-data'
import {formatCurrency} from '../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {verticalsRevenueTableBreakdownColumnState} from '../../../state/pulse'
import {verticalsRevenueTableBreakdownColumn, verticalsRevenueTableBreakdownColumnName} from '../../../types/pulse'

export default function useGetVerticalsRevenueChartData(recoilValue: { data: PulseVerticalsRevenueTableDataModel, isLoading: boolean }) {
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const breakdownColumn = useRecoilValue(verticalsRevenueTableBreakdownColumnState)

	const getOptions = () => {
		const options: ChartOptions<'pie'> = {
			plugins: {
				legend: {
					labels: {
						usePointStyle: true,
						padding: 15
					},
				},
				tooltip: {
					enabled: true,
					mode: 'index',
					bodySpacing: 8,
					boxPadding: 4,
					padding: 16,
					titleMarginBottom: 8,
					callbacks: {
						label: (tooltip: TooltipItem<'pie'>) => {
							let value = tooltip.raw as any
							let sum = 0

							tooltip.dataset.data.map(data => {
								sum += data;
							});
							let percentage = sum > 0 ? (value * 100 / sum).toFixed(2) : "0"

							return `${verticalsRevenueTableBreakdownColumnName[breakdownColumn]}: ` +
								`${formatCurrency(coreCurrency, value, 0, 0)} - ${percentage}%`
						}
					}
				}
			}
		}

		return options
	}

	const getLabels = () =>
		(recoilValue.data.verticals && recoilValue.data.verticals.map(vertical => vertical.verticalName))
		|| []

	const getDatasets = () => {
		const datasets: ChartDataset<'pie'>[] = []

		if (!recoilValue.data.verticals) {
			return datasets
		}

		const data: number[] = []

		for (let i = 0; i < recoilValue.data.verticals.length; i++) {
			const vertical = recoilValue.data.verticals[i]

			switch (breakdownColumn) {
				case verticalsRevenueTableBreakdownColumn.RefundProtectValue:
					data.push(vertical.refundProtectValue || 0)
					break
				case verticalsRevenueTableBreakdownColumn.ProtectionValue:
					data.push(vertical.protectionValue || 0)
					break
				case verticalsRevenueTableBreakdownColumn.ApprovedRefundsValue:
					data.push(vertical.approvedRefundsValue || 0)
					break
				case verticalsRevenueTableBreakdownColumn.ProfitValue:
					data.push(vertical.profitValue || 0)
					break
			}

		}

		datasets.push({
			data: data
		})

		return datasets
	}

	return {
		getDatasets,
		getLabels,
		getOptions
	}
}
