import React, {useEffect, memo} from 'react'
import {Col, Row} from 'reactstrap'
import {DateFilters} from './components/DateFilters'
import {UserFilter} from './components/UserFilter'
import {UserCommissionTotal} from './components/UserComissionTotal'
import {TeamCommissionTotal} from './components/TeamComissionTotal'
import {UserTeamRanking} from './components/UserTeamRanking'
import {EpCommissionTable} from './components/EpCommissionTable'
import {UserCommissionChart} from './components/UserCommissionChart'
import {RpCommissionTable} from './components/RpCommissionTable'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {CompanyRanking} from './components/CompanyRanking'
import {isSidebarOpenState} from '../../state/ui'
import UserCommissionRates from './components/UserCommissionRates'
import {isPartnerCommissionDashboardUserState} from '../../state/auth'
import {AutoRefresh} from '../../components/AutoRefresh'

const UserHeaders = memo(() => {
	return (
		<>
			<Row className="pb-4 d-flex flex-column flex-md-row justify-content-end">
				<Col sm={12} md={4} className="d-flex pr-md-4">
					<DateFilters/>
				</Col>
				<Col sm={12} md={4} className="d-flex flex-grow-1 pl-md-4">
					<UserFilter/>
				</Col>
			</Row>
			<div className="pb-4 d-flex flex-column flex-md-row justify-content-between">
				<div className="d-flex flex-fill pr-md-2">
					<UserCommissionTotal/>
				</div>
				<div className="d-flex flex-fill px-md-2">
					<TeamCommissionTotal/>
				</div>
				<div className="d-flex flex-fill px-md-2">
					<UserTeamRanking/>
				</div>
				<div className="d-flex flex-fill pl-md-2">
					<CompanyRanking/>
				</div>
			</div>
		</>
	)
})

const PartnerUserHeaders = memo(() => {
	return (
		<div className="pb-4 d-flex flex-column flex-md-row justify-content-end">
			<div className="d-flex flex-fill pr-md-2">
				<UserCommissionTotal/>
			</div>
			<div className="d-flex pl-md-2">
				<DateFilters/>
			</div>
		</div>
	)
})

const CommissionView = () => {
	const setSidebarOpen = useSetRecoilState(isSidebarOpenState)
	const isPartnerUser = useRecoilValue(isPartnerCommissionDashboardUserState)

	useEffect(() => {
		setSidebarOpen(false)
	}, [setSidebarOpen])

	return (
		<div className="content">
			<AutoRefresh/>
			{
				isPartnerUser
					? <PartnerUserHeaders/>
					: <UserHeaders/>
			}
			<Row className="pb-4">
				<Col sm={12}>
					<UserCommissionChart/>
				</Col>
			</Row>
			<Row className="pb-4">
				<Col sm={12}>
					<RpCommissionTable/>
				</Col>
			</Row>
			<Row className="pb-4">
				<Col sm={12}>
					<EpCommissionTable/>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<UserCommissionRates/>
				</Col>
			</Row>
		</div>
	)
}

export default CommissionView
