import {atom, selector} from 'recoil'
import moment, {Moment} from 'moment'
import {
	dateFormat,
} from '../utils/date'
import {EnumTransactionQuery} from '../views/Member/Enums/MemberEnum'
import {EnumCurrencyCode} from '../views/Member/Enums/CurrencyEnum'
import {IdEnum} from '../views/Member/Enums/IdEnum'
import {FilterEnum} from '../views/Member/Enums/FilterEnum'

export const formattedDateFromStateMember = selector<string>({
	key: 'formattedDateFromStateMember',
	get: ({get}) => get(dateFromStateMember).format(dateFormat)
})

export const formattedDateToStateMember = selector<string>({
	key: 'formattedDateToStateMember',
	get: ({get}) => get(dateToStateMember).format(dateFormat)
})

export const dateFromStateMember = atom<Moment>({
	key: 'dateFromStateMember',
	default: moment().startOf('year')
})

export const dateToStateMember = atom<Moment>({
	key: 'dateToStateMember',
	default: moment()
})

export const selectedMainProductIdStateMember = atom<number>({
	key: 'selectedMainProductIdStateMember',
	default: IdEnum.DefaultId
})

export const selectedCoreCurrencyStateMember = atom<string>({
	key: 'selectedCoreCurrencyStateMember',
	default: 'USD'
})

export const selectedCurrencyStateMember = atom<string>({
	key: 'selectedCurrencyStateMember',
	default: EnumCurrencyCode.DefaultCurrencyCode  // Equals to "All".
})

export const selectedProductTypeStateMember = atom<number>({
	key: 'selectedProductTypeStateMember',
	default: IdEnum.DefaultId
})

export const selectedProductTypeCodeStateMember = atom<string>({
	key: 'selectedProductTypeCodeStateMember',
	default: FilterEnum.DefaultFilterAll
})

export const selectedEventsStateMember = atom<any>({
	key: 'selectedEventsStateMember',
	default: null
})

export const selectedLocationStateMember = atom<string>({
	key: 'selectedLocationStateMember',
	default: FilterEnum.DefaultFilterAll
})

export const selectedMemberStateMember = atom<number | undefined>({
	key: 'selectedMemberStateMember',
	default: undefined
})

export const transactionReferenceIdState = atom<number | null>({
	key: 'transactionReferenceIdState',
	default: null
})

export const transactionTableCurrentPageNumberState = atom<number>({
	key: 'transactionTableCurrentPageNumberState',
	default: 1
})

export const transactionTableColumnSortQueryState = atom<string>({
	key: 'transactionTableColumnSortQueryState',
	default: EnumTransactionQuery.DefaultTransactionQuery
})

export const transactionTablePageSizeState = atom<number>({
	key: 'transactionTablePageSizeState',
	default: 10
})

export const transactionSearchTextState = atom<string>({
	key: 'transactionSearchTextState',
	default: '',
})

export const tempTransactionSearchTextState = atom<string>({
	key: 'tempTransactionSearchTextState',
	default: '',
})
