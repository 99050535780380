import React from 'react'
import {batchAction, batchActionName} from '../../../../../types/pulse'
import {useRecoilState, useRecoilValue} from 'recoil'
import {selectedTransactionsState, transactionBatchActionState} from '../../../../../state/pulse'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react'

const TransactionBatchActions = () => {
	const selectedTransactions = useRecoilValue(selectedTransactionsState)
	const [action, setAction] = useRecoilState(transactionBatchActionState)

	const handleClick = (value: batchAction) => {
		if (!selectedTransactions || selectedTransactions.length === 0) {
			return
		}

		setAction(value)
	}

	return (
		<Listbox value={action} onChange={(value) => handleClick(value)}>
			<ListboxButton>
				<div className="
					mb-2 pl-3 pr-2 py-[2px]
					bg-white
					text-nowrap text-sm uppercase
					border border-pulse-icon-gray rounded-full
					cursor-pointer
					hover:bg-pulse-button-blue
					lg:mb-0"
				>
					<span className="leading-5 tracking-[1px]">{batchActionName[action]}</span>
					<span className="
						align-middle ml-1
						leading-5
						material-symbols-outlined"
					>
							keyboard_arrow_down
						</span>
				</div>
			</ListboxButton>
			<ListboxOptions
				className="
					min-w-[var(--button-width)] ml-4 mt-[5px] py-2 w-11/12 z-50
					bg-white
					border border-pulse-popup-border-gray rounded-lg
					lg:w-auto lg:ml-0"
				anchor="bottom start"
			>
				<ListboxOption
					disabled={selectedTransactions.length === 0}
					value={batchAction.Cancel}
					className="
						px-4 py-2
						text-sm text-pulse-red tracking-[1px] uppercase
						cursor-pointer
						data-[disabled]:text-pulse-icon-gray
						data-[focus]:bg-pulse-button-blue"
				>
					{batchActionName[batchAction.Cancel]}
				</ListboxOption>
			</ListboxOptions>
		</Listbox>
	)
}

export default TransactionBatchActions
