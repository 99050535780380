import React from 'react'
import classNames from 'classnames'

interface TrustPilotReviewCardProps {
	header: string,
	body: string,
	stars: number
}

const TrustPilotReviewCard = (props: TrustPilotReviewCardProps) => {
	const starCount = 5

	return (
		<div className="min-h-20 pt-5">
			<div className="h-[16px] mb-1 relative">
				<div>
					{[
						...Array(starCount),
					].map((_, index) => (
						<span
							key={`trustpilotstar-${index}`}
							className={classNames('align-top px-[2px] py-[2px]',
								'text-xs text-white',
								'border-x border-r-pulse-gray', {
									'bg-pulse-icon-gray': index > props.stars - 1,
									'bg-pulse-green': index < props.stars,
								})}
						>
							★
						</span>
					))}
				</div>
			</div>
			<div className="
				mb-2
				font-medium text-black text-xs"
			>
				{props.header}
			</div>
			<div className="
				mb-1
				text-black text-xs"
			>
				{props.body}
			</div>
		</div>
	)
}

export default TrustPilotReviewCard
