import classNames from 'classnames'
import React from 'react'
import {ValueDirectionProps} from '../Card/ValueStatusCard'

interface ValueStatusCardProps {
	values: ValueDirectionProps[]
	displayStatusIndicator?: boolean
}

export const ValueStatusTableCell = (props: ValueStatusCardProps) => {
	return (
		<div>
			{
				props.values?.map((x) => {
						const {value, direction, colour} = x
						return (
							<>
								<span>{value}</span>
								{
									!props.displayStatusIndicator &&
									(<i className={classNames('tim-icons', 'pl-2', 'pr-2', {
										'icon-minimal-up': direction === 'up',
										'icon-minimal-down': direction === 'down',
										'text-success': colour === 'green',
										'text-error': colour === 'red'
									})}/>)
								}
							</>
						)
					}
				)
			}
		</div>
	)
}
