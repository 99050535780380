import React from 'react'
import PrimaryVolumeCard from './Common/Cards/PrimaryVolumeCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {Numbers} from './Common/FormatElements'

const OverviewDeclinedRefundVolumeSummary = () => {
	const {getDeclinedRefundVolumeSummary, getDeclinedRefundVolumeTrend} = usePulseApi()
	const value = useApiValue(filterState, getDeclinedRefundVolumeSummary, 0)
	const trendRate = useApiValue(filterState, getDeclinedRefundVolumeTrend, 0)
	const title = 'Declined Refunds Volume'
	return (
		<PrimaryVolumeCard
			value={<Numbers value={value.data}/>}
			header={<>{title}</>}
			icon="people"
			tooltipTitle={title}
			tooltipText="Total count of denied refund applications."
			trendValue={trendRate.data}
			valueClassName="text-black"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewDeclinedRefundVolumeSummary
