import React, {ReactElement} from 'react'
import Tooltip from '../Tooltip/Tooltip'
import {v4 as uuidv4} from 'uuid'
import LoadingSpinner from '../Spinner/LoadingSpinner'

interface SecondaryValueCardProps {
	header: string,
	tooltipText: string,
	value: ReactElement,
	isLoading: boolean
}

const SecondaryValueCard = (props: SecondaryValueCardProps) => {
	const id = uuidv4()

	return (
		<div className="
			flex flex-col h-full px-5 py-3 relative
			bg-white
			border border-gray-200 rounded-lg"
		>
			<LoadingSpinner
				isLoading={props.isLoading}
				className="h-full w-full"
			>
				<>
					<div className="
						flex-1 mb-1
						text-pulse-label-gray text-xs"
					>
						{props.header}
					</div>
					<div className="flex">
						<div className="flex-none mr-2">
							<div className="flex h-full items-center">
								<span className="
									mb-2
									text-2xl text-pulse-icon-gray
									material-symbols-outlined"
								>
									monitoring
								</span>
							</div>
						</div>
						<div>
							<div className="mb-1 text-2xl text-black">{props.value}</div>
							{props.tooltipText && (
								<Tooltip
									id={`${id}-popup`}
									tooltipTitle={props.header}
									tooltipText={props.tooltipText}
								/>
							)}
						</div>
					</div>
				</>
			</LoadingSpinner>
		</div>
	)
}

export default SecondaryValueCard
