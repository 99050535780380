import React from 'react'
import SupportCard from './Common/SupportCard/SupportCard'
import {supportDetailState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'

const DashboardSupport = () => {
	const supportDetail = useRecoilValue(supportDetailState)
	const commercialEmail = supportDetail.commercialEmail || process.env.REACT_APP_PULSE_SUPPORT_COMMERCIAL_EMAIL
	const customerExperienceEmail = process.env.REACT_APP_PULSE_SUPPORT_CUSTOMEREXPERIENCE_EMAIL
	const financeEmail = process.env.REACT_APP_PULSE_SUPPORT_FINANCE_EMAIL

	return (
		<>
			<h2 className="
				mb-2
				font-semibold text-2xl"
			>
				Support
			</h2>
			<div className="
				mb-4
				md:content-end md:flex md:space-x-6"
			>
				<div className="
					flex flex-col mb-4 justify-end
					md:mb-0"
				>
					<SupportCard
						icon="support_agent"
						title="Want to optimise your performance?"
						text={<>Get in touch with your <span className="whitespace-nowrap">Partnership Manager</span></>}
						popupTitle="Partnership Manager"
						popupBody={(<>
							{supportDetail.commercialName && (
								<p className="font-medium mb-4">{supportDetail.commercialName}</p>
							)}
							<p className="mb-2">
								<span className="
									align-middle mr-2
									text-pulse-icon-gray
									material-symbols-outlined"
								>
									mail
								</span>
								<a
									href={`mailto:${commercialEmail}`}
									className="text-black hover:opacity-60">{commercialEmail}</a>
							</p>
							{supportDetail.commercialPhone && (
								<p className="mb-2">
									<span className="
										align-middle mr-2
										text-pulse-icon-gray
										material-symbols-outlined"
									>
										call
									</span>
									<span>{supportDetail.commercialPhone}</span>
								</p>
							)}
						</>)}/>
				</div>
				<div className="
					flex flex-col justify-end mb-4
					md:mb-0"
				>
					<SupportCard
						icon="support_agent"
						title="Do you need help?"
						text={<>Contact our <span className="whitespace-nowrap">Support Team</span></>}
						popupTitle="Support Team"
						popupBody={(<>
							<p className="mb-2">
								<span className="
									align-middle mr-2
									text-pulse-icon-gray
									material-symbols-outlined"
								>
									mail
								</span>
								<a
									href={`mailto:${customerExperienceEmail}`}
									className="text-black hover:opacity-60"
								>
									{customerExperienceEmail}
								</a>
							</p>
						</>)}/>
				</div>
				<div className="
					flex flex-col justify-end mb-4
					md:mb-0"
				>
					<SupportCard
						icon="support_agent"
						title="Do you need invoicing help?"
						text={<>Contact our <span className="whitespace-nowrap">Finance Team</span></>}
						popupTitle="Finance Team"
						popupBody={(<>
							<p className="mb-2">
								<span className="
									align-middle mr-2
									text-pulse-icon-gray
									material-symbols-outlined"
								>
									mail
								</span>
								<a
									href={`mailto:${financeEmail}`}
									className="text-black hover:opacity-60"
								>
									{financeEmail}
								</a>
							</p>
						</>)}/>
				</div>
			</div>
		</>
	)
}

export default DashboardSupport
