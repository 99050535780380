import React from 'react'
import SecondaryValueCard from './Common/Cards/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {Percentage} from './Common/FormatElements'

const OverviewPriceToCustomerSummary = () => {
	const {getAverageTransactionRate} = usePulseApi()
	const value = useApiValue(filterState, getAverageTransactionRate, 0)

	return (
		<SecondaryValueCard
			value={<Percentage value={value.data}/>}
			header="Average Refund Protect Fee"
			tooltipText="This refers to the average Refund Protect fee shown to customers. This average fee is calculated
				based on the total Refund Protect fees offered on the placement, divided by the Gross Booking Sales."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewPriceToCustomerSummary
