import {selectorFamily, atomFamily} from 'recoil'
import {EnumMainProduct} from '../views/Member/Enums/MainProductEnum'
import {selectedMainProductIdStateMember} from './member-filters'
import {MapProductToggle, mapToggleLabels, RegionIds} from '../types/region'

export const mapTogglesState = atomFamily<MapProductToggle[], RegionIds>({
	key: 'mapTogglesState',
	default: Object.keys(mapToggleLabels).map(key => ({
		label: mapToggleLabels[key],
		isSelected: true
	}))
})

export const isMapToggleSelectedState = selectorFamily<boolean, any>({
	key: 'isMapToggleSelectedState',
	get: ({region, label}) => ({get}) => {
		const toggles = get(mapTogglesState(region))
		const toggle = toggles.find(x => x.label === label)
		return toggle?.isSelected ?? false
	}
})

const mapButtonColors = {
	all: 'member-all-for-region-maps',
	none: 'member-dark-gray',
	ep: 'member-ep',
	rp: 'member-rp'
}

export const mapToggleColorsState = selectorFamily<string[], RegionIds>({
	key: 'mapToggleColorsState',
	get: (region: RegionIds) => ({get}) => {
		const toggles = get(mapTogglesState(region))

		if (toggles.every(x => x.isSelected)) {
			return [mapButtonColors.all, mapButtonColors.all]
		}

		if (toggles[0].isSelected) {
			return [mapButtonColors.ep, mapButtonColors.none]
		}

		if (toggles[1].isSelected) {
			return [mapButtonColors.none, mapButtonColors.rp]
		}

		return [mapButtonColors.none, mapButtonColors.none]
	}
})

export const mapDetailColorState = selectorFamily<string, RegionIds>({
	key: 'mapDetailColorState',
	get: (region: RegionIds) => ({get}) => {
		const toggles = get(mapTogglesState(region))
		const selectedProduct = get(selectedMainProductIdStateMember)

		if (selectedProduct === EnumMainProduct.EventProtect) {
			return mapButtonColors.ep
		}

		if (selectedProduct !== EnumMainProduct.All) {
			return mapButtonColors.all
		}

		if (toggles.every(x => x.isSelected)) {
			return mapButtonColors.all
		}

		if (toggles[0].isSelected) {
			return mapButtonColors.ep
		}

		if (toggles[1].isSelected) {
			return mapButtonColors.rp
		}

		return mapButtonColors.none
	}
})
