import React from 'react'
import {filterState} from '../../../state/dynamic-performance'
import {ValueCard} from '../../../components/Card/ValueCard'
import {useDynamicPerformanceApi} from '../../../hooks/api/dynamic'
import {useApiValue} from '../../../hooks/api'

const AverageConversionRate = () => {
	const {getAverageConversion} = useDynamicPerformanceApi()
	const {data} = useApiValue(filterState, getAverageConversion, 0)

	return (
		<ValueCard
			title="Average Conversion"
			value={`${data}%`}
			icon="icon-sound-wave"
			iconColor="rp"
		/>
	)
}

export default AverageConversionRate
