import React, {useEffect, useState} from 'react'
import {selectedProductTypeCodeStateMember} from '../../../../state/member-filters'
import {ProductType} from '../../../../types'
import useGetMainProductStates from '../../hooks/useGetMainProductStates'
import {FilterEnum} from '../../Enums/FilterEnum'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {useMemberApi} from '../../../../hooks/api/member'
import {useApiValue} from '../../../../hooks/api'
import {memberFilterState} from '../../../../state/memberFilterState'
import {productTypeDropdownData} from '../../../../types/member'

const TypeDropdown = () => {
	const {getProductTypes} = useMemberApi()
	const {isProductEp, isProductRp} = useGetMainProductStates()
	const {data, isLoading} = useApiValue(memberFilterState, getProductTypes, [])
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])

	useEffect(() => {
		if (isProductRp) {
			const next = [
				{value: FilterEnum.DefaultFilterAll, label: FilterEnum.DefaultFilterAll},
				...productTypeDropdownData.map((x) =>
					(
						{
							value: x.productCode,
							label: x.productName
						}
					)
				)
			]
			setMenuItems(next)
		}
		if (isProductEp) {
			if (data && !isLoading) {
				const next = [
					{value: FilterEnum.DefaultFilterAll, label: FilterEnum.DefaultFilterAll},
					...data.map((x: ProductType) => (
						{
							value: x.productCode,
							label: x.productName
						}
					))
				]
				setMenuItems(next)
			}
		}
	}, [data, isLoading, isProductRp, isProductEp])

	return (
		<StatefulSelectFilterCard
			state={selectedProductTypeCodeStateMember}
			title="Type"
			items={menuItems}
		/>
	)
}

export {TypeDropdown}
