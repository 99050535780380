import React, {useEffect, useState} from 'react';
import {Currency} from '../../../../types';
import {uploadFilterState} from '../../../../state/upload-filter';
import {selectedCurrencyUpload} from '../../../../state/upload-data';
import Select from 'react-select';
import {useRecoilState} from 'recoil';
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter';
import {useUploadApi} from "../../../../hooks/api/upload";
import {useApiValue} from "../../../../hooks/api";

const CurrencyDropdown = () => {

	const {getCurrencies} = useUploadApi()
	const {data, isLoading} = useApiValue(uploadFilterState, getCurrencies, [])
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const [uploadCurrencyState, setUploadCurrencyState] = useRecoilState(selectedCurrencyUpload)

	useEffect(() => {
		if (data?.length > 0 && !isLoading) {
			const next = data.map((x: Currency) => ({
				value: x.currencyCode,
				label: x.currencyCode,
			}));
			setMenuItems(next);
		}
	}, [data, isLoading]);

	const handleCurrencyChange = (selectedOption: SelectFilterItem | null) => {
		setUploadCurrencyState(selectedOption?.value || '');
	};

	return (
		<Select
			options={menuItems}
			className="react-select max-width-75"
			classNamePrefix="react-select"
			value={menuItems.find((item) => item.value === uploadCurrencyState)}
			onChange={handleCurrencyChange}
		/>
	);
};

export {CurrencyDropdown}