import {Badge, Card, CardBody, Col, Row} from 'reactstrap'
import moment from 'moment'
import React, {memo} from 'react'
import useGetMainProductStates from '../../hooks/useGetMainProductStates'
import {productChartColors} from '../../../../utils/chart'
import {useRecoilValue} from 'recoil'
import {autoRefreshIntervalState} from '../../../../state/filters'
import Countdown from 'react-countdown'
import {dateTimeFormat} from '../../../../utils/date'
import {useMemberApi} from '../../../../hooks/api/member'
import {useApiValue} from '../../../../hooks/api'
import {memberFilterState} from '../../../../state/memberFilterState'

const LastTransactionDates = memo(() => {
	const {getLastTransactionDates} = useMemberApi()
	const {data} = useApiValue(memberFilterState, getLastTransactionDates, {ep: '', rp: ''})
	const {isProductEp, isProductRp, isProductAll} = useGetMainProductStates()
	const autoRefreshValue = useRecoilValue(autoRefreshIntervalState)

	let layoutScale = 6
	isProductAll && (layoutScale = 4)

	return (
		<>
			<Card className="pb-0">
				<CardBody xs="auto" s="auto">
					<Row>
						{
							(isProductEp || isProductAll) && (
								<Col xs="12" s="6" md={layoutScale} lg={layoutScale}>
									<h6 className="d-flex text-white chart-summary-badge-radius">
										<Badge style={{background: productChartColors.EP}} className={'w-100'}>
											Last EP	Transaction:
										</Badge>
										<Badge color="dark" className={'w-100'}>
											{
												moment.utc(data.ep).local().format(dateTimeFormat)
											}
										</Badge>
									</h6>
								</Col>
							)
						}
						{
							(isProductRp || isProductAll) && (
								<Col xs="12" s="6" md={layoutScale} lg={layoutScale}>
									<h6 className="d-flex text-white chart-summary-badge-radius">
										<Badge style={{background: productChartColors.RP}} className={'w-100'}>
											Last RP	Transaction:
										</Badge>
										<Badge color="dark" className={'w-100'}>
											{
												moment.utc(data.rp).local().format(dateTimeFormat)
											}
										</Badge>
									</h6>
								</Col>
							)
						}
						<Col xs="12" s="6" md={layoutScale} lg={layoutScale}>
							<h6 className="d-flex text-white chart-summary-badge-radius">
								<Badge style={{background: productChartColors.All}} className={'w-100'}>
									Auto-Refresh in:
								</Badge>
								<Badge color="dark" className={'w-100'}>
									<Countdown daysInHours={true} date={(Date.now() + autoRefreshValue)}/>
								</Badge>
							</h6>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</>
	)
})

export {LastTransactionDates}
