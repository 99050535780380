import React, {memo} from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import useGetSummaryValues from '../../hooks/useGetSummaryValues'
import useSummaryCardColor from '../../hooks/useSummaryCardColor'

const ProtectionRate = memo(() => {
	const {totalProtectionRate} = useGetSummaryValues()
	const color = useSummaryCardColor()

	return (
		<ValueCard
			title={`Protection Rate`}
			value={parseFloat(totalProtectionRate.toString()).toFixed(2) + '%'}
			icon="icon-satisfied"
			tooltip={'Overall protection rate.'}
			iconColor={color}
		/>
	)
})

export {ProtectionRate}
