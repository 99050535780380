import React, {useEffect, useRef, useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import _ from 'underscore'
import {TableCard} from '../../../components/Card/TableCard'
import {Button} from 'reactstrap'
import {toUsd} from '../../../utils/number'
import {TimeValueData, ValueData, Variable} from '../../../types/dynamic-pricing'
import {
	dynamicPricingFilterState,
	pagingState,
	timeValueFilterState
} from '../../../state/dynamic-pricing'
import {ColouredCell} from '../../../components/Dashboard/Dynamic/TableCell'
import {TestResult} from '../../../types/dynamic-data'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import classNames from 'classnames'
import {useDynamicPricingApi} from '../../../hooks/api/dynamic-pricing'
import {useApiValue} from '../../../hooks/api'

const MAX_VISIBLE_FILTERS = 5

interface ValueTableData extends ValueData {
}

interface TimeTableRow {
	rate: number
	price: number
	conversion: number
	sales: number
	revenue: number
	status: TestResult
}

interface TimeTableData {
	band: string
	variance: number
	period: string
	rows: TimeTableRow[]
}

const useTimeValueData = () => {
	const {getTimeValueData} = useDynamicPricingApi()
	const {data} = useApiValue(dynamicPricingFilterState, getTimeValueData, [])
	const [valueTable, setValueTable] = useState<ValueTableData[]>([])
	const [timeTables, setTimeTables] = useState<TimeTableData[]>([])
	const [valueKeys, setValueKeys] = useState<string[]>([])
	const [timeKeys, setTimeKeys] = useState<string[]>([])
	const setFilter = useSetRecoilState(timeValueFilterState)

	useEffect(() => {
		if (!data || !data.length) {
			return
		}

		const values: ValueTableData[] = data.map((x: TimeValueData) => {
			return {
				...x.valueData
			}
		})

		const timeGroups = _.groupBy(
			data.flatMap((x: TimeValueData) => x.timeData),
			'value'
		)

		const time: TimeTableData[] = Object.keys(timeGroups)
			.map(x => {
				const rows = timeGroups[x]
				return {
					band: rows[0].name,
					variance: rows[0].variance,
					period: rows[0].value,
					rows: rows
						.map(row => {
							return {
								...row,
								revenue: row.revenueValue,
							}
						})
				}
			})

		const vKeys = values.map(x => x.key)
		const tKeys = time.map(x => x.period)

		setValueTable(values)
		setTimeTables(time)
		setValueKeys(vKeys)
		setTimeKeys(tKeys)

	}, [data])

	useEffect(() => {
		setFilter(curr => {
			const selectedTimeKeys = curr?.selectedTimeKeys

			if (selectedTimeKeys && selectedTimeKeys.length > 0) {
				return curr
			}

			return {
				selectedValueKeys: [],
				selectedTimeKeys: timeKeys.slice(0, MAX_VISIBLE_FILTERS)
			}
		})
	}, [timeKeys, setFilter])

	return {valueTable, timeTables, valueKeys, timeKeys}
}

const MemberPricingDetailTable = () => {
	const {valueTable, timeTables} = useTimeValueData()

	return (
		<TableCard title="Member Pricing Overview" headerContent={<PrimaryTableHeader/>}>
			<>
				<div className="d-flex flex-row w-100 border-top">
					<div className="border-right">
						<ValueTable data={valueTable}/>
					</div>
					<div className="horizontal-scroll border-left ml-2">
						<TimeTablesContainer data={timeTables}/>
					</div>
				</div>
			</>
		</TableCard>
	)
}

export default MemberPricingDetailTable

const PrimaryTableHeader = () => {
	return (
		<div className="d-flex flex-fill justify-content-between align-items-center pl-5">
			<VariableFilters/>
			<div className="d-flex">
				<Button
					size="sm"
				>
					<i className="tim-icons icon-upload pr-2"/>
					Export
				</Button>
			</div>
		</div>
	)
}


const TimeValueFilter = () => {
	const [filterOpen, setFilterOpen] = useState<boolean>(false)
	const toggleRef = useRef<any>()
	const {timeKeys} = useTimeValueData()
	const [filter, setFilter] = useRecoilState(timeValueFilterState)

	const isSelected = (key: string) => filter?.selectedTimeKeys.includes(key)

	const toggleFilter = () => setFilterOpen(curr => !curr)

	const toggleKey = (key: string) => () => {
		setFilter(curr => {
			const next = curr ? {...curr} : {selectedTimeKeys: [], selectedValueKeys: []}
			const index = next.selectedTimeKeys.indexOf(key)

			if (index < 0) {
				return {
					...next,
					selectedTimeKeys: [
						...next.selectedTimeKeys,
						key
					]
				}
			}

			return {
				...next,
				selectedTimeKeys: [
					...next.selectedTimeKeys.slice(0, index),
					...next.selectedTimeKeys.slice(index + 1)
				]
			}
		})
	}

	const close = (event: any) => {
		const {target} = event

		if (toggleRef.current?.contains(target)) {
			return
		}

		setFilterOpen(false)
	}

	const handleSelectAllClick = () => {
		setFilter(curr => {
			const next = curr ? {...curr} : {selectedTimeKeys: [], selectedValueKeys: []}
			return {
				...next,
				selectedTimeKeys: [...timeKeys]
			}
		})
	}

	const handleResetClick = () => {
		setFilter(curr => {
			const next = curr ? {...curr} : {selectedTimeKeys: [], selectedValueKeys: []}
			return {
				...next,
				selectedTimeKeys: [...timeKeys.slice(0, MAX_VISIBLE_FILTERS)]
			}
		})
	}

	useEffect(() => {
		document.addEventListener('click', close)

		return () => {
			document.removeEventListener('click', close)
		}
	}, [])

	return (
		<div className="time-value-filter-container">
			<div className="time-value-filter" ref={toggleRef}>
				<div
					className="d-flex flex-row align-items-baseline pb-1 justify-content-between cursor-pointer"
					onClick={toggleFilter}
				>
					<div className="d-flex flex-row">
						{
							filter?.selectedTimeKeys.slice(0, MAX_VISIBLE_FILTERS).map((x, i) => {
								return (
									<div key={`time-key-${i}`} className="time-filter-pill">{x}</div>
								)
							})
						}
						{
							(filter && filter.selectedTimeKeys.length > MAX_VISIBLE_FILTERS) && (
								<>
									<div className="pl-2 time-filter-label">...</div>
									<div className="pl-2 time-filter-label">
										{filter.selectedTimeKeys.length - MAX_VISIBLE_FILTERS}
									</div>
								</>
							)
						}
					</div>
					<div className="d-flex flex-row">
						<span>
							<i className={classNames('tim-icons', 'tim-icons-bg-square', 'tim-icons-sm', {
								'icon-minimal-up': filterOpen,
								'icon-minimal-down': !filterOpen
							})}/>
						</span>
					</div>
				</div>
				<div className={classNames('time-key-checkbox-container', {'is-open': filterOpen})}>
					{
						timeKeys.map(x => {
							return (
								<div key={`value-key-${x}`} className="time-key-checkbox" onClick={toggleKey(x)}>
									<i className={classNames('tim-icons', 'tim-icons-bg-square', 'tim-icons-sm', {
										'icon-check-2': isSelected(x),
										'icon-simple-delete': !isSelected(x)
									})}/>
									<span className="pl-2">{x}</span>
								</div>
							)
						})
					}
				</div>
			</div>
			<div
				className="d-flex align-items-center"
				style={{
					marginLeft: toggleRef.current?.getBoundingClientRect().width + 20
				}}
			>
				<span
					className="cursor-pointer bg-white text-black-50 px-1 rounded"
					onClick={handleSelectAllClick}>ALL</span>
				<span
					className="cursor-pointer bg-white text-black-50 px-1 rounded ml-2"
					onClick={handleResetClick}>RESET</span>
			</div>
		</div>
	)
}

const VariableFilters = () => {
	const {getVariables} = useDynamicPricingApi()
	const {data} = useApiValue(dynamicPricingFilterState, getVariables, [])

	return (
		<div className="d-flex flex-column">
			<div className="d-flex align-items-center">
				<div className="pr-2">
					<span>CONDITIONS APPLIED:</span>
				</div>
				<div className="d-flex flex-wrap">
					{
						data.map((x, i) => {
							return (
								<div key={`variable-filter-${i}`} className="p-1">
									<VariableFilter item={x}/>
								</div>
							)
						})
					}
				</div>
			</div>
			<div className="d-flex">

			</div>
		</div>
	)
}

interface VariableFilterProps {
	item: Variable
}

const VariableFilter = (props: VariableFilterProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const toggleRef = useRef<any>()
	const {item: {name, values}} = props
	const items = values.map(x => ({label: x, value: x}))

	const handleToggle = () => setIsOpen(curr => !curr)

	const close = (event: any) => {
		const {target} = event

		if (toggleRef.current.contains(target)) {
			return
		}

		setIsOpen(false)
	}

	useEffect(() => {
		document.addEventListener('click', close)

		return () => {
			document.removeEventListener('click', close)
		}
	}, [])

	return (
		<div className={classNames('variable-filter-container', {'is-open': isOpen})}>
			<div className="variable-filter-select cursor-pointer" onClick={handleToggle} ref={toggleRef}>
				<span>{name}</span>
				<span>
					<i className={classNames('tim-icons', 'tim-icons-bg-square', 'tim-icons-sm', {
						'icon-minimal-up': isOpen,
						'icon-minimal-down': !isOpen
					})}/>
				</span>
			</div>
			<div className={classNames('variable-filter-options', {'is-open': isOpen})}>
				{
					items.map((x) => {
						return (
							<div key={`${x.label}-${x.value}`} className="variable-filter-option">{x.label}</div>
						)
					})
				}
			</div>
		</div>
	)
}

const valueTableColumns: TableColumn<ValueTableData> [] = [
	{
		name: 'Value',
		selector: ({key}) => key,
		center: true
	},
	{
		name: 'Transaction Volume',
		selector: ({transactionVolume}) => transactionVolume,
		center: true
	},
	{
		name: 'Transaction Value',
		selector: ({transactionValue}) => transactionValue,
		center: true,
		cell: (row: ValueTableData) => toUsd(row.transactionValue.toString())
	},
	{
		name: 'Total Revenue',
		selector: ({totalRevenue}) => totalRevenue,
		center: true,
		cell: (row: ValueTableData) => toUsd(row.totalRevenue.toString())
	},
	{
		name: 'Revenue by Unit ($1)',
		selector: ({revenueValue}) => revenueValue,
		center: true,
		cell: (row: ValueTableData) => toUsd(row.revenueValue.toString())
	},
	{
		name: '% Opportunity',
		selector: (row: any) => row.opportunityPercentage,
		center: true,
		cell: (row: ValueTableData) => `${row.opportunity}%`
	}
]

interface ValueTableProps {
	data: ValueTableData[]
}

const ValueTable = (props: ValueTableProps) => {
	const setPaging = useSetRecoilState(pagingState)
	const {data} = props
	const headerClasses = 'd-flex justify-content-center w-25'

	const handlePageChange = (page: number) => setPaging(curr => ({
		...curr,
		pageNumber: page
	}))

	const handleRowsChange = (currentRows: number, currentPage: number) => setPaging({
		pageNumber: currentPage,
		pageSize: currentRows
	})

	return (
		<div>
			<div className="pricing-table-headers padding-bottom d-flex flex-column align-items-end">
				<div className={headerClasses}>Time</div>
				<div className={headerClasses}>Variation+/-</div>
			</div>
			<div>
				<DataTable
					data={data}
					columns={valueTableColumns}
					theme="dark"
					pagination
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleRowsChange}
				/>
			</div>
		</div>
	)
}

interface TimeTablesContainerProps {
	data: TimeTableData[]
}

const TimeTablesContainer = (props: TimeTablesContainerProps) => {
	const {data} = props
	const [tables, setTables] = useState<TimeTableData[]>([])
	const filter = useRecoilValue(timeValueFilterState)

	useEffect(() => {
		const next = () => {
			if (!filter) {
				return data
			}
			return data.filter(x => filter.selectedTimeKeys.includes(x.period))
		}
		setTables(next())
	}, [filter, data])

	return (
		<>
			<div className="mt-3 ml-3">
				<TimeValueFilter/>
			</div>
			<div className="d-flex flex-row">
				{
					tables.map((x, i) => {
						return (
							<TimeTable data={x} key={`band-table-${i}`}/>
						)
					})
				}
			</div>
		</>

	)
}

interface TimeTableProps {
	data: TimeTableData
}

const TimeTable = (props: TimeTableProps) => {
	const [rows, setRows] = useState<TimeTableRow[]>([])
	const paging = useRecoilValue(pagingState)
	const {data} = props
	const headerClasses = 'd-flex justify-content-center w-100'

	useEffect(() => {
		const start = (paging.pageNumber - 1) * paging.pageSize
		const end = start + paging.pageSize
		const next = [...data.rows].slice(start, end)
		setRows(next)
	}, [data, paging])

	return (
		<div className="d-flex flex-column pr-1">
			<div className="pricing-table-headers d-flex flex-column align-items-center">
				<div className={headerClasses}>{data.period}</div>
				<div className={headerClasses}>{data.variance}</div>
			</div>
			<div className="no-coloured-cell-padding">
				<DataTable columns={timeBandTableColumns} data={rows}/>
			</div>
		</div>
	)
}

const timeBandTableColumns: TableColumn<TimeTableRow> [] = [
	{
		name: 'Rate',
		selector: ({rate}) => rate,
		center: true,
		cell: (row: TimeTableRow) => {
			return <ColouredCell
				status={row.status}
				value={`${row.rate.toFixed(2)}%`}
				inactive={row.rate === 0}/>
		}
	},
	{
		name: 'Price ($)',
		selector: ({price}) => price,
		center: true,
		cell: (row: TimeTableRow) => {
			return <ColouredCell
				status={row.status}
				value={toUsd(row.price.toString())}
				inactive={row.rate === 0}/>
		}
	},
	{
		name: 'Conversion (%)',
		selector: ({conversion}) => conversion,
		center: true,
		cell: (row: TimeTableRow) => {
			return <ColouredCell
				status={row.status}
				value={`${row.conversion.toFixed(2)}%`}
				inactive={row.rate === 0}/>
		}
	},
	{
		name: 'Sales',
		selector: ({sales}) => sales,
		center: true,
		cell: (row: TimeTableRow) => {
			return <ColouredCell
				status={row.status}
				value={row.sales}
				inactive={row.rate === 0}/>
		}
	},
	{
		name: 'Revenue ($)',
		selector: ({revenue}) => revenue,
		center: true,
		cell: (row: TimeTableRow) => {
			return <ColouredCell
				status={row.status}
				value={toUsd(row.revenue.toString())}
				inactive={row.rate === 0}/>
		}
	}
]
