import React, {useEffect, useState} from 'react'
import {selectedLocationStateMember} from '../../../../state/member-filters'
import {Location} from '../../../../types'
import {FilterEnum} from '../../Enums/FilterEnum'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {useMemberApi} from '../../../../hooks/api/member'
import {useApiValue} from '../../../../hooks/api'
import {memberFilterState} from '../../../../state/memberFilterState'

const LocationDropdown = () => {
	const {getLocations} = useMemberApi()
	const {data, isLoading} = useApiValue(memberFilterState, getLocations, [])
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])

	useEffect(() => {
		if (data.length > 0 && !isLoading) {
			const next = [
				{value: FilterEnum.DefaultFilterAll, label: FilterEnum.DefaultFilterAll},
				...data?.map((x: Location) => (
					{
						value: x.transactionCountryCode,
						label: x.transactionCountryName
					}
				))
			]
			setMenuItems(next)
		}
	}, [data, isLoading])

	return (
		<StatefulSelectFilterCard
			state={selectedLocationStateMember}
			title="Location"
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {LocationDropdown}
