import React, {memo, useEffect, useState} from 'react'
import {TableCard} from '../../../components/Card/TableCard'
import DataTable, {TableColumn} from 'react-data-table-component'
import {useRecoilValue} from 'recoil'
import {documentFilterState, isSelectedActorInsurer} from '../../../state/document-filters'
import {useDocumentApi} from '../../../hooks/api/document'
import {useApiValue} from '../../../hooks/api'

interface DocumentLinkProps {
	document: any,
}

const DocumentLink = (props: DocumentLinkProps) => {
	const {document: {path, actorId, documentName, blobName}} = props
	const {getBlobInfo} = useDocumentApi()
	const {data: blobInfo} = useApiValue(documentFilterState, getBlobInfo, [])
	const [documentPath, setDocumentPath] = useState<string>('')

	useEffect(() => {
		if (blobInfo) {
			const {sasToken, baseUrl} = blobInfo
			const next = `${baseUrl}/${blobName}/${documentName}${sasToken}`
			setDocumentPath(next)
		}
	}, [blobInfo, path, actorId, documentName])

	return (
		<a target="_blank" rel="noreferrer"
		   href={documentPath}>
			{documentName} </a>
	)
}

const DocumentTable = memo(() => {
	const {getDocuments} = useDocumentApi()
	const {data, isLoading} = useApiValue(documentFilterState, getDocuments, [])
	const isInsurer = useRecoilValue(isSelectedActorInsurer)

	const headerName = () => {
		if (isInsurer !== null) {
			if (isInsurer) {
				return 'Insurer'
			}
			return 'Member'
		}
		return 'Actor'
	}

	const columns: TableColumn<any> [] = [
		{
			name: 'Actor Id',
			selector: (row: any) => row.actorId,
			omit: true
		},
		{
			name: headerName(),
			selector: (row: any) => row.actorName,
			sortable: true,
		},
		{
			name: 'Document',
			selector: (row: any) => row.documentName,
			sortable: true,
			format: (row: any) => <DocumentLink document={row}/>
		},
		{
			name: 'Document Type',
			selector: (row: any) => row.documentType,
			sortable: true,
		},
		{
			name: 'Date Uploaded',
			selector: (row: any) => row.dateUploaded,
			sortable: true,
		},
		{
			name: 'Size',
			selector: (row: any) => row.size,
			sortable: true,
			format: (row: any) => (row['size'] + ' KB')
		}
	]

	return (
		<TableCard
			title="Documents"
			titleClass="card-title"
			showToggle={false}
			isLoading={isLoading}
		>
			<DataTable
				data={data}
				columns={columns}
				theme="dark"
				pagination={true}
				highlightOnHover={true}
				disabled={isLoading}
				paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40, 50, 100]}
			/>
		</TableCard>
	)
})

export {DocumentTable}
