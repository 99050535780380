import React, {memo} from 'react'
import {ValueCard} from '../../../../components/Card/ValueCard'
import {integrationFilterState} from '../../../../state/integration'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'
import {defaultStatusData} from '../../../../types/integration'

const StatusProgressSummary = memo(() => {
	const {getStatusSummary} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getStatusSummary, defaultStatusData)

	return (
		<ValueCard
			title={`Integrations in progress`}
			value={data.inProgress}
			icon="icon-double-right"
			tooltip={'Total count of in progress integrations'}
		/>
	)
})

export {StatusProgressSummary}