import React from 'react'
import {dashboardPaths, Dashboards} from '../../../types'
import {NavLink, useLocation} from 'react-router-dom'
import classNames from 'classnames'

const DashboardNavBar = () => {
	return (
		<nav className="
			bottom-0 fixed flex items-center justify-center left-0 right-0 z-10
			bg-white
			border-pulse-icon-gray border-t
			lg:hidden"
		>
			<NavBarLink icon="all_inclusive" dashboard={Dashboards.PulseOverview}/>
			<NavBarLink icon="account_balance_wallet" dashboard={Dashboards.PulseRevenueTrends}/>
			<NavBarLink icon="travel_explore" dashboard={Dashboards.PulseCustomerTrends}/>
			<NavBarLink icon="receipt_long" dashboard={Dashboards.PulseTransactions}/>
			<NavBarLink icon="tune" dashboard={Dashboards.PulseSettings}/>
		</nav>
	)
}

const NavBarLink = ({ icon, dashboard }: { icon: string, dashboard: Dashboards }) => {
	const {pathname} = useLocation()

	return (
		<NavLink
			to={dashboardPaths[dashboard]}
			className={classNames('block p-4',
				'text-3xl',
				'material-symbols-outlined',
				'hover:text-pulse-blue', {
					'text-pulse-blue': pathname === dashboardPaths[dashboard],
					'text-pulse-icon-gray': pathname !== dashboardPaths[dashboard]
				})}
		>
			{icon}
		</NavLink>
	)
}

export default DashboardNavBar
