import React, {memo} from 'react';
import {ValueCard} from '../../../../components/Card/ValueCard';
import useSummaryCardColor from "../../hooks/useSummaryCardColor";
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const EventsSummary = memo(() => {
	const {getEventsSummary} = useInsuranceApi();
	const {data} = useApiValue(insuranceProviderFilterState, getEventsSummary, 0)
	const color = useSummaryCardColor()
	
	return (
		<ValueCard
			title={`Events`}
			value={data}
			icon="icon-book-bookmark"
			iconColor={color}
			tooltip={"Total Count of Events."}
		/>
	);
})

export {EventsSummary}