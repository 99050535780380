import React, {ReactElement} from 'react'
import classNames from 'classnames'
import {Card, CardBody, CardHeader} from 'reactstrap'

interface PerformanceCardProps {
	position?: number | string,
	icon?: string,
	title: string,
	subTitle?: string,
	children: ReactElement,
	inverse?: boolean,
	headerColor?: string
}

interface PerformanceCardValueProps {
	title: string,
	value: string,
	previousValue?: string,
	valueIcon?: ReactElement
}

const PerformanceCard = (props: PerformanceCardProps) => {
	const {position, icon, title, subTitle, children, inverse, headerColor} = props
	const headerStyle: React.CSSProperties = {}

	if (headerColor) {
		headerStyle.color = headerColor
	}

	return (
		<Card
			className={classNames({
				'inverse': inverse
			})}
		>
			<CardHeader>
				<div className="d-flex flex-row justify-content-between align-items-center">
					<div className="pr-2 card-header-icon">
						{
							position
								? (
									<span className="text-white">{position}</span>
								) : (
									<i className={classNames('tim-icons', icon)}/>
								)
						}
					</div>
					<div className="card-title w-100 text-right text-uppercase" style={headerStyle}>{title}</div>
				</div>
				{
					(subTitle) && (
						<div className="card-subtitle">{subTitle}</div>
					)
				}
			</CardHeader>
			<CardBody>
				{children}
			</CardBody>
		</Card>
	)
}

const PerformanceCardValue = (props: PerformanceCardValueProps) => {
	const {title, value, valueIcon, previousValue} = props
	return (
		<div className="d-flex flex-row justify-content-between text-uppercase pl-1 pr-1 text-white">
			<div className="performance-card-value-title text-white">{title}</div>
			<div className="d-flex align-items-center">
				{
					(previousValue) && (
						<span className="text-muted comparison-text pr-2 text-white">({previousValue})</span>
					)
				}
				{
					(valueIcon) && (
						<span className="pr-2">{valueIcon}</span>
					)
				}
				{value}
			</div>
		</div>
	)
}

export {PerformanceCard, PerformanceCardValue}
