import React from 'react'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import TrustPilotValueCard from './Common/TrustPilotValueCard/TrustPilotValueCard'

const RefundAutoApprovalSummary = () => {
	const {getRefundAutoApproveRate} = usePulseApi()
	const value = useApiValue(filterState, getRefundAutoApproveRate, 0)

	return (
		<TrustPilotValueCard
			value={`${value.data}%`}
			header="% of AI Instant Refunds"
			tooltipText="This refers to the percentage of refunds that are processed automatically through our AI technology
				without any manual intervention."
			isLoading={value.isLoading}
		/>
	)
}

export default RefundAutoApprovalSummary
