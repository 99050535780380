import React, {ReactElement, useRef, useState} from 'react'
import {CSVLink} from 'react-csv'
import * as XLSX from 'xlsx'
import {ExportData} from '../../../types/pulse-data'

export function useExportData(props: {
	getData(): Promise<any>
	getHeaders(): string[]
	getFileName(): string
}): ExportData {
	const [csvElement, setCSVElement] = useState<ReactElement>()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)

	const exportCSV = async () => {
		try {
			setIsLoading(true)
			setCSVElement(<CSVLink
				data={await props.getData()}
				headers={props.getHeaders()}
				filename={`${props.getFileName()}.csv`}
				ref={csvLinkRef}
				target="_blank"/>)
			setTimeout(() => csvLinkRef.current?.link.click(), 500)
		} finally {
			setIsLoading(false)
		}

	}

	const exportXLSX = async () => {
		try {
			setIsLoading(true)
			const data = await props.getData()
			const headers = props.getHeaders()
			const xlsxData = [headers]

			for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
				const row = data[rowIndex]
				const rowArray: string[] = []

				for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
					rowArray.push(row[headers[headerIndex]])
				}

				xlsxData.push(rowArray)
			}

			const workBook = XLSX.utils.book_new()
			const workSheet = XLSX.utils.aoa_to_sheet(xlsxData)

			XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1')
			XLSX.writeFile(workBook, `${props.getFileName()}.xlsx`)
		} finally {
			setIsLoading(false)
		}
	}

	return {
		exportCSV,
		exportXLSX,
		csvElement,
		isLoading
	}
}
