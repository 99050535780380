import React from 'react'
import {Col, Row} from 'reactstrap';
import {InsuranceValueSummary} from "./InsuranceValueSummary";
import {InsuranceRateSummary} from "./InsuranceRateSummary";
import {InsurancePremiumValueSummary} from "./InsurancePremiumValue";
import useGetMainProductStates from "../../hooks/useGetMainProductStates";
import {LastTransactionDates} from "./LastTransactionDates";
import SummaryCardWrapper from "../../../../components/Dashboard/SummaryCardWrapper";
import {EventsSummary} from "./EventsSummary";
import {TransactionsSummary} from "./TransactionsSummary";

const HeaderInfo = () => {
  const {isProductRp} = useGetMainProductStates()

  return (
    <>
      <Row>
        <Col className="ml-auto col-sm-6 pb-1">
          <LastTransactionDates/>
        </Col>
      </Row>
      <div className="d-flex justify-content-between">
        {
          !isProductRp &&
          <SummaryCardWrapper className="px-1">
            <EventsSummary/>
          </SummaryCardWrapper>
        }
        <SummaryCardWrapper className="px-1">
          <TransactionsSummary/>
        </SummaryCardWrapper>
        <SummaryCardWrapper className="px-1">
          <InsuranceValueSummary/>
        </SummaryCardWrapper>
        <SummaryCardWrapper className="px-1">
          <InsuranceRateSummary/>
        </SummaryCardWrapper>
        <SummaryCardWrapper className="px-1">
          <InsurancePremiumValueSummary/>
        </SummaryCardWrapper>
      </div>
    </>
  )
}

export {HeaderInfo}