import React from "react";
import {TransactionsTableSearchFilter} from "./TransactionsTableSearchFilter";
import {DownloadCsvFile} from "./DownloadCsvFile";

interface TransactionsTableHeaderProps {
    loading: boolean;
}

const TransactionsTableHeaderActions = (props: TransactionsTableHeaderProps) => {
    const {loading} = props;

    return (
        <div className={`ml-auto ${loading ? "disabled-transactions-table-header-actions" : ""}`}>
            <DownloadCsvFile/>
            <TransactionsTableSearchFilter/>
        </div>
    )
}

export {TransactionsTableHeaderActions}
