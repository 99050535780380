import React from 'react'
import ChartSummaryPill from './ChartSummaryPill'
import {ChartSummaryData} from '../../../types'

interface ChartSummaryTableProps {
	data: ChartSummaryData
	format: (value: number | string) => string
	isLoading?: boolean
}

export const ChartSummaryTable = (props: ChartSummaryTableProps) => {
	const {format} = props

	return (
		<div className="chart-summary-pills">
			<ChartSummaryPill value={props.data.totalDtd} label="DAY" format={format}/>
			<ChartSummaryPill value={props.data.totalWtd} label="WTD" format={format}/>
			<ChartSummaryPill value={props.data.totalMtd} label="MTD" format={format}/>
			<ChartSummaryPill value={props.data.totalYtd} label="YTD" format={format}/>
		</div>
	)
}
