import React, {useEffect, useState} from 'react'
import {Button, FormGroup, Spinner} from 'reactstrap'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {CSVLink} from 'react-csv'
import {memberFilterState} from '../../../../state/memberFilterState'
import {notificationMessageState} from '../../../../state/ui'
import {NotificationMessageType} from '../../../../types'
import {selectedCoreCurrencyStateMember} from '../../../../state/member-filters'
import {useMemberApi} from '../../../../hooks/api/member'

const DownloadCsvFile = () => {
	const {getTransactionCsvData} = useMemberApi()
	const [isLoading, setIsLoading] = useState(false)
	const filter = useRecoilValue(memberFilterState)
	const setNotification = useSetRecoilState(notificationMessageState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyStateMember)

	const [csvData, setCsvData] = useState<any>([])

	async function getCsvData() {
		setIsLoading(true)

		try {
			const data = await getTransactionCsvData(filter)
			setCsvData(data)
		} catch (e) {
			setNotification({
				message: 'Unable to retrieve data',
				type: NotificationMessageType.error
			})
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		setCsvData([])
	}, [filter])

	const CSVFileName = () => 'PG-Member Transactions ' + new Date().toDateString() + '.csv'

	const headers = [
		{label: 'Member Name', key: 'memberName'},
		{label: 'Booking Reference', key: 'transactionReferenceId'},
		{label: 'Product Code', key: 'productCode'},
		{label: 'Customer Name', key: 'customerName'},
		{label: 'Core Value', key: `transactionValue${coreCurrency}`},
		{label: 'Core Currency', key: 'coreCurrency'},
		{label: 'Original Value', key: 'originalTransactionValue'},
		{label: 'Original Currency', key: 'originalCurrency'},
		{label: 'Consumer Rate', key: 'consumerRate'},
		{label: 'Transaction Date', key: 'transactionDate'},
		{label: 'Event Date', key: 'transactionEndDate'},
		{label: 'Protected', key: 'isInsured'},
		{label: 'Cancelled', key: 'isCancelled'}
	]

	return (
		<div className="d-flex justify-content-end">
			{
				!isLoading ? //TODO: This part should be in useEffect.
					(
						<FormGroup className="d-flex pb-1">
							{
								(csvData.length === 0) && (
									<Button
										className="ml-2 btn btn-sm my-0"
										onClick={getCsvData}>Prepare (CSV File)</Button>
								)
							}
							{
								(csvData.length > 0) && (
									<CSVLink
										filename={CSVFileName()}
										data={csvData}
										headers={headers}
										className="ml-2 btn btn-success btn-sm">
										Download
									</CSVLink>
								)
							}
						</FormGroup>
					)
					:
					(
						<Spinner className="ml-2 mb-2" color="white"/>
					)
			}
		</div>
	)
}

export {DownloadCsvFile}
