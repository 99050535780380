import {usePulseApi} from '../../../hooks/api/pulse'
import {filterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import moment from 'moment'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {formatCurrency} from '../../../utils/currency'
import {ExportData} from '../../../types/pulse-data'
import {useExportData} from './useExportData'
import {formatNumberMember} from '../../../utils/number'

export default function useExportOverviewRefundsData(): ExportData {
	const {
		getApprovedRefundValueSummary,
		getApprovedRefundVolumeSummary,
		getDeclinedRefundValueSummary,
		getDeclinedRefundVolumeSummary,
		getRefundValueSummary,
		getRefundVolumeSummary,
		getAverageApprovedRefundValue,
		getMaxRefundValue,
		getApprovedRefundConversionRate,
		getDeclinedRefundConversionRate,
		getTopApprovedRefundReasons,
		getTopDeclinedRefundReasons
	} = usePulseApi()
	const filter = useRecoilValue(filterState)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	const getData = async (): Promise<any> => {
		const result = [
			{
				Property: 'Total Refund Applications Value',
				Value: formatCurrency(coreCurrency, await getRefundValueSummary(filter))
			},
			{
				Property: 'Number of Refund Applications',
				Value: formatNumberMember(await getRefundVolumeSummary(filter))
			},
			{
				Property: 'Declined Refunds',
				Value: formatCurrency(coreCurrency, await getDeclinedRefundValueSummary(filter))
			},
			{
				Property: 'Declined Refunds Volume',
				Value: formatNumberMember(await getDeclinedRefundVolumeSummary(filter))
			},
			{
				Property: 'Paid Refunds',
				Value: formatCurrency(coreCurrency, await getApprovedRefundValueSummary(filter))
			},
			{
				Property: 'Paid Refunds Volume',
				Value: formatNumberMember(await getApprovedRefundVolumeSummary(filter))
			},
			{
				Property: 'Approved Refunds',
				Value: `${await getApprovedRefundConversionRate(filter)}%`
			},
			{
				Property: 'Declined Refunds',
				Value: `${await getDeclinedRefundConversionRate(filter)}%`
			},
			{
				Property: 'Average Refund Amount',
				Value: formatCurrency(coreCurrency, await getAverageApprovedRefundValue(filter), 0, 4)
			},
			{
				Property: 'Highest Refund Amount',
				Value: formatCurrency(coreCurrency, await getMaxRefundValue(filter), 0, 4)
			}
		]

		const topApprovedReasons = await getTopApprovedRefundReasons(filter)
		topApprovedReasons.forEach((value, index) => result.push({
			Property: `Top Approved Refund Reason ${index + 1}`,
			Value: value
		}))

		const topDeclinedReasons = await getTopDeclinedRefundReasons(filter)
		topDeclinedReasons.forEach((value, index) => result.push({
			Property: `Top Declined Refund Reason ${index + 1}`,
			Value: value
		}))

		return result
	}

	const getFileName = () => `Overview-Refunds-${moment().toISOString()}`
	const getHeaders = () => ['Property', 'Value']

	return useExportData({
		getData: getData,
		getFileName: getFileName,
		getHeaders: getHeaders
	})
}
