import React, {useCallback, useEffect, useState} from 'react'
import {selectedMainProductIdStateMember} from '../../../../state/member-filters'
import {MainProduct} from '../../../../types'
import {FilterEnum} from '../../Enums/FilterEnum'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {useMemberApi} from '../../../../hooks/api/member'
import {useApiValue} from '../../../../hooks/api'
import {memberFilterState} from '../../../../state/memberFilterState'

const ProductDropdown = () => {
	const {getProducts} = useMemberApi()
	const {data, isLoading} = useApiValue(memberFilterState, getProducts, [])
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])

	const handleData = useCallback(() => {
		if (data.length > 0 && !isLoading) {
			const next = [
				{value: 0, label: FilterEnum.DefaultFilterAll},
				...data?.map((x: MainProduct) => (
					{
						value: x.id,
						label: x.productName
					}
				))
			]
			setMenuItems(next)
		}
	}, [data, isLoading])

	useEffect(handleData, [handleData])

	return (
		<StatefulSelectFilterCard
			state={selectedMainProductIdStateMember}
			title="Product"
			items={menuItems}
			showDefault={false}
		/>
	)
}

export {ProductDropdown}
