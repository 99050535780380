import {useApiActions} from './index'
import {CommissionFilter, Member} from '../../types'
import {UserChartData} from '../../types/commission'

export const useCommissionApi = () => {
	const {callApi} = useApiActions('commission')

	const getCompanyRanking = async (filter: CommissionFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'company-ranking'
		})
	}

	const getEpCommissionData = async (filter: CommissionFilter): Promise<any> => {
		return await callApi<any, string>({
			query: filter,
			path: 'ep'
		})
	}

	const getMembers = async (filter: CommissionFilter): Promise<Member[]> => {
		return await callApi<Member[], string>({
			query: filter,
			path: 'members'
		})
	}

	const getRpCommissionData = async (filter: CommissionFilter): Promise<any> => {
		return await callApi<any, string>({
			query: filter,
			path: 'rp'
		})
	}

	const getTeamTotalCommission = async (filter: CommissionFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'team-total'
		})
	}

	const getUsers = async (filter: CommissionFilter): Promise<any[]> => {
		return await callApi<any[], string>({
			query: filter,
			path: 'users'
		})
	}

	const getUserChartData = async (filter: CommissionFilter): Promise<UserChartData> => {
		return await callApi<UserChartData, string>({
			query: filter,
			path: 'user'
		})
	}

	const getUserRates = async (filter: CommissionFilter): Promise<any[]> => {
		return await callApi<any[], string>({
			query: filter,
			path: 'user-rates'
		})
	}

	const getUserSalesTeams = async (filter: CommissionFilter): Promise<any[]> => {
		return await callApi<any[], string>({
			query: filter,
			path: 'sales-teams'
		})
	}

	const getUserTotalCommission = async (filter: CommissionFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'user-total'
		})
	}

	const getUserTeamRanking = async (filter: CommissionFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'team-ranking'
		})
	}

	return {
		getCompanyRanking,
		getEpCommissionData,
		getMembers,
		getRpCommissionData,
		getTeamTotalCommission,
		getUsers,
		getUserChartData,
		getUserRates,
		getUserSalesTeams,
		getUserTeamRanking,
		getUserTotalCommission,
	}
}
