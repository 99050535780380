import React from 'react'
import classNames from 'classnames'
import {dateRange} from '../../../../../types/pulse'
import moment from 'moment/moment'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {dateFromState, dateToState, selectedDateRangeState} from '../../../../../state/pulse-filters'
import {calendarDatesState} from '../../../../../state/pulse'

const YearToDateFilter = () => {
	const currentDate = moment()
	const date = useRecoilValue(selectedDateRangeState)
	const setDateFrom = useSetRecoilState(dateFromState)
	const setDateTo = useSetRecoilState(dateToState)
	const setCalendarDates = useSetRecoilState(calendarDatesState)

	const handleYearToDateClick = () => {
		const from = moment().startOf('year')
		const to = moment().startOf('day')

		setCalendarDates([from.toDate(), to.toDate()])
		setDateFrom(from)
		setDateTo(to)
	}

	return (
		<div
			className={classNames('left-4 mb-2 mr-2 px-3 py-[2px] top-2',
				'border border-pulse-icon-gray rounded-full',
				'text-nowrap text-sm tracking-[1px] uppercase',
				'cursor-pointer',
				'hover:bg-pulse-button-blue',
				'lg:mb-0 lg:relative lg:left-0 lg:top-0', {
					'bg-pulse-button-blue': date === dateRange.YearToDate,
					'bg-white': date !== dateRange.YearToDate,
					'absolute': date === dateRange.YearToDate
				})}
			onClick={handleYearToDateClick}
		>
			<span className="align-middle leading-5">YTD {currentDate.format('YYYY')}</span>
		</div>
	)
}

export default YearToDateFilter
