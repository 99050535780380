import React from 'react'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import ReactDatetime from 'react-datetime'
import {Button, ButtonGroup, FormGroup} from 'reactstrap'
import {FilterCard} from '../../../components/Card/FilterCard'
import {
	dateFromState,
	dateToState,
	isDateFilterToggleSelectedState,
	selectedDateFilterToggleState
} from '../../../state/filters'
import moment, {Moment} from 'moment'
import {monthYearFormat} from '../../../utils/date'
import {DateFilterToggle} from '../../../types'
import classNames from 'classnames'
import {dateFilterTogglesState} from '../../../state/filter-data'

interface DateFilterToggleProps {
	toggle: DateFilterToggle
}

const DateFilterToggleButton = (props: DateFilterToggleProps) => {
	const {toggle} = props
	const isSelected = useRecoilValue(isDateFilterToggleSelectedState(toggle))

	const setDateFrom = useSetRecoilState(dateFromState)
	const setDateTo = useSetRecoilState(dateToState)
	const setToggle = useSetRecoilState(selectedDateFilterToggleState)

	const handleClick = () => {
		setDateFrom(toggle.dateFrom)
		setDateTo(toggle.dateTo)
		setToggle(toggle)
	}

	return (
		<Button
			color="info"
			size="sm"
			tag="label"
			className={classNames('btn-simple', {
				active: isSelected
			})}
			onClick={handleClick}
		>
			<input defaultChecked name="options" type="radio"/>
			<span className="d-block">
        {toggle.label}
      </span>
		</Button>
	)
}

const DateShortcuts = () => {
	const toggles = useRecoilValue(dateFilterTogglesState)

	return (
		<ButtonGroup className="btn-group-toggle">
			{toggles.map(toggle => <DateFilterToggleButton key={toggle.label} toggle={toggle}/>)}
		</ButtonGroup>
	)
}

const DateFilters = () => {
	const [dateFrom, setDateFrom] = useRecoilState(dateFromState)
	const [dateTo, setDateTo] = useRecoilState(dateToState)

	const handleDateFromChange = (date: Moment | string) => setDateFrom(moment(date))
	const handleDateToChange = (date: Moment | string) => setDateTo(moment(date))

	return (
		<FilterCard
			title="From / To"
			iconColor="warning"
			icon="icon-calendar-60"
		>
			<>
				<div className="d-flex flex-row justify-content-end">
					<FormGroup className="pr-2 mb-0">
						<ReactDatetime
							inputProps={{
								className: 'form-control',
								placeholder: 'Date From'
							}}
							value={dateFrom}
							onChange={handleDateFromChange}
							closeOnSelect={true}
							dateFormat={monthYearFormat}
							timeFormat={false}
						/>
					</FormGroup>
					<FormGroup className="mb-0">
						<ReactDatetime
							inputProps={{
								className: 'form-control',
								placeholder: 'Date To'
							}}
							value={dateTo}
							onChange={handleDateToChange}
							closeOnSelect={true}
							dateFormat={monthYearFormat}
							timeFormat={false}
							initialViewMode="months"
						/>
					</FormGroup>
				</div>
				<div className="d-flex justify-content-end">
					<DateShortcuts/>
				</div>
			</>
		</FilterCard>
	)
}

export {DateFilters}
