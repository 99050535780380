import {atom, selector, selectorFamily} from 'recoil'
import {User} from 'oidc-client'
import {dashboardRoles, Dashboards, Roles} from '../types'

export const authenticatedUserState = atom<User | null>({
	key: 'authenticatedUserState',
	default: null
})

export const isUserAuthenticatedState = selector<boolean>({
	key: 'isUserAuthenticatedState',
	get: ({get}) => {
		const user = get(authenticatedUserState)
		return user !== null
	}
})

export const loggedInUserState = selector<string | undefined>({
	key: 'loggedInUserState',
	get: ({get}) => {
		const user = get(authenticatedUserState)
		if (user) {
			return user.profile.name?.trim()
		}
		return undefined
	}
})

export const userIdTokenState = selector<string | undefined>({
	key: 'userIdTokenState',
	get: ({get}) => {
		const user = get(authenticatedUserState)
		return user?.id_token
	}
})

const userRolesState = selector<string>({
	key: 'userRolesState',
	get: ({get}) => {
		const user = get(authenticatedUserState)

		if (user !== null && (user as User) !== null) {
			const cast: User = (user as User)
			return cast.profile?.Roles
		}

		return ''
	}
})

export const userInsuranceProviderDataIdsState = selector<string>({
	key: 'userInsuranceProviderDataIdsState',
	get: ({get}) => {
		const user = get(authenticatedUserState)

		if (user !== null && (user as User) !== null) {
			const cast: User = (user as User)
			return cast.profile?.InsuranceProviderDataIds ?? ''
		}

		return ''
	}
})

export const userBrokerDataIdsState = selector<string>({
	key: 'userBrokerDataIdsState',
	get: ({get}) => {
		const user = get(authenticatedUserState)

		if (user !== null && (user as User) !== null) {
			const cast: User = (user as User)
			return cast.profile?.BrokerDataIds ?? ''
		}

		return ''
	}
})

export const userHasRoleState = selectorFamily<boolean, Roles>({
	key: 'userHasRoleState',
	get: (role: Roles) => ({get}) => {
		const roles = get(userRolesState)
		return roles.indexOf(role) > -1
	}
})

export const canUserSeeLinkState = selectorFamily<boolean, Dashboards>({
	key: 'canUserSeeLinkState',
	get: (dashboard: Dashboards) => ({get}) => {
		const roles = get(userRolesState)
		const requiredRole = dashboardRoles[dashboard]
		if (!roles) {
			return false
		}

		return roles?.indexOf(requiredRole) > -1
	}
})

export const isPartnerCommissionDashboardUserState = selector<boolean>({
	key: 'isPartnerCommissionDashboardUserState',
	get: ({get}) => {
		return get(userRolesState).indexOf('CommissionDashboardPartnerUser') > -1
	}
})

export const canDownloadValueBandDataState = selector<boolean>({
	key: 'canDownloadValueBandDataState',
	get: ({get}) => {
		const roles = get(userRolesState)
		return (roles.indexOf('Admin') > -1 || roles.indexOf('EventProtectAdmin') > -1)
	}
})
