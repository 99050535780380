import React, {useEffect, useState} from 'react'
import {salesFilterState, selectedRegionState, selectedTerritoryState} from '../../../../state/sales'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {FilterEnum} from '../../../../types/sales'
import {DropdownData} from '../../../../types'
import {useRecoilValue} from 'recoil'
import {useSalesApi} from '../../../../hooks/api/sales'
import {useApiValue} from '../../../../hooks/api'

const selectFilterItem = {value: FilterEnum.DefaultFilterAll, label: FilterEnum.DefaultFilterAll}
export const RegionFilter = () => {

	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const {getRegions} = useSalesApi()
	const {data} = useApiValue(salesFilterState, getRegions, [])
	const selectedTerritory = useRecoilValue(selectedTerritoryState)

	useEffect(() => {
		let next: SelectFilterItem[] = [selectFilterItem]
		if (selectedTerritory !== FilterEnum.DefaultFilterAll && data) {
			next.push(
				...data.map((x: DropdownData) => ({
					value: x.name,
					label: x.name
				})))
		}
		setMenuItems(next)
	}, [data])

	return (
		<StatefulSelectFilterCard
			state={selectedRegionState}
			title="Region"
			items={menuItems}
			showDefault={false}
		/>
	)
}
