import React from 'react'
import PrimaryValueCard from './Common/Cards/PrimaryValueCard'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'

const OverviewDeclinedRefundValueSummary = () => {
	const {getDeclinedRefundValueSummary, getDeclinedRefundRateTrend} = usePulseApi()
	const value = useApiValue(filterState, getDeclinedRefundValueSummary, 0)
	const trendRate = useApiValue(filterState, getDeclinedRefundRateTrend, 0)

	return (
		<PrimaryValueCard
			value={value.data}
			trendValue={trendRate.data}
			header="Declined Refunds"
			tooltipText="Total value of refund requests that were denied."
			valueClassName="text-black"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewDeclinedRefundValueSummary
