import {RegionData} from "../../../types/dynamic-performance";
import {useRecoilValue} from "recoil";
import {autoRefreshState} from "../../../state/filters";
import {useEffect, useState} from "react";
import {filterState} from '../../../state/dynamic-performance'
import {usePerformanceApi} from '../../../hooks/api/live-performance'
import {useStatelessApiValue} from '../../../hooks/api'
import {useDynamicPerformanceApi} from '../../../hooks/api/dynamic'
import {Region} from '../../../types'

const getFilteredRegions = (data: RegionData[]) => {
	return data
		.filter(region => {
			return mapsByRegionId[region.regionId] !== undefined
		})
}

export const useGetRegionData = () => {
	const {getRegionData} = useDynamicPerformanceApi()
	const {getPerformanceRegions} = usePerformanceApi()
	const {data: regions} = useStatelessApiValue(getPerformanceRegions, [])
	const autoRefresh = useRecoilValue(autoRefreshState)
	const filter = useRecoilValue(filterState)
	const [data, setData] = useState<RegionData[]>([])

	useEffect(() => {
		if (!regions.length) {
			return
		}

		(async () => {
			try {
				const requests = regions.map((x: Region) => getRegionData(filter, x.id))
				const responses = await Promise.all(requests)
				const next = getFilteredRegions(responses)
				setData(next)
			} catch (err) {
				console.error(err)
			}
		})()
	}, [regions, autoRefresh, filter])

	return { data }
}

const mapsByRegionId: Record<number, string> = {
	2: 'oceania_mill',
	3: 'asia_wme_mill',
	4: 'europe_mill',
	6: 'africa_me_mill',
	7: 'north_america_mill',
	8: 'south_america_mill'
}
