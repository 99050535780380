import React, {ReactElement} from 'react'
import {DateFilters} from '../../components/Dashboard/DateFilters'
import MemberFilter from './components/MemberFilter'
import ValueBandFilter from './components/ValueBandFilter'
import TimeBandFilter from './components/TimeBandFilter'
import AgeDemographicFilter from './components/AgeDemographicFilter'
import UserFilter from './components/UserFilter'
import SalesVolumeChart from './components/SalesVolumeChart'
import SalesValueChart from './components/SalesValueChart'
import {Col, Row} from 'reactstrap'
import OpportunityValueChart from './components/OpportunityValueChart'
import RevenueGeneratedChart from './components/RevenueGeneratedChart'
import RevenueValueChart from './components/RevenueValueChart'
import PricingAndContentTestTable from './components/PricingAndContentTestTable'
import RegionMaps from './components/RegionMaps'
import RevenueByValueChart from './components/RevenueByValueChart'
import RevenueByTimeChart from './components/RevenueByTimeChart'
import {AutoRefresh} from '../../components/AutoRefresh'
import SummaryCardWrapper from '../../components/Dashboard/SummaryCardWrapper'
import FilterCardWrapper from '../../components/Dashboard/Dynamic/FilterCardWrapper'
import SalesVolumeSummary from '../../components/Dashboard/Dynamic/SalesVolumeSummary'
import SalesValueSummary from '../../components/Dashboard/Dynamic/SalesValueSummary'
import OpportunityValueSummary from '../../components/Dashboard/Dynamic/OpportunityValueSummary'
import RevenueGeneratedSummary from '../../components/Dashboard/Dynamic/RevenueGeneratedSummary'
import RevenueValueSummary from '../../components/Dashboard/Dynamic/RevenueValueSummary'
import CurrencyFilter from '../../components/Dashboard/Dynamic/CurrencyFilter'
import RiskTypeFilter from '../../components/Dashboard/Dynamic/RiskTypeFilter'
import RegionFilter from '../../components/Dashboard/Dynamic/RegionFilter'
import {dateFromState, dateToState} from '../../state/dynamic-data'
import {useRecoilValue} from 'recoil'
import {userHasRoleState} from '../../state/auth'
import {Roles} from '../../types'
import {TransactionTable} from './components/TransactionTable'
import AverageTransactionRate from './components/AverageTransactionRate'
import AverageConversionRate from './components/AverageConversionRate'

const AdminHeaders = () => {
	return (
		<>
			<Row className="d-flex justify-content-between pt-4">
				<FilterCardWrapper className="pr-2">
					<DateFilters fromState={dateFromState} toState={dateToState}/>
				</FilterCardWrapper>
				<FilterCardWrapper className="pr-2">
					<MemberFilter/>
				</FilterCardWrapper>
				<FilterCardWrapper className="pr-2">
					<ValueBandFilter/>
				</FilterCardWrapper>
				<FilterCardWrapper>
					<TimeBandFilter/>
				</FilterCardWrapper>
			</Row>
			<Row className="d-flex justify-content-between pt-1">
				<FilterCardWrapper className="pr-2">
					<RiskTypeFilter/>
				</FilterCardWrapper>
				<FilterCardWrapper className="pr-2">
					<RegionFilter/>
				</FilterCardWrapper>
				<FilterCardWrapper className="pr-2">
					<CurrencyFilter/>
				</FilterCardWrapper>
				<FilterCardWrapper className="pr-2">
					<AgeDemographicFilter/>
				</FilterCardWrapper>
				<FilterCardWrapper>
					<UserFilter/>
				</FilterCardWrapper>
			</Row>
		</>
	)
}

const UserHeaders = () => {
	return (
		<div className="d-flex justify-content-around pt-2">
			<FilterCardWrapper className="pr-2">
				<DateFilters fromState={dateFromState} toState={dateToState}/>
			</FilterCardWrapper>
			<FilterCardWrapper className="pl-2">
				<MemberFilter/>
			</FilterCardWrapper>
		</div>
	)
}

const DynamicPerformanceView = () => {
	const isAdmin = useRecoilValue(userHasRoleState(Roles.DynamicDashboardAdmin))

	const headers: Record<string, ReactElement> = {
		'true': <AdminHeaders/>,
		'false': <UserHeaders/>
	}

	return (
		<div className="content">
			<AutoRefresh/>
			<div className="d-flex flex-wrap justify-content-between">
				<SummaryCardWrapper className="px-1 pb-2 w-25">
					<SalesVolumeSummary/>
				</SummaryCardWrapper>
				<SummaryCardWrapper className="px-1 pb-2 w-25">
					<SalesValueSummary/>
				</SummaryCardWrapper>
				<SummaryCardWrapper className="px-1 pb-2 w-25">
					<OpportunityValueSummary/>
				</SummaryCardWrapper>
				<SummaryCardWrapper className="px-1 pb-2 w-25">
					<RevenueGeneratedSummary/>
				</SummaryCardWrapper>
				<SummaryCardWrapper className="px-1 pb-2 w-25">
					<RevenueValueSummary/>
				</SummaryCardWrapper>
				<SummaryCardWrapper className="px-1 pb-2 w-25">
					<AverageTransactionRate/>
				</SummaryCardWrapper>
				<SummaryCardWrapper className="px-1 pb-2 w-25">
					<AverageConversionRate/>
				</SummaryCardWrapper>
			</div>
			{
				headers[isAdmin.toString()]
			}
			<Row className="d-flex justify-content-between pt-4">
				<Col sm={12} lg={4}>
					<SalesVolumeChart/>
				</Col>
				<Col sm={12} lg={4}>
					<SalesValueChart/>
				</Col>
				<Col sm={12} lg={4}>
					<OpportunityValueChart/>
				</Col>
			</Row>
			<Row className="pt-4">
				<Col sm={12} lg={6}>
					<RevenueGeneratedChart/>
				</Col>
				<Col sm={12} lg={6}>
					<RevenueValueChart/>
				</Col>
			</Row>
			<Row className="pt-4">
				<Col>
					<TransactionTable/>
				</Col>
			</Row>
			{
				(isAdmin) && (
					<Row className="pt-4">
						<Col>
							<PricingAndContentTestTable/>
						</Col>
					</Row>
				)
			}
			<Row className="pt-4">
				<Col>
					<RegionMaps/>
				</Col>
			</Row>
			<Row className="pt-4">
				<Col>
					<RevenueByValueChart/>
				</Col>
			</Row>
			<Row className="pt-4">
				<Col>
					<RevenueByTimeChart/>
				</Col>
			</Row>
		</div>
	)
}

export default DynamicPerformanceView
