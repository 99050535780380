import React from 'react'
import SummaryCardWrapper from '../../components/Dashboard/SummaryCardWrapper'
import SalesVolumeSummary from '../../components/Dashboard/Dynamic/SalesVolumeSummary'
import SalesValueSummary from '../../components/Dashboard/Dynamic/SalesValueSummary'
import OpportunityValueSummary from '../../components/Dashboard/Dynamic/OpportunityValueSummary'
import RevenueGeneratedSummary from '../../components/Dashboard/Dynamic/RevenueGeneratedSummary'
import RevenueValueSummary from '../../components/Dashboard/Dynamic/RevenueValueSummary'
import LiveTestCount from '../DynamicPricingAnalysis/components/LiveTestCount'
import {Col, Row} from 'reactstrap'
import FilterCardWrapper from '../../components/Dashboard/Dynamic/FilterCardWrapper'
import {DateFilters} from '../../components/Dashboard/DateFilters'
import {dateFromState, dateToState} from '../../state/filters'
import MemberFilter from '../DynamicPerformance/components/MemberFilter'
import CurrencyFilter from '../../components/Dashboard/Dynamic/CurrencyFilter'
import RiskTypeFilter from '../../components/Dashboard/Dynamic/RiskTypeFilter'
import RegionFilter from '../../components/Dashboard/Dynamic/RegionFilter'
import CountryFilter from '../DynamicPricingAnalysis/components/CountryFilter'
import MemberContentSummaryTable from './components/MemberContentSummaryTable'
import ContentPerformanceTable from './components/ContentPerformanceTable'

const DynamicContentView = () => {
	return (
		<div className="content">
			<div className="d-flex justify-content-between">
				<SummaryCardWrapper>
					<SalesVolumeSummary/>
				</SummaryCardWrapper>
				<SummaryCardWrapper className="px-1">
					<SalesValueSummary/>
				</SummaryCardWrapper>
				<SummaryCardWrapper className="px-1">
					<OpportunityValueSummary/>
				</SummaryCardWrapper>
				<SummaryCardWrapper className="px-1">
					<RevenueGeneratedSummary/>
				</SummaryCardWrapper>
				<SummaryCardWrapper className="px-1">
					<RevenueValueSummary/>
				</SummaryCardWrapper>
				<SummaryCardWrapper>
					<LiveTestCount/>
				</SummaryCardWrapper>
			</div>
			<div className="d-flex justify-content-between pt-4">
				<Col lg={2}>
					<FilterCardWrapper>
						<DateFilters fromState={dateFromState} toState={dateToState}/>
					</FilterCardWrapper>
				</Col>
				<FilterCardWrapper className="pr-2">
					<MemberFilter/>
				</FilterCardWrapper>
				<FilterCardWrapper className="pr-2">
					<CurrencyFilter/>
				</FilterCardWrapper>
				<FilterCardWrapper className="pr-2">
					<RiskTypeFilter/>
				</FilterCardWrapper>
				<FilterCardWrapper className="pr-2">
					<RegionFilter/>
				</FilterCardWrapper>
				<FilterCardWrapper>
					<CountryFilter/>
				</FilterCardWrapper>
			</div>
			<Row className="pt-4">
				<MemberContentSummaryTable/>
			</Row>
			<Row className="pt-4">
				<ContentPerformanceTable/>
			</Row>
		</div>
	)
}

export default DynamicContentView
