export default function useGetPercentageChartOptions() {
	return {
		interaction: {
			intersect: false,
		},
		scales: {
			y: {
				display: true,
				position: 'left',
			}
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: function (context: { dataset: { label: string }, parsed: { y: number | null }, }) {

						if (context.dataset.label && context.parsed.y !== null) {
							return `${context.dataset.label}: ${context.parsed.y}%`
						}

						return ''
					}
				}
			},
		},
		elements: {
			line: {
				tension: 0.3
			}
		},
	}
}
