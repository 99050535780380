import React, {memo} from 'react';
import {ValueCard} from '../../../../components/Card/ValueCard';
import useSummaryCardColor from "../../hooks/useSummaryCardColor";
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const TransactionsSummary = memo(() => {
  const {getTransactionsSummary} = useInsuranceApi();
  const {data} = useApiValue(insuranceProviderFilterState, getTransactionsSummary, 0)
  const color = useSummaryCardColor()

  return (
    <ValueCard
      title={`Transactions`}
      value={data}
      icon="icon-chart-bar-32"
      iconColor={color}
      tooltip={"Total number of transactions protected."}
    />
  );
});

export {TransactionsSummary}