import React from 'react'
import SelectFilterCard, {SelectFilterItem} from '../../../components/Dashboard/SelectFilter'

const CurrencyFilter = () => {
	const handleChange = (value: any) => {}

	return (
		<SelectFilterCard
			title="Currency"
			items={currencies}
			onChange={handleChange}
			icon=""
		/>
	)
}

export default CurrencyFilter

const currencies: SelectFilterItem[] = [
	{value: 1, label: 'GBP'},
	{value: 2, label: 'USD'}
]
