import React from 'react'
import {Line} from 'react-chartjs-2'
import {FixedHeightChartCard} from '../../../../components/Card/FixedHeightChartCard'
import {ChartHeightValue, tooltipList} from '../../../../utils/chart'
import {integrationFilterState, monthsInDateRangeState} from '../../../../state/integration'
import {useGetChartData} from '../../../../hooks/useGetChartDataSummary'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'
import {defaultChartData} from '../../../../types/integration'

const IntegrationsMrrChart = () => {
	const {getIntegrationMrrChartData} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getIntegrationMrrChartData, defaultChartData)
	const {chartOptions, getChartData} = useGetChartData(data, monthsInDateRangeState)

	return (
		<FixedHeightChartCard
			title={`Completed Integrations MRR`}
			titleClass="card-title-info"
			height={ChartHeightValue['small']}
			tooltip={tooltipList.IntegrationsMrr}
		>
			<Line
				data={getChartData()}
				options={chartOptions}
				className="main-chart-max-height"
			/>
		</FixedHeightChartCard>
	)
}

export {IntegrationsMrrChart}
