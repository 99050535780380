import {Outlet, useLocation} from 'react-router-dom'
import classNames from 'classnames'
import {NotificationWrapper} from '../components/Notifications/NotificationWrapper'
import {TokenManager} from '../components/Auth/TokenManager'
import {Sidebar} from '../components/Sidebar'
import {DashboardNavbar} from '../components/Navbar/DashboardNavbar'
import React, {useEffect} from 'react'

const Layout = () => {
	const location = useLocation()
	const hideScrollBar = location.pathname === '/live-performance'
	const isPulseDashboard = location.pathname.startsWith('/pulse')
	const showSideBar = !isPulseDashboard
	const showNavBar = !isPulseDashboard
	const showNotifications = !isPulseDashboard

	useEffect(() => {
		document.body.classList.toggle('dashboard-dark-mode', !isPulseDashboard)
		document.body.classList.toggle('dashboard-light-mode', isPulseDashboard)
	}, [isPulseDashboard])

	return (
		<div
			className={classNames('wrapper', {
				'hide-scroll': hideScrollBar
			})}
		>
			<TokenManager/>
			{showNotifications && (<NotificationWrapper/>)}
			{showSideBar && (<Sidebar/>)}
			<div className={classNames({
				'main-panel': !isPulseDashboard
			})}>
				{showNavBar && (<DashboardNavbar/>)}
				<Outlet/>
			</div>
		</div>
	)
}

export {Layout}
