import React from 'react'
import SecondaryValueCard from './Common/Cards/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {Percentage} from './Common/FormatElements'

const OverviewApprovedRefundConversionRateSummary = () => {
	const {getApprovedRefundConversionRate} = usePulseApi()
	const value = useApiValue(filterState, getApprovedRefundConversionRate, 0)

	return (
		<SecondaryValueCard
			value={<Percentage value={value.data}/>}
			header="Approved Refunds"
			tooltipText="Percentage of refund requests that were approved."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewApprovedRefundConversionRateSummary
