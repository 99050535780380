import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {signInRedirectCallback} from '../../services/user-service'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {authenticatedUserState} from '../../state/auth'
import {defaultRouteState} from '../../state/ui'
import {useApiCore} from '../../hooks/api'
import {dashboardPaths, Dashboards, Roles} from '../../types'

const SignInRedirect = () => {
	const navigate = useNavigate()
	const setUser = useSetRecoilState(authenticatedUserState)
	const defaultRoute = useRecoilValue(defaultRouteState)
	const {setUser: setApiUser} = useApiCore()
	const defaultPulseRoute = dashboardPaths[Dashboards.PulseOverview]

	useEffect(() => {
		signInRedirectCallback()
			.then(user => {
				setApiUser(user?.profile?.name ?? '')
				setUser(user)
				if (user.profile?.Roles.indexOf(Roles.PulseDashboardUser) > -1
					&& defaultRoute === '/home') {
					return navigate(defaultPulseRoute)
				}
				navigate(defaultRoute)
			})
			.catch(err => {
				console.log('sign in redirect failed', err)
			})
	}, [navigate, setApiUser, setUser, defaultRoute])

	return (
		<>
		</>
	)
}

export {SignInRedirect}
