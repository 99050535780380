import React, {useEffect, useState} from 'react'
import {TableColumn} from 'react-data-table-component'
import {Growth, MemberRow, MonthValue} from '../../../types/sales'
import classNames from 'classnames'
import {ValueStatusTableCell} from '../../../components/Table/ValueStatusTableCell'
import {ValueDirectionProps} from '../../../components/Card/ValueStatusCard'
import {formatCurrency} from '../../../utils/currency'
import {formatNumberWithoutSeparator} from '../../../utils/number'
import {useRecoilValue} from 'recoil'
import {salesFilterState, selectedCoreCurrencyState} from '../../../state/sales'
import {useSalesApi} from '../../../hooks/api/sales'
import {useApiValue} from '../../../hooks/api'

const defaultValueDirectionProps: ValueDirectionProps = {
	value: '0',
	direction: null,
	colour: null
}

const generateMonthColumn = (dataRow: MonthValue, index: number, currency: string) => ({
	name: dataRow.month,
	selector: (row: any) => row.dataRow.month,
	compact: true,
	minWidth: '10px',
	cell: (row: MemberRow) => <MonthRightBorder monthsData={row.months} index={index} currency={currency}/>
})

const MonthRightBorder = (props: { monthsData: MonthValue[], index: number, currency: string }) => {
	if (props.index === props.monthsData.length - 1) {
		return (
			<RightBorder
				cellValue={formatCurrency(props.currency, formatNumberWithoutSeparator(props.monthsData[props.index].value))}/>
		)
	}
	return (
		<>
			{formatCurrency(props.currency, formatNumberWithoutSeparator(props.monthsData[props.index]?.value ?? 0))}
		</>
	)
}

const RightBorder = (props: { cellValue: string | number }) => {
	return (
		<div className={classNames('w-75 d-flex align-items-center h-100')}
		     style={{marginRight: '10px', borderRight: '2px solid white'}}>
			<div className={'text-center'}>{props.cellValue}</div>
		</div>
	)
}

const generateGrowthColumn = (rowData: Growth, currency: string) => ({
	name:
		<div className={'pl-5 w-100 text-center'}>
			{rowData.label}
		</div>,
	selector: (row: any) => row.rowData.label,
	minWidth: '10px',
	cell: (row: MemberRow) => AvgColumn({
		growthData: row.growth.filter((x: Growth) => x.label === rowData.label),
		currency: currency
	})
})

const AvgColumn = (props: { growthData: Growth[], currency: string }) => {
	const values = props.growthData?.map(growth => getValueObject({
		value: growth.percent,
		isPositive: growth.isPositive
	}))
	const count = props.growthData.reduce((a, growth) => a + growth.count, 0)
	return (
		<div className={'d-flex flex-fill'}>
			<div className={'w-50 text-center border-right pr-1'}>
				{formatCurrency(props.currency, formatNumberWithoutSeparator(count))}
			</div>
			<div className={'pl-2 w-50 text-center'}>
				<ValueStatusTableCell
					values={values}
				/>
			</div>
		</div>
	)
}

const getValueObject = (row: any) => {
	if (row === null) {
		return defaultValueDirectionProps
	}

	const {value, isPositive} = row
	return {
		value: `${value} %`,
		direction: isPositive ? 'down' : 'up',
		colour: isPositive ? 'red' : 'green'
	} as ValueDirectionProps
}

export const useGetMemberTableData = () => {
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyState)
	const defaultColumns: TableColumn<any>[] = [
		{name: 'Member', selector: (row: any) => row.member, compact: true, minWidth: '10px'},
		{
			name: 'Day',
			selector: (row: any) => row.day,
			compact: true,
			minWidth: '10px',
			cell: (row: any) => formatCurrency(coreCurrencyState, formatNumberWithoutSeparator(row.day))
		},
		{
			name: 'MTD',
			selector: (row: any) => row.mtd,
			compact: true,
			minWidth: '10px',
			cell: (row: any) => formatCurrency(coreCurrencyState, formatNumberWithoutSeparator(row.mtd))
		},
		{
			name: 'YTD',
			selector: (row: any) => row.ytd,
			compact: true,
			minWidth: '10px',
			cell: (row: any) => <RightBorder
				cellValue={formatCurrency(coreCurrencyState, formatNumberWithoutSeparator(row.ytd))}/>
		}
	]

	const [columns, setColumns] = useState(defaultColumns)
	const {getMemberOverviewTable} = useSalesApi()
	const {data: {count, rows}} = useApiValue(salesFilterState, getMemberOverviewTable, {count: 0, rows: []})

	useEffect(() => {
		if (rows.length > 0) {
			const updatedColumns = [...defaultColumns]
			const monthsData = rows[0].months
			const growthData = rows[0].growth
			if (monthsData.length > 0) {
				for (let i = 0; i < monthsData.length; i++) {
					updatedColumns.push(generateMonthColumn(monthsData[i], i, coreCurrencyState))
				}
			}
			if (growthData.length > 0) {
				for (let i = 0; i < growthData.length; i++) {
					updatedColumns.push(generateGrowthColumn(growthData[i], coreCurrencyState))
				}
			}
			setColumns(updatedColumns)
		}
	}, [rows, coreCurrencyState])

	return {
		rows,
		columns,
		count
	}
}
