import {MainProduct} from '../../../../types'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {salesFilterState, selectedProductState} from '../../../../state/sales'
import React, {useEffect, useState} from 'react'
import {FilterEnum, IdEnum} from '../../../../types/sales'
import {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {useSalesApi} from '../../../../hooks/api/sales'
import {useApiValue} from '../../../../hooks/api'

export const ProductFilter = () => {

	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const {getProducts} = useSalesApi()
	const {data} = useApiValue(salesFilterState, getProducts, [])

	useEffect(() => {
		if (data) {
			const next = [
				{value: IdEnum.DefaultId, label: FilterEnum.DefaultFilterAll},
				...data.map((x: MainProduct) => ({
					value: x.id,
					label: x.productName
				}))
			]
			setMenuItems(next)
		}
	}, [data])

	return (
		<StatefulSelectFilterCard
			state={selectedProductState}
			title="Product"
			items={menuItems}
			showDefault={false}
		/>
	)
}
