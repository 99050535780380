import React, {useState, useEffect, useLayoutEffect, useRef, useCallback, memo, useMemo} from 'react'
import _ from 'underscore'
import {useTransition, animated, useSpring} from 'react-spring'
import {
    PerformanceCard,
    PerformanceCardValue
} from '../../../components/Card/PerformanceCard'
import {toPosition, toUsd} from '../../../utils/number'
import {useCountUp} from 'react-countup'
import classNames from 'classnames'
import {usePerformanceApi} from "../../../hooks/api/live-performance";
import {useStatelessApiValue} from "../../../hooks/api";

const SalesCardValue = ({title, value, newValue, compareTitle, compareValue}) => {
    const {countUp, update} = useCountUp({end: value, separator: ','})

    useEffect(() => {
        update(value)
    }, [value, update])

    const getValueIcon = useMemo(() => {
        if (!compareTitle || !compareValue || value === compareValue) {
            return null
        }

        return (
            <i className={classNames('tim-icons', {
                'icon-minimal-up': value > compareValue,
                'icon-minimal-down': value < compareValue,
                'up': value > compareValue,
                'down': value < compareValue,
            })}/>
        )
    }, [compareValue, value, compareTitle])

    return (
        <>
            <PerformanceCardValue
                title={title}
                value={`(${toUsd(newValue, 0)}) ${toUsd(countUp.toString(), 0)}`}
                valueIcon={getValueIcon}
            />
        </>
    )
}

const SalesCard = ({position, item, index, previousData, ...rest}) => {
    const {
        name, monthTotal, yearTotal, previousMonthTotal, monthTotalNew, yearTotalNew
    } = item

    const previous = previousData[index]?.name
    const hasChanged = previous && previous !== name
    const previousIndex = _.findIndex(previousData, x => x.name === name)
    const movedUp = hasChanged && index < previousIndex
    const movedDown = hasChanged && index > previousIndex

    const defaultColor = 'white'

    const props = useSpring({
        from: {color: defaultColor, zIndex: 1},
        to: async next => {
            let background = defaultColor

            if (movedUp) {
                background = '#09c202'
            }

            if (movedDown) {
                background = '#a60d02'
            }

            await next({color: background, zIndex: 100})
            await next({color: defaultColor, zIndex: 1})
        }
    })

    return (
        <PerformanceCard
            title={name.substring(0, name.indexOf('@'))}
            position={toPosition(position)}
            {...rest}
        >
            <animated.div style={props} className="animated-card">
                <div className="d-flex flex-column justify-content-center h-100">
                    <SalesCardValue
                        title="MTD"
                        value={monthTotal}
                        newValue={monthTotalNew}
                        compareTitle="Previous Month"
                        compareValue={previousMonthTotal}
                    />
                    <SalesCardValue title="YTD" value={yearTotal} newValue={yearTotalNew}/>
                </div>
            </animated.div>
        </PerformanceCard>
    )
}

const defaultCardWidth = 260

const SalesCards = memo(() => {
    const {getUserPerformanceData} = usePerformanceApi()
    const {data} = useStatelessApiValue(getUserPerformanceData, {})
    const [sortedData, setSortedData] = useState([])
    const [dataLength, setDataLength] = useState(0)
    const [cardWidth, setCardWidth] = useState(defaultCardWidth)
    const [containerNode, setContainerNode] = useState(null)
    const containerRef = useCallback(node => {
        if (node) {
            setContainerNode(node)
        }
    }, [])
    const previousDataRef = useRef([])

    useEffect(() => {
        previousDataRef.current = sortedData
    })

    const previousData = previousDataRef.current

    useEffect(() => {
        if (data.length) {
            const next = _.sortBy(data, x => {
                const {monthTotal} = x
                return monthTotal
            }).reverse()
            setSortedData(next)
            setDataLength(data.length)
        }
    }, [data])

    useLayoutEffect(() => {
        if (containerNode) {
            const updateCardWidth = () => {
                const width = containerNode.getBoundingClientRect().width

                setCardWidth(curr => {
                    const cardWidthTotal = dataLength * curr
                    const nextCardSpacing = (width - cardWidthTotal) / dataLength

                    let nextCardWidth = defaultCardWidth + nextCardSpacing

                    if (nextCardWidth < defaultCardWidth) {
                        nextCardWidth = defaultCardWidth
                    }

                    if (nextCardSpacing !== Infinity) {
                        return nextCardWidth
                    }

                    return curr
                })
            }

            if (dataLength > 0) {
                updateCardWidth()
            }

            window.addEventListener('resize', updateCardWidth)

            return () => {
                window.removeEventListener('resize', updateCardWidth)
            }
        }
    }, [dataLength, containerNode])

    const transitions = useTransition(
        sortedData.map((item, i) => ({...item, y: i * cardWidth, width: cardWidth})),
        d => d.name,
        {
            from: {width: 0},
            leave: {width: 0},
            enter: ({y, width}) => {
                return {
                    y,
                    width,
                    opacity: 1
                }
            },
            update: ({y, width}) => ({y, width}),
        }
    )

    return (
        <div className="d-flex px-4 sales-transition-container" ref={containerRef}>
            {
                transitions.map(({item, props: {y, ...rest}, key}, index) => {
                    return (
                        <animated.div
                            key={key}
                            className="card-wrapper"
                            style={{
                                zIndex: data.length - index,
                                transform: y.interpolate(y => `translate3d(${y}px, 0, 0)`),
                                ...rest
                            }}
                        >
                            <SalesCard
                                position={index + 1}
                                index={index}
                                previousData={previousData}
                                item={item}
                                className="card"
                            />
                        </animated.div>
                    )
                })
            }
        </div>
    )
})

export {SalesCards}
