import React, {useEffect, useState} from 'react'
import {Table} from 'reactstrap'
import {EnumMainProduct} from '../../Enums/MainProductEnum'
import {useRecoilValue} from 'recoil'
import {selectedMainProductIdStateMember} from '../../../../state/member-filters'
import {selectedCoreCurrencyStateMember} from '../../../../state/member-filters'
import {formatCurrency} from '../../../../utils/currency'
import useGetMapToggleStates from '../../../../hooks/useGetMapToggleStates'
import {continentLabels} from '../../../../types/region'

const RegionMapTable = ({regionData, region}) => {
    const {isEpSelected, isRpSelected} = useGetMapToggleStates(region)
    const mainProductFilterData = useRecoilValue(selectedMainProductIdStateMember)
    const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateMember)
    const [tableData, setTableData] = useState({
        transactions: {
            wtd: 0,
            mtd: 0,
            ytd: 0
        },
        value: {
            wtd: 0,
            mtd: 0,
            ytd: 0
        },
        cost: {
            wtd: 0,
            mtd: 0,
            ytd: 0
        }
    })

    useEffect(() => {
        if (!regionData) {
            return
        }

        const getCombinedData = () => {
            const getTransactions = () => {
                const getWtd = () => {
                    const {epTransactionCountInsuredWTD, rpTransactionCountInsuredWTD} = regionData
                    if (isEpSelected && isRpSelected) {
                        return epTransactionCountInsuredWTD + rpTransactionCountInsuredWTD
                    }

                    if (isEpSelected) {
                        return epTransactionCountInsuredWTD
                    }

                    if (isRpSelected) {
                        return rpTransactionCountInsuredWTD
                    }

                    return 0
                }

                const getMtd = () => {
                    const {epTransactionCountInsuredMTD, rpTransactionCountInsuredMTD} = regionData

                    if (isEpSelected && isRpSelected) {
                        return epTransactionCountInsuredMTD + rpTransactionCountInsuredMTD
                    }

                    if (isEpSelected) {
                        return epTransactionCountInsuredMTD
                    }

                    if (isRpSelected) {
                        return rpTransactionCountInsuredMTD
                    }

                    return 0
                }

                const getYtd = () => {
                    const {epTransactionCountInsuredYTD, rpTransactionCountInsuredYTD} = regionData

                    if (isEpSelected && isRpSelected) {
                        return epTransactionCountInsuredYTD + rpTransactionCountInsuredYTD
                    }

                    if (isEpSelected) {
                        return epTransactionCountInsuredYTD
                    }

                    if (isRpSelected) {
                        return rpTransactionCountInsuredYTD
                    }

                    return 0
                }

                const wtd = getWtd()
                const mtd = getMtd()
                const ytd = getYtd()

                return {
                    wtd,
                    mtd,
                    ytd
                }
            }

            const getValue = () => {
                const getWtd = () => {
                    const {epTransactionValueInsuredWTD, rpTransactionValueInsuredWTD} = regionData

                    if (isEpSelected && isRpSelected) {
                        return epTransactionValueInsuredWTD + rpTransactionValueInsuredWTD
                    }

                    if (isEpSelected) {
                        return epTransactionValueInsuredWTD
                    }

                    if (isRpSelected) {
                        return rpTransactionValueInsuredWTD
                    }

                    return 0
                }

                const getMtd = () => {
                    const {epTransactionValueInsuredMTD, rpTransactionValueInsuredMTD} = regionData

                    if (isEpSelected && isRpSelected) {
                        return epTransactionValueInsuredMTD + rpTransactionValueInsuredMTD
                    }

                    if (isEpSelected) {
                        return epTransactionValueInsuredMTD
                    }

                    if (isRpSelected) {
                        return rpTransactionValueInsuredMTD
                    }

                    return 0
                }

                const getYtd = () => {
                    const {epTransactionValueInsuredYTD, rpTransactionValueInsuredYTD} = regionData

                    if (isEpSelected && isRpSelected) {
                        return epTransactionValueInsuredYTD + rpTransactionValueInsuredYTD
                    }

                    if (isEpSelected) {
                        return epTransactionValueInsuredYTD
                    }

                    if (isRpSelected) {
                        return rpTransactionValueInsuredYTD
                    }

                    return 0
                }

                const wtd = formatCurrency(coreCurrencyState, getWtd())
                const mtd = formatCurrency(coreCurrencyState, getMtd())
                const ytd = formatCurrency(coreCurrencyState, getYtd())

                return {
                    wtd,
                    mtd,
                    ytd
                }
            }

            const getCost = () => {
                const getWtd = () => {
                    const {epTransactionProtectionValueWTD, rpTransactionProtectionValueWTD} = regionData

                    if (isEpSelected && isRpSelected) {
                        return epTransactionProtectionValueWTD + rpTransactionProtectionValueWTD
                    }

                    if (isEpSelected) {
                        return epTransactionProtectionValueWTD
                    }

                    if (isRpSelected) {
                        return rpTransactionProtectionValueWTD
                    }

                    return 0
                }

                const getMtd = () => {
                    const {epTransactionProtectionValueMTD, rpTransactionProtectionValueMTD} = regionData

                    if (isEpSelected && isRpSelected) {
                        return epTransactionProtectionValueMTD + rpTransactionProtectionValueMTD
                    }

                    if (isEpSelected) {
                        return epTransactionProtectionValueMTD
                    }

                    if (isRpSelected) {
                        return rpTransactionProtectionValueMTD
                    }

                    return 0
                }

                const getYtd = () => {
                    const {epTransactionProtectionValueYTD, rpTransactionProtectionValueYTD} = regionData

                    if (isEpSelected && isRpSelected) {
                        return rpTransactionProtectionValueYTD + rpTransactionProtectionValueYTD
                    }

                    if (isEpSelected) {
                        return epTransactionProtectionValueYTD
                    }

                    if (isRpSelected) {
                        return rpTransactionProtectionValueYTD
                    }

                    return 0
                }

                const wtd = formatCurrency(coreCurrencyState, getWtd())
                const mtd = formatCurrency(coreCurrencyState, getMtd())
                const ytd = formatCurrency(coreCurrencyState, getYtd())

                return {
                    wtd,
                    mtd,
                    ytd
                }
            }

            const transactions = getTransactions()
            const value = getValue()
            const cost = getCost()

            return {
                transactions,
                value,
                cost
            }
        }

        const getEpData = () => {
            const getTransactions = () => {
                const getWtd = () => {
                    const {epTransactionCountInsuredWTD} = regionData
                    return epTransactionCountInsuredWTD
                }
                const getMtd = () => {
                    const {epTransactionCountInsuredMTD} = regionData
                    return epTransactionCountInsuredMTD
                }
                const getYtd = () => {
                    const {epTransactionCountInsuredYTD} = regionData
                    return epTransactionCountInsuredYTD
                }

                const wtd = getWtd()
                const mtd = getMtd()
                const ytd = getYtd()

                return {
                    wtd,
                    mtd,
                    ytd
                }
            }

            const getValue = () => {
                const getWtd = () => {
                    const {epTransactionValueInsuredWTD} = regionData
                    return epTransactionValueInsuredWTD
                }
                const getMtd = () => {
                    const {epTransactionValueInsuredMTD} = regionData
                    return epTransactionValueInsuredMTD
                }
                const getYtd = () => {
                    const {epTransactionValueInsuredYTD} = regionData
                    return epTransactionValueInsuredYTD
                }

                const wtd = formatCurrency(coreCurrencyState, getWtd())
                const mtd = formatCurrency(coreCurrencyState, getMtd())
                const ytd = formatCurrency(coreCurrencyState, getYtd())

                return {
                    wtd,
                    mtd,
                    ytd
                }
            }

            const getCost = () => {
                const getWtd = () => {
                    const {epTransactionProtectionValueWTD} = regionData
                    return epTransactionProtectionValueWTD
                }
                const getMtd = () => {
                    const {epTransactionProtectionValueMTD} = regionData
                    return epTransactionProtectionValueMTD
                }
                const getYtd = () => {
                    const {epTransactionProtectionValueYTD} = regionData
                    return epTransactionProtectionValueYTD
                }

                const wtd = formatCurrency(coreCurrencyState, getWtd())
                const mtd = formatCurrency(coreCurrencyState, getMtd())
                const ytd = formatCurrency(coreCurrencyState, getYtd())

                return {
                    wtd,
                    mtd,
                    ytd
                }
            }

            const transactions = getTransactions()
            const value = getValue()
            const cost = getCost()

            return {
                transactions,
                value,
                cost
            }
        }

        const getRpData = () => {
            const getTransactions = () => {
                const getWtd = () => {
                    const {rpTransactionCountInsuredWTD} = regionData
                    return rpTransactionCountInsuredWTD
                }
                const getMtd = () => {
                    const {rpTransactionCountInsuredMTD} = regionData
                    return rpTransactionCountInsuredMTD
                }
                const getYtd = () => {
                    const {rpTransactionCountInsuredYTD} = regionData
                    return rpTransactionCountInsuredYTD
                }

                const wtd = getWtd()
                const mtd = getMtd()
                const ytd = getYtd()

                return {
                    wtd,
                    mtd,
                    ytd
                }
            }

            const getValue = () => {
                const getWtd = () => {
                    const {rpTransactionValueInsuredWTD} = regionData
                    return rpTransactionValueInsuredWTD
                }
                const getMtd = () => {
                    const {rpTransactionValueInsuredMTD} = regionData
                    return rpTransactionValueInsuredMTD
                }
                const getYtd = () => {
                    const {rpTransactionValueInsuredYTD} = regionData
                    return rpTransactionValueInsuredYTD
                }

                const wtd = formatCurrency(coreCurrencyState, getWtd())
                const mtd = formatCurrency(coreCurrencyState, getMtd())
                const ytd = formatCurrency(coreCurrencyState, getYtd())

                return {
                    wtd,
                    mtd,
                    ytd
                }
            }

            const getCost = () => {
                const getWtd = () => {
                    const {rpTransactionProtectionValueWTD} = regionData
                    return rpTransactionProtectionValueWTD
                }
                const getMtd = () => {
                    const {rpTransactionProtectionValueMTD} = regionData
                    return rpTransactionProtectionValueMTD
                }
                const getYtd = () => {
                    const {rpTransactionProtectionValueYTD} = regionData
                    return rpTransactionProtectionValueYTD
                }

                const wtd = getWtd()
                const mtd = getMtd()
                const ytd = getYtd()

                return {
                    wtd,
                    mtd,
                    ytd
                }
            }

            const transactions = getTransactions()
            const value = getValue()
            const cost = getCost()

            return {
                transactions,
                value,
                cost
            }
        }

        const dataFn = {
            [EnumMainProduct.All]: getCombinedData,
            [EnumMainProduct.EventProtect]: getEpData,
            [EnumMainProduct.RefundProtect]: getRpData
        }

        const next = dataFn[mainProductFilterData]()
        setTableData(next)

    }, [mainProductFilterData, regionData, coreCurrencyState, isEpSelected, isRpSelected])

    return (
        <Table className={'custom-table-hover'}>
            <thead>
            <tr>
                <th><h6>{continentLabels[regionData.regionName]}</h6></th>
                <th>WTD</th>
                <th>MTD</th>
                <th>YTD</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row">Transactions</th>
                {
                    Object.values(tableData.transactions)?.map((value, i) => (
                        <td key={`transactions-${region}-${i}`}>{value}</td>
                    ))
                }
            </tr>
            <tr>
                <th scope="row">Value</th>
                {
                    Object.values(tableData.value)?.map((value, i) => (
                        <td key={`value-${region}-${i}`}>{value}</td>
                    ))
                }
            </tr>
            <tr>
                <th scope="row">Protection Cost</th>
                {
                    Object.values(tableData.cost)?.map((value, i) => (
                        <td key={`protection-cost-${region}-${i}`}>{value}</td>
                    ))
                }
            </tr>
            </tbody>
        </Table>
    )
}

export default RegionMapTable
