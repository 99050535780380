import {useApiActions} from './index'
import {Region} from '../../types'
import {CountryRevenue} from '../../types/live-performance'

export const usePerformanceApi = () => {
	const {callApi} = useApiActions('performance')

	const getAverageMemberRevenue = async (): Promise<number> => {
		return await callApi<number, any>({
			path: 'average-revenue'
		})
	}

	const getMemberMarkers = async (): Promise<any> => {
		return await callApi<any, any>({
			path: 'members'
		})
	}

	const getPerformanceMapData = async (): Promise<CountryRevenue[]> => {
		return await callApi<CountryRevenue[], any>({
			path: 'map'
		})
	}

	const getPerformanceRegions = async (): Promise<Region[]> => {
		return await callApi<Region[], any>({
			path: 'regions'
		})
	}

	const getRegionPerformanceData = async (): Promise<any> => {
		return await callApi<any, any>({
			path: 'region'
		})
	}

	const getTransactingMemberCount = async (): Promise<number> => {
		return await callApi<number, any>({
			path: 'transacting-member-count'
		})
	}

	const getUserPerformanceData = async (): Promise<any> => {
		return await callApi<any, any>({
			path: 'user'
		})
	}

	return {
		getAverageMemberRevenue,
		getMemberMarkers,
		getPerformanceMapData,
		getPerformanceRegions,
		getRegionPerformanceData,
		getTransactingMemberCount,
		getUserPerformanceData
	}
}
