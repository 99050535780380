import React from 'react'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {selectedCoreCurrencyStateInsurance} from "../../../../state/insurance-provider-filters"
import {coreCurrencyDropdownData} from "../../../../utils/data";

const menuItems = [
	...coreCurrencyDropdownData.map((x) => ({
		value: x.value,
		label: x.value
	}))
]

const CoreCurrencyDropdown = () => {
	return (
		<StatefulSelectFilterCard
			title="Core Currency"
			items={menuItems}
			state={selectedCoreCurrencyStateInsurance}
			showDefault={false}
		/>
	)
}

export {CoreCurrencyDropdown}