import useGetMainProductStates from './useGetMainProductStates'
import {useEffect, useState} from 'react'

export default function useSummaryCardColor() {
	const [color, setColor] = useState<string>('dark')
	const { isProductRp, isProductEp } = useGetMainProductStates()
	
	useEffect(() => {
		let next = 'dark'
		
		if (isProductRp) {
			next = 'rp'
		}
		
		if (isProductEp) {
			next = 'ep'
		}
		
		setColor(next)
	}, [isProductEp, isProductRp])
	
	return color
}
