import React, {useEffect, useState} from 'react'
import SelectFilterCard, {SelectFilterItem} from '../../../../components/Dashboard/SelectFilter'
import {selectedEventIdsStateInsurance, selectedEventStateInsurance} from "../../../../state/insurance-provider-filters"
import {FilterEnum} from "../../../../types/insurance-provider";
import {insuranceProviderFilterState} from "../../../../state/insurance-provider";
import {useRecoilState} from "recoil";
import {useInsuranceApi} from "../../../../hooks/api/insurance";
import {useApiValue} from "../../../../hooks/api";

const EventDropdown = () => {
	const {getEvents} = useInsuranceApi();
	const {data, isLoading} = useApiValue(insuranceProviderFilterState, getEvents, [])
	const [menuItems, setMenuItems] = useState<SelectFilterItem[]>([])
	const [eventsData, setEventsData] = useState([])
	const [stateValue, setStateValue] = useRecoilState(selectedEventStateInsurance)
	const [eventIds, setEventIds] = useRecoilState(selectedEventIdsStateInsurance)
	
	const handleChange = (item: SelectFilterItem) => {
		setStateValue(item.value);
		
		const next = item.value === FilterEnum.DefaultFilterAll
			? []
			: eventsData
				.filter((x:any) => x.eventName === item.value)
				.map((xx: any) => xx.eventIds)
		
		setEventIds(next)
	}
	
	useEffect(() => {
		if (data && !isLoading) {
			setEventsData(data)
			const next = [
				{
					value: FilterEnum.DefaultFilterAll,
					label: FilterEnum.DefaultFilterAll
				},
				...data.map((x: any) => ({
					value: x.eventName,
					label: x.eventName
				}))
			]
			setMenuItems(next)
		}
	}, [data,isLoading])
	
	return (
		<SelectFilterCard
			title="Event"
			items={menuItems}
			showDefault={false}
			value={stateValue}
			onChange={handleChange}
		/>
	)
}

export {EventDropdown}