import React from 'react'
import StatefulSelectFilterCard from '../../../../components/Dashboard/StatefulSelectFilterCard'
import {FilterEnum} from '../../../../types/sales'
import {salesFilterState, selectedTerritoryState} from '../../../../state/sales'
import {DropdownData} from '../../../../types'
import {useSalesApi} from '../../../../hooks/api/sales'
import {useApiValue} from '../../../../hooks/api'

export const TerritoryFilter = () => {
	const {getTerritories} = useSalesApi()
	const {data} = useApiValue(salesFilterState, getTerritories, [])

	const menuItems = [
		{value: FilterEnum.DefaultFilterAll, label: FilterEnum.DefaultFilterAll},
		...data.map((x: DropdownData) => ({
			value: x.name,
			label: x.name
		}))
	]

	return (
		<StatefulSelectFilterCard
			state={selectedTerritoryState}
			title="Territory"
			items={menuItems}
			showDefault={false}
		/>
	)
}
