import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyStateMember} from '../../../state/member-filters'
import {formatCurrency} from '../../../utils/currency'

export default function useGetCurrencyChartOptions() {
	const coreCurrencyState = useRecoilValue(selectedCoreCurrencyStateMember)

	return {
		spanGaps: true,
		interaction: {
			mode: 'index',
			intersect: false,
		},
		scales: {
			y: {
				display: true,
				position: 'left',
				ticks: {
					callback: (label: any) => {
						return formatCurrency(coreCurrencyState, label)
					}
				}
			}
		},
		plugins: {
			tooltip: {
				borderColor: 'transparent',
				callbacks: {
					label: function (context: { dataset: { label: string; }; parsed: { y: number | null; }; }) {
						let label = context.dataset.label || ''

						if (label) {
							label += ': '
						}

						if (context.parsed.y !== null) {
							label += formatCurrency(coreCurrencyState, context.parsed.y)
						}
						return label
					}
				}
			},
		},
		elements: {
			line: {
				tension: 0.3
			}
		},
	}
}
