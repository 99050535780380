import React, {useEffect, useState} from 'react'
import {ValueDirectionProps, ValueStatusCard} from '../../../components/Card/ValueStatusCard'
import {refundFilterState, ValueStatus} from '../../../types/refund'
import {useRefundApi} from '../../../hooks/api/refund'
import {useApiValue} from '../../../hooks/api'

export const OutboundEmailSummary = () => {
	const [avgMtdOutboundEmailCount, setAvgMtdOutboundEmailCount] = useState<ValueDirectionProps[]>([])
	const {getOutboundEmailCount} = useRefundApi()
	const {data, isLoading} = useApiValue(refundFilterState, getOutboundEmailCount, {} as ValueStatus)

	useEffect(() => {
		if (data && !isLoading) {
			const {value} = data
			const next: ValueDirectionProps = {
				value: (`${value}`),
				direction: null,
				colour: null
			}
			setAvgMtdOutboundEmailCount([next])
		}
	}, [data, isLoading])

	return (
		<>
			<ValueStatusCard
				title={'Outbound Email'}
				values={avgMtdOutboundEmailCount}
				subTitle="MTD"
			/>
		</>
	)
}