import {ValueCard} from '../../../components/Card/ValueCard'
import React from 'react'
import {dynamicPricingFilterState} from '../../../state/dynamic-pricing'
import {useDynamicPricingApi} from "../../../hooks/api/dynamic-pricing";
import {useApiValue} from "../../../hooks/api";

const LiveTestCount = () => {
	const {getLiveTestCount} = useDynamicPricingApi();
	const {data} = useApiValue(dynamicPricingFilterState, getLiveTestCount, 0)

	return (
		<ValueCard
			title="No. Of Live Tests"
			value={data}
			icon="icon-sound-wave"
			iconColor="rp"
		/>
	)
}

export default LiveTestCount
