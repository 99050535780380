import {ValueCard} from '../../Card/ValueCard'
import React from 'react'
import {filterState} from '../../../state/dynamic-performance'
import {toUsd} from '../../../utils/number'
import {useDynamicPerformanceApi} from '../../../hooks/api/dynamic'
import {useApiValue} from '../../../hooks/api'

const SalesValueSummary = () => {
	const {getSalesValueSummary} = useDynamicPerformanceApi()
	const {data} = useApiValue(filterState, getSalesValueSummary, 0)

	return (
		<ValueCard
			title="Sales Value"
			value={toUsd(data.toString(), 0)}
			icon="icon-money-coins"
			iconColor="rp"
		/>
	);
}

export default SalesValueSummary
